import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as DeleteIcon } from '@ecp/common/src/assets/icon/icon__delete.svg';

import SearchKeywordHistoryTabOnOff from '@fo-pages/home/gnb/search/SearchKeywordHistoryTabOnOff';

const ListTab = ({ list, handleSearch, handleDelete }) => (
  <ListTabBox data-ds-label2={'g_rece_search_list'}>
    {list?.map(({ keyword }, index) => (
      <FlexBox key={index} width={'100%'}>
        <TextBox clickable hoverColor={theme.color.text.selected} ellipsis onClick={() => handleSearch(keyword)}>
          {keyword}
        </TextBox>
        <Spacing right={10} />
        <DeleteButton onClick={() => handleDelete(keyword)} />
      </FlexBox>
    ))}
  </ListTabBox>
);

const ListTabBox = styled(FlexBoxColumn)`
  width: 100%;
  min-height: 153px;
  align-items: flex-start;
  padding: 30px 0 20px;
  gap: 10px;
`;

const DeleteButton = styled(DeleteIcon)`
  width: 10px;
  cursor: pointer;
`;

ListTab.propTypes = {
  list: PropTypes.array,
  handleSearch: PropTypes.func,
  handleDelete: PropTypes.func,
};

const EmptyTab = ({ text }) => (
  <EmptyTabBox>
    <TextBox size={theme.font.size.table} color={theme.color.text.guide}>
      {text}
    </TextBox>
  </EmptyTabBox>
);

const EmptyTabBox = styled(FlexBox)`
  width: 100%;
  height: 153px;
  justify-content: center;
`;

EmptyTab.propTypes = {
  text: PropTypes.string,
};

const SearchKeywordHistoryTab = ({
  keywordList,
  isOn,
  onSearch,
  handleDelete,
  handleDeleteAll,
  handleHistoryOnOff,
}) => {
  const isEmpty = useMemo(() => {
    return isOn && keywordList?.length === 0;
  }, [isOn, keywordList?.length]);

  const showList = useMemo(() => {
    return isOn && keywordList?.length > 0;
  }, [isOn, keywordList?.length]);

  const mappedKeywordList = useMemo(() => {
    return keywordList?.slice(0, 10);
  }, [keywordList]);

  return (
    <FlexBoxColumn width={'440px'}>
      {!isOn && <EmptyTab text={'검색어 저장 기능이 꺼져 있습니다.'} />}
      {isEmpty && <EmptyTab text={'최근 저장된 검색어가 없습니다.'} />}
      {showList && <ListTab list={mappedKeywordList} handleSearch={onSearch} handleDelete={handleDelete} />}
      <Bottom>
        {showList && (
          <TextBox
            width={'100%'}
            size={theme.font.size.small}
            decoration={'underline'}
            hoverDecoration={'underline'}
            color={theme.color.text.sub}
            cursor={'pointer'}
            onClick={handleDeleteAll}
          >
            전체 삭제
          </TextBox>
        )}
        <SearchKeywordHistoryTabOnOff isOn={isOn} handleClick={handleHistoryOnOff} />
      </Bottom>
      <Spacing top={10} />
    </FlexBoxColumn>
  );
};

const Bottom = styled(FlexBoxSpaceBetween)`
  width: 100%;
  height: 20px;
`;

export default SearchKeywordHistoryTab;

SearchKeywordHistoryTab.propTypes = {
  keywordList: PropTypes.array,
  isOn: PropTypes.bool,
  onSearch: PropTypes.func,
  handleDelete: PropTypes.func,
  handleDeleteAll: PropTypes.func,
  handleHistoryOnOff: PropTypes.func,
};
