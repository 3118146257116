import React from 'react';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';

import { ReactComponent as NoResultIcon } from '@fo-assets/icon/icon__search-noresult.svg';

const BrandNoResult = () => {
  return (
    <FlexBoxColumn width="100%" justify-content="center">
      <Spacing top="100px" />
      <NoResultIcon />
      <Spacing top="30px" />
      <TextBox size="21px" weight="500">
        찾으시는 브랜드가 없습니다.
      </TextBox>
      <Spacing top="14px" />
      <TextBox size="15px" color="#727272">
        브랜드명을 다시 입력하시거나 철자와 띄어쓰기를 확인해보세요.
      </TextBox>
      <Spacing bottom="40px" />
    </FlexBoxColumn>
  );
};

export default BrandNoResult;
