import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import WpayMemberRegForm from '@ecp/common/src/components/interface/WpayMemberRegForm';

const WpayMemberRegComp = ({ wpayApi }) => {
  const { state } = useLocation();
  const [memData, setMemData] = useState({});
  const [getMemParam, setGetMemParam] = useState(false);

  useEffect(() => {
    setGetMemParam(true);
  }, []);

  useEffect(() => {
    if (!getMemParam) return;
    wpayApi.getMemReqParams(state ? state.oid : '').then((result) => {
      setMemData({
        mid: result.mid,
        userId: result.userIdEnc,
        ci: result.ciEnc || '',
        userNm: result.userNm || '',
        hNum: result.hanNumEnc || '',
        hCorp: result.hCorp || '',
        birthDay: result.birthDayEnc || '',
        socialNo2: result.socialNo2 || '',
        frnrYn: result.frnrYn || '',
        returnUrl: result.returnUrl,
        signature: result.signature,
      });
    });
  }, [getMemParam, state, wpayApi]);

  return <WpayMemberRegForm memData={memData} />;
};

export default WpayMemberRegComp;

WpayMemberRegComp.propTypes = {
  wpayApi: PropTypes.object,
};
