import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useStaticImageHostname } from '@ecp/common/src/hooks/interface/useHostname';

const WpayPaymentAuthForm = ({ paymentAuthData }) => {
  const paymentAuthForm = useRef(null);
  const hostname = useStaticImageHostname();

  useEffect(() => {
    if (paymentAuthData.mid) {
      paymentAuthForm.current.submit();
    }
  }, [paymentAuthData]);

  return (
    <div style={{ display: 'none' }}>
      <form
        name="form_postsubmit_pay_approval"
        id="form_postsubmit_pay_approval"
        action={`${process.env.REACT_APP_INICIS_WPAY_HOST}/stdwpay/su/payreqauth`}
        method="post"
        ref={paymentAuthForm}
      >
        <input type="hidden" name="mid" value={paymentAuthData.mid} />
        <input type="hidden" name="wpayUserKey" value={paymentAuthData.wpayUserKeyEnc} />
        <input type="hidden" name="ci" value={paymentAuthData.ciEnc || ''} />
        <input type="hidden" name="oid" value={paymentAuthData.oid} />
        <input type="hidden" name="goodsName" value={paymentAuthData.goodsNameEncoded} />
        <input type="hidden" name="goodsPrice" value={paymentAuthData.goodsPrice} />
        <input type="hidden" name="buyerName" value={paymentAuthData.buyerNameEncoded} />
        <input type="hidden" name="buyerTel" value={paymentAuthData.buyerTel} />
        <input type="hidden" name="buyerEmail" value={paymentAuthData.buyerEmail || ''} />
        <input type="hidden" name="flagPin" value={paymentAuthData.flagPin} />
        <input type="hidden" name="returnUrl" value={paymentAuthData.returnUrlEncoded} />
        <input type="hidden" name="signature" value={paymentAuthData.signature} />

        <input type="hidden" name="titleBarColor" id="titleBarColor" value="#eeeeee" />
        <input
          type="hidden"
          name="tiltleBarBiImgUrl"
          value={`${hostname}/contents/static/images/welstorypay_logo.png`}
        />
        <input
          type="hidden"
          name="content"
          id="content"
          value="가맹점에서 간편하게 클릭한번으로<br>구매 가능한 결제서비스 입니다."
        />
        <input type="hidden" name="authBtnColor" id="authBtnColor" value="#333333" />
        <input type="hidden" name="authBtnTextcolor" id="authBtnTextcolor" value="#ffffff" />
        <input type="hidden" name="closeBtnType" id="closeBtnType" value="A" />
      </form>
    </div>
  );
};

export default WpayPaymentAuthForm;

WpayPaymentAuthForm.propTypes = {
  paymentAuthData: PropTypes.object,
};
WpayPaymentAuthForm.displayName = 'WpayPaymentAuthForm';
