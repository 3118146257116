import { useMemo } from 'react';

import useControlled from '../../../hooks/useControlled';

const usePagination = ({
  page,
  totalCount,
  defaultPage,
  countPerPage,
  numberOfPageGroup,
  onPageChange,
  hideUnusableButtons,
  hideFirstButton,
  hidePrevButton,
  hideNextButton,
  hideLastButton,
}) => {
  const numberOfTotalPage = Math.trunc(totalCount / countPerPage) + (totalCount % countPerPage > 0 ? 1 : 0);

  const { value: currentPage, setValueWhenUncontrolled } = useControlled({
    value: page ? Math.max(Math.min(numberOfTotalPage, page), 1) : 1,
    defaultValue: defaultPage,
  });

  const startPage = Math.trunc((currentPage - 1) / numberOfPageGroup) * numberOfPageGroup + 1;

  const endPage = Math.min(startPage + numberOfPageGroup - 1, numberOfTotalPage);

  const pages = Array(endPage - startPage + 1)
    .fill(0)
    .map((key, index) => index + startPage);

  const setCurrentPage = (page) => {
    const value = Math.max(Math.min(numberOfTotalPage, page), 1);

    setValueWhenUncontrolled(value);

    onPageChange?.(value);
  };

  const { hideFirst, hidePrev, hideNext, hideLast } = useMemo(() => {
    let hideFirst = false;
    let hidePrev = false;
    let hideNext = false;
    let hideLast = false;
    if (hideUnusableButtons) {
      hideFirst = totalCount <= countPerPage;
      hidePrev = totalCount <= countPerPage;
      hideNext = totalCount <= countPerPage;
      hideLast = totalCount <= countPerPage;
    }
    const isFirstPage = page === 1;
    const isLastPage = page === numberOfTotalPage;
    return {
      hideFirst: hideFirstButton || hideFirst || isFirstPage,
      hidePrev: hidePrevButton || hidePrev,
      hideNext: hideNextButton || hideNext,
      hideLast: hideLastButton || hideLast || isLastPage,
    };
  }, [
    page,
    countPerPage,
    hideUnusableButtons,
    hideFirstButton,
    hideLastButton,
    hideNextButton,
    hidePrevButton,
    totalCount,
  ]);

  return {
    numberOfTotalPage,
    currentPage,
    setCurrentPage,
    pages,
    goNextPageGroup: () => {
      setCurrentPage(
        Math.min(numberOfTotalPage, (Math.trunc((currentPage - 1) / numberOfPageGroup) + 1) * numberOfPageGroup + 1)
      );
    },
    goPrevPageGroup: () =>
      setCurrentPage(Math.max(1, Math.trunc((currentPage - 1) / numberOfPageGroup) * numberOfPageGroup)),
    goNextPage: () => setCurrentPage(Math.min(numberOfTotalPage, currentPage + 1)),
    goPrevPage: () => setCurrentPage(Math.max(1, currentPage - 1)),
    goFirstPage: () => setCurrentPage(1),
    goLastPage: () => setCurrentPage(numberOfTotalPage),
    hideFirst,
    hidePrev,
    hideNext,
    hideLast,
  };
};

export default usePagination;
