import React from 'react';
import PropTypes from 'prop-types';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

const RecommendKeyword = ({ recommendKeywords, onSearch }) => (
  <FlexBoxColumn width={'100%'} align-items={'flex-start'}>
    <HorizontalDivider />
    <Spacing top={20} />
    <TextBox size={theme.font.size.title} weight={theme.font.weight.medium}>
      추천 검색어
    </TextBox>
    <Spacing top={20} />
    {!!recommendKeywords?.length && (
      <FlexBox
        gap={'10px 18px'}
        width={'100%'}
        flex-wrap={'wrap'}
        padding={'14px 20px'}
        data-ds-label2={'g_reco_search'}
        background-color={theme.color.background.basic}
      >
        {recommendKeywords?.map((keyword, index) => (
          <TextBox
            ellipsis
            clickable
            key={`recommend-item__${index}`}
            size={theme.font.size.small}
            height={'19px'}
            hoverColor={theme.color.text.selected}
            style={{ maxWidth: '120px' }}
            onClick={() => onSearch(keyword)}
          >
            {keyword}
          </TextBox>
        ))}
      </FlexBox>
    )}
  </FlexBoxColumn>
);

RecommendKeyword.propTypes = {
  recommendKeywords: PropTypes.array,
  onSearch: PropTypes.func,
};

export default RecommendKeyword;
