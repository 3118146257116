import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { overrideProperties } from '../style/utils';
import { paddingPropTypes, sizePropTypes } from '../style/propTypes';

const StyledSpacing = styled.div`
  ${overrideProperties(paddingPropTypes, {
    'padding-top': 0,
    'padding-right': 0,
    'padding-bottom': 0,
    'padding-left': 0,
  })}
  ${overrideProperties(sizePropTypes, {
    width: 'auto',
    height: 'auto',
  })}
  margin: ${({ margin }) => margin};
`;

export const Spacing = ({ value, top, right, bottom, left, margin, children, ...props }) => (
  <StyledSpacing
    padding={value}
    padding-top={top}
    padding-right={right}
    padding-bottom={bottom}
    padding-left={left}
    margin={margin}
    {...props}
  >
    {children}
  </StyledSpacing>
);

Spacing.displayName = 'Spacing';

Spacing.propTypes = {
  value: PropTypes.string,
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margin: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
};

Spacing.defaultProps = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  margin: undefined,
};
