import React from 'react';
import styled from 'styled-components';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as ServerErrorIcon } from '@ecp/common/src/assets/icon/icon__error-server.svg';

const ServerError = () => {
  return (
    <Container>
      <ServerErrorIcon />
      <Spacing top={20} />
      <TextBox size={'28px'} weight={'500'} height={'24px'}>
        이 서비스와 연결할 수 없습니다.
      </TextBox>
      <Spacing top={10} />
      <TextBox size={'14px'} weight={'350'} height={'22.4px'} style={{ textAlign: 'center' }}>
        문제 해결을 위해 노력하고 있습니다. 잠시 후 다시 확인해 주세요. <br />
        페이지 오류가 계속 발생할 경우 아래 연락처로 문의 부탁 드립니다.
      </TextBox>
      <Spacing top={50} />
      <MessageWrapper>
        <TextBox size={'15px'} weight={'500'} height={'24px'}>
          • 고객센터
        </TextBox>
        <TextBox size={'15px'} weight={'350'} height={'24px'} color={'#5A5A5A'}>
          080-288-5252 / welmall.sei@samsung.com
        </TextBox>
      </MessageWrapper>
    </Container>
  );
};

export default ServerError;

const Container = styled(FlexBoxColumn)`
  justify-content: center;
`;

const MessageWrapper = styled(FlexBox)`
  width: 494px;
  height: 84px;
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 30px 50px;
  box-sizing: border-box;
  gap: 35px;
`;
