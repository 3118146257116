import { useCallback, useEffect, useState } from 'react';

export const SSO_STATUS = {
  UNCONNECTED: 'unconnected',
  READY: 'ready',
  SUCCESS: 'success',
  FAIL: 'fail',
  CLOSED: 'closed',
};

export const useSso = ({ loginApi, infoApi }) => {
  const [ws, setWs] = useState({});
  const [resultData, setResultData] = useState();
  const [socketResponseData, setSocketResponseData] = useState({ key: '', result: '', userInfo: '' });
  const [status, setStatus] = useState(SSO_STATUS.UNCONNECTED);

  const sendCallback = useCallback(async ({ data: resp }, onSuccess) => {
    const { rpcode, data } = JSON.parse(resp);

    if (rpcode === 'RETURN_SUCCESS') {
      const { key, result, userInfo } = JSON.parse(data);
      if (result === 'success') {
        if (onSuccess) {
          const { data: resultData } = await onSuccess({ key, result, userInfo });
          setResultData(resultData);
        }
        setSocketResponseData({ key, result, userInfo });
        setStatus(SSO_STATUS.SUCCESS);
        // ws.close();
        console.log("[success] EP tray resultData ::: " + resultData )
        console.log("[success] SSO_STATUS ::: " + status )
        return;
      }
    }

    console.log("[fail] EP tray resultData ::: " + resultData )
    console.log("[fail] SSO_STATUS ::: " + status )

    if (rpcode === 'EMPTY_BOX') {
      // SSO 없는 상태 (Knox 미로그인)
    }

    setStatus(SSO_STATUS.FAIL);
  }, []);

  useEffect(() => {
    if (status === SSO_STATUS.SUCCESS || status === SSO_STATUS.FAIL) {
      ws?.close && ws.close();
    }
  }, [ws, status]);

  const loginCallback = useCallback(
    async (data) => {
      await sendCallback(data, loginApi);
    },
    [loginApi, sendCallback]
  );

  const getKnoxInfoCallback = useCallback(
    async (data) => {
      await sendCallback(data, infoApi);
    },
    [infoApi, sendCallback]
  );

  const getOnlySocketResponseCallback = useCallback(
    async (data) => {
      await sendCallback(data, undefined);
    },
    [sendCallback]
  );

  const send = useCallback(
    (onMessageCallback) => {
      // open
      if (ws.readyState === 1) {
        ws.onmessage = onMessageCallback;
        ws.send(`{"rqtype":"getknoxsso","token":"","data":"${process.env.REACT_APP_KNOX_EP_TRAY_ID}"}`);
        return;
      }
      // closing or closed
      if (ws.readyState === 2 || ws.readyState === 3) {
        setStatus(SSO_STATUS.FAIL);
      }
      // connecting
      if (ws.readyState === 0) {
        setStatus(SSO_STATUS.UNCONNECTED);
      }
    },
    [ws]
  );

  const connect = useCallback(() => {
    const socket = new WebSocket(process.env.REACT_APP_KNOX_EP_TRAY_URL);

    socket.onopen = () => {
      setStatus(SSO_STATUS.READY);
    };
    socket.onerror = () => {
      setStatus(SSO_STATUS.FAIL);
    };
    socket.onclose = ({ wasClean }) => {
      if (!wasClean) {
        setStatus(SSO_STATUS.FAIL);
      } else {
        setStatus(SSO_STATUS.CLOSED);
      }
    };

    setWs(socket);
  }, []);

  const ssoLogin = useCallback(() => {
    send(loginCallback);
  }, [send, loginCallback]);

  const getKnoxInfo = useCallback(() => {
    send(getKnoxInfoCallback);
  }, [send, getKnoxInfoCallback]);

  const getOnlySocketResponse = useCallback(() => {
    send(getOnlySocketResponseCallback);
  }, [send, getOnlySocketResponseCallback]);

  return { connect, ssoLogin, getKnoxInfo, getOnlySocketResponse, status, resultData, socketResponseData };
};
