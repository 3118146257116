import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { zIndex } from '@ecp/common/src/style/constant';

const EventEndDimPopupWrap = ({ text }) => {
  return <EventEndDimPopupWrapBox>{text}</EventEndDimPopupWrapBox>;
};

EventEndDimPopupWrap.propTypes = {
  text: PropTypes.string,
};
export default EventEndDimPopupWrap;

const EventEndDimPopupWrapBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: ${zIndex.dimWrapper};
  border-radius: 8px;
  white-space: pre-line;
  text-align: center;
`;
