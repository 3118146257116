import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { FloatingTree } from '@floating-ui/react';

import '@ecp/common/src/assets/css/default.css';

import RecoilExternalStatePortal from '@fo-recoil/RecoilExternalStatePortal';
import ToastProvider from '@fo-components/common/provider/ToastProvider';
import SiteInformationProvider from '@fo-components/common/provider/SiteInformationProvider';
import LoadingSpinner from '@fo-components/common/LoadingSpinner';
import SuspenseSpinner from '@fo-components/common/SuspenseSpinner';
import InvisibleLoading from '@fo-components/common/InvisibleLoading';
import routes from './routes/Routes';
import './App.css';
import useNetfunnelComplete from '@ecp/common/src/hooks/useNetfunnelComplete';

function App() {

  useNetfunnelComplete();  

  return (
    <React.StrictMode>
      <RecoilRoot>
        <SiteInformationProvider />
        <FloatingTree>
          <ToastProvider />
          <DndProvider backend={HTML5Backend}>
            <Suspense
              fallback={<SuspenseSpinner height={'100vh'} message={'페이지를 열고 있어요\n조금만 기다려주세요'} />}
            >
              <RouterProvider router={routes}></RouterProvider>
            </Suspense>
          </DndProvider>
        </FloatingTree>
        <InvisibleLoading />
        <LoadingSpinner />
        <RecoilExternalStatePortal />
      </RecoilRoot>
    </React.StrictMode>
  );
}

export default App;
