import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MembershipSilver from '@ecp/common/src/assets/image/mypage/image__membership-silver--main.svg';
import MembershipGold from '@ecp/common/src/assets/image/mypage/image__membership-gold--main.svg';
import MembershipPlatinum from '@ecp/common/src/assets/image/mypage/image__membership-platinum--main.svg';
import MembershipSvip from '@ecp/common/src/assets/image/mypage/image__membership-svip--main.svg';
import MembershipSilverSmall from '@ecp/common/src/assets/image/mypage/image__membership-silver--front.svg';
import MembershipGoldSmall from '@ecp/common/src/assets/image/mypage/image__membership-gold--front.svg';
import MembershipPlatinumSmall from '@ecp/common/src/assets/image/mypage/image__membership-platinum--front.svg';
import MembershipSvipSmall from '@ecp/common/src/assets/image/mypage/image__membership-svip--front.svg';

const IMAGE_MEMBERSHIP_BADGE_LARGE = {
  SILVER: MembershipSilver,
  GOLD: MembershipGold,
  PLATINUM: MembershipPlatinum,
  SVIP: MembershipSvip,
  DEFAULT: '',
};

const IMAGE_MEMBERSHIP_BADGE_SMALL = {
  SILVER: MembershipSilverSmall,
  GOLD: MembershipGoldSmall,
  PLATINUM: MembershipPlatinumSmall,
  SVIP: MembershipSvipSmall,
  DEFAULT: '',
};
export const MembershipBadge = ({ gradeName = '', small = false }) => {
  return <MembershipBadgeStyled className={small ? 'small' : ''} gradeName={gradeName} />;
};

MembershipBadge.propTypes = {
  gradeName: PropTypes.string,
  small: PropTypes.bool,
};

const MembershipBadgeStyled = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  align-content: center;
  justify-content: center;

  background: url(${({ gradeName }) => IMAGE_MEMBERSHIP_BADGE_LARGE[(gradeName ?? 'default').toUpperCase()]}) no-repeat
    center;
  background-size: contain;
  &.small {
    width: 40px;
    height: 40px;
    background: url(${({ gradeName }) => IMAGE_MEMBERSHIP_BADGE_SMALL[(gradeName ?? 'default').toUpperCase()]})
      no-repeat center;
  }
`;
