import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import CalendarUnstyled from '@ecp/common/src/components/unstyled/calendar/CalendarUnstyled';
import useCalendar from '@ecp/common/src/components/calendar/useCalendar';
import calendarImagePath from '@ecp/common/src/assets/icon/icon__calendar.svg';

export const calendarClassNames = {
  error: 'editor-error',
  readOnly: 'read-only',
};
export const StyledCalendar = styled.div`
  ${({ inputStyle }) => inputStyle};

  .react-datepicker {
    border: 1px solid ${theme.color.secondary};
    border-radius: 0;
    padding: 10px 8px 6px 8px;
    color: ${theme.color.text.basic};

    &-popper {
      z-index: 5;
      &[data-placement^='bottom'] {
        padding-top: 6px;
      }
      &[data-placement^='top'] {
        padding-bottom: 6px;
      }
      &[data-placement^='right'] {
        padding-left: 6px;
      }
      &[data-placement^='left'] {
        padding-right: 6px;
      }
    }

    &__navigation {
      top: 12px;

      &-icon::before {
        border-color: ${theme.color.line.selected};
        border-width: 2px 2px 0 0;
        height: 8px;
        top: 9px;
      }
    }

    &__header {
      border-bottom: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: none;
      padding-top: 0;
    }

    &__month {
      margin: 0;
      width: 212px;
    }

    &__current-month {
      background: ${theme.color.background.white};
      border: 1px solid ${theme.color.line.disabled};
      border-radius: 32px;
      width: 120px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 16px;
      font-size: ${theme.font.size.basic};
      color: ${theme.color.text.basic};
    }

    &__week {
      display: flex;
      position: relative;
      justify-content: space-evenly;
      width: 197px;
      margin: auto;
    }

    &__day {
      color: ${theme.color.text.basic};
      border-radius: 50%;
      background: none;
      width: 30px;
      height: 30px;
      line-height: 30px;
      flex-shrink: 0;
      margin: auto;
      font-size: ${theme.font.size.calendar};

      &:hover {
        background: ${theme.color.line.disabled};
        border-radius: 50%;
      }

      &--weekend {
      }

      &--sunday {
        color: ${theme.color.text.error};
      }

      &--today {
        background: ${theme.color.background.sub};
        border: 1px solid ${theme.color.primary};
        border-radius: 50%;
        color: ${theme.color.text.basic};
        &::after {
          visibility: hidden;
        }
      }

      &--selected {
        color: #ffffff;
        background: ${theme.color.primary};
        border-radius: 50%;
      }

      &--in {
        &-range {
          background-color: #faf9ff;
          border: 1px solid #e9eaf0;

          &.react-datepicker__day--range {
            &-start {
              background-color: ${theme.color.primary};
            }
            &-end {
              background-color: ${theme.color.primary};
            }
          }
        }
      }

      &--disabled {
        color: ${theme.color.text.disabled};
        background: none;
        &:hover {
          background: none;
        }
      }

      &--outside-month {
        visibility: hidden;
      }

      &-name {
        width: 30px;
        height: 30px;
        line-height: 30px;
        flex-shrink: 0;
        margin: auto;
        font-size: ${theme.font.size.calendar};
        font-weight: ${theme.font.weight.bold};
        color: ${theme.color.text.basic};
      }

      &-names {
        border-top: solid 2px ${theme.color.line.emphasis};
        margin-top: 8px;
        display: flex;
        justify-content: space-evenly;
        width: 197px;
      }
    }
  }

  .react-datepicker__input-container {
    height: 40px;
    width: 200px;
    font-size: ${theme.font.size.basic};
    font-weight: ${theme.font.weight.demiLight};

    ${({ inputStyle }) => inputStyle};

    input {
      width: 100%;
      height: 100%;
      outline: none;
      padding: 0 14px;
      border: 1px solid ${theme.color.line.disabled};
      background-image: url('${calendarImagePath}');
      background-position: top 8px right 9px;
      background-repeat: no-repeat;
      background-size: 20px;

      &:focus-visible {
        border: 1px solid ${theme.color.line.selected};
      }

      ::placeholder {
        color: ${theme.color.text.placeholder};
      }

      ::-ms-input-placeholder {
        color: ${theme.color.text.placeholder};
      }
    }
  }

  &.${calendarClassNames.error} {
    & input {
      border: 1px solid ${theme.color.text.error};
    }
  }

  &.${calendarClassNames.readOnly} {
    pointer-events: none;
  }
`;

const StyledErrorMessage = styled(TextBox)`
  margin-top: 6px;
  color: ${theme.color.text.error};
  font-size: ${theme.font.size.error};
  line-height: 19px;
`;

const Calendar = ({ inputStyle, error, errorMessage, ...props }) => {
  const { value, readOnly } = props;
  const { ignoreError } = useIgnorableError({ error, value });
  const stateClasses = {
    [calendarClassNames.error]: error,
    [calendarClassNames.readOnly]: readOnly,
  };

  return (
    <FlexBoxColumn align-items={'flex-start'} width={'100%'}>
      <StyledCalendar inputStyle={inputStyle} className={clsx(stateClasses)}>
        <CalendarUnstyled {...props} error={!ignoreError && error} />
      </StyledCalendar>
      {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </FlexBoxColumn>
  );
};

Calendar.displayName = 'Calendar';

export const CalenderPropTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  filterDate: PropTypes.func,
  renderDayContents: PropTypes.func,
  dayClassName: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  inline: PropTypes.bool,
  inputStyle: PropTypes.object,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Calendar.propTypes = {
  ...CalenderPropTypes,
};

Calendar.defaultProps = {
  inline: true,
  inputStyle: {},
};

const CalendarButton = styled(FlexBox)`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 28px;
  background: ${theme.color.background.basic};
  border: 1px solid ${theme.color.line.basic};
  cursor: pointer;

  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.small};

  color: ${theme.color.text.basic};
`;

export const CalendarRange = ({
  startDate,
  endDate,
  inputStyle,
  startMinDate,
  onStartChange,
  onEndChange,
  onAllDateChange,
  showToday,
  error,
  errorMessage,
  ...props
}) => {
  const { ignoreError } = useIgnorableError({ error, value: { startDate, endDate } });
  const { handleStartToday, handleEndToday, handleStartChange, handleEndChange } = useCalendar({
    startMinDate,
    startDate,
    endDate,
    onStartChange,
    onEndChange,
    onAllDateChange,
  });

  return (
    <FlexBoxColumn align-items={'flex-start'} width={'100%'}>
      <FlexBox gap={'6px'}>
        <Calendar
          selectsStart
          inline={false}
          {...props}
          value={startDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startMinDate}
          maxDate={endDate}
          onChange={handleStartChange}
          inputStyle={inputStyle}
          error={!ignoreError && error}
        >
          {showToday && <CalendarButton onClick={handleStartToday}>오늘</CalendarButton>}
        </Calendar>
        ~
        <Calendar
          selectsEnd
          inline={false}
          {...props}
          value={endDate}
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          onChange={handleEndChange}
          inputStyle={inputStyle}
          error={!ignoreError && error}
        >
          {showToday && <CalendarButton onClick={handleEndToday}>오늘</CalendarButton>}
        </Calendar>
      </FlexBox>
      {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </FlexBoxColumn>
  );
};

CalendarRange.propTypes = {
  ...CalenderPropTypes,
  direction: PropTypes.oneOf(['row', 'column']),
  startMinDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
  showToday: PropTypes.bool,
};

CalendarRange.defaultProps = {
  inputStyle: {},
};

export default Calendar;
