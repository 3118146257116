import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperSlideUnstyled from '@ecp/common/src/components/unstyled/swiper/SwiperSlideUnstyled';
import useSwipe from '@ecp/common/src/components/unstyled/swiper/useSwipe';
import SwiperItem from '@ecp/common/src/components/swiper/component/SwiperItem';

const SwiperUnstyled = React.forwardRef(({ onClick, ...props }, ref) => {
  const {
    width,
    height,
    round,
    backgroundColor,
    itemList,
    itemProps,
    itemTitleProps,
    pagingProps,
    rootComponent: RootComponent,
    wrapperComponent: WrapperComponent,
    controllerComponent: ControllerComponent,
    arrowPrevComponent: ArrowPrevComponent,
    arrowNextComponent: ArrowNextComponent,
    pagingComponent: PagingComponent,
    itemComponent: Item,
    rootProps,
    carouselProps,
    arrowProps,
    controlProps,
    adultGood,
    initialSlide,
    tagGb,
    title,
  } = props;

  const sliderRef = useRef(null);
  useImperativeHandle(ref, () => sliderRef.current);
  const {
    selectedIndex,
    isFirst,
    isLast,
    showArrow,
    showControl,
    totalSlideCount,
    handleItemClick,
    getControlProps,
    getCarouselProps,
    getSlideProps,
    getPagingProps,
    handlePrev,
    handleNext,
    handlePaging,
  } = useSwipe(props, sliderRef, onClick);

  return (
    <RootComponent {...rootProps}>
      <WrapperComponent
        width={width}
        height={height}
        round={round}
        backgroundColor={backgroundColor}
        data-testid="carousel-wrapper"
        {...props}
      >
        {!!itemList.length && (
          <Swiper ref={sliderRef} {...getCarouselProps(carouselProps)}>
            {itemList.map((item, index) => (
              <SwiperSlide key={`swiper-slide-item__${index}`} {...getSlideProps(itemProps)} aria-label={''}>
                <SwiperSlideUnstyled
                  index={index}
                  item={item}
                  current={initialSlide !== undefined ? initialSlide : selectedIndex}
                  total={totalSlideCount}
                  onClick={handleItemClick}
                  tagGb={tagGb}
                  title={title}
                >
                  <Item
                    item={item}
                    itemProps={itemProps}
                    itemTitleProps={itemTitleProps}
                    adultGood={adultGood}
                    index={index}
                    total={totalSlideCount}
                  />
                </SwiperSlideUnstyled>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {showControl &&
          (ControllerComponent?.displayName === 'SwiperArrowLabel' ? (
            <ControllerComponent
              {...getControlProps(controlProps)}
              data-testid="carousel-controller"
              prevEvent={handlePrev}
              nextEvent={handleNext}
            />
          ) : (
            <ControllerComponent
              {...getControlProps(controlProps)}
              data-testid="carousel-controller"
              itemList={itemList}
              handleItemClick={handleItemClick}
              btnViewAllFlag={props?.btnViewAllFlag}
              itemComponent={Item}
              //itemProps={itemProps}
            />
          ))}
        {showArrow && (
          <ArrowPrevComponent
            onClick={handlePrev}
            {...arrowProps}
            isEdge={arrowProps.isEdge ?? isFirst}
            data-testid="carousel-Arrow-Prev"
          />
        )}
        {showArrow && (
          <ArrowNextComponent
            onClick={handleNext}
            {...arrowProps}
            isEdge={arrowProps.isEdge ?? isLast}
            data-testid="carousel-Arrow-next"
          />
        )}
        {PagingComponent && (
          <PagingComponent
            {...getPagingProps(pagingProps)}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handlePaging={handlePaging}
            data-testid="carousel-paging"
          />
        )}
      </WrapperComponent>
    </RootComponent>
  );
});

SwiperUnstyled.propTypes = {
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  itemTitleProps: PropTypes.object,
  arrowProps: PropTypes.object,
  pagingProps: PropTypes.object,
  onClick: PropTypes.func,
  handleSwiper: PropTypes.func,
  onChange: PropTypes.func,
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  wrapperComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  controllerComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  arrowPrevComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  arrowNextComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  pagingComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),

  itemComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  carouselProps: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  round: PropTypes.bool,
  backgroundColor: PropTypes.string,
  initialSlide: PropTypes.number,
  rootProps: PropTypes.object,
  controlProps: PropTypes.object,
  adultGood: PropTypes.bool,
  hideUnusableArrow: PropTypes.bool,
  hideUnusableControl: PropTypes.bool,
  handleIsLocked: PropTypes.func,
  tagGb: PropTypes.oneOf(['button']),
  title: PropTypes.string,
  btnViewAllFlag: PropTypes.string,
};

SwiperUnstyled.defaultProps = {
  itemList: [],
  carouselProps: {
    delay: 3500,
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
  },
  controlProps: {},
  rootComponent: 'div',
  wrapperComponent: 'div',
  itemComponent: SwiperItem,
  onClick: () => {},
  itemTitleProps: {},
  hideUnusableArrow: false,
};

SwiperUnstyled.displayName = 'SwiperUnstyled';

export default SwiperUnstyled;
