import { getRequest } from '@fo-apis/axios';

const getRecommendationBrandList = async () =>
  await getRequest(
    {
      url: '/api/v1/brand/recommendation-brand-list',
    },
    { skipSpinner: true }
  );

const getBestBrandList = async (limit = 5) =>
  await getRequest(
    {
      url: '/api/v1/brand/best',
      params: { limit },
    },
    { skipSpinner: true }
  );

const getBrandList = async (params) =>
  await getRequest({
    url: '/api/v1/brand',
    params: { ...params, searchKeyword: encodeURIComponent(params.searchKeyword) },
  });

const getBrandListByConsonant = async (params) =>
  await getRequest({
    url: '/api/v1/brand/consonant',
    params: { ...params, searchKeyword: encodeURIComponent(params.searchKeyword) },
  });

const getBrand = async (brandNumber) =>
  await getRequest({
    url: `/api/v1/brand/${brandNumber}`,
  });

const getBrandDisplayCategoryList = async (brandNumber) =>
  await getRequest({
    url: `/api/v1/brand/${brandNumber}/display-category`,
  });

const getBrandMostOrderGoodList = async (brandNumber, limit, skipSpinner = false) =>
  await getRequest(
    {
      url: `/api/v1/brand/${brandNumber}/recommendation-good`,
      params: { limit },
    },
    { skipSpinner }
  );

const brandApi = {
  getRecommendationBrandList,
  getBestBrandList,
  getBrandList,
  getBrandListByConsonant,
  getBrand,
  getBrandDisplayCategoryList,
  getBrandMostOrderGoodList,
};

export default brandApi;
