import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { GOOD_PRICE_FILTER_LIST } from '@ecp/common/src/const/good';

import PriceFilter from '@fo-components/search/PriceFilter';
import CategoryTree from '@fo-components/search/CategoryTree';

const BrandFilter = ({
  brand,
  price,
  customPrice,
  currentCategory,
  filteredLargeCategory,
  filteredMiddleCategory,
  filteredSmallCategory,
  filteredDetailCategory,
  getTypeOfTreeItem,
  handlePriceFilterChange,
  handleCustomPriceChange,
  handlePriceCustomApply,
  handleCategoryConditionChange,
}) => {
  const hasCategory = useMemo(() => {
    return (
      filteredLargeCategory?.length > 0 ||
      filteredMiddleCategory?.length > 0 ||
      filteredSmallCategory?.length > 0 ||
      filteredDetailCategory?.length > 0
    );
  }, [
    filteredDetailCategory?.length,
    filteredLargeCategory?.length,
    filteredMiddleCategory?.length,
    filteredSmallCategory?.length,
  ]);

  return (
    <FlexBoxColumnStyle>
      <FlexBoxColumn align-items={'flex-start'} width={'100%'}>
        {brand?.imageUrl && (
          <FlexBoxColumn>
            <ImageBox imageSrc={brand?.imageUrl} width={'230px'} height={'210px'} radius={'12px'} />
            <Spacing top={14} />
          </FlexBoxColumn>
        )}
        <BrandName size={theme.font.size.title} weight={theme.font.weight.medium}>
          {brand?.name}
        </BrandName>
      </FlexBoxColumn>
      {hasCategory && (
        <>
          <Spacing top="30px" />
          <CategoryTree
            currentCategory={currentCategory}
            filteredLargeCategory={filteredLargeCategory}
            filteredMiddleCategory={filteredMiddleCategory}
            filteredSmallCategory={filteredSmallCategory}
            filteredDetailCategory={filteredDetailCategory}
            getTypeOfTreeItem={getTypeOfTreeItem}
            handleCategoryChange={handleCategoryConditionChange}
          />
        </>
      )}
      <Spacing top={21} />
      <FlexBoxColumn width={'100%'} justify-content={'flex-start'} align-items={'flex-start'}>
        <PriceFilter
          priceList={GOOD_PRICE_FILTER_LIST}
          selectedPrice={price}
          customPrice={customPrice}
          handlePriceFilterChange={handlePriceFilterChange}
          handlePriceCustomChange={handleCustomPriceChange}
          handlePriceCustomApply={handlePriceCustomApply}
        />
      </FlexBoxColumn>
    </FlexBoxColumnStyle>
  );
};

const FlexBoxColumnStyle = styled(FlexBoxColumn)`
  width: 230px;
`;

const BrandName = styled(TextBox)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export default BrandFilter;

BrandFilter.propTypes = {
  brand: PropTypes.shape({
    name: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
  price: PropTypes.object,
  customPrice: PropTypes.object,
  currentCategory: PropTypes.object,
  filteredLargeCategory: PropTypes.array,
  filteredMiddleCategory: PropTypes.array,
  filteredSmallCategory: PropTypes.array,
  filteredDetailCategory: PropTypes.array,
  getTypeOfTreeItem: PropTypes.func,
  handlePriceFilterChange: PropTypes.func,
  handleCategoryConditionChange: PropTypes.func,
  handleCustomPriceChange: PropTypes.func,
  handlePriceCustomApply: PropTypes.func,
};
