import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { positionPropTypes } from '@ecp/common/src/style/propTypes';
import { FlexBox, FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import useSwiperController from '@ecp/common/src/components/swiper/useSwiperController';
import pause from '@ecp/common/src/assets/icon/pause.svg';
import play from '@ecp/common/src/assets/icon/play.svg';
import playWhite from '@ecp/common/src/assets/icon/play__white.svg';
import pauseWhite from '@ecp/common/src/assets/icon/pause__white.svg';

import theme from '../../../style/theme/default';
import CheckBoxComponent from '../../unstyled/checkbox/default/CheckBoxComponent';
import CheckboxUnstyled from '../../unstyled/checkbox/CheckboxUnstyled';

const RootComponent = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 4;
  cursor: pointer;
  height: 20px;
  width: 50px;

  ${overrideProperties(positionPropTypes, { right: '30px', bottom: '20px' })};
`;

// inner
const InnerRootComponent = styled(RootComponent)`
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  width: 79px;
  height: 31px;
  padding: 6px 14px;
`;

const StyledLabel = styled(FlexBox)`
  color: ${({ isWhite }) => (isWhite ? '#ffffff' : '#141415')};
  font-size: ${({ size }) => (size ? size : theme.font.size.label)};
`;

const StyledCurrentLabel = styled.span`
  font-weight: 700;
`;

const StyledTotalLabel = styled.span`
  font-weight: 300;
`;
export const LabelComponent = ({ current, total, isWhite, ...otherProps }) => (
  <StyledLabel isWhite={isWhite} {...otherProps}>
    <StyledCurrentLabel>{current}</StyledCurrentLabel>
    <StyledTotalLabel>/{total}</StyledTotalLabel>
  </StyledLabel>
);

LabelComponent.displayName = 'LabelComponent';

LabelComponent.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  isWhite: PropTypes.bool,
};

const StyledToggleButton = styled(CheckBoxComponent)`
  width: 6px;
  height: 12px;
  background-size: 6px 12px;
  flex-shrink: 0;

  &.checkbox-checked {
    background-image: url(${play});
  }

  &.checkbox-unchecked {
    background-image: url(${pause});
  }
`;

export const SwiperController = ({ current, total, togglePlay, ...otherProps }) => {
  const { handleChange } = useSwiperController({ togglePlay });

  const componentId = randomComponentId('SwiperController');
  const htmlForProps = { htmlFor: componentId };
  const combineProps = { ...htmlForProps, ...otherProps };

  return (
    <CheckboxUnstyled
      id={componentId}
      playGbAddTitle="슬라이드"
      playGb={'Y'}
      rootProps={combineProps}
      rootComponent={InnerRootComponent}
      checkboxComponent={StyledToggleButton}
      onChange={handleChange}
      label={LabelComponent}
      labelProps={{ current, total }}
    />
  );
};

SwiperController.displayName = 'SwiperController';

SwiperController.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  togglePlay: PropTypes.func,
};

// label
const StyledSwiperLabel = styled(StyledLabel)`
  justify-content: center;
  color: #141415;
  font-size: ${theme.font.size.basic};
  padding: ${({ padding }) => padding || '0px'};
`;

export const SwiperLabel = ({ current, total, ...otherProps }) => (
  <StyledSwiperLabel {...otherProps}>
    <StyledCurrentLabel>{current}</StyledCurrentLabel>
    <StyledTotalLabel>/{total}</StyledTotalLabel>
  </StyledSwiperLabel>
);

SwiperLabel.displayName = 'SwiperLabel';

SwiperLabel.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

// whiteInnerLabel
export const SwiperWhiteInnerLabel = ({ current, total, ...otherProps }) => (
  <RootComponent {...otherProps}>
    <StyledLabel isWhite={true}>
      <StyledCurrentLabel>{current}</StyledCurrentLabel>
      <StyledTotalLabel>/{total}</StyledTotalLabel>
    </StyledLabel>
  </RootComponent>
);

SwiperWhiteInnerLabel.displayName = 'SwiperWhiteInnerLabel';

SwiperWhiteInnerLabel.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

//outer
const RootOuterComponent = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 84px;
  position: absolute;
  left: calc(50% - 42px);
  bottom: ${({ padding }) => (padding ? `calc(-${padding} - 15px)` : '-15px')};
  z-index: 4;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 15px;
  cursor: pointer;
  padding: 0 15px;
  box-sizing: border-box;
`;

const StyledOuterLabel = styled(StyledLabel)`
  display: flex;
  align-items: flex-end;
  color: #141415;
  font-size: ${theme.font.size.basic};
`;

const LabelOuterComponent = ({ current, total }) => (
  <StyledOuterLabel>
    <StyledCurrentLabel>{current}</StyledCurrentLabel>
    <StyledTotalLabel>/{total}</StyledTotalLabel>
  </StyledOuterLabel>
);

LabelOuterComponent.displayName = 'LabelOuterComponent';

LabelOuterComponent.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

export const SwiperOuterController = ({ current, total, togglePlay, ...otherProps }) => {
  const { handleChange } = useSwiperController({ togglePlay });

  const componentId = randomComponentId('SwiperOuterController');
  const htmlForProps = { htmlFor: componentId };
  const combineProps = { ...htmlForProps, ...otherProps };
  return (
    <CheckboxUnstyled
      id={componentId}
      rootProps={combineProps}
      rootComponent={RootOuterComponent}
      checkboxComponent={StyledToggleButton}
      onChange={handleChange}
      label={LabelOuterComponent}
      labelProps={{ current, total }}
      playGb={'Y'}
      playGbAddTitle="슬라이드"
    />
  );
};

SwiperOuterController.displayName = 'SwiperOuterController';

SwiperOuterController.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  togglePlay: PropTypes.func,
};

const StyledToggleWhiteButton = styled(StyledToggleButton)`
  &.checkbox-checked {
    background-image: url(${playWhite});
  }

  &.checkbox-unchecked {
    background-image: url(${pauseWhite});
  }
`;

const RootEdgeComponent = styled.label`
  display: flex;
  justify-content: space-between;
  background-color: rgba(20, 20, 21, 0.45);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 68px;
  height: 26px;
  border-top-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 0 14px;
  align-items: center;
  z-index: 4;
  cursor: pointer;
`;

export const SwiperEdgeController = (props) => {
  const { current, total, togglePlay, ...otherProps } = props;
  const { handleChange } = useSwiperController({ togglePlay });

  return (
    <CheckboxUnstyled
      rootProps={{ ...otherProps }}
      rootComponent={RootEdgeComponent}
      checkboxComponent={StyledToggleWhiteButton}
      onChange={handleChange}
      label={LabelComponent}
      labelProps={{ current, total, isWhite: true, size: theme.font.size.error }}
    />
  );
};

SwiperEdgeController.displayName = 'SwiperEdgeController';

SwiperEdgeController.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  togglePlay: PropTypes.func,
};

const OuterLabelComponent = styled(FlexBoxCenter)`
  height: 30px;
  width: 84px;
  position: absolute;
  left: calc(50% - 42px);
  bottom: ${({ padding }) => (padding ? `calc(-${padding} - 15px)` : '-15px')};
  z-index: 4;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 15px;
  padding: 0 15px;
  box-sizing: border-box;
  color: #141415;
  font-size: ${theme.font.size.basic};
`;

export const SwiperOuterLabel = ({ current, total }) => (
  <OuterLabelComponent>
    <StyledCurrentLabel>{current}</StyledCurrentLabel>
    <StyledTotalLabel>/{total}</StyledTotalLabel>
  </OuterLabelComponent>
);

SwiperOuterLabel.displayName = 'SwiperOuterLabelController';

SwiperOuterLabel.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
};

export const styledComponent = {
  RootComponent,
  StyledCurrentLabel,
  StyledTotalLabel,
  StyledToggleButton,
};

export default SwiperController;

const randomComponentId = (id) => {
  return id.concat(Math.floor(Math.random() * (9999 - 1) + 1));
};
