import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxCenter, FlexBoxColumn, FlexBoxColumnATag } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

const StyledRootATag = styled(FlexBoxColumnATag)`
  ${overrideProperties(sizePropTypes, {})};
`;

const StyledContents = styled.div`
  ${overrideProperties(sizePropTypes, {})};
  position: relative;
  cursor: pointer;
`;

const StyledFlexBox = styled(FlexBox)`
  position: absolute;
  right: 4px;
  &.top {
    top: 4px;
  }
  &.bottom {
    bottom: 4px;
  }
`;

const StyledChip = styled(FlexBoxCenter)`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  min-width: 79px;
  width: max-content;
  padding: 0 14px;
  height: 28px;
  font-weight: ${theme.font.weight.medium};
  font-size: 13px;
  color: ${theme.color.text.white};
`;

const StyledTitle = styled(TextBox)`
  white-space: pre-wrap;
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.title};
  line-height: ${theme.font.lineHeight.demiLight};
  width: 100%;
  cursor: pointer;
`;

const StyledSubTitle = styled(TextBox)`
  white-space: pre-wrap;
  line-height: ${theme.font.lineHeight.demiLight};
  color: ${theme.color.text.guide};
  width: 100%;
  cursor: pointer;
`;

const Exhibition = ({ item, itemProps, chipPlacement, ...props }) => (
  <StyledRootATag gap={'14px'} {...props} data-ds-contents={itemProps.exhibitionNumber}>
    <StyledContents {...itemProps}>
      <StyledImg src={item?.src} {...itemProps} alt={''} />
      {item?.displayTypes && (
        <StyledFlexBox justify-content={'flex-end'} align-items={'flex-end'} gap={'3px'} className={chipPlacement}>
          {item?.displayTypes?.map((displayType, index) => (
            <StyledChip key={index}>{displayType?.name}</StyledChip>
          ))}
        </StyledFlexBox>
      )}
    </StyledContents>
    {(item?.title || item?.subTitle) && (
      <FlexBoxColumn width={'100%'}>
        {item?.title && <StyledTitle>{item?.title}</StyledTitle>}
        {item?.subTitle && <StyledSubTitle>{item?.subTitle}</StyledSubTitle>}
      </FlexBoxColumn>
    )}
  </StyledRootATag>
);

Exhibition.propTypes = {
  item: PropTypes.shape({
    displayTypes: PropTypes.array,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    src: PropTypes.string,
  }),
  itemProps: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func,
  chipPlacement: PropTypes.oneOf(['top', 'bottom']),
};

Exhibition.defaultProps = {
  itemProps: {
    width: '500px',
    height: '290px',
    radius: '12px',
  },
  width: '500px',
  height: '354px',
  chipPlacement: 'top',
};
export default Exhibition;
