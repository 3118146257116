import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import { zIndex } from '@ecp/common/src/style/constant';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { backgroundPropTypes, paddingPropTypes } from '@ecp/common/src/style/propTypes';
import { modalRootProps, ModalWrapperStyle } from '@ecp/common/src/components/modal/Modal';
import DrawerUnstyled from '@ecp/common/src/components/unstyled/floating/DrawerUnstyled';
import { CloseButton } from '@ecp/common/src/components/paper/Paper';

import { modalPlacementClassNames, placements } from '../unstyled/floating/useModal';

const DrawerRoot = React.forwardRef(({ children, zIndex: zIndexProp, ...props }, ref) => {
  const rootRef = useRef(null);

  useImperativeHandle(ref, () => rootRef.current);

  return (
    <RemoveScroll>
      <FloatingOverlay
        style={{
          zIndex: zIndexProp ?? zIndex.modal,
          display: 'grid',
          background: 'rgba(25, 25, 25, 0.8)',
          overflow: 'hidden',
        }}
      >
        <DrawerWrapper ref={rootRef} {...props}>
          {children}
        </DrawerWrapper>
      </FloatingOverlay>
    </RemoveScroll>
  );
});

DrawerRoot.propTypes = {
  zIndex: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.arrayOf(PropTypes.string),
};

DrawerRoot.displayName = 'DrawerRoot';

const Drawer = ({ value, children, placement, offset, duration = 500, delay = '50ms', ...props }) => (
  <DrawerUnstyled
    closeable
    duration={duration}
    delay={delay}
    value={value}
    rootComponent={DrawerRoot}
    rootProps={modalRootProps(props)}
    placement={placement}
    offset={offset}
    closeComponent={<CloseComponent />}
    {...props}
  >
    {children}
  </DrawerUnstyled>
);

Drawer.displayName = 'Drawer';

Drawer.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node,
  onClose: PropTypes.func,
  placement: PropTypes.oneOf(placements),
  offset: PropTypes.number,
  duration: PropTypes.number,
  delay: PropTypes.string,
};

export default Drawer;

const CloseComponent = styled(CloseButton)`
  top: 40px;
  right: 40px;
`;
export const DrawerWrapper = styled(ModalWrapperStyle)`
  max-width: none;
  max-height: none;

  ${overrideProperties(paddingPropTypes, { padding: '40px' })};

  ${overrideProperties(backgroundPropTypes, { 'background-color': '#ffffff' })};

  &.${modalPlacementClassNames.right} {
    height: 100vh;
    right: 0;
  }

  &.${modalPlacementClassNames.left} {
    height: 100vh;
    left: 0;
  }

  &.${modalPlacementClassNames.top} {
    width: 100vw;
    top: 0;
  }

  &.${modalPlacementClassNames.bottom} {
    width: 100vw;
    bottom: 0;
  }

  overflow-y: ${({ overflowY = 'auto' }) => overflowY};

  &.${modalPlacementClassNames.center} {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
`;
