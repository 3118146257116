import { useMemo } from 'react';

export const useStaticImageHostname = () => {
  const hostname = useMemo(() => {
    if (window.location.host.startsWith('localhost')) {
      return 'https://devfo.welstorymall.com';
    } else {
      return 'https://' + window.location.host;
    }
  }, []);

  return hostname;
};
