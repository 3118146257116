import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';

import { useAlertDialog } from '@fo-hooks/common/useModal';
import ServerError from '@fo-components/error/ServerError';

const ServerErrorPage = () => {
  const location = useLocation();
  const { showAlertDialog } = useAlertDialog();

  const state = location.state;

  useEffect(() => {
    if (state?.path && state?.path.length > 0) {
      // TODO: Will be Delete
      showAlertDialog(`* Temporary popup *
      error: "${state.error}",
      path: "${state.path}"`);
    }
  });

  return (
    <Container>
      <ServerError />
    </Container>
  );
};

const Container = styled(FlexBoxColumn)`
  height: 100vh;
  justify-content: center;
`;

export default ServerErrorPage;
