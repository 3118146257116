import { useEffect, useMemo } from 'react';

import {
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';

import useControlled from '../../../hooks/useControlled';

export const placements = ['bottom', 'left', 'right', 'top', 'center'];

const useDrawer = ({
  duration,
  delay = '0ms',
  placement: placementProp,
  closeWhenOutside = true,
  openProp,
  defaultOpen = false,
  onOpenChange,
  onClose,
}) => {
  const { value: open, setValueWhenUncontrolled: setOpen } = useControlled({
    value: openProp,
    defaultValue: defaultOpen,
  });
  const nodeId = useFloatingNodeId();

  const { strategy, context, placement, refs } = useFloating({
    nodeId,
    placement: placementProp,
    open,
    onOpenChange: (changedValue) => {
      setOpen(changedValue);
      onOpenChange?.(changedValue);
      !changedValue && onClose?.(changedValue);
    },
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { enabled: true }),
    useDismiss(context, {
      outsidePress: !!closeWhenOutside,
      bubbles: {
        escapeKey: false,
        outsidePress: !!closeWhenOutside,
      },
    }),
  ]);

  const { isMounted, styles } = useTransitionStyles(context, {
    duration,
    initial: ({ side }) => ({
      transitionDelay: delay,
      transform: {
        top: 'translateY(-100%)',
        bottom: 'translateY(calc(100% - 15px))',
        left: 'translateX(-100%)',
        right: 'translateX(calc(100% - 32px))',
      }[side],
    }),
  });

  const modalClassNames = useMemo(() => [placement], [placement]);

  useEffect(() => {
    if (open) {
      refs.floating.current?.focus?.();
    }
  }, [open, refs.floating]);

  return {
    nodeId,
    open: isMounted,
    styles,
    setOpen,
    refs,
    getReferenceProps,
    getModalProps: getFloatingProps,
    modalClassNames,
    positionStrategy: strategy,
  };
};

export default useDrawer;
