import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Tooltip from '@ecp/common/src/components/tooltip/Tooltip';
import { PageButton } from '@ecp/common/src/components/button/Button';
import NumberInput from '@ecp/common/src/components/input/NumberInput';
import Chip from '@ecp/common/src/components/chip/Chip';
import { GOOD_PRICE_FILTER_LIST } from '@ecp/common/src/const/good';

import { useAlertDialog } from '@fo-hooks/common/useModal';
import { ReactComponent as QuestionIcon } from '@fo-assets/image/good/icon_submenu_question.svg';

const PriceFilter = ({
  priceList,
  selectedPrice,
  customPrice,
  handlePriceFilterChange,
  handlePriceCustomChange,
  handlePriceCustomApply,
}) => {
  const { showAlertDialog } = useAlertDialog();

  const mappedPriceList = useMemo(() => {
    const priceFilterList = priceList ?? GOOD_PRICE_FILTER_LIST;
    return priceFilterList.map((filter) => ({
      ...filter,
      selected: Number(filter.min) === Number(selectedPrice.min) && Number(filter.max) === Number(selectedPrice.max),
    }));
  }, [priceList, selectedPrice.max, selectedPrice.min]);

  const handleCustomPriceFilterApply = useCallback(() => {
    const min = customPrice?.customMin;
    const max = customPrice?.customMax;

    if (!max) {
      showAlertDialog('검색을 원하시는 최저가와 최고가를 모두 입력해주세요.');
      return;
    }
    if (min > max) {
      showAlertDialog('최저가는 최고가보다 낮아야 합니다.');
      return;
    }

    if (selectedPrice?.min === min && selectedPrice?.max === max) {
      return;
    }

    handlePriceCustomApply({ min, max, label: '' });
  }, [customPrice, showAlertDialog, handlePriceCustomApply, selectedPrice]);

  return (
    <Box data-ds-label2={'price_filter'}>
      <Header>
        <TextBox size={theme.font.size.table} weight={theme.font.weight.bold}>
          가격
        </TextBox>
        <Spacing right="6px" />
        <Tooltip
          arrow
          closeable
          openWhenClicked
          placement="bottom-start"
          value={
            <TextBox size={theme.font.size.error} weight={theme.font.weight.demiLight} color={theme.color.text.sub}>
              {'검색을 원하시는 금액대를 선택하시거나,\n' +
                '직접입력하여 상품 가격을 설정 가능합니다.\n' +
                '※ 검색된 상품은 할인 등 혜택이 적용된\n' +
                '실제 구매가격 기준으로 검색됩니다.'}
            </TextBox>
          }
        >
          <QuestionIcon width="18px" height="24px" />
        </Tooltip>
      </Header>
      <FlexBoxColumn align-items="flex-start" width="100%" style={{ padding: '20px 14px' }}>
        <FlexBoxColumn gap={'6px'} width={'100%'} align-items={'flex-start'}>
          {mappedPriceList.map(({ key, label, min, max, selected }) => (
            <Chip
              type={'default'}
              key={key}
              closable={false}
              checked={selected}
              onClick={() => handlePriceFilterChange({ min, max, label })}
            >
              {label}
            </Chip>
          ))}
        </FlexBoxColumn>
        <Spacing top="20px" />
        <FlexBoxSpaceBetween width="100%">
          <NumberInput
            minWidth="auto"
            suffixText="원"
            clearable={false}
            value={customPrice?.customMin}
            onChange={(e) =>
              handlePriceCustomChange({
                customMax: customPrice.customMax,
                customMin: Number(e.target.value),
              })
            }
          />
          <TextBoxStyle>~</TextBoxStyle>
          <NumberInput
            minWidth="auto"
            suffixText="원"
            clearable={false}
            value={customPrice?.customMax}
            onChange={(e) =>
              handlePriceCustomChange({
                customMin: customPrice.customMin,
                customMax: Number(e.target.value),
              })
            }
          />
        </FlexBoxSpaceBetween>
        <Spacing top="10px" />
        <PageButton width={'100%'} height="40px" type="Default" onClick={handleCustomPriceFilterApply}>
          적용
        </PageButton>
      </FlexBoxColumn>
    </Box>
  );
};

const Box = styled(FlexBoxColumn)`
  width: 100%;
  border: 1px solid ${theme.color.line.basic};
`;

const Header = styled(FlexBox)`
  width: 100%;
  background: ${theme.color.background.basic};
  padding: 10px 14px;
  align-items: center;
`;

const TextBoxStyle = styled(TextBox)`
  font-size: ${theme.font.size.small};
  font-weight: ${theme.font.weight.demiLight};
  padding: 0 3px;
`;

export default PriceFilter;

PriceFilter.propTypes = {
  priceList: PropTypes.array,
  selectedPrice: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  customPrice: PropTypes.shape({
    customMin: PropTypes.number,
    customMax: PropTypes.number,
  }),
  handlePriceFilterChange: PropTypes.func,
  handlePriceCustomChange: PropTypes.func,
  handlePriceCustomApply: PropTypes.func,
};
