import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavigationType, useLocation, useNavigationType, useParams } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  getBrandCategoryFilterList,
  navigateWithFilterInfoTable,
} from '@ecp/common/src/utils/good/brandDetailSearchUtil';
import useNavigateWithFilter from '@ecp/common/src/hooks/search/useNavigateWithFilter';
import useCategoryFilter from '@ecp/common/src/hooks/search/useCategoryFilter';
import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { BENEFIT_MESSAGE } from '@ecp/common/src/const/promotion';

import { getPageQueryParams } from '@fo-utils/commonUtils';
import { withCanDisplayCategoryList } from '@fo-recoil/category/selector';
import { employeeBenefitBrandNameState, employeeBenefitMessageState } from '@fo-recoil/main/atom';
import brandApi from '@fo-apis/good/brandApi';
import displayCategoryApi from '@fo-apis/display/displayCategoryApi';
import couponApi from '@fo-apis/coupon/couponApi';
import SelectedSearchCondition from '@fo-components/search/SelectedSearchCondition';
import GoodTableCardList from '@fo-components/good/GoodTableCardList';
import BrandFilter from '@fo-pages/brand/components/BrandFilter';

const initialCustomPrice = {
  customMin: null,
  customMax: null,
};

const BrandDetailPage = () => {
  const { brandNumber } = useParams();
  const location = useLocation();
  const navigationType = useNavigationType();

  const [brand, setBrand] = useState({
    name: '',
    imageUrl: '',
  });
  const [customPrice, setCustomPrice] = useState(initialCustomPrice);
  const [totalCount, setTotalCount] = useState(undefined);
  const [goodList, setGoodList] = useState(null);
  const [brandCategoryList, setBrandCategoryList] = useState([]);

  const {
    searchCondition,
    pageParam,
    currentCategory,
    setCurrentCategory,
    handleRefresh,
    handlePageChange,
    handleCountPerPageChange,
    handleSortChange,
    handlePriceFilterChange,
    handleCategoryFilterChange,
    handleDeletePriceFilter,
    handleDeleteCategoryFilter,
    handleViewTypeChange,
  } = useNavigateWithFilter(navigateWithFilterInfoTable);

  const totalDisplayCategoryList = useRecoilValue(withCanDisplayCategoryList);

  const setEmployeeBenefitMessageState = useSetRecoilState(employeeBenefitMessageState);
  const setEmployeeBenefitBrandNameState = useSetRecoilState(employeeBenefitBrandNameState);
  const resetEmployeeBenefitMessage = useResetRecoilState(employeeBenefitMessageState);

  useEffect(() => {
    if (navigationType === NavigationType.Replace) {
      window.scroll({ top: 0 });
    }
  }, [navigationType, location]);

  useEffect(() => {
    (async () => {
      const { result: couponList } = await couponApi.getBrandBenefitSummary(brandNumber);
      const { brand, count } = couponList;

      if (brand && count > 0) {
        setEmployeeBenefitMessageState(BENEFIT_MESSAGE.BRAND_DETAIL);
      }
    })();
    return () => resetEmployeeBenefitMessage();
  }, [brandNumber, resetEmployeeBenefitMessage, setEmployeeBenefitMessageState]);

  const brandCategoryFilterList = useMemo(() => {
    return getBrandCategoryFilterList(brandCategoryList, totalDisplayCategoryList);
  }, [brandCategoryList, totalDisplayCategoryList]);
  const {
    filteredLargeCategory,
    filteredMiddleCategory,
    filteredSmallCategory,
    filteredDetailCategory,
    getTypeOfTreeItem,
    getCategoryToBeChanged,
  } = useCategoryFilter({
    currentCategory,
    ...brandCategoryFilterList,
  });

  const fetchBrandGoodList = useCallback(async () => {
    const requestBody = {
      brandList: [brandNumber],
      minPrice: searchCondition.price.min,
      maxPrice: searchCondition.price.max,
      categoryNumber: currentCategory.categoryNumber.current,
      depthNumber: currentCategory.depthNumber,
    };
    const pageParams = getPageQueryParams(pageParam?.page, pageParam?.countPerPage, pageParam?.sort?.value);

    const {
      result: { content = [], totalElements },
    } = await displayCategoryApi.getGoodListByDisplayCategory(requestBody, pageParams);
    setGoodList(content);
    setTotalCount(totalElements);
  }, [
    brandNumber,
    currentCategory.categoryNumber,
    currentCategory.depthNumber,
    pageParam?.countPerPage,
    pageParam?.page,
    pageParam?.sort?.value,
    searchCondition.price.max,
    searchCondition.price.min,
  ]);

  const fetchBrand = useCallback(async () => {
    setCustomPrice(initialCustomPrice);
    const { result } = await brandApi.getBrand(brandNumber);
    const { brandName = '', pcImageUrl = '' } = result;
    setBrand({
      name: brandName,
      imageUrl: pcImageUrl,
    });
    setEmployeeBenefitBrandNameState(brandName);
  }, [brandNumber, setEmployeeBenefitBrandNameState]);

  const fetchBrandCategoryList = useCallback(async () => {
    const { result } = await brandApi.getBrandDisplayCategoryList(brandNumber);
    setBrandCategoryList(result);
  }, [brandNumber]);

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([fetchBrand(), fetchBrandCategoryList()]);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [fetchBrand, fetchBrandCategoryList]);

  useEffect(() => {
    (async () => {
      await fetchBrandGoodList();
    })();
  }, [fetchBrandGoodList]);

  const handleCategoryConditionChange = useCallback(
    (category) => {
      const categoryNumber = category?.categoryNumber || '';
      const depthNumber = category?.depthNumber || 0;
      const updatedCategory = {
        categoryNumber: { ...currentCategory.categoryNumber, ...getCategoryToBeChanged(categoryNumber, depthNumber) },
        depthNumber,
      };
      handleCategoryFilterChange(updatedCategory);
      setCurrentCategory(updatedCategory);
    },
    [currentCategory.categoryNumber, getCategoryToBeChanged, handleCategoryFilterChange, setCurrentCategory]
  );

  const handleResetFilter = useCallback(() => {
    handleRefresh();
    setCustomPrice(initialCustomPrice);
  }, [handleRefresh]);

  const handlePriceFilterClick = useCallback(
    (changedPriceCondition) => {
      handlePriceFilterChange(changedPriceCondition);
      setCustomPrice(initialCustomPrice);
    },
    [handlePriceFilterChange]
  );

  const handleDeletePrice = useCallback(() => {
    handleDeletePriceFilter();
    setCustomPrice(initialCustomPrice);
  }, [handleDeletePriceFilter, setCustomPrice]);

  const selectedSearchCondition = useMemo(() => {
    return {
      categoryNumber: currentCategory.categoryNumber,
      price: searchCondition.price,
    };
  }, [currentCategory.categoryNumber, searchCondition.price]);

  const categoryNumberToName = useMemo(() => {
    return totalDisplayCategoryList.reduce((acc, elem) => {
      acc[elem.categoryNumber] = elem.categoryName;
      return acc;
    }, {});
  }, [totalDisplayCategoryList]);

  const hasFilter = useMemo(() => {
    return !!searchCondition?.price?.min || !!searchCondition?.price?.max || !!searchCondition?.categoryNumber?.current;
  }, [searchCondition]);

  const cardType = useMemo(() => (pageParam.listView ? 'tableMedium' : 'small'), [pageParam.listView]);

  return (
    <FlexBoxColumn width="1540px">
      <Spacing top="50px" />
      <FlexBoxSpaceBetween align-items="flex-start" width="100%">
        <Spacing right={30}>
          <BrandFilter
            brand={brand}
            price={searchCondition.price}
            customPrice={customPrice}
            currentCategory={currentCategory}
            filteredLargeCategory={filteredLargeCategory}
            filteredMiddleCategory={filteredMiddleCategory}
            filteredSmallCategory={filteredSmallCategory}
            filteredDetailCategory={filteredDetailCategory}
            getTypeOfTreeItem={getTypeOfTreeItem}
            handlePriceFilterChange={handlePriceFilterClick}
            handleCustomPriceChange={setCustomPrice}
            handlePriceCustomApply={handlePriceFilterChange}
            handleCategoryConditionChange={handleCategoryConditionChange}
          />
        </Spacing>
        <FlexBoxColumn flex={1}>
          <SelectedSearchCondition
            padding={'20px 0 20px'}
            backgroundColor={theme.color.background.basic}
            searchCondition={selectedSearchCondition}
            categoryNumberToName={categoryNumberToName}
            handleResetFilter={handleResetFilter}
            handleDeleteCategoryFilter={handleDeleteCategoryFilter}
            handleDeletePriceFilter={handleDeletePrice}
          />
          {hasFilter && <Spacing top={50} />}
          {goodList && (
            <GoodTableCardList
              goodList={goodList}
              pageProps={{ ...pageParam, totalCount }}
              cardType={cardType}
              onSortChange={handleSortChange}
              onCountPerPageChange={handleCountPerPageChange}
              onViewChange={handleViewTypeChange}
              onPageChange={handlePageChange}
              titleType={'secondary'}
            />
          )}
        </FlexBoxColumn>
      </FlexBoxSpaceBetween>
    </FlexBoxColumn>
  );
};

export default BrandDetailPage;
