import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getConsonantBrandTotalPage } from '@ecp/common/src/utils/good/brandUtil';
import { FlexBox, FlexBoxButtonCenter, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/TextButton';

const PER_PAGE_SIZE = 20;

const BrandResultItem = ({ list, brandGroup, brandCountGroup, handleShowMoreBrand }) => {
  const totalPage = useCallback(
    (consonant) => {
      return getConsonantBrandTotalPage(brandCountGroup, consonant, PER_PAGE_SIZE);
    },
    [brandCountGroup]
  );

  const showMoreButton = useCallback(
    (consonant) => {
      return brandGroup[consonant].page < totalPage(consonant);
    },
    [brandGroup, totalPage]
  );

  return (
    <>
      {list?.map((key) => (
        <BrandResultItemWrap key={key} width="100%" align-items="flex-start">
          <TextBox size="18px" weight="700">
            {key}
          </TextBox>
          <BrandResultListBox>
            {brandGroup[key]?.brandList?.map((brand) => (
              <BrandTextBox key={brand.brandNumber}>
                <BrandText to={`/brand/${brand.brandNumber}`}>{brand.brandName}</BrandText>
              </BrandTextBox>
            ))}
          </BrandResultListBox>
          {showMoreButton(key) && (
            <>
              <Spacing top={20} />
              <HorizontalDivider />
              <Spacing top={6} />
              <FlexBoxButtonCenter width={'100%'}>
                <TextButton type={'list'} onClick={() => handleShowMoreBrand(key)}>{`더보기(${
                  brandGroup[key].page
                }/${totalPage(key)})`}</TextButton>
              </FlexBoxButtonCenter>
            </>
          )}
        </BrandResultItemWrap>
      ))}
    </>
  );
};

const BrandResultItemWrap = styled(FlexBoxColumn)`
  padding-top: 50px;
  &:first-child {
    padding-top: 20px;
  }
`;

const BrandTextBox = styled(FlexBox)`
  width: 20%;
`;

const BrandText = styled(Link)`
  color: #5a5a5a;
  font-size: 14px;
  font-weight: 500;
  padding-top: 10px;
`;

const BrandResultListBox = styled(FlexBox)`
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 10px;
`;

export default BrandResultItem;

BrandResultItem.propTypes = {
  list: PropTypes.array,
  brandGroup: PropTypes.object,
  brandCountGroup: PropTypes.object,
  handleShowMoreBrand: PropTypes.func,
};
