import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import RadioGroupUnstyled from '@ecp/common/src/components/unstyled/input/RadioGroupUnstyled';

const RadioGroup = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <RadioGroupUnstyled ref={ref} rootComponent={StyledRadioGroupWrapper} {...props}>
      {children}
    </RadioGroupUnstyled>
  );
});

RadioGroup.displayName = 'RadioGroup';
RadioGroup.propTypes = {
  children: PropTypes.node,
  gap: PropTypes.string,
  ...sizePropTypes,
};

RadioGroup.defaultProps = {
  height: 'fit-content',
  width: 'fit-content',
  gap: '0',
};

const StickyCss = css`
  position: sticky;
  top: ${({ stickyTop = 'auto' }) => stickyTop};
  left: ${({ stickyLeft = 'auto' }) => stickyLeft};

  transition: top 0.3s ease-in-out;
  z-index: 10;
`;
const StyledRadioGroupWrapper = styled(FlexBox)`
  ${overrideProperties(sizePropTypes, {
    height: 'fit-content',
    width: 'fit-content',
  })};

  ${({ sticky }) => sticky && StickyCss};
`;

export default RadioGroup;
