import { getRequest } from '@fo-apis/axios';

const getDisplayCategory = () =>
  getRequest({
    url: '/api/v1/display/category',
  });

const getCategoryGoodList = (params, categoryNumber) =>
  getRequest(
    {
      url: `/api/v1/display/category/goods/${categoryNumber}`,
      params,
    },
    { skipError: true }
  );

const getBannerList = (mainFunctionKindCode) =>
  getRequest({
    url: `/api/v1/display/banner/${mainFunctionKindCode}`,
  });

const displayApi = {
  getCategoryGoodList,
  getDisplayCategory,
  getBannerList,
};

export default displayApi;
