import React from 'react';

import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Netfunnel from '@ecp/common/src/utils/netfunnel.d.ts';
import { getExpiredLocalStorage } from '@ecp/common/src/utils/utils';

function rootRender() {

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <>
      <App />
    </>
  );
}

if(getExpiredLocalStorage("netfunnel_pass")) rootRender();
else {
  Netfunnel.NetFunnel_Action({}, ()=> {
    rootRender();
  });
}



reportWebVitals();
