import React, { useEffect, useRef, useState } from 'react';

import mobiliansApi from '@fo-apis/interface/mobiliansApi';

const MobiliansForm = () => {
  const ref = useRef();
  const [mobilians, setMobilians] = useState({});

  useEffect(() => {
    const fetchMobiliansInformation = async () => {
      const { result } = await mobiliansApi.getMobiliansInformation();
      setMobilians(result);
    };

    fetchMobiliansInformation();
  }, [setMobilians]);

  useEffect(() => {
    if (mobilians.ciSvcId) {
      ref.current.submit();
    }
  }, [mobilians.ciSvcId]);

  return (
    <form id="mobilians" method="post" action="https://auth.mobilians.co.kr/goCashMain.mcash" ref={ref}>
      <input type="hidden" name="CASH_GB" id="CASH_GB" value="CI" />
      <input type="hidden" name="CI_SVCID" id="CI_SVCID" value={mobilians.ciSvcId || ''} />
      <input type="hidden" name="PAY_MODE" id="PAY_MODE" value={mobilians.payMode || ''} />
      <input type="hidden" name="Okurl" id="Okurl" value={mobilians.okUrl || ''} />
      <input type="hidden" name="CALL_TYPE" id="CALL_TYPE" value={mobilians.callType || ''} />
      <input type="hidden" name="LOGO_YN" id="LOGO_YN" value={mobilians.logoYn || ''} />
      <input type="hidden" name="CI_Mode" id="CI_Mode" value={mobilians.ciMode || ''} />
      <input type="hidden" name="DI_CODE" id="DI_CODE" value={mobilians.diCode || ''} />
      <input type="hidden" name="Siteurl" id="Siteurl" value={mobilians.siteUrl || ''} />
      <input type="hidden" name="SUB_CPID" id="SUB_CPID" value={mobilians.subCpid || ''} />
      <input type="hidden" name="Callback" id="Callback" value={mobilians.callback || ''} />
      <input type="hidden" name="Smstext" id="Smstext" value={mobilians.smsText || ''} />
      <input type="hidden" name="Lmstitle" id="Lmstitle" value={mobilians.lmsTitle || ''} />
      <input type="hidden" name="Lmstext" id="Lmstext" value={mobilians.lmsText || ''} />
      <input type="hidden" name="Tradeid" id="Tradeid" value={mobilians.tradeId || ''} />
      <input type="hidden" name="Cryptokurl" id="Cryptokurl" value={mobilians.cryptokurl || ''} />
      <input type="hidden" name="CI_FIXCOMMID" id="CI_FIXCOMMID" value={mobilians.ciFixCommId || ''} />
      <input type="hidden" name="Closeurl" id="Closeurl" size="30" value={mobilians.closeUrl || ''} />
      <input type="hidden" name="MSTR" value={mobilians.popupCloseUrl || ''} />
      <input type="hidden" name="onlyEncrypt" value="Y" />
      <input type="hidden" name="Sendtype" value="GET" />
    </form>
  );
};

export default MobiliansForm;
