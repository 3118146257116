import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { overrideProperties } from '@ecp/common/src/style/utils';
import SwiperUnstyled from '@ecp/common/src/components/unstyled/swiper/SwiperUnstyled';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/grid';
import { ArrowNextButton, ArrowPrevButton } from '@ecp/common/src/components/swiper/component/SwiperArrow';
import SwiperController, {
  SwiperEdgeController,
  SwiperLabel,
  SwiperOuterController,
  SwiperOuterLabel,
  SwiperWhiteInnerLabel,
} from '@ecp/common/src/components/swiper/component/SwiperController';
import SwiperPaging from '@ecp/common/src/components/swiper/component/SwiperPaging';

export const StyledRoundedWrapper = styled.div`
  position: relative;
  ${overrideProperties(sizePropTypes, {})};
  background-color: ${({ backgroundColor }) => backgroundColor ?? 'none'};

  .swiper {
    border-radius: ${({ round }) => (round ? '20px' : 0)};
    ${({ radius }) => radius && { 'border-radius': radius }}
    height: 100%;
    width: 100%;

    &-wrapper {
      transform-style: preserve-3d;
    }

    &-slide {
      display: flex;
      & > div {
        display: flex;
      }
    }
  }

  .swiper-3d .swiper-slide-shadow {
    background: none;
  }
`;

export const getController = (control) => {
  switch (control) {
    case 'inner':
      return SwiperController;
    case 'outer':
      return SwiperOuterController;
    case 'edge':
      return SwiperEdgeController;
    case 'outerLabel':
      return SwiperOuterLabel;
    case 'label':
      return SwiperLabel;
    case 'whiteInnerLabel':
      return SwiperWhiteInnerLabel;
    default:
      return undefined;
  }
};

export const Swiper = React.forwardRef(({ padding, control, ...props }, ref) => {
  const additionalProps = {
    rootProps: { style: { padding } },
    wrapperComponent: StyledRoundedWrapper,
    controllerComponent: getController(control),
    pagingComponent: props.pagingProps && SwiperPaging,
  };

  return (
    <SwiperUnstyled
      ref={ref}
      arrowPrevComponent={ArrowPrevButton}
      arrowNextComponent={ArrowNextButton}
      wrapperComponent={StyledRoundedWrapper}
      {...additionalProps}
      {...props}
    />
  );
});

Swiper.propTypes = {
  arrowProps: PropTypes.shape({
    type: PropTypes.oneOf(['50', '30', '20', '15', '10', '8']),
    position: PropTypes.oneOf(['outer', 'inner']),
  }),
  itemList: PropTypes.array,
  itemProps: PropTypes.object,
  pagingProps: PropTypes.object,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  carouselProps: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  control: PropTypes.oneOf(['inner', 'outer', 'label', 'edge', 'whiteInnerLabel', 'outerLabel', undefined]),
  controlProps: PropTypes.object,
  round: PropTypes.bool,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export const SwiperDefaultProps = {
  carouselProps: {
    delay: 3500,
    slidesPerView: 1,
    spaceBetween: 0,
    autoplay: true,
    loop: true,
  },
};

Swiper.defaultProps = {
  itemList: [],
  itemProps: {},
  ...SwiperDefaultProps,
};

Swiper.displayName = 'Swiper';
export default Swiper;
