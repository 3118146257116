const BANNER_LAYOUT_COMPOSE_CODE = {
  ONE_COLUMN: '10',
  TWO_COLUMN: '20',
};

const BANNER_CONTENTS_DIVISION_CODE = {
  EMPTY: '',
  EXHIBITION: '10',
  EVENT: '20',
  BRAND: '30',
  GOOD: '40',
  ETC: '90',
};

export { BANNER_LAYOUT_COMPOSE_CODE, BANNER_CONTENTS_DIVISION_CODE };
