import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import memberWishApi from '@fo-apis/member/memberWishApi';
import goodListApi from '@fo-apis/good/goodListApi';
import RecentGoodList from '@fo-pages/home/gnb/sideFloating/RecentGoodList';

const RecentGood = ({ recentlyViewedGoodList, onClose }) => {
  const [orderOftenGoodList, setOrderOftenGoodList] = useState([]);
  const [recentlyGoodList, setRecentlyGoodList] = useState(recentlyViewedGoodList);

  useEffect(() => {
    (async () => {
      const { result } = await goodListApi.getGoodOrderOftenList();
      const goodList = result.map(({ goodNumber, thumbnailImageUrl03, orderTimesSum, wish, adultGood }) => ({
        goodNumber,
        badgeContent: orderTimesSum > 99 ? '99+' : `${orderTimesSum}`,
        thumbnailImageUrl03,
        wish,
        adultGood,
      }));
      setOrderOftenGoodList(goodList);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const goodWishList = recentlyViewedGoodList.map(({ goodNumber }) => goodNumber).join();
      const { result = [] } = await memberWishApi.getGoodWishList(goodWishList);

      const wishGoodMap = result.reduce((goodMap, good) => {
        goodMap[good.goodNumber] = good.wish;
        return goodMap;
      }, {});

      const goodList = recentlyViewedGoodList.map(({ goodNumber, ...good }) => ({
        goodNumber,
        ...good,
        wish: !!wishGoodMap[goodNumber],
      }));
      setRecentlyGoodList(goodList);
    })();
  }, [recentlyViewedGoodList]);

  return (
    <>
      <Spacing top={30} />
      <FlexBoxColumn gap={40}>
        <RecentGoodList
          title={'최근 본 상품'}
          goodList={recentlyGoodList}
          emptyListText={'최근 본 상품이 없습니다.'}
          emptyListPadding={'172px 0'}
          onClose={onClose}
        />
        <HorizontalDivider />
        <RecentGoodList
          title={'자주 구매한 상품'}
          goodList={orderOftenGoodList}
          emptyListText={'자주 구매한 상품이 없습니다.'}
          onClose={onClose}
        />
      </FlexBoxColumn>
    </>
  );
};

export default RecentGood;

RecentGood.propTypes = {
  recentlyViewedGoodList: PropTypes.array,
  onClose: PropTypes.func,
};
