import React, { cloneElement, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { FloatingNode, FloatingPortal, FloatingTree } from '@floating-ui/react';

import useDrawer from '@ecp/common/src/components/unstyled/floating/useDrawer';

import { placements } from './useModal';

export const customModalPropsKey = 'custom-floating-props';

const DrawerUnstyled = ({
  duration,
  children,
  rootComponent: RootComponent,
  rootProps,
  zIndex,
  title,
  value,
  closeComponent: CloseComponent,
  closeable: showClose,
  placement,
  offset,
  closeWhenOutside,
  open: openProp,
  defaultOpen,
  onOpenChange,
  onClose,
}) => {
  const modalProps = useDrawer({
    duration,
    placement,
    closeWhenOutside,
    openProp,
    defaultOpen,
    onOpenChange,
    onClose,
  });

  const { nodeId, open, setOpen, getReferenceProps, getModalProps, modalClassNames, positionStrategy, refs, styles } =
    modalProps;

  const close = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [onClose, setOpen]);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const setReference = useCallback(
    (el) => {
      el && refs.setReference(el);
    },
    [refs]
  );

  const ChildrenComponent = useMemo(
    () => <>{children && cloneElement(children, { ref: setReference, ...getReferenceProps() })}</>,
    [children, getReferenceProps, setReference]
  );

  const setFloating = useCallback(
    (el) => {
      el && refs.setFloating(el);
    },
    [refs]
  );

  const ValueComponent = useMemo(
    () => (
      <>
        {value &&
          cloneElement(value, {
            onClose: () => {
              value.props.onClose?.();
              handleClose();
            },
          })}
      </>
    ),
    [handleClose, value]
  );

  return (
    <>
      {ChildrenComponent}
      <FloatingNode id={nodeId}>
        <FloatingPortal>
          {open && (
            <RootComponent
              className={modalClassNames}
              ref={setFloating}
              style={{
                position: positionStrategy,
                [placement]: `${offset}px`,
                ...rootProps,
                ...styles,
              }}
              zIndex={zIndex}
              title={title}
              onClose={handleClose}
              {...getModalProps()}
            >
              {ValueComponent}
              {showClose &&
                CloseComponent &&
                cloneElement(CloseComponent, {
                  onClick: () => {
                    CloseComponent.props.onClick?.();
                    handleClose();
                  },
                })}
            </RootComponent>
          )}
        </FloatingPortal>
      </FloatingNode>
    </>
  );
};

DrawerUnstyled.propTypes = {
  duration: PropTypes.number,
  delay: PropTypes.string,
  children: PropTypes.node,
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  rootProps: PropTypes.object,
  zIndex: PropTypes.number,
  title: PropTypes.string,
  closeComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
  offset: PropTypes.number,
  closeable: PropTypes.bool,
  placement: PropTypes.oneOf(placements),
  closeWhenOutside: PropTypes.bool,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
  onClose: PropTypes.func,
};

DrawerUnstyled.defaultProps = {
  rootComponent: FloatingTree,
  title: '',
};

DrawerUnstyled.displayName = 'DrawerUnstyled';

export default DrawerUnstyled;
