import isEmpty from 'lodash-es/isEmpty';

import { getLabelByPrice } from '@ecp/common/src/utils/good/goodSearchUtil';
import { GOOD_SORT_TYPE } from '@ecp/common/src/const/good';

const initialSearchCondition = {
  price: {
    min: '',
    max: '',
  },
  categoryNumber: {
    large: '',
    middle: '',
    small: '',
    detail: '',
    current: '',
  },
  depthNumber: 0,
};

const initialPageParam = {
  page: 1,
  countPerPage: 60,
  sort: GOOD_SORT_TYPE[0],
  totalCount: 0,
  listView: false,
};

const mobileInitialPageParam = {
  page: 1,
  countPerPage: 20,
  sort: GOOD_SORT_TYPE[0],
  totalCount: 0,
  listView: false,
};

const getQueryString = (filterInfo, pageInfo) => {
  const large = filterInfo.categoryNumber?.large ? `large=${filterInfo.categoryNumber.large}` : '';
  const middle = filterInfo.categoryNumber?.middle ? `middle=${filterInfo.categoryNumber.middle}` : '';
  const small = filterInfo.categoryNumber?.small ? `small=${filterInfo.categoryNumber.small}` : '';
  const detail = filterInfo.categoryNumber?.detail ? `detail=${filterInfo.categoryNumber.detail}` : '';
  const min = filterInfo.price?.min ? `min=${filterInfo.price.min}` : '';
  const max = filterInfo.price?.max ? `max=${filterInfo.price.max}` : '';

  const countPerPage = pageInfo.countPerPage ? `countPerPage=${pageInfo.countPerPage}` : '';
  const page = pageInfo.page ? `page=${pageInfo.page}` : '';
  const sort = pageInfo.sort ? `sort=${pageInfo.sort.key}` : '';
  const listView = pageInfo.listView ? `listView=${pageInfo.listView}` : '';

  return [large, middle, small, detail, min, max, countPerPage, page, sort, listView]
    .filter((it) => !isEmpty(it))
    .join('&');
};

const getDepthNumber = (categoryNumber) => {
  if (categoryNumber.detail) {
    return 4;
  }
  if (categoryNumber.small) {
    return 3;
  }
  if (categoryNumber.middle) {
    return 2;
  }
  if (categoryNumber.large) {
    return 1;
  }
  return 0;
};

const getFilterInfo = (initialFilterInfo, searchParams) => {
  const categoryNumber = {
    large: searchParams.get('large') || '',
    middle: searchParams.get('middle') || '',
    small: searchParams.get('small') || '',
    detail: searchParams.get('detail') || '',
    current:
      searchParams.get('detail') ||
      searchParams.get('small') ||
      searchParams.get('middle') ||
      searchParams.get('large') ||
      '',
  };
  return {
    ...initialFilterInfo,
    categoryNumber,
    depthNumber: getDepthNumber(categoryNumber),
    price: {
      min: searchParams.get('min') || '',
      max: searchParams.get('max') || '',
      label: getLabelByPrice(searchParams.get('min') || '', searchParams.get('max') || ''),
    },
  };
};

const getPageInfo = (initialPageInfo, searchParams) => ({
  page: searchParams.get('page') ? Number(searchParams.get('page')) : initialPageInfo.page,
  countPerPage: searchParams.get('countPerPage')
    ? Number(searchParams.get('countPerPage'))
    : initialPageInfo.countPerPage,
  sort: searchParams.get('sort')
    ? GOOD_SORT_TYPE.find(({ key }) => key === searchParams.get('sort'))
    : initialPageInfo.sort,
  listView: searchParams.get('listView') || initialPageInfo.listView,
});

const navigateWithFilterInfoTable = {
  pathName: '/brand/',
  initSearchCondition: initialSearchCondition,
  initPageParam: initialPageParam,
  getQueryString,
  getFilterInfo,
  getPageInfo,
};

const mobileNavigateWithFilterInfoTable = {
  pathName: '/brand/',
  initSearchCondition: initialSearchCondition,
  initPageParam: mobileInitialPageParam,
  getQueryString,
  getFilterInfo,
  getPageInfo,
};

const getBrandCategoryFilterList = (brandCategoryList, totalDisplayCategoryList) => {
  const brandCategoryNumberSet = new Set([
    ...brandCategoryList.map(({ largeCategoryNumber }) => largeCategoryNumber),
    ...brandCategoryList.map(({ middleCategoryNumber }) => middleCategoryNumber),
    ...brandCategoryList.map(({ smallCategoryNumber }) => smallCategoryNumber),
    ...brandCategoryList.map(({ detailCategoryNumber }) => detailCategoryNumber),
  ]);
  const categoryList = totalDisplayCategoryList.filter(({ categoryNumber }) =>
    brandCategoryNumberSet.has(categoryNumber)
  );
  const largeCategoryList = categoryList.filter(({ depthNumber }) => depthNumber === 1);
  const middleCategoryList = categoryList.filter(({ depthNumber }) => depthNumber === 2);
  const smallCategoryList = categoryList.filter(({ depthNumber }) => depthNumber === 3);
  const detailCategoryList = categoryList.filter(({ depthNumber }) => depthNumber === 4);
  return {
    largeCategoryList,
    middleCategoryList,
    smallCategoryList,
    detailCategoryList,
  };
};

export {
  mobileInitialPageParam,
  navigateWithFilterInfoTable,
  mobileNavigateWithFilterInfoTable,
  getBrandCategoryFilterList,
  getQueryString,
};
