import React, { useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

const TabPanelUnstyled = React.forwardRef(
  ({ index, selectedTabIndex, children, rootComponent: RootComponent, undead, ...props }, ref) => {
    const tabPanelRef = useRef(null);
    useImperativeHandle(ref, () => tabPanelRef.current);

    const isCurrentTab = useMemo(() => index === selectedTabIndex, [index, selectedTabIndex]);
    return (
      (undead || isCurrentTab) && (
        <RootComponent ref={tabPanelRef} style={{ display: isCurrentTab ? 'flex' : 'none' }} {...props}>
          {children}
        </RootComponent>
      )
    );
  }
);

TabPanelUnstyled.displayName = 'TabPanelUnstyled';

TabPanelUnstyled.propTypes = {
  index: PropTypes.number,
  selectedTabIndex: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  undead: PropTypes.bool,
};

TabPanelUnstyled.defaultProps = {
  rootComponent: 'div',
};

export default TabPanelUnstyled;
