export const CUSTOM_EMAIL_DOMAIN = {
  label: '직접입력',
  key: 'custom',
  value: 'custom',
};

export const EMAIL_DOMAIN = [
  CUSTOM_EMAIL_DOMAIN,
  {
    label: 'samsung.com',
    key: 'samsung',
    value: 'samsung.com',
  },
  {
    label: 'partner.samsung.com',
    key: 'partnerSamsung',
    value: 'partner.samsung.com',
  },
  {
    label: 'gmail.com',
    key: 'google',
    value: 'gmail.com',
  },
  {
    label: 'naver.com',
    key: 'naver',
    value: 'naver.com',
  },
  {
    label: 'hanmail.net',
    key: 'daum',
    value: 'hanmail.net',
  },
  {
    label: 'korea.com',
    key: 'korea',
    value: 'korea.com',
  },
];

export const MAX_EMAIL_ID_LENGTH = 100;
export const MAX_EMAIL_DOMAIN_LENGTH = 100;
