import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import usePagination from './usePagination';

export const paginationButtonClassNames = {
  selected: `pagination-button-selected`,
};

const PaginationUnstyled = ({
  rootComponent: Root,
  firstButtonComponent: FirstButton,
  prevButtonComponent: PrevButton,
  pageNumberComponent: PageNumber,
  nextButtonComponent: NextButton,
  lastButtonComponent: LastButton,
  totalCount,
  page,
  defaultPage,
  countPerPage,
  numberOfPageGroup,
  hideFirstButton,
  hidePrevButton,
  hideNextButton,
  hideLastButton,
  onPageChange,
  hideUnusableButtons,
  ...props
}) => {
  const {
    currentPage,
    pages,
    setCurrentPage,
    goNextPage,
    goPrevPage,
    goFirstPage,
    goLastPage,
    hideFirst,
    hidePrev,
    hideNext,
    hideLast,
  } = usePagination({
    page,
    totalCount,
    defaultPage,
    countPerPage,
    numberOfPageGroup,
    onPageChange,
    hideUnusableButtons,
    hideFirstButton,
    hidePrevButton,
    hideNextButton,
    hideLastButton,
  });

  return (
    <Root {...props}>
      {!hideFirst &&
        React.cloneElement(React.createElement(FirstButton), {
          onClick: goFirstPage,
        })}
      {!hidePrev &&
        React.cloneElement(React.createElement(PrevButton), {
          onClick: goPrevPage,
        })}
      {pages.map((page) =>
        React.cloneElement(
          <PageNumber
            key={page}
            value={page}
            className={clsx({
              [paginationButtonClassNames.selected]: page === currentPage,
            })}
            selected={page === currentPage}
          >
            {page}
          </PageNumber>,
          {
            onClick: () => setCurrentPage(page),
          }
        )
      )}
      {!hideNext &&
        React.cloneElement(React.createElement(NextButton), {
          onClick: goNextPage,
        })}
      {!hideLast &&
        React.cloneElement(React.createElement(LastButton), {
          onClick: goLastPage,
        })}
    </Root>
  );
};

export default PaginationUnstyled;

PaginationUnstyled.propTypes = {
  rootComponent: PropTypes.elementType,
  firstButtonComponent: PropTypes.elementType,
  prevButtonComponent: PropTypes.elementType,
  pageNumberComponent: PropTypes.elementType,
  nextButtonComponent: PropTypes.elementType,
  lastButtonComponent: PropTypes.elementType,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  defaultPage: PropTypes.number,
  countPerPage: PropTypes.number,
  numberOfPageGroup: PropTypes.number,
  hideFirstButton: PropTypes.bool,
  hidePrevButton: PropTypes.bool,
  hideNextButton: PropTypes.bool,
  hideLastButton: PropTypes.bool,
  hideUnusableButtons: PropTypes.bool,
  onPageChange: PropTypes.func,
};

PaginationUnstyled.defaultProps = {
  rootComponent: 'div',
  firstButtonComponent: 'button',
  prevButtonComponent: 'button',
  pageNumberComponent: 'button',
  nextButtonComponent: 'button',
  lastButtonComponent: 'button',
  totalCount: 0,
  defaultPage: 1,
  countPerPage: 10,
  numberOfPageGroup: 10,
  hideFirstButton: false,
  hidePrevButton: false,
  hideNextButton: false,
  hideLastButton: false,
  hideButtons: false,
};
