const MAIN_FUNCTION_TYPE_CODE = {
  COMMON: '10',
  MAIN: '20',
};

const MAIN_FUNCTION_CODE = {
  ALLIANCE_BAR: '11',
  SEARCH_PHRASE: '13',
  GNB: '12',
  AD_BANNER: '70',
  BEST_BRAND: '80',
  EMPLOYEE_BENEFIT: '50',
  ALLIANCE_BANNER: '71',
  POPUP_BANNER_LARGE: '72',
  POPUP_BANNER_SMALL: '73',
  POPUP_BANNER_BOTTOM: '78',
  POPUP_BANNER_EXHIBIT: '99', //sdi전용기획전일때 popup띄우기위해 전용으로 만듦
  POPUP_BANNER_EXHIBIT_SECONDARY: '98', //sdi전용기획전일때 popup띄우기위해 전용으로 만듦2
  CUSTOM: '10',
  TODAY_SALE: '20',
  WEEKLY_SALE: '21',
  BEST_REVIEW: '30',
  SAMSUNG_FAMILY: '40',
  NEW_ITEM: '60',
  BEST_ITEM: '61',
  HERO_BANNER: '74',
  STRIP_BANNER_COMMON: '75',
  STRIP_BANNER_ONE: '76',
  STRIP_BANNER_TWO: '77',
  RECOMMEND_BRAND: '81',
  EXHIBITION: '90',
  MD_PICK: '91',
  GOOD_ONE: '93',
  GOOD_TWO: '94',
  GOOD_THREE: '95',
  EVENT_MALL: '29',
};

const BEST_ITEM_CLASS_CODE = {
  VIEW: '10',
  ORDER: '20',
  BRAND: '30',
  REAL_TIME: '40',
};

export { MAIN_FUNCTION_TYPE_CODE, MAIN_FUNCTION_CODE, BEST_ITEM_CLASS_CODE };
