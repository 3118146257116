import React from 'react';
import PropTypes from 'prop-types';

import theme from '@ecp/common/src/style/theme/default';

const HighlightText = ({ children, keyword, style }) => {
  if (keyword?.length > 0) {
    const regEscape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const parts = children !== null ? children.split(new RegExp(`(${regEscape(keyword)})`, 'gi')) : [];

    return (
      <span>
        {parts.map((part, i) => (
          <span key={i} style={part.toLowerCase() === keyword?.toLowerCase?.() ? style : {}}>
            {part}
          </span>
        ))}
      </span>
    );
  } else {
    return <span>{children}</span>;
  }
};

HighlightText.propTypes = {
  children: PropTypes.string,
  keyword: PropTypes.string,
  style: PropTypes.object,
};

HighlightText.defaultProps = {
  children: '',
  keyword: '',
  style: { color: theme.color.primary, fontWeight: '700' },
};

export default HighlightText;
