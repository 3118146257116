import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import ko from 'date-fns/locale/ko';
import { isPast, isSunday } from 'date-fns';

import useControlled from '@ecp/common/src/hooks/useControlled';
import CalendarHeader from '@ecp/common/src/components/calendar/CalendarHeader';
import { NO_END_DATE } from '@ecp/common/src/components/calendar/useCalendar';
import { DivTitle } from '@ecp/common/src/layouts/flex/styled';

registerLocale('ko', ko);

const CalendarUnstyled = React.forwardRef((props, ref) => {
  const {
    value: valueProp,
    defaultValue,
    onChange,
    dateFormat = 'yyyy.MM.dd',
    header: CustomHeader,
    selectOnly,
    dateGb,
    ...otherProps
  } = props;

  const { value, setValueWhenUncontrolled } = useControlled({
    value: valueProp,
    defaultValue,
  });

  const handleChange = useCallback(
    (date) => {
      setValueWhenUncontrolled(date);
      onChange?.(date);
    },
    [onChange, setValueWhenUncontrolled]
  );

  const handleSelectOnly = useCallback(
    (e) => {
      selectOnly && e.preventDefault();
    },
    [selectOnly]
  );

  const dayClassName = (date) => {
    const classList = [
      isPast(date) ? 'react-datepicker__day--past' : '',
      isSunday(date) ? 'react-datepicker__day--sunday' : '',
    ];
    return classList.join(' ');
  };

  const highlightDates = props?.highlightDates;
  const highlightDatesFilter = (date) =>
    highlightDates.filter((filterDate) => filterDate.toDateString() === date.toDateString());

  const renderDayContents = (day, date) => {
    const isToday = date.toDateString() === new Date().toDateString();
    return (
      <span>
        {day}
        {isToday && ( highlightDates ? (highlightDatesFilter(date).length === 0 && (
          <DivTitle>오늘</DivTitle> )) : ( <DivTitle>오늘</DivTitle> ))}
        {highlightDates && ( highlightDatesFilter(date).length > 0 && (
          <DivTitle>
            Wel story 출석완료
          </DivTitle>
        ))}
      </span>
    );
  };

  return (
    <DatePicker
      {...otherProps}
      strictParsing
      locale={'ko'}
      dayClassName={dayClassName}
      dateFormat={dateFormat}
      selected={value}
      onChange={handleChange}
      onKeyDown={handleSelectOnly}
      renderCustomHeader={(props) => <CustomHeader showMonthYearPicker={otherProps.showMonthYearPicker} {...props} />}
      ref={ref}
      title={dateGb ? (dateGb === 'start' ? '시작일' : '종료일') : '년/월/일'}
      renderDayContents={renderDayContents}
    />
  );
});

CalendarUnstyled.displayName = 'CalendarUnstyled';

CalendarUnstyled.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func,
  isAttendance: PropTypes.bool,
  renderDayContents: PropTypes.func,
  dayClassName: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  excludeDates: PropTypes.array,
  excludeDateIntervals: PropTypes.array,
  includeDates: PropTypes.array,
  includeDateIntervals: PropTypes.array,
  highlightDates: PropTypes.array,
  inline: PropTypes.bool,
  dateFormat: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
  showMonthYearPicker: PropTypes.bool,
  selectOnly: PropTypes.bool,
  dateGb: PropTypes.string,
};

CalendarUnstyled.defaultProps = {
  onChange: () => {},
  showPopperArrow: false,
  defaultValue: new Date(),
  enabledDates: [],
  disabledDates: [],
  attendanceDates: [],
  maxDate: new Date(NO_END_DATE),
  header: CalendarHeader,
};

CalendarUnstyled.displayName = 'CalendarUnstyled';

export default CalendarUnstyled;
