import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import qs from 'qs';

const MobiliansClosePage = () => {
  const location = useLocation();
  useEffect(() => {
    window.opener.postMessage(qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (s) => s }), '*');
    window.close();
  }, [location.search]);

  return <div></div>;
};

export default MobiliansClosePage;
