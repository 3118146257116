import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  getApplyLimit,
  getAvailableDate,
  getCouponDetailByDevice,
  getCouponDetailByType,
} from '@ecp/common/src/utils/couponUtils';
import theme from '@ecp/common/src/style/theme/default';
import { zIndex } from '@ecp/common/src/style/constant';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox, TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';
import Tooltip from '@ecp/common/src/components/tooltip/Tooltip';
import { ReactComponent as DownloadIcon } from '@ecp/common/src/assets/icon/icon__app-download.svg';
import { ReactComponent as WhiteDownloadIcon } from '@ecp/common/src/assets/icon/icon_download_white.svg';
import { ReactComponent as DownloadCompleteStampImage } from '@ecp/common/src/assets/image/image__coupon_download_complete.svg';

import { BadgeText, CouponNameText, HelpIcon } from '@fo-components/coupon/CouponComponents';

const HorizontalCouponCard = ({ width, coupon, onClick }) => {
  const couponDetail = useMemo(
    () => ({
      ...getCouponDetailByType({ ...coupon }),
      ...getCouponDetailByDevice({ ...coupon }),
      applyLimit: getApplyLimit({ ...coupon }),
      availableDate: getAvailableDate({ ...coupon }),
    }),
    [coupon]
  );
  const handleDownloadCouponButtonClick = () => {
    if (!coupon.downloadable) return;
    onClick && onClick(coupon.promotionNo);
  };

  return (
    <FlexBox width={width}>
      <FlexBox>{!coupon.downloadable && <DownloadCompleteDimWrapper />}</FlexBox>
      <CouponDetailContainer width={width}>
        <FlexBoxSpaceBetween width={'100%'}>
          <FlexBox>
            <TextBox weight={700} size={24}>
              {couponDetail.benefitValue}
            </TextBox>
            <TextBox weight={700} size={20}>
              {couponDetail.benefitValueType}
            </TextBox>
          </FlexBox>
          <FlexBox gap={'6px'}>
            <BadgeText {...couponDetail}>{couponDetail.badgeText}</BadgeText>
            {couponDetail.applyDevice !== 'all' && <BadgeText>{couponDetail.applyDevice}</BadgeText>}
          </FlexBox>
        </FlexBoxSpaceBetween>
        <Spacing bottom={5} />
        <FlexBox>
          <CouponNameText width="406px">{coupon.promotionName}</CouponNameText>
          {coupon.promotionNoticeMessage && (
            <FlexBox>
              <Spacing left={4} />
              <Tooltip
                value={
                  <TextBox
                    padding={'0 25px 0 0'}
                    size={theme.font.size.error}
                    weight={'350'}
                    color={theme.color.text.sub}
                    style={{ overflowWrap: 'anywhere' }}
                  >
                    {coupon.promotionNoticeMessage}
                  </TextBox>
                }
                arrow
                closeable={true}
                openWhenClicked
                placement={'bottom-end'}
                rootProps={{ zIndex: zIndex.tooltip }}
              >
                <HelpIcon />
              </Tooltip>
            </FlexBox>
          )}
        </FlexBox>
        <Spacing bottom={8} />
        <TextBox weight={350} size={12} color={theme.color.text.sub} line-height={'18px'}>
          {couponDetail.applyLimit}
          <br />
          {couponDetail.availableDate}
        </TextBox>
      </CouponDetailContainer>
      <DownloadButtonWithQuantity
        onClick={handleDownloadCouponButtonClick}
        limited={coupon.pidIssueQuantityCode !== '20'}
        count={coupon.downloadableCount}
        applyDevice={couponDetail.applyDevice}
        styleProps={{ backgroundColor: couponDetail.rightSideColor || theme.color.text.sub }}
      />
    </FlexBox>
  );
};

export default HorizontalCouponCard;

HorizontalCouponCard.propTypes = {
  width: PropTypes.string,
  coupon: PropTypes.object,
  onClick: PropTypes.func,
};

HorizontalCouponCard.defaultProps = {
  width: '520px',
};

const CouponDetailContainer = styled(FlexBoxColumn)`
  align-items: flex-start;
  padding: 20px 20px;
  width: 470px;
  height: 130px;
  border: 1px solid #eaeaea;
  border-right: none;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #ffffff;
`;

const DownloadButtonWithQuantity = ({ limited, count, applyDevice, styleProps, onClick }) => {
  const whiteVersion = applyDevice !== 'all';
  return (
    <DownloadButton onClick={onClick} style={styleProps}>
      {(!limited || count > 0) && (whiteVersion ? <WhiteDownloadIcon /> : <DownloadIcon />)}
      {(!limited || count > 1) && (
        <TextBoxAlignCenter weight={500} size={10} color={whiteVersion ? '#ffffff' : '#141415'}>
          {limited ? count : '+99'}장
        </TextBoxAlignCenter>
      )}
    </DownloadButton>
  );
};

DownloadButtonWithQuantity.propTypes = {
  styleProps: PropTypes.object,
  applyDevice: PropTypes.string,
  count: PropTypes.number,
  limited: PropTypes.bool,
  onClick: PropTypes.func,
};

const DownloadButton = styled(FlexBoxColumn)`
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 130px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const DownloadCompleteDimWrapper = () => {
  return (
    <DimWrapper>
      <FlexBox width="80px" height="80px">
        <DownloadCompleteStampImage />
      </FlexBox>
    </DimWrapper>
  );
};

const DimWrapper = styled(FlexBox)`
  justify-content: flex-end;
  position: absolute;
  width: 520px;
  height: 130px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  border-radius: 10px;
  padding: 20px;
`;
