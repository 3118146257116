import { useCallback, useEffect, useMemo } from 'react';

import { getLocalStorageItem, saveLocalStorageItem } from '@ecp/common/src/utils/utils';
import adultPath from '@ecp/common/src/assets/icon/icon__adult-certification.svg';

const RECENTLY_VIEWED_GOOD_LIST = 'recently_viewed_good_list';
const MAX_GOOD_LIST_SIZE = 150;

const useRecentlyViewedGoodList = (userInfo, recentlyViewedGoodList, adultCertification, setRecentlyViewedGoodList) => {
  const userId = useMemo(() => {
    return userInfo?.userId;
  }, [userInfo?.userId]);

  const addRecentlyViewedGood = useCallback(
    ({ goodDetail }) => {
      const goodListMap = JSON.parse(getLocalStorageItem(RECENTLY_VIEWED_GOOD_LIST)) || {};
      const myRecentlyViewedGoodList = goodListMap?.[userId] || [];
      const updatedGoodList = [
        { ...goodDetail, viewDate: new Date().toISOString() },
        ...myRecentlyViewedGoodList.filter((it) => it.goodNumber !== goodDetail?.goodNumber),
      ].slice(0, MAX_GOOD_LIST_SIZE);
      saveLocalStorageItem(
        RECENTLY_VIEWED_GOOD_LIST,
        JSON.stringify({ ...goodListMap, [userId]: [...updatedGoodList] })
      );
      setRecentlyViewedGoodList(updatedGoodList);
    },
    [setRecentlyViewedGoodList, userId]
  );

  const floatingThumbnail = useMemo(() => {
    if (!recentlyViewedGoodList || !recentlyViewedGoodList[0]) {
      return '';
    }

    const mostRecentGood = recentlyViewedGoodList[0];
    const adultVerified = adultCertification || !mostRecentGood.adultGood;
    return adultVerified ? mostRecentGood.thumbnailImageUrl03 : adultPath;
  }, [adultCertification, recentlyViewedGoodList]);

  return {
    recentlyViewedGoodList,
    floatingThumbnail,
    addRecentlyViewedGood,
  };
};

export const useGetRecentlyViewedGoodList = (
  userInfo,
  recentlyViewedGoodList,
  setRecentlyViewedGoodList,
  getGoodDisplayableList
) => {
  const userId = useMemo(() => {
    return userInfo?.userId;
  }, [userInfo?.userId]);

  const getRecentlyViewedGoodList = useCallback(() => {
    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - 30);
    const dateAgoStr = daysAgo.toISOString();
    const goodListMap = JSON.parse(getLocalStorageItem(RECENTLY_VIEWED_GOOD_LIST)) || {};
    const goodList = goodListMap[userId] || [];

    return goodList
      .filter(({ viewDate }) => dateAgoStr < viewDate)
      .sort((a, b) => new Date(b?.viewDate) - new Date(a?.viewDate));
  }, [userId]);

  useEffect(() => {
    (async () => {
      if (!recentlyViewedGoodList && userId) {
        const goodList = getRecentlyViewedGoodList();
        const goodNumberListStr = goodList.map(({ goodNumber }) => goodNumber).join(',');
        const { result = [] } = await getGoodDisplayableList(goodNumberListStr, MAX_GOOD_LIST_SIZE);

        const displayableGoodNumberToGood = result.reduce((acc, elem) => {
          acc[elem.goodNumber] = elem;
          return acc;
        }, []);
        const displayableGoodList = goodList
          .map(({ goodNumber, viewDate }) => {
            if (!displayableGoodNumberToGood[goodNumber]) {
              return null;
            }
            return { ...displayableGoodNumberToGood[goodNumber], viewDate };
          })
          .filter((it) => !!it);
        setRecentlyViewedGoodList(displayableGoodList);
      }
    })();
  }, [getGoodDisplayableList, getRecentlyViewedGoodList, recentlyViewedGoodList, setRecentlyViewedGoodList, userId]);
};

export default useRecentlyViewedGoodList;
