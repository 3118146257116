import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import {
  FlexBox,
  FlexBoxColumn,
  FlexBoxSpaceBetween,
  HorizontalDivider,
  VerticalDivider,
} from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Pagination from '@ecp/common/src/components/pagination/Pagination';
import Dropdown from '@ecp/common/src/components/dropdown/Dropdown';
import { IconButton } from '@ecp/common/src/components/button/IconButton';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import { GOOD_PRINT_TYPE, GOOD_SORT_TYPE } from '@ecp/common/src/const/good';
import { ReactComponent as CardViewIcon } from '@ecp/common/src/assets/icon/icon__card-view.svg';
import { ReactComponent as ListViewIcon } from '@ecp/common/src/assets/icon/icon__list-view.svg';

import GoodCard from '@fo-components/good/GoodCard';
import NoResultFoundList from '@fo-components/common/NoResultFoundList';

const GoodTableCardList = ({
  goodList,
  pageProps,
  onSortChange,
  onPageChange,
  onCountPerPageChange,
  cardType,
  onViewChange,
  titleType,
  tabList,
  onCardClick,
  noDataMessage,
  showRelated,
  maxCount,
  handleSubDisplayCategoryGoodSoldOutChangeList,
}) => (
  <>
    <TopArea
      pageProps={pageProps}
      onSortChange={onSortChange}
      onCountPerPageChange={onCountPerPageChange}
      cardType={cardType}
      onViewChange={onViewChange}
      titleType={titleType}
      tabList={tabList}
      maxCount={maxCount}
    />
    <Spacing top={14} />
    {goodList.length > 0 ? (
      <>
        <ContentsList goodList={goodList} cardType={cardType} onCardClick={onCardClick} showRelated={showRelated} handleSubDisplayCategoryGoodSoldOutChangeList={handleSubDisplayCategoryGoodSoldOutChangeList}/>
        <Spacing top={20} />
        <Pagination
          hideUnusableButtons
          data-ds-label2={'pagination'}
          page={pageProps.page}
          countPerPage={pageProps.countPerPage}
          defaultPage={1}
          onPageChange={onPageChange}
          totalCount={pageProps.totalCount}
        />
      </>
    ) : (
      <NoResultFoundList message={noDataMessage} />
    )}
  </>
);

GoodTableCardList.propTypes = {
  goodList: PropTypes.array,
  pageProps: PropTypes.object,
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onCountPerPageChange: PropTypes.func,
  cardType: PropTypes.oneOf(['tableSmall', 'tableMedium', 'small']),
  onViewChange: PropTypes.func,
  titleType: PropTypes.oneOf(['primary', 'secondary', 'third']),
  tabList: PropTypes.array,
  onCardClick: PropTypes.func,
  noDataMessage: PropTypes.string,
  showRelated: PropTypes.bool,
  maxCount: PropTypes.number,
  handleSubDisplayCategoryGoodSoldOutChangeList: PropTypes.func,
};
GoodTableCardList.defaultProps = {
  pageProps: {
    page: 1,
    countPerPage: 60,
    sort: GOOD_SORT_TYPE[0],
    totalCount: 0,
  },
  maxCount: 999,
};

const initialSelectedItem = {
  countPerPage: 60,
  sort: GOOD_SORT_TYPE[0],
};

const TopArea = ({
  pageProps,
  onSortChange,
  onCountPerPageChange,
  cardType,
  onViewChange,
  titleType,
  tabList,
  maxCount,
}) => {
  const { countPerPage, sort, totalCount } = pageProps;
  const [selectedCountPerPage, setSelectedCountPerPage] = useState(
    countPerPage ? countPerPage : initialSelectedItem.countPerPage
  );
  const [selectedSortItem, setSelectedSortItem] = useState(sort ? sort : initialSelectedItem.sort);

  const handleChangeSortDropDown = useCallback(
    (sort) => {
      if (selectedSortItem === sort) {
        return;
      }
      onSortChange(sort);
      setSelectedSortItem(sort);
    },
    [selectedSortItem, onSortChange]
  );

  const handleChangeCountDropDown = useCallback(
    (countPerPage) => {
      if (selectedCountPerPage === countPerPage) {
        return;
      }
      onCountPerPageChange(countPerPage);
      setSelectedCountPerPage(countPerPage?.value);
    },
    [selectedCountPerPage, onCountPerPageChange]
  );

  const selectedCountPerPageItem = useMemo(() => {
    return GOOD_PRINT_TYPE.find((it) => it.value === selectedCountPerPage);
  }, [selectedCountPerPage]);

  useEffect(() => {
    setSelectedCountPerPage(countPerPage);
    setSelectedSortItem(sort);
  }, [countPerPage, sort]);
  return (
    <FlexBoxSpaceBetween width={'100%'}>
      {totalCount === undefined && <FlexBox> </FlexBox>}
      {titleType === 'primary' && totalCount !== undefined && (
        <FlexBox width={'160px'} height={'40px'} align-items={'flex-end'}>
          <TextBox size={theme.font.size.table}>전체</TextBox>
          <Spacing left={6} />
          <TextBox size={theme.font.size.table} weight={theme.font.weight.bold}>
            {totalCount ? (totalCount <= maxCount ? NumberFormat(totalCount) : NumberFormat(maxCount) + '+') : 0}
          </TextBox>
          <TextBox size={theme.font.size.table}>개</TextBox>
        </FlexBox>
      )}
      {titleType === 'secondary' && totalCount !== undefined && (
        <FlexBox>
          <TextBox size={theme.font.size.title} weight={theme.font.weight.bold} color={theme.color.primary}>
            {totalCount ? (totalCount <= maxCount ? NumberFormat(totalCount) : NumberFormat(maxCount) + '+') : 0}
          </TextBox>
          <TextBox size={theme.font.size.title} weight={theme.font.weight.medium}>
            개 상품이 있습니다.
          </TextBox>
        </FlexBox>
      )}
      {titleType === 'third' && tabList?.length > 0 && (
        <FlexBox padding={'10px 0 0 0 '} data-ds-label2={'prd_type'}>
          {tabList?.map(({ label, selected, onClick, value }, index) => (
            <FlexBox key={`top-area-tab__${index}`}>
              {index !== 0 && (
                <>
                  <Spacing left={10} />
                  <VerticalDivider width={'1px'} height={'16px'} color={theme.color.line.background} />
                  <Spacing left={12} />
                </>
              )}
              <TextButton
                textProps={{
                  size: theme.font.size.table,
                  weight: selected ? theme.font.weight.medium : theme.font.weight.demiLight,
                  color: selected ? theme.color.primary : theme.color.text.sub,
                }}
                style={{
                  textDecoration: selected ? 'underline' : '',
                  color: selected ? theme.color.primary : '',
                  textUnderlineOffset: selected ? '4px' : '',
                }}
                onClick={() => onClick(value)}
              >
                {label}
              </TextButton>
            </FlexBox>
          ))}
        </FlexBox>
      )}
      <FlexBox width={'auto'}>
        <Dropdown
          width={'160px'}
          data-ds-label2={'sort_type'}
          value={selectedSortItem}
          items={GOOD_SORT_TYPE}
          onChange={(val) => handleChangeSortDropDown(val)}
        />
        {onCountPerPageChange && (
          <>
            <Spacing left={6} />
            <Dropdown
              width={'120px'}
              data-ds-label2={'sort_type'}
              value={selectedCountPerPageItem}
              items={GOOD_PRINT_TYPE}
              onChange={(val) => handleChangeCountDropDown(val)}
            />
          </>
        )}
        {cardType === 'tableMedium' && (
          <>
            <Spacing left={10} />
            <FlexBox onClick={onViewChange}>
              <IconButton
                icon={CardViewIcon}
                width={'18'}
                height={'18'}
                buttonProp={{ ariaLabel: '바둑형으로 보기' }}
              />
            </FlexBox>
          </>
        )}
        {cardType === 'small' && (
          <>
            <Spacing left={10} />
            <FlexBox onClick={onViewChange}>
              <IconButton
                icon={ListViewIcon}
                width={'18'}
                height={'18'}
                buttonProp={{ ariaLabel: '목록형으로 보기' }}
              />
            </FlexBox>
          </>
        )}
      </FlexBox>
    </FlexBoxSpaceBetween>
  );
};

TopArea.propTypes = {
  pageProps: PropTypes.object,
  onSortChange: PropTypes.func,
  onCountPerPageChange: PropTypes.func,
  cardType: PropTypes.oneOf(['tableSmall', 'tableMedium', 'small']),
  onViewChange: PropTypes.func,
  titleType: PropTypes.oneOf(['primary', 'secondary', 'third']),
  tabList: PropTypes.array,
  maxCount: PropTypes.number,
};
TopArea.defaultProps = {
  titleType: 'primary',
};
const ContentsList = ({ goodList = [], cardType, onCardClick, showRelated, handleSubDisplayCategoryGoodSoldOutChangeList }) => {
  const navigate = useNavigate();
  const handleCardClick = useCallback(
    (goodDetail) => {
      navigate(`/good/${goodDetail?.goodNumber}`);
      onCardClick && onCardClick();
    },
    [navigate, onCardClick]
  );

  const itemList = useMemo(
    () => (
      <>
        {goodList?.map((goodDetail, index) => (
          <GoodCard
            key={`${goodDetail?.goodNumber}__${index}`}
            showRelated={showRelated}
            onClick={() => handleCardClick(goodDetail)}
            {...goodDetail}
            type={cardType}
            handleSubDisplayCategoryGoodSoldOutChangeList={handleSubDisplayCategoryGoodSoldOutChangeList}
          />
        ))}
      </>
    ),
    [goodList, showRelated, cardType, handleCardClick]
  );

  return (
    <FlexBoxColumn flex={'1'} width={'100%'} data-ds-label2={'prd_list'}>
      <HorizontalDivider height={'2px'} color={theme.color.line.emphasis} />
      {cardType.includes('table') ? (
        <FlexBoxColumn width={'100%'}>{itemList}</FlexBoxColumn>
      ) : (
        <>
          <Spacing top={30} />
          <FlexBox
            width={'100%'}
            flex-direction={'row'}
            flex-wrap={'wrap'}
            gap={'20px'}
            justify-content={'flex-start'}
            align-items={'flex-start'}
          >
            {itemList}
          </FlexBox>
          <Spacing top={30} />
        </>
      )}
    </FlexBoxColumn>
  );
};

ContentsList.propTypes = {
  goodList: PropTypes.array,
  cardType: PropTypes.oneOf(['tableSmall', 'tableMedium', 'small']),
  onCardClick: PropTypes.func,
  showRelated: PropTypes.bool,
  handleSubDisplayCategoryGoodSoldOutChangeList: PropTypes.func,
};
ContentsList.defaultProps = {
  cardType: 'tableSmall',
};
export default GoodTableCardList;
