import { selector } from 'recoil';

import { BEST_CATEGORY_ALL } from '@ecp/common/src/const/good';

import { withMainCategoryTreeList } from '@fo-recoil/category/selector';

export const withLargeCategoryList = selector({
  key: 'withLargeCategoryList',
  get: ({ get }) => {
    const categories =
      get(withMainCategoryTreeList)
        .filter((item) => item.depthNumber === 1)
        .map(({ categoryNumber, categoryName, hasNewGood }) => ({
          displayGroupNumber: categoryNumber,
          name: categoryName,
          hasNewGood,
        })) || [];
    return [BEST_CATEGORY_ALL, ...categories];
  },
});
