import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getMonth } from 'date-fns';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import CouponBaseCard from '@ecp/common/src/components/coupon/components/CouponBaseCard';
import { COUPON_PROPERTY, COUPON_STYLE_PC } from '@ecp/common/src/const/coupon/couponConst';

const BirthdayCouponCard = ({ baseStyle, handleButtonClick }) => {
  const CardMessage = useMemo(() => {
    return (
      <FlexBoxColumn style={baseStyle.MESSAGE_STYLE} justify-content={'flex-start'} align-items={'flex-start'}>
        <FlexBox>
          <TextBoxAlignLeft style={baseStyle.MESSAGE} color={theme.color.text.selected}>
            {`${getMonth(new Date()) + 1}월`}
          </TextBoxAlignLeft>
          <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'이 생일이시네요~'}</TextBoxAlignLeft>
        </FlexBox>
        <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'생일 축하 쿠폰을 선물로 드려요'}</TextBoxAlignLeft>
        <Spacing top={baseStyle.DISCOUNT_SPACING} />
        <FlexBox justify-content={'flex-start'} align-items={'baseline'}>
          <TextBoxAlignLeft style={baseStyle.DISCOUNT}>{'8'}</TextBoxAlignLeft>
          <TextBoxAlignLeft style={baseStyle.DISCOUNT_UNIT}>{'%'}</TextBoxAlignLeft>
        </FlexBox>
      </FlexBoxColumn>
    );
  }, [
    baseStyle.DISCOUNT,
    baseStyle.DISCOUNT_SPACING,
    baseStyle.DISCOUNT_UNIT,
    baseStyle.MESSAGE,
    baseStyle.MESSAGE_STYLE,
  ]);

  return (
    <CouponBaseCard
      downloadable
      baseStyle={baseStyle}
      iconImage={COUPON_PROPERTY.BIRTHDAY.iconImage}
      cardColor={COUPON_PROPERTY.BIRTHDAY.cardColor}
      buttonText={COUPON_PROPERTY.BIRTHDAY.buttonText}
      handleButtonClick={handleButtonClick}
      altText={(getMonth(new Date()) + 1) + '월이 생일이시네요 생일 축하 쿠폰을 선물로 드려요 ,' + COUPON_PROPERTY.BIRTHDAY.buttonText}
    >
      {CardMessage}
    </CouponBaseCard>
  );
};

BirthdayCouponCard.propTypes = {
  baseStyle: PropTypes.object,
  handleButtonClick: PropTypes.func,
};

BirthdayCouponCard.defaultProps = {
  baseStyle: COUPON_STYLE_PC,
  handleButtonClick: () => {},
};

export default BirthdayCouponCard;
