import { getRequest, postRequest } from '@fo-apis/axios';

export const getCommonCodes = (commonCode) =>
  getRequest({
    url: 'api/v1/common/code/get-code',
    params: { commonCode },
  });

const getSiteInformation = () =>
  getRequest({
    url: '/api/v1/system/site/default-information',
  });

const uploadPublicFiles = (payload) =>
  postRequest({
    url: '/api/v1/common/file/upload-public',
    data: payload,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

const uploadProtectedFiles = (payload) =>
  postRequest({
    url: '/api/v1/common/file/upload-protected',
    data: payload,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });

const getMemberInterestCode = () =>
  getRequest({
    url: 'api/v1/common/code/get-member-interest-code',
  });

const getMemberGenderCode = () =>
  getRequest({
    url: 'api/v1/common/code/get-member-gender-code',
  });

const sendAppDownloadSms = (payload) =>
  postRequest({
    url: 'api/v1/common/app-download/send-sms',
    data: { ...payload },
  });

const sendUnknownError = (error) => 
  postRequest({
    headers: {
      'Content-Type': 'text/plain'
    },
    url: 'api/v1/system/common/send-unknown-error',
    data: error,
  });

export default {
  getCommonCodes,
  getSiteInformation,
  uploadPublicFiles,
  uploadProtectedFiles,
  getMemberInterestCode,
  getMemberGenderCode,
  sendAppDownloadSms,
  sendUnknownError,
};
