import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import CouponBaseCard from '@ecp/common/src/components/coupon/components/CouponBaseCard';
import { COUPON_PROPERTY, COUPON_STYLE_PC } from '@ecp/common/src/const/coupon/couponConst';

const ReviewCouponCard = ({ pointValue, countValue, baseStyle, handleButtonClick }) => {
  const localePointValue = useMemo(() => {
    return Number(pointValue).toLocaleString();
  }, [pointValue]);

  const CardMessage = useMemo(() => {
    return (
      <FlexBoxColumn style={baseStyle.MESSAGE_STYLE} justify-content={'flex-start'} align-items={'flex-start'}>
        <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'리뷰를 작성하고'}</TextBoxAlignLeft>
        <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'적립금을 받아 보세요'}</TextBoxAlignLeft>
      </FlexBoxColumn>
    );
  }, [baseStyle.MESSAGE, baseStyle.MESSAGE_STYLE]);

  return (
    <CouponBaseCard
      baseStyle={baseStyle}
      iconImage={COUPON_PROPERTY.REVIEW.iconImage}
      cardColor={COUPON_PROPERTY.REVIEW.cardColor}
      buttonText={COUPON_PROPERTY.REVIEW.buttonText}
      iconPointValue={localePointValue}
      iconPointValueStyle={baseStyle.POINT_LOCATION.review}
      iconCountValue={countValue}
      iconCountUnit={'건'}
      iconCountValueStyle={baseStyle.COUNT_LOCATION.review}
      handleButtonClick={handleButtonClick}
      altText={'리뷰를 작성하고 적립금을 받아 보세요 ,' + COUPON_PROPERTY.REVIEW.buttonText}
    >
      {CardMessage}
    </CouponBaseCard>
  );
};

ReviewCouponCard.propTypes = {
  pointValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  baseStyle: PropTypes.object,
  handleButtonClick: PropTypes.func,
};

ReviewCouponCard.defaultProps = {
  pointValue: '',
  countValue: '',
  baseStyle: COUPON_STYLE_PC,
};
export default ReviewCouponCard;
