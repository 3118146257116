import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { mergeRefs } from 'react-merge-refs';

import clsx from 'clsx';

import { FlexBox } from '@ecp/common/src/layouts/flex/styled';

import useDropdown from './useDropdown';
import SelectUnstyled from './SelectUnstyled';

export const stateClassNames = Object.freeze({
  open: 'dropdown-open',
  disabled: 'dropdown-disabled',
  notSelected: 'dropdown-notSelected',
  readOnly: 'dropdown-readOnly',
  error: 'dropdown-error',
});

const DropdownUnstyled = React.forwardRef((props, ref) => {
  const { dropdownRoot: DropdownRoot, labelSlot: Label, disabled, readOnly, error, ...dropdownProps } = props;
  const {
    value,
    handleClickLabel,
    getLabelProps,
    getSelectProps,
    open,
    refs,
    getReferenceProps,
    floatingPosition,
    positionStrategy,
  } = useDropdown(props);

  const targetRef = useMemo(() => mergeRefs([refs.setReference, ref]), [refs.setReference, ref]);

  const stateClasses = {
    [stateClassNames.open]: open,
    [stateClassNames.notSelected]: !value,
    [stateClassNames.disabled]: disabled,
    [stateClassNames.readOnly]: readOnly,
    [stateClassNames.error]: !open && error,
  };

  return (
    <DropdownRoot {...dropdownProps}>
      <FlexBox width={'auto'} height={'auto'} {...getReferenceProps({ ref: targetRef, onClick: handleClickLabel })}>
        <Label {...dropdownProps} {...getLabelProps()} className={clsx(stateClasses)} />
      </FlexBox>
      {open && !disabled && !readOnly && (
        <SelectUnstyled
          ref={refs.setFloating}
          style={{
            position: positionStrategy,
            top: floatingPosition.top ?? 0,
            left: floatingPosition.left ?? 0,
          }}
          {...getSelectProps()}
        />
      )}
    </DropdownRoot>
  );
});

DropdownUnstyled.displayName = 'DropdownUnstyled';

DropdownUnstyled.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  dropdownRoot: PropTypes.elementType,
  dropdownItemsRoot: PropTypes.elementType,
  labelSlot: PropTypes.elementType,
  itemSlot: PropTypes.elementType,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  itemsMaxHeight: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
};

export default DropdownUnstyled;
