import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import { zIndex } from '@ecp/common/src/style/constant';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';

import { StyledDefaultItem, StyledDefaultLabel, StyledDivLabel } from '../unstyled/dropdown/DropdownDefaultComponent';
import DropdownUnstyled from '../unstyled/dropdown/DropdownUnstyled';
import { overrideProperties } from '../../style/utils';

const StyledErrorMessage = styled.div`
  font-weight: ${theme.font.weight.demiLight};
  font-size: ${theme.font.size.error};
  color: ${theme.color.text.error};
  line-height: 19px;
  height: 19px;
`;

export const StyledDropdownRoot = styled.div`
  position: relative;
  ${overrideProperties(sizePropTypes, { width: '100%', height: '40px' })};
  ${({ maxWidth }) => `max-width: ${maxWidth};`}
  background-color: ${theme.color.background.white};
  cursor: pointer;
`;

export const StyledDropdownButtonRoot = styled.button`
  position: relative;
  ${overrideProperties(sizePropTypes, { width: '100%', height: '40px' })};
  ${({ maxWidth }) => `max-width: ${maxWidth};`}
  background-color: ${theme.color.background.white};
  cursor: pointer;
  text-align: left;
`;

const DropdownItemsRootProps = {
  position: PropTypes.string,
  top: PropTypes.string,
  left: PropTypes.string,
  'max-height': PropTypes.string,
};

const DropdownItemsRootDefaultProps = {
  position: 'absolute',
  top: 'o',
  left: '0',
  'max-height': '300px',
};

export const flatTopItem = css`
  border-bottom: none;
`;

export const flatBottomItem = css`
  border-top: none;
`;

export const DropdownItemsRoot = styled.div`
  border: 1px solid ${theme.color.line.selected};
  ${({ isFlip }) => (isFlip ? flatTopItem : flatBottomItem)}
  width: inherit;
  box-sizing: border-box;
  background-color: ${theme.color.background.white};
  ${overrideProperties(DropdownItemsRootProps, DropdownItemsRootDefaultProps)};
  overflow-y: auto;
  z-index: ${zIndex.dropdown};
  cursor: pointer;
`;

const Dropdown = React.forwardRef(({ error, errorMessage, ...props }, ref) => {
  const { width = '100%', maxWidth = '100%' } = props;
  const { ignoreError } = useIgnorableError({ error, value: props.value });
  return (
    <FlexBoxColumn align-items={'flex-start'} gap={'6px'} width={width} maxWidth={maxWidth}>
      <DropdownUnstyled
        ref={ref}
        dropdownRoot={StyledDropdownRoot}
        dropdownItemsRoot={DropdownItemsRoot}
        {...props}
        error={!ignoreError && error}
      />
      {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </FlexBoxColumn>
  );
});
Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  labelSlot: PropTypes.elementType,
  itemSlot: PropTypes.elementType,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  itemsMaxHeight: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Dropdown.defaultProps = {
  labelSlot: StyledDefaultLabel,
  itemSlot: StyledDefaultItem,
  items: [],
  placeholder: '선택하세요',
  placement: 'bottom-start',
};

export default Dropdown;

// 웹접근성
export const DivDropdown = React.forwardRef(({ error, errorMessage, ...props }, ref) => {
  const { width = '100%', maxWidth = '100%' } = props;
  const { ignoreError } = useIgnorableError({ error, value: props.value });
  return (
    <FlexBoxColumn align-items={'flex-start'} gap={'6px'} width={width} maxWidth={maxWidth}>
      <DropdownUnstyled
        ref={ref}
        dropdownRoot={StyledDropdownButtonRoot}
        dropdownItemsRoot={DropdownItemsRoot}
        {...props}
        error={!ignoreError && error}
      />
      {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
    </FlexBoxColumn>
  );
});
DivDropdown.displayName = 'DivDropdown';

DivDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
  labelSlot: PropTypes.elementType,
  itemSlot: PropTypes.elementType,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  itemsMaxHeight: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

DivDropdown.defaultProps = {
  labelSlot: StyledDivLabel,
  itemSlot: StyledDefaultItem,
  items: [],
  placeholder: '선택하세요',
  placement: 'bottom-start',
};
