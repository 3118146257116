import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as IconArrow } from '@ecp/common/src/assets/icon/icon__arrow--10x7.svg';

import CheckboxUnstyled, { checkboxClassNames } from '../unstyled/checkbox/CheckboxUnstyled';
import { flexCenter } from '../../style/theme/defaultStyled.js';

const ToggleMenuButton = React.forwardRef(({ children, onChange, ...props }, ref) => {
  const handleChange = (e) => {
    onChange?.(e.target.checked);
  };
  return (
    <div ref={ref}>
      <CheckboxUnstyled
        defaultChecked={false}
        value={1}
        rootComponent={RootComponent}
        checkboxComponent={ToggleIcon}
        onChange={handleChange}
        {...props}
      >
        {children}
      </CheckboxUnstyled>
    </div>
  );
});

export default ToggleMenuButton;

ToggleMenuButton.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
};

ToggleMenuButton.displayName = 'ToggleMenuButton';

const RootComponent = styled.label`
  ${flexCenter};
  flex-direction: row-reverse;
  cursor: pointer;
  gap: 6px;
`;

const ToggleIcon = styled(IconArrow)`
  transition: transform 0.3s;

  &.${checkboxClassNames.checked} {
    transform: rotate(-180deg);
  }
`;
