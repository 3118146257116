import { postRequest } from '@fo-apis/axios';

const getGoodItems = (goodNumber, brandName, largeDisplayCategoryNumber, subGoodList) => {
  const goodNumberList = subGoodList ? [goodNumber, ...subGoodList.map((good) => good.goodNumber)] : [goodNumber];
  return goodNumberList.map((number) => {
    return { id: number, brand: brandName, c1: largeDisplayCategoryNumber };
  });
};

const getBasketItems = (itemList) => {
  return itemList.map(({ goodNumber, orderQuantity }) => {
    return { id: goodNumber, count: orderQuantity };
  });
};

const getOrderItems = (orderNumber, itemList) => {
  return itemList.map((good) => {
    return {
      id: good.goodNumber,
      total_sales: good.detail?.map((item) => item.discountPrice).reduce((pre, curr) => pre + curr, 0),
      count: good.detail ? good.detail.map((item) => item.goodOrderCount).reduce((pre, curr) => pre + curr, 0) : 1,
      order_no: orderNumber,
    };
  });
};

const createRecommendationItemLog = async (
  action = 'view',
  { goodNumber, brandName, largeDisplayCategoryNumber, subGoodList },
  ref = '',
  url = ''
) => {
  const { data } = await postRequest(
    {
      url: `/api/v1/interfaces/recopick/recommendation-log`,
      params: {
        action,
      },
      data: {
        items: getGoodItems(goodNumber, brandName, largeDisplayCategoryNumber, subGoodList),
        ref: ref,
        url: url,
      },
    },
    { skipError: true, skipSpinner: true }
  );
  return data;
};

const createRecommendationBasketLog = async (action = 'basket', { itemList }, ref = '', url = '') => {
  const { data } = await postRequest(
    {
      url: `/api/v1/interfaces/recopick/recommendation-log`,
      params: {
        action,
      },
      data: {
        items: getBasketItems([...itemList]),
        ref: ref,
        url: url,
      },
    },
    { skipError: true, skipSpinner: true }
  );
  return data;
};

const createRecommendationOrderLog = async (action = 'order', { orderNumber, itemList }, ref = '', url = '') => {
  const { data } = await postRequest(
    {
      url: `/api/v1/interfaces/recopick/recommendation-log`,
      params: {
        action,
      },
      data: {
        items: getOrderItems(orderNumber, [...itemList]),
        ref: ref,
        url: url,
      },
    },
    { skipError: true, skipSpinner: true }
  );
  return data;
};

const createRecommendationSearchLog = async (action = 'search', { q, ref = '', url = '' }) => {
  const { data } = await postRequest(
    {
      url: `/api/v1/interfaces/recopick/recommendation-log`,
      params: {
        action,
      },
      data: {
        q,
        ref: ref,
        url: url,
      },
    },
    { skipError: true, skipSpinner: true }
  );
  return data;
};

const createRecommendationClickLog = async (url = '', action = 'click') => {
  const { data } = await postRequest(
    {
      url: `/api/v1/interfaces/recopick/recommendation-log`,
      params: {
        action,
      },
      data: {
        url,
      },
    },
    { skipError: true, skipSpinner: true }
  );
  return data;
};

const recopickApi = {
  createRecommendationItemLog,
  createRecommendationBasketLog,
  createRecommendationOrderLog,
  createRecommendationSearchLog,
  createRecommendationClickLog,
};

export default recopickApi;
