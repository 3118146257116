import React, { cloneElement, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { mergeRefs } from 'react-merge-refs';

import { FloatingNode, FloatingPortal, FloatingTree } from '@floating-ui/react';

import useModal, { placements } from './useModal';

const ModalUnstyled = React.forwardRef(
  (
    {
      children,
      rootComponent: RootComponent,
      rootProps,
      zIndex,
      title,
      closeComponent: CloseComponent,
      value,
      closeable: showClose,
      placement,
      offset,
      openWhenClicked,
      closeWhenOutside,
      open: openProp,
      defaultOpen,
      onOpenChange,
      innerModal,
      onClose,
      modalWrapper,
    },
    ref
  ) => {
    const { refs, getReferenceProps, getModalProps, ...modalProps } = useModal({
      placement,
      openWhenClicked,
      closeWhenOutside,
      openProp,
      defaultOpen,
      onOpenChange,
      innerModal,
      onClose,
    });

    const { nodeId, open, setOpen, modalClassNames, positionStrategy } = modalProps;

    const handleClose = useCallback(() => {
      setOpen(false);
      onClose?.();
    }, [onClose, setOpen]);

    refs.setFloating(ref?.current);

    const targetRef = useMemo(() => mergeRefs([refs.setReference, children?.ref]), [refs.setReference, children]);

    const getReference = useCallback((userProps) => getReferenceProps(userProps), [getReferenceProps]);

    const clonedChildren = useMemo(
      () =>
        children &&
        cloneElement(children, {
          ref: targetRef,
          ...getReference({
            ...(children.props || {}),
          }),
        }),
      [children, getReference, targetRef]
    );

    return (
      <>
        {clonedChildren}
        <FloatingNode id={nodeId}>
          <FloatingPortal id={nodeId}>
            {open && (
              <RootComponent
                className={modalClassNames}
                ref={refs.floating}
                style={{
                  position: positionStrategy,
                  [placement]: `${offset}px`,
                  ...rootProps,
                }}
                modalWrapper={modalWrapper}
                zIndex={zIndex}
                title={title}
                data-testid="modal-root"
                onClose={handleClose}
                {...getModalProps()}
              >
                {value && typeof value === 'function' ? value({ onClose: handleClose, ...value.props }) : value}
                {showClose &&
                  CloseComponent &&
                  cloneElement(CloseComponent, {
                    onClick: () => {
                      const { onClick } = CloseComponent.props;
                      if (onClick) {
                        onClick();
                      }
                      handleClose();
                    },
                    'data-testid': 'modal-close',
                  })}
              </RootComponent>
            )}
          </FloatingPortal>
        </FloatingNode>
      </>
    );
  }
);

ModalUnstyled.propTypes = {
  children: PropTypes.node,
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  rootProps: PropTypes.object,
  zIndex: PropTypes.number,
  title: PropTypes.string,
  closeComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
  offset: PropTypes.number,
  closeable: PropTypes.bool,
  placement: PropTypes.oneOf(placements),
  openWhenClicked: PropTypes.bool,
  closeWhenOutside: PropTypes.bool,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
  maxHeight: PropTypes.string,
  innerModal: PropTypes.bool,
  onClose: PropTypes.func,
  modalWrapper: PropTypes.elementType,
};

ModalUnstyled.defaultProps = {
  rootComponent: FloatingTree,
  title: '',
};

ModalUnstyled.displayName = 'ModalUnstyled';

export default ModalUnstyled;
