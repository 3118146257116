import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

import FooterHeader from './FooterHeader';
import FooterBottom from './FooterBottom';

const Footer = ({ showInquiry }) => {
  return (
    <Container>
      <HorizontalDivider width={'100%'} />
      <FooterHeader showInquiry={showInquiry} />
      <HorizontalDivider width={'100%'} />
      <Spacing top={20} />
      <FooterBottom />
    </Container>
  );
};

export default Footer;

const Container = styled(FlexBoxColumn)`
  width: 100%;
  height: 327px;
  margin-top: 80px;
`;

Footer.propTypes = {
  showInquiry: PropTypes.bool,
};

Footer.defaultProps = {
  showInquiry: true,
};
