import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxCenter, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import { ReactComponent as IconLeftArrow } from '@ecp/common/src/assets/icon/icon__bo__arrow--left.svg';

import { ReactComponent as ArrowDown } from '@fo-assets/image/good/btn_smdrow.svg';

const listMaxSize = 5;

const getCategoryNameWithGoodCount = ({ categoryName, goodCount }) => {
  if (!goodCount) {
    return categoryName;
  }
  return categoryName + '(' + goodCount + ')';
};

const CategoryTreeItem = ({ label, type, selected, ...props }) => {
  const itemProps = useMemo(() => {
    if (['primary', 'default'].includes(type)) {
      return { padding: '14px 0 0 10px' };
    } else if (type === 'secondary') {
      return { padding: '10px 0 10px 30px' };
    } else if (type === 'third') {
      return { padding: '0 0 6px 40px' };
    }
  }, [type]);

  const textProps = useMemo(() => {
    return {
      color: selected ? theme.color.primary : theme.color.third,
      weight:
        selected || ['primary', 'secondary'].includes(type) ? theme.font.weight.medium : theme.font.weight.demiLight,
      size: ['primary', 'secondary', 'default'].includes(type) ? theme.font.size.basic : theme.font.size.small,
    };
  }, [selected, type]);

  return (
    <>
      {type !== 'none' && (
        <FlexBox width={'100%'} {...itemProps}>
          {type === 'primary' && (
            <>
              <IconLeftArrow />
              <Spacing left={6} />
            </>
          )}
          <TextButton {...props} textProps={textProps}>
            {label}
          </TextButton>
        </FlexBox>
      )}
    </>
  );
};

CategoryTreeItem.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary', 'third', 'default', 'none']),
  selected: PropTypes.bool,
};
CategoryTreeItem.defaultProps = {
  type: 'primary',
};

const CategoryTree = ({
  currentCategory,
  filteredLargeCategory,
  filteredMiddleCategory,
  filteredSmallCategory,
  filteredDetailCategory,
  goodTotalCount,
  handleCategoryChange,
  getTypeOfTreeItem,
}) => {
  const { depthNumber, categoryNumber } = currentCategory;
  const [isFold, setIsFold] = useState(true);

  const handleFold = useCallback(() => {
    setIsFold((prev) => !prev);
  }, []);

  const isShowButton = useMemo(() => {
    switch (depthNumber) {
      case 1:
        return filteredMiddleCategory?.length > listMaxSize;
      case 2:
        return filteredSmallCategory?.length > listMaxSize;
      case 3:
        return filteredDetailCategory?.length > listMaxSize;
      case 4:
        return filteredDetailCategory?.length > listMaxSize;
      default:
        return filteredLargeCategory?.length > listMaxSize;
    }
  }, [
    depthNumber,
    filteredLargeCategory?.length,
    filteredMiddleCategory?.length,
    filteredSmallCategory?.length,
    filteredDetailCategory?.length,
  ]);

  return (
    <FlexBoxColumn width={'100%'} data-ds-label2={'ctg_filter'}>
      <TextBox width={'100%'} size={theme.font.size.table} weight={theme.font.weight.bold}>
        카테고리{`${goodTotalCount ? `(${NumberFormat(goodTotalCount)})` : ''}`}
      </TextBox>
      <Spacing top="10px" />
      <HorizontalDivider color={theme.color.third} height="2px" />
      <Spacing top="6px" />
      {!!categoryNumber.large && (
        <CategoryTreeItem onClick={() => handleCategoryChange()} label={'전체카테고리'} type={'primary'} />
      )}
      {filteredLargeCategory?.map(
        (category, index) =>
          !(isFold && index >= listMaxSize) && (
            <FlexBoxColumn width="100%" key={`large-category__${index}`}>
              <CategoryTreeItem
                selected={categoryNumber.current === category.categoryNumber}
                onClick={() => handleCategoryChange(category)}
                label={getCategoryNameWithGoodCount(category)}
                type={getTypeOfTreeItem(depthNumber)?.largeCategoryType}
              />
            </FlexBoxColumn>
          )
      )}
      {filteredMiddleCategory?.map(
        (category, index) =>
          !(isFold && index >= listMaxSize) && (
            <FlexBoxColumn width="100%" key={`middle-category__${index}`}>
              <CategoryTreeItem
                selected={categoryNumber.current === category.categoryNumber}
                onClick={() => handleCategoryChange(category)}
                label={getCategoryNameWithGoodCount(category)}
                type={getTypeOfTreeItem(depthNumber)?.middleCategoryType}
              />
            </FlexBoxColumn>
          )
      )}
      {filteredSmallCategory?.map(
        (category, index) =>
          !(isFold && index >= listMaxSize) && (
            <FlexBoxColumn width="100%" key={`small-category__${index}`}>
              <CategoryTreeItem
                selected={categoryNumber.current === category.categoryNumber}
                onClick={() => handleCategoryChange(category)}
                label={getCategoryNameWithGoodCount(category)}
                type={getTypeOfTreeItem(depthNumber)?.smallCategoryType}
              />
            </FlexBoxColumn>
          )
      )}
      {filteredDetailCategory?.map(
        (category, index) =>
          !(isFold && index >= listMaxSize) && (
            <FlexBoxColumn width="100%" key={`detail-category__${index}`}>
              <CategoryTreeItem
                selected={categoryNumber.current === category.categoryNumber}
                onClick={() => handleCategoryChange(category)}
                label={getCategoryNameWithGoodCount(category)}
                type={getTypeOfTreeItem(depthNumber)?.detailCategoryType}
              />
            </FlexBoxColumn>
          )
      )}
      <Spacing top="14px" />
      <HorizontalDivider />
      {isShowButton && (
        <>
          <Spacing top="6px" />
          <FlexBoxCenter width="100%" onClick={handleFold}>
            <TextButton
              textProps={{
                color: theme.color.text.sub,
                size: theme.font.size.small,
                weight: theme.font.weight.demiLight,
              }}
            >
              {isFold ? '더보기' : '접기'}
            </TextButton>
            <Spacing left="3px" />
            <IconWrapper down={isFold}>
              <ArrowDown width="10px" height="10px" />
            </IconWrapper>
          </FlexBoxCenter>
          <Spacing top="6px" />
          <HorizontalDivider />
        </>
      )}
    </FlexBoxColumn>
  );
};
export default CategoryTree;

CategoryTree.propTypes = {
  currentCategory: PropTypes.object,
  filteredLargeCategory: PropTypes.array,
  filteredMiddleCategory: PropTypes.array,
  filteredSmallCategory: PropTypes.array,
  filteredDetailCategory: PropTypes.array,
  goodTotalCount: PropTypes.number,
  handleCategoryChange: PropTypes.func,
  getTypeOfTreeItem: PropTypes.func,
};

const IconWrapper = styled.div`
  transform: ${({ down }) => (down ? 'rotate(0)' : 'rotate(180deg)')};
`;

export class getCategoryNameExcludeCount {}
