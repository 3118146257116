import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { getFilePath } from '@ecp/common/src/utils/fileUtil';
import useEmployeeBenefitCoupon from '@ecp/common/src/hooks/coupon/useEmployeeBenefitCoupon';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import BenefitCouponCard from '@ecp/common/src/components/coupon/BenefitCouponCard';
import { COUPON_POPUP_TYPE, COUPON_TYPE } from '@ecp/common/src/const/coupon/couponConst';

import { withCategoryById } from '@fo-recoil/category/selector';
import { employeeBenefitBrandCountState, employeeBenefitBrandNameState } from '@fo-recoil/main/atom';
import exhibitionApi from '@fo-apis/exhibition/exhibitionApi';
import eventApi from '@fo-apis/event/eventApi';
import { EventCard } from '@fo-components/event/EventCard';
import Exhibition from '@fo-components/exhibition/Exhibition';
import EmployeeBenefitCouponPopup from '@fo-components/employeeBenefit/popup/EmployeeBenefitCouponPopup';
import couponApi from '@ecp/fo/src/apis/coupon/couponApi';
import myReviewApi from '@ecp/fo/src/apis/myPage/myReviewApi';
import myMembershipApi from '@ecp/fo/src/apis/myPage/myMembershipApi';
import { useAlertDialog } from '@ecp/fo/src/hooks/common/useModal';

const AvailableBenefitTab = ({ gradeInfo, handleDrawerClose, setInfoChanged }) => {
  const { pathname } = useLocation();
  const { brandNumber, categoryNumber } = useParams();
  const navigate = useNavigate();
  const { showAlertDialog } = useAlertDialog();

  const [newestExhibition, setNewestExhibition] = useState(null);
  const [newestEvent, setNewestEvent] = useState(null);
  const [selectedBenefitCardType, setSelectedBenefitCardType] = useState(COUPON_POPUP_TYPE.BRAND);
  const [couponPopupOpen, setCouponPopupOpen] = useState(false);

  const categoryState = useRecoilValue(withCategoryById(categoryNumber));
  const brandCountState = useRecoilValue(employeeBenefitBrandCountState);
  const brandNameState = useRecoilValue(employeeBenefitBrandNameState);

  const {
    downloadableCouponCount,
    myNoReviewCount,
    maxReviewSaveMoney,
    brandCouponCount,
    brandCount,
    brandListCouponCount,
    brandName,
    categoryCouponCount,
    showBrandListCoupon,
    showBrandCoupon,
    showCategoryCoupon,
    showBirthdayCoupon,
    showFirstOrderCoupon,
    showMembershipCoupon,
    showDownloadCoupon,
    showReviewCoupon,
    handleBirthDayCouponDownloadClick,
    handleFirstPurCouponDownloadClick,
    handleNavigateButtonClick,
  } = useEmployeeBenefitCoupon({
    pathname,
    categoryNumber,
    brandNumber,
    brandCountState,
    brandNameState,
    couponApi,
    myReviewApi,
    myMembershipApi,
    showAlertDialog,
    handleDrawerClose,
    setInfoChanged,
  });

  const categoryName = useMemo(() => {
    return categoryState?.categoryName || '';
  }, [categoryState?.categoryName]);

  const couponDetailName = useMemo(() => {
    if (selectedBenefitCardType === COUPON_POPUP_TYPE.BRAND) {
      return brandName;
    } else if (selectedBenefitCardType === COUPON_POPUP_TYPE.CATEGORY) {
      return categoryName;
    } else {
      return '';
    }
  }, [brandName, categoryName, selectedBenefitCardType]);

  const getNewestExhibition = useCallback(async () => {
    const { result } = await exhibitionApi.getNewestExhibition();
    if (result) {
      setNewestExhibition({
        ...result,
        title: result.name,
        subTitle: result.subName,
        src: getFilePath(result.pcImageFilePath),
      });
    }
  }, []);

  const getNewestEvent = useCallback(async () => {
    const { result } = await eventApi.getNewestEvent();
    if (result) {
      setNewestEvent(result);
    }
  }, []);

  const handleCouponPopupOpen = useCallback((popupType) => {
    setSelectedBenefitCardType(popupType);
    setCouponPopupOpen(true);
  }, []);

  const handleCouponPopupClose = useCallback(() => {
    setCouponPopupOpen(false);
    setInfoChanged(false);
  }, [setInfoChanged]);

  const noBenefit = useMemo(
    () =>
      !showBrandListCoupon &&
      !showBrandCoupon &&
      !showCategoryCoupon &&
      !showFirstOrderCoupon &&
      !showBirthdayCoupon &&
      !showMembershipCoupon &&
      !showDownloadCoupon &&
      !showReviewCoupon,
    [
      showBirthdayCoupon,
      showBrandCoupon,
      showBrandListCoupon,
      showCategoryCoupon,
      showDownloadCoupon,
      showFirstOrderCoupon,
      showMembershipCoupon,
      showReviewCoupon,
    ]
  );

  useEffect(() => {
    (async () => {
      await Promise.all([getNewestExhibition(), getNewestEvent()]);
    })();
  }, [getNewestEvent, getNewestExhibition]);

  return (
    <FlexBoxColumn width={'100%'} data-ds-label2={'g_benefit_p'}>
      <Spacing top={20} />
      <FlexBoxColumn gap={'20px'}>
        {showBrandListCoupon && (
          <BenefitCouponCard
            couponType={COUPON_TYPE.BRAND_LIST}
            messageValue={brandCount}
            countValue={brandListCouponCount}
            handleButtonClick={() => handleCouponPopupOpen(COUPON_POPUP_TYPE.BRAND_LIST)}
          />
        )}
        {showBrandCoupon && (
          <BenefitCouponCard
            couponType={COUPON_TYPE.BRAND}
            messageValue={brandName}
            countValue={brandCouponCount}
            handleButtonClick={() => handleCouponPopupOpen(COUPON_POPUP_TYPE.BRAND)}
          />
        )}
        {showCategoryCoupon && (
          <BenefitCouponCard
            couponType={COUPON_TYPE.CATEGORY}
            messageValue={categoryName}
            countValue={categoryCouponCount}
            handleButtonClick={() => handleCouponPopupOpen(COUPON_POPUP_TYPE.CATEGORY)}
          />
        )}
        {showFirstOrderCoupon && (
          <BenefitCouponCard
            couponType={COUPON_TYPE.FIRST_ORDER}
            handleButtonClick={handleFirstPurCouponDownloadClick}
          />
        )}
        {showBirthdayCoupon && (
          <BenefitCouponCard couponType={COUPON_TYPE.BIRTHDAY} handleButtonClick={handleBirthDayCouponDownloadClick} />
        )}
        {showMembershipCoupon && (
          <BenefitCouponCard
            couponType={COUPON_TYPE.MEMBERSHIP}
            messageValue={gradeInfo?.memberGradeName}
            handleButtonClick={() => handleNavigateButtonClick(COUPON_TYPE.MEMBERSHIP)}
          />
        )}
        {showDownloadCoupon && (
          <BenefitCouponCard
            couponType={COUPON_TYPE.DOWNLOAD}
            countValue={downloadableCouponCount}
            handleButtonClick={() => handleNavigateButtonClick(COUPON_TYPE.DOWNLOAD)}
          />
        )}
        {showReviewCoupon && (
          <BenefitCouponCard
            couponType={COUPON_TYPE.REVIEW}
            countValue={myNoReviewCount}
            pointValue={maxReviewSaveMoney}
            handleButtonClick={() => handleNavigateButtonClick(COUPON_TYPE.REVIEW)}
          />
        )}
      </FlexBoxColumn>
      {!noBenefit && (
        <>
          <Spacing top={40} />
          <HorizontalDivider />
          <Spacing top={40} />
        </>
      )}
      <FlexBoxColumn gap={'20px'}>
        {newestExhibition && (
          <Exhibition
            width={'380px'}
            height={'280px'}
            itemProps={{
              exhibitionNumber: newestExhibition.exhibitionNumber,
              width: '380px',
              height: '220px',
              radius: '12px',
            }}
            item={{ ...newestExhibition }}
            onClick={() => {
              navigate(`/exhibition/${newestExhibition.exhibitionNumber}`);
              handleDrawerClose();
            }}
          />
        )}
        {newestEvent && (
          <EventCard
            eventNumber={newestEvent.eventNumber}
            name={newestEvent.eventName}
            subName={newestEvent.eventSubName}
            imgPath={newestEvent.pcBannerImageUrl}
            handleEventCardClick={() => {
              navigate(`/event/${newestEvent.eventNumber}`);
              handleDrawerClose();
            }}
            startDate={newestEvent.displayStartDate}
            endDate={newestEvent.displayEndDate}
            cardProps={{ width: '380px', height: '280px' }}
            imageProps={{ width: '380px', height: '220px' }}
            dateHidden
          />
        )}
      </FlexBoxColumn>
      <EmployeeBenefitCouponPopup
        open={couponPopupOpen}
        type={selectedBenefitCardType}
        detailNumber={brandNumber || categoryNumber || ''}
        detailName={couponDetailName}
        setInfoChanged={setInfoChanged}
        onClose={handleCouponPopupClose}
      />
    </FlexBoxColumn>
  );
};

export default React.memo(AvailableBenefitTab);

AvailableBenefitTab.propTypes = {
  gradeInfo: PropTypes.object,
  handleDrawerClose: PropTypes.func,
  setInfoChanged: PropTypes.func,
};
