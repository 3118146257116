import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { overrideProperties } from '@ecp/common/src/style/utils';
import TabPanelUnstyled from '@ecp/common/src/components/unstyled/tab/TabPanelUnstyled';

const StyledTabPanel = styled(TabPanelUnstyled)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${overrideProperties(sizePropTypes, {
    height: 'fit-content',
    width: '100%',
  })};
`;

export const TabPanel = (props) => <StyledTabPanel {...props} />;

TabPanel.displayName = 'TabPanel';

TabPanel.propTypes = {
  ...sizePropTypes,
  undead: PropTypes.bool,
};

TabPanel.defaultProps = {
  height: 'fit-content',
  width: '100%',
};
