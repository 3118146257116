import { atom } from 'recoil';

export const userInfoState = atom({
  key: 'userInfoState',
  default: {
    userId: '',
    userName: '',
    siteNumber: '',
    subsidiaryCompanyCode: '',
    loginType: '',
    memberNumber: '',
    adultCertificationDate: '',
  },
});

export const recentlyViewedGoodListState = atom({
  key: 'recentlyViewedGoodListState',
  default: null,
});
