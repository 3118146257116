import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import clsx from 'clsx';

import useSticky from '@ecp/common/src/hooks/useSticky';
import theme from '@ecp/common/src/style/theme/default';
import { zIndex } from '@ecp/common/src/style/constant';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import Drawer from '@ecp/common/src/components/modal/Drawer';
import Popover from '@ecp/common/src/components/popover/Popover';
import { gnbDefault } from '@ecp/common/src/const/home/gnbConst';
import { MAIN_FUNCTION_TYPE_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import { userInfoState } from '@fo-recoil/common/user/atom';
import { commonMainFunctionListState, employeeBenefitOpenState, mallListState } from '@fo-recoil/main/atom';
import mainFunctionApi from '@fo-apis/display/mainFunctionApi';
import MallGroup from '@fo-pages/home/MallGroup';
import CategoryBoard from '@fo-pages/home/gnb/CategoryBoard';
import GnbHeader from '@fo-pages/home/gnb/GnbHeader';
import SideFloatingArea from '@fo-pages/home/gnb/sideFloating/SideFloatingArea';
import GnbHeaderBottom from '@fo-pages/home/gnb/GnbHeaderBottom';
import EmployeeBenefit from '@fo-pages/home/gnb/sideFloating/EmployeeBenefit';

const Gnb = () => {
  const setCommonMainFunctionList = useSetRecoilState(commonMainFunctionListState);
  const [employeeBenefitOpen, setEmployeeBenefitOpen] = useRecoilState(employeeBenefitOpenState);
  const [mallList, setMallList] = useRecoilState(mallListState);
  const userInfo = useRecoilValue(userInfoState);

  const [open, setOpen] = useState(false);
  const [gnbList, setGnbList] = useState([]);
  const [searchPhraseList, setSearchPhraseList] = useState([]);
  const [searchPraseListIndex, setSearchPraseListIndex] = useState(0);

  const { ref, sticky } = useSticky();
  const { pathname } = useLocation();
  const className = clsx({ sticky });

  const fetchAllianceBarList = useCallback(async () => {
    const { result } = await mainFunctionApi.getAllianceBarList();
    const mallList = result.map(({ exposureSiteName, pcUrl, pcNewTab }) => ({
      label: exposureSiteName,
      url: pcUrl,
      target: pcNewTab ? '_blank' : '_self',
    }));
    setMallList(mallList);
  }, [setMallList]);

  const fetchGnbList = useCallback(async () => {
    const { result } = await mainFunctionApi.getGnbList();
    const gnbList = result.map(({ title, bullet, fontColorCode, highLight, pcNewTab, pcUrl }) => ({
      title: title.slice(0, 6),
      link: pcUrl,
      dot: bullet,
      bold: highLight,
      color: fontColorCode ? `#${fontColorCode}` : gnbDefault.color,
      target: pcNewTab ? '_blank' : '_self',
    }));
    setGnbList(gnbList);
  }, [setGnbList, userInfo]);

  const fetchSearchPhraseList = useCallback(async () => {
    const { result } = await mainFunctionApi.getSearchPhraseList();
    const searchPhraseList = result.map(({ text, pcNewTab, pcUrl }) => ({
      text,
      pcUrl,
      target: pcNewTab ? '_blank' : '_self',
    }));
    setSearchPhraseList(searchPhraseList);
  }, []);

  const fetchCommonMainFunctionList = useCallback(async () => {
    const { result } = await mainFunctionApi.getMainFunctionList(MAIN_FUNCTION_TYPE_CODE.COMMON);
    setCommonMainFunctionList(result);
  }, [setCommonMainFunctionList]);

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([
          fetchAllianceBarList(),
          fetchGnbList(),
          fetchCommonMainFunctionList(),
          fetchSearchPhraseList(),
        ]);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [fetchAllianceBarList, fetchCommonMainFunctionList, fetchGnbList, fetchSearchPhraseList]);

  useEffect(() => {
    setSearchPraseListIndex(searchPraseListIndex >= searchPhraseList.length - 1 ? 0 : searchPraseListIndex + 1);
  }, [pathname]);

  const hideCategory = () => {
    setOpen(false);
  };

  const toggleCategory = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setEmployeeBenefitOpen(false);
  }, [setEmployeeBenefitOpen]);

  const gnbHeight = useMemo(() => {
    return mallList?.length > 0 ? '189px' : '153px';
  }, [mallList?.length]);

  return (
    <Container height={gnbHeight} className={className}>
      <Popover
        closeWhenOutside
        closeWhenReference
        blockAutoFocus
        placement={'bottom-start'}
        open={open}
        value={<CategoryBoard sticky={sticky} gnbList={gnbList} open={open} onClose={hideCategory} />}
        onOpenChange={hideCategory}
      >
        <GnbWrapper className={className}>
          <GnbHeaderContainer className={className}>
            <MallGroup items={mallList} />
            {!sticky && <Spacing top={23} />}
            <GnbHeader ref={ref} searchPhraseList={searchPhraseList} searchPraseListIndex={searchPraseListIndex} />
            {!sticky && <Spacing top={26} />}
            <GnbHeaderBottom open={open} gnbList={gnbList} onCategory={toggleCategory} sticky={sticky} />
            <Spacing top={!sticky ? 19 : 14} />
            <HorizontalDivider width="calc(max(100vw, 1630px))" />
            <SideFloatingArea />
          </GnbHeaderContainer>
        </GnbWrapper>
      </Popover>
      <Drawer
        placement={'right'}
        open={employeeBenefitOpen}
        onClose={handleClose}
        value={<EmployeeBenefit onClose={handleClose} />}
      />
    </Container>
  );
};

export default Gnb;

const Container = styled.div`
  width: calc(max(100vw, 1630px));
  ${({ height }) => `height: ${height}`};

  z-index: ${zIndex.appBar};

  &.sticky {
    height: 80px;
  }
`;

const GnbWrapper = styled.div`
  width: calc(max(100vw, 1630px));
  height: 100%;

  position: relative;
  background-color: #ffffff;

  &.sticky {
    height: 80px;

    position: fixed;
    top: 0;
  }
`;

const GnbHeaderContainer = styled(FlexBoxColumn)`
  width: 1540px;

  position: absolute;
  top: 0;
  left: calc(max(50% - 770px, 45px));

  background-color: ${theme.color.background.white};
  font-weight: ${theme.font.weight.demiLight};

  &.sticky {
    position: fixed;
    top: 0;
    height: 80px;
    padding: 15px 0 0;
    transition-duration: 0.2s;
  }
`;
