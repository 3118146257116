import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import useButton from './useButton';

export const buttonPropTypes = {
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  onClick: PropTypes.func,
  children: PropTypes.node,
  btnType: PropTypes.string,
  title: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export const buttonClassNames = {
  pressed: 'button-pressed',
};

const ButtonUnstyled = React.forwardRef(({ rootComponent: RootComponent, children, btnType, ariaLabel, ...props }, ref) => {
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);

  const { pressed, getRootProps } = useButton({ ...props }, buttonRef);

  const stateClasses = {
    [buttonClassNames.pressed]: pressed,
  };
  return (
    <RootComponent
      ref={buttonRef}
      className={clsx(stateClasses)}
      data-testid="buttonRoot"
      {...getRootProps()}
      type={btnType}
      title={props.title}
      aria-label={ariaLabel}
    >
      {children}
    </RootComponent>
  );
});

ButtonUnstyled.displayName = 'ButtonUnstyled';

ButtonUnstyled.propTypes = buttonPropTypes;

ButtonUnstyled.defaultProps = {
  rootComponent: 'button',
  btnType: 'button',
  title: '',
  ariaLabel: '',
};

export default ButtonUnstyled;
