import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import { CHIP_TYPE } from '@ecp/common/src/components/chip/Chip';

const useChip = ({ type, onClick, onClose = onClick, chipType = CHIP_TYPE }) => {
  const chipProps = chipType[type];
  const handleClick = (e) => {
    onClick?.(e);
  };

  const handleClose = useEventCallback(
    (value, e) => {
      onClose?.(e, value);
    },
    { stop: true }
  );

  return { chipProps, handleClick, handleClose };
};

export default useChip;
