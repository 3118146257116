import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateBack = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(() => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('/');
    }
  }, [location.key, navigate]);
};

export default useNavigateBack;
