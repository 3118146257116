import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SUCCESS } from '@ecp/common/src/const/wpayResCode';

const Blank = styled.div``;

const WpayPaymentAuthReturnComp = ({ showAlertDialog, wpayApi }) => {
  const [searchParams] = useSearchParams();
  const [firstRender, setFirstRender] = useState(false);

  const resultCode = searchParams.get('resultCode');
  const resultMsg = searchParams.get('resultMsg');

  useEffect(() => {
    setFirstRender(true);
  }, []);

  const closeWpayPopup = () => {
    window.close();
  };
  const showErrMgs = useCallback(
    (errMsg) => {
      showAlertDialog(errMsg, () => {
        closeWpayPopup();
      });
    },
    [showAlertDialog]
  );

  useEffect(() => {
    if (!firstRender) return;

    if (resultCode !== SUCCESS) {
      showErrMgs(`결제 인증을 실패하였습니다.\n(${decodeURI(resultMsg).replaceAll('+', ' ')})`);
    } else {
      const wpayPaymentAuthReturn = {
        resultCode,
        resultMsgEncoded: resultMsg,
        wtid: searchParams.get('wtid'),
        wpayUserKeyEnc: searchParams.get('wpayUserKey'),
        wpayTokenEnc: searchParams.get('wpayToken'),
        signature: searchParams.get('signature'),
        oid: searchParams.get('oid'),
      };

      wpayApi.postPaymentAuthReturn(wpayPaymentAuthReturn).then(({ message = '' }) => {
        if (message) {
          showErrMgs(message);
        } else {
          showAlertDialog('결제가 성공하였습니다.', () => {
            opener.window.location = `/order/complete/${wpayPaymentAuthReturn.oid}`;
            closeWpayPopup();
          });
        }
      });
    }
  }, [resultCode, firstRender, resultMsg, searchParams, showAlertDialog, wpayApi, showErrMgs]);

  return <Blank></Blank>;
};

export default WpayPaymentAuthReturnComp;

WpayPaymentAuthReturnComp.propTypes = {
  showAlertDialog: PropTypes.func,
  wpayApi: PropTypes.object,
};

WpayPaymentAuthReturnComp.displayName = 'WpayPaymentAuthComp';
