import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ASSET_RESOURCE_DIVISION_CODE,
  ASSET_POINT_SUMMARY_ORDER,
  ASSET_SUMMARY_ICON_TYPE,
  ASSET_GUIDE,
  ASSET_RESOURCE_DIVISION_NAME,
} from '@ecp/common/src/const/asset';

const useAssetPoint = ({ myCouponCount } = {}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getSortedAssetSummary = useCallback(
    (assetSummary) =>
      ASSET_POINT_SUMMARY_ORDER.reduce((summary, current) => {
        const currentSummary = assetSummary?.find(
          ({ assetResourceDivisionCode }) => assetResourceDivisionCode === current
        );

        if (currentSummary) {
          summary.push(currentSummary);
        } else {
          (ASSET_RESOURCE_DIVISION_CODE.COUPON !== current || myCouponCount !== undefined) &&
            summary.push({
              assetResourceDivisionCode: current,
              assetDivisionName: ASSET_RESOURCE_DIVISION_NAME[current],
              assetBalance: ASSET_RESOURCE_DIVISION_CODE.COUPON === current ? myCouponCount : 0,
            });
        }
        return summary;
      }, []),
    [myCouponCount]
  );

  const getAssetTypeIcon = useCallback(
    (assetResourceDivisionCode) => ASSET_SUMMARY_ICON_TYPE[assetResourceDivisionCode],
    []
  );

  const getAssetGuide = useCallback((selectedCategoryCode) => ASSET_GUIDE[selectedCategoryCode], []);

  const getAssetUnit = useCallback(
    (assetResourceDivisionCode) =>
      assetResourceDivisionCode === ASSET_RESOURCE_DIVISION_CODE.SAVE_MONEY
        ? '원'
        : assetResourceDivisionCode === ASSET_RESOURCE_DIVISION_CODE.COUPON
        ? '장'
        : 'P',
    []
  );

  const createMoveAssetPointClickHandler = useCallback(
    (assetResourceDivisionCode) => () => {
      if (assetResourceDivisionCode === ASSET_RESOURCE_DIVISION_CODE.COUPON) {
        navigate('/my-page/coupon');
      } else {
        navigate('/my-page/asset-point', { state: { selectedTabCode: assetResourceDivisionCode } });
      }
    },
    [navigate]
  );

  const getDefaultSelectedTabIndex = useCallback(
    (assetSummary) => {
      const index = assetSummary?.findIndex(
        ({ assetResourceDivisionCode }) => assetResourceDivisionCode === location?.state?.selectedTabCode
      );

      return index < 0 ? undefined : index;
    },
    [location?.state?.selectedTabCode]
  );

  const getFormattedOrderNumber = useCallback(
    (orderNumber) => (orderNumber?.length > 0 ? ` (${orderNumber})` : ''),
    []
  );

  const getSaveUseDetailText = useCallback(
    ({ saveUseReasonCode, saveUseReasonName, pointUseCode, orderNumber, promotionName, saveAmount }) => {
      switch (`${saveUseReasonCode} ${pointUseCode}`) {
        case '9010 10':
          return promotionName?.length > 0 ? `[${promotionName}] : 적립(관리자)` : '적립(관리자)';
        case '9010 40':
          return '차감(관리자)';
        case '1060 10':
          return [100, 50].includes(Number(saveAmount))
            ? `상품평(${Number(saveAmount) === 100 ? '이미지' : '글'}) 작성${getFormattedOrderNumber(orderNumber)}`
            : `우수상품평선정${getFormattedOrderNumber(orderNumber)}`;
        case '1012 10':
          return `주문적립${getFormattedOrderNumber(orderNumber)}`;
        case '1012 30':
        case '1060 30':
        case '1010 30':
        case '9010 30':
          return `주문취소환급${getFormattedOrderNumber(orderNumber)}`;
        case '1021 60':
          return `주문적립취소${getFormattedOrderNumber(orderNumber)}`;
        case '1020 20':
          return `주문사용${getFormattedOrderNumber(orderNumber)}`;
        default:
          return saveUseReasonName;
      }
    },
    [getFormattedOrderNumber]
  );

  const withSaveUseDetailText = useCallback(
    (content = []) => content.map((item) => ({ ...item, saveUseDetailText: getSaveUseDetailText(item) })),
    [getSaveUseDetailText]
  );

  return {
    getSortedAssetSummary,
    getAssetTypeIcon,
    getAssetGuide,
    getAssetUnit,
    createMoveAssetPointClickHandler,
    getDefaultSelectedTabIndex,
    withSaveUseDetailText,
  };
};

export default useAssetPoint;
