import React from 'react';

import WpayPaymentAuthReturnComp from '@ecp/common/src/components/interface/WpayPaymentAuthReturnComp';

import { useAlertDialog } from '@fo-hooks/common/useModal';
import wpayApi from '@fo-apis/interface/wpayApi';

const WpayPaymentAuthReturn = () => {
  const { showAlertDialog } = useAlertDialog();
  return <WpayPaymentAuthReturnComp showAlertDialog={showAlertDialog} wpayApi={wpayApi} />;
};

export default WpayPaymentAuthReturn;
