import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { format } from 'date-fns';

import { isValidContents, isValidEmail } from '@ecp/common/src/utils/InputValidateUtils';
import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import Popup from '@ecp/common/src/components/modal/Popup';
import { EMAIL_DOMAIN } from '@ecp/common/src/const/email';

import { useAlertDialog, useConfirmDialog } from '@fo-hooks/common/useModal';
import { createBulkPurchaseQuestion } from '@fo-apis/customerService/mallQuestionApi';
import BulkOrderDescription from '@fo-pages/home/footer/popup/bulkOrder/BulkOrderDescription';
import BulkOrderApplicationForm from '@fo-pages/home/footer/popup/bulkOrder/BulkOrderApplicationForm';
import BulkOrderAgreement from '@fo-pages/home/footer/popup/bulkOrder/BulkOrderAgreement';
import GnbIconPopupButton from '@fo-pages/home/gnb/GnbIconPopupButton';
import { ReactComponent as IconBulkPurchase } from '@fo-assets/icon/icon__gnb-bp.svg';
import PropTypes from "prop-types";

const BulkOrderPopupButton = (props) => {
  const initialStateValue = useMemo(() => {
    return {
      title: '',
      company: '',
      mobile: '',
      emailValue: '',
      emailDomain: EMAIL_DOMAIN[0],
      emailDirectDomain: '',
      delivery: {},
      deliveryDate: null,
      inquiry: '',
      agree: '0',
    };
  }, []);

  const [open, setOpen] = useState(false);
  const [formValue, setFormValue] = useState({
    ...initialStateValue,
  });
  const buttonRef = useRef();

  const handleClose = useCallback(() => {
    setOpen(false);
    //console.log('buttonRef ::', buttonRef);
    buttonRef.current.focus();
  }, [setOpen]);

  useEffect(() => {
    if (!open) {
      setFormValue({ ...initialStateValue });
    }
  }, [open, initialStateValue]);

  const { showAlertDialog } = useAlertDialog();
  const { showConfirmDialog } = useConfirmDialog();

  const validateForm = useCallback(
    (form) => {
      const validateFormList = [
        { validateForm: isValidContents(form.title), alertMessage: '제목을 입력해주세요.' },
        { validateForm: isValidContents(form.company), alertMessage: '소속을 입력해주세요.' },
        { validateForm: isValidContents(form.mobile, 9, 11), alertMessage: '연락처를 정확히 입력해주세요.' },
        { validateForm: isValidEmail(form.email), alertMessage: '이메일을 정확하게 입력해주세요.' },
        { validateForm: Object.keys(form.delivery).length > 0, alertMessage: '배송방법을 선택해주세요.' },
        {
          validateForm: !!form.deliveryDate && form.deliveryDate.toDateString?.() !== '',
          alertMessage: '희망 배송일을 선택해주세요.',
        },
        {
          validateForm: isValidContents(form.inquiry, 1, 200),
          alertMessage: '대량 구매 문의 내용을 입력해주세요.',
        },
        {
          validateForm: form.agree === '1',
          alertMessage: '개인정보수집 및 이용에 동의하지 않을 경우\n 대량구매 문의를 하실 수 없습니다.',
        },
      ];

      return !validateFormList.some(({ validateForm, alertMessage }) => {
        if (!validateForm) {
          showAlertDialog(alertMessage);
          return true;
        }
      });
    },
    [showAlertDialog]
  );

  const handleChangeFormValues = useEventCallback(
    (v, data) => {
      setFormValue((prev) => ({ ...prev, [data.key]: v }));
    },
    { stop: true, prevent: true }
  );

  const handleChangeFormDropdownValues = useCallback((key, item) => {
    setFormValue((prev) => ({ ...prev, [key]: item, ...(key === 'emailDomain' && { emailDirectDomain: '' }) }));
  }, []);

  const handleBulkOrderClick = useCallback(async () => {
    const form = {
      ...formValue,
      email: `${formValue.emailValue}@${
        formValue.emailDirectDomain === '' ? formValue.emailDomain.value : formValue.emailDirectDomain
      }`,
      questionDate: format(new Date(), 'yyyyMMdd'),
      companyName: formValue.company,
      contactNo: formValue.mobile,
      questionContent: formValue.inquiry,
      questionTitle: formValue.title,
      deliveryMethodCode:
        formValue.delivery.value === 'individualDelivery'
          ? '10'
          : formValue.delivery.value === 'bundleDelivery'
          ? '20'
          : '90',
      hopeDeliDate: formValue.deliveryDate ? format(formValue.deliveryDate, 'yyyyMMdd') : '',
    };

    if (!validateForm(form)) return;

    showConfirmDialog('문의신청을 하시겠습니까?', async () => {
      const { message, code } = await createBulkPurchaseQuestion({ ...form });

      if (code) {
        showAlertDialog('문의 신청간 에러가 발생하였습니다. \n' + '[' + message + code + ']', handleClose);
      } else {
        showAlertDialog('문의 신청이 완료되었습니다.\n검토후 빠른 회신 드리겠습니다. 감사합니다.', handleClose);
      }
    });
  }, [formValue, showAlertDialog, validateForm, handleClose, showConfirmDialog]);

  return (
    <>
      <Popup
        open={open}
        onClose={handleClose}
        header={'대량구매문의'}
        useCancelButton={false}
        confirmButtonText={'문의신청'}
        onConfirmClick={handleBulkOrderClick}
      >
        <FlexBoxColumn>
          <BulkOrderDescription />
          <Spacing top={40} />
          <BulkOrderApplicationForm
            formValue={formValue}
            onChangeFormValues={handleChangeFormValues}
            onChangeDropdownValues={handleChangeFormDropdownValues}
          />
          <BulkOrderAgreement formValue={formValue} onChangeAgreement={handleChangeFormValues} />
          <HorizontalDivider width={'100%'} color={'#DFDFDF'} />
        </FlexBoxColumn>
      </Popup>
      {props.isIcon ? (
        <GnbIconPopupButton
          onClick={() => setOpen(true)}
          iconType={IconBulkPurchase}
          label={'대량구매'}
          data-ds-label2={'g_bp'}
          ref={buttonRef}
        />
      ) : (
        <TextButton textProps={{ weight: '350' }} onClick={() => setOpen(true)} ref={buttonRef} tabIndex={0}>
          대량구매문의
        </TextButton>
      )}
    </>
  );
};

export default BulkOrderPopupButton;

BulkOrderPopupButton.propTypes = {
  isIcon: PropTypes.bool,
}

BulkOrderPopupButton.defaultProps = {
  isIcon: false,
};
