import React, { useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import JoditEditor, { Jodit } from 'jodit-react';
import clsx from 'clsx';
import ImageMap from 'image-map';

import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import boTheme from '@ecp/common/src/style/theme/bo';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { StyledErrorMessage } from '@ecp/common/src/components/input/bo/Input';

const editorClassNames = {
  error: 'editor-error',
};
const Editor = React.forwardRef(
  (
    {
      html,
      placeholder,
      readonly,
      onChange,
      height,
      error,
      errorMessage,
      disabled,
      backgroundColor,
      config: configProps,
      setEditor,
    },
    ref
  ) => {
    const [content, setContent] = useState(html);
    const wrapperRef = useRef(false);
    const editorRef = useRef();
    const { ignoreError } = useIgnorableError({ error, value: content });

    useImperativeHandle(ref, () => wrapperRef.current);
    useLayoutEffect(() => {
      setContent(html);
    }, [html]);

    useEffect(() => {
      wrapperRef.current.querySelectorAll('img[usemap]')?.forEach((image) => {
        editorRef.current.e.one(image, 'load', () => {
          ImageMap('img[usemap]');
        });
      });
    }, []);

    const stateClasses = useMemo(
      () => ({
        [editorClassNames.error]: !ignoreError && error,
      }),
      [error, ignoreError]
    );

    const config = useMemo(() => {
      if (disabled) {
        return {
          height,
          toolbar: true,
          defaultMode: Jodit.MODE_WYSIWYG,
          placeholder,
          readonly: true,
          statusbar: false,
          disablePlugins: 'add-new-line',
          cleanHTML: { fillEmptyParagraph: false, denyTags: 'head,style,title,meta' },
        };
      }
      if (readonly) {
        return {
          inline: true,
          toolbar: false,
          defaultMode: Jodit.MODE_WYSIWYG,
          placeholder,
          readonly: true,
          statusbar: false,
          disablePlugins: 'add-new-line',
          cleanHTML: { fillEmptyParagraph: false, denyTags: 'head,style,title,meta' },
        };
      }

      return configProps;
    }, [disabled, readonly, configProps, height, placeholder]);

    const handleRef = useCallback(
      (el) => {
        setEditor?.(el);
        editorRef.current = el;
      },
      [setEditor]
    );

    return (
      <FlexBoxColumn align-items={'flex-start'} width={'100%'}>
        <Wrapper ref={wrapperRef} className={clsx(stateClasses)} backgroundColor={backgroundColor}>
          <JoditEditor editorRef={handleRef} value={content} config={config} tabIndex={1} onBlur={onChange} />
        </Wrapper>
        {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </FlexBoxColumn>
    );
  }
);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'unset')};
  .jodit-react-container {
    width: 100%;
  }

  &.${editorClassNames.error} {
    border: 1px solid ${boTheme.color.error1};
    border-radius: 3px;
  }

  .react-transform-component & img {
    pointer-events: auto;
  }

  img {
    vertical-align: bottom;
    user-select: none;
  }

  textarea {
    user-select: auto;
  }
`;

Editor.propTypes = {
  html: PropTypes.string,
  placeholder: PropTypes.string,
  readonly: PropTypes.bool,
  onChange: PropTypes.func,
  height: PropTypes.number,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  config: PropTypes.object,
  setEditor: PropTypes.func,
};

Editor.displayName = 'Editor';

Editor.defaultProps = {
  height: 300,
  placeholder: '입력하세요',
  disabled: false,
  readonly: true,
  setEditor: () => {},
};
export default React.memo(Editor);
