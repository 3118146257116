export const PRIVACY_POLICY_TITLE = '개인정보처리방침';
export const CONSENT_TO_USE_TITLE = '개인정보 수집 및 이용동의 안내';
export const TERM_OF_USE_TITLE = '이용약관';
export const MARKETING_CONSENT_TITLE = '마케팅정보 수신동의 안내';
export const TERM_OF_USE_BUTTON_TEXT = '이용약관';
export const PREFIX_HISTORY = '변경 이력 : ';
export const ADDITIONAL_TYPE_CD_TERM = '10';
export const ADDITIONAL_TYPE_CD_PRIVACY = '90';
export const ADDITIONAL_TYPE_CD_CONSENT_TO_USE = '35';
export const ADDITIONAL_TYPE_CD_MARKETING = '70';
