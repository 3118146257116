import { useCallback, useEffect, useRef, useState } from 'react';

import debounce from 'lodash-es/debounce';

const useScrollDirection = (offset = 10) => {
  const lastScrollY = useRef(0);
  const [direction, setDirection] = useState('');

  const getDirection = useCallback(() => {
    const { scrollY, innerHeight } = window;
    const {
      body: { offsetHeight },
    } = document;

    const difference = scrollY - lastScrollY.current;

    if (scrollY === 0) {
      return 'top';
    }

    if (innerHeight + scrollY >= offsetHeight) {
      return 'bottom';
    }

    if (lastScrollY.current !== scrollY && Math.abs(difference) >= offset) {
      return difference > 0 ? 'down' : 'up';
    }
  }, [offset]);

  const listener = useCallback(() => {
    const direction = getDirection();
    if (direction) {
      const { scrollY } = window;
      setDirection(direction);
      lastScrollY.current = scrollY;
    }
  }, [getDirection]);

  useEffect(() => {
    window.addEventListener('scroll', debounce(listener, 10));
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [listener]);

  return direction;
};

export default useScrollDirection;
