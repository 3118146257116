import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { textEllipsis } from '@ecp/common/src/style/theme/defaultStyled';
import CollapseArrow from '@ecp/common/src/assets/icon/collapse_arrow.svg';

import { FlexBox, FlexBoxButtonSpaceBetween, FlexBoxSpaceBetween } from '../../../layouts/flex/styled';
import { theme } from '../../../style/theme/default';
import { stateClassNames } from './DropdownUnstyled';

const DefaultLabelRoot = styled(FlexBoxButtonSpaceBetween)`
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 14px;
  border: 1px #d3d3d3 solid;
  gap: 4px;
  text-align: left;

  &.${stateClassNames.disabled} {
    background-color: ${theme.color.background.disabled};
  }

  &.${stateClassNames.open} {
    border-color: ${theme.color.line.selected};
  }

  &.${stateClassNames.error} {
    border-color: ${theme.color.line.error};
  }
`;

const DivLabelRoot = styled(FlexBoxSpaceBetween)`
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 0 14px;
  border: 1px #d3d3d3 solid;
  gap: 4px;

  &.${stateClassNames.disabled} {
    background-color: ${theme.color.background.disabled};
  }

  &.${stateClassNames.open} {
    border-color: ${theme.color.line.selected};
  }

  &.${stateClassNames.error} {
    border-color: ${theme.color.line.error};
  }
`;

const DefaultLabelText = styled.div`
  ${textEllipsis};
  width: 100%;

  &.${stateClassNames.notSelected} {
    font-size: ${theme.font.size.basic};
    color: ${theme.color.text.placeholder};
  }
`;

const DefaultLabelIcon = styled.div`
  width: 18px;
  height: 18px;
  background-image: url(${CollapseArrow});
  background-repeat: no-repeat;
  background-position: center;

  &.${stateClassNames.disabled} {
    color: #bfbfbf;
  }

  &.${stateClassNames.open} {
    transform: rotate(180deg);
  }
`;

export const StyledDefaultLabel = ({ item, placeholder, className }) => {
  return (
    <DefaultLabelRoot className={className}>
      <DefaultLabelText className={className}>{item?.label || placeholder}</DefaultLabelText>
      <DefaultLabelIcon className={className} />
    </DefaultLabelRoot>
  );
};
StyledDefaultLabel.displayName = 'StyledDefaultLabel';
StyledDefaultLabel.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export const StyledDivLabel = ({ item, placeholder, className }) => {
  return (
    <DivLabelRoot className={className}>
      <DefaultLabelText className={className}>{item?.label || placeholder}</DefaultLabelText>
      <DefaultLabelIcon className={className} />
    </DivLabelRoot>
  );
};
StyledDivLabel.displayName = 'StyledDivLabel';
StyledDivLabel.propTypes = {
  item: PropTypes.object,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export const DropdownItemWrapper = styled(FlexBox)`
  align-items: center;
  height: 40px;
  line-height: 28px;
  padding: 0 14px;

  &:hover {
    background-color: ${theme.color.background.sub};
  }

  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  &[disabled] {
    color: ${theme.color.secondary};
  }
`;

export const DropdownItem = styled.div`
  ${textEllipsis};
  width: 100%;
`;

export const StyledDefaultItem = ({ label, disabled }) => (
  <DropdownItemWrapper disabled={disabled}>
    <DropdownItem>{label}</DropdownItem>
  </DropdownItemWrapper>
);

StyledDefaultItem.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};
