import React, { useState } from 'react';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import { ReactComponent as CardBenefitIcon } from '@ecp/common/src/assets/icon/icon__benefit-card.svg';

import CardInterestFreeGuidePopup from '@fo-pages/good/popup/CardInterestFreeGuidePopup';

const CardBenefit = () => {
  const [cardBenefitOpen, setCardBenefitOpen] = useState(false);
  const showCardBenefit = () => setCardBenefitOpen(true);
  const hideCardBenefit = () => setCardBenefitOpen(false);
  return (
    <Wrapper>
      <MessageWrapper>
        <TitleBox>{`카드 무이자
할부 혜택`}</TitleBox>
        <TextButton type="move" onClick={showCardBenefit}>
          확인하기
        </TextButton>
      </MessageWrapper>
      <IconWrapper>
        <CardBenefitIcon />
      </IconWrapper>
      <CardInterestFreeGuidePopup open={cardBenefitOpen} onClose={hideCardBenefit} />
    </Wrapper>
  );
};

const Wrapper = styled(FlexBox)`
  background-color: #fff2b0;
  border-radius: 12px;
  width: 380px;
  height: 160px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 0 30px;
`;

const MessageWrapper = styled(FlexBoxColumn)`
  justify-content: space-between;
  align-items: flex-start;
  height: 100px;
`;

const IconWrapper = styled(FlexBox)`
  width: 144px;
  height: 120px;
`;

const TitleBox = styled(TextBox)`
  color: #4cb1a7;
  font-size: 22px;
  font-weight: 700;
  line-height: 140%;
`;

export default CardBenefit;
