import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { zIndex } from '@ecp/common/src/style/constant';
import { paddingPropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import { overrideProperties } from '@ecp/common/src/style/utils';
import Popover from '@ecp/common/src/components/popover/Popover';

const StyledContextMenu = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: ${theme.color.line.background};
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background-color: ${theme.color.background.white};
  z-index: ${zIndex.contextMenu};
  ${overrideProperties(paddingPropTypes, { padding: '14px' })}

  &:focus-visible {
    outline: none;
  }
`;

const ContextMenu = (props) => {
  return <Popover rootComponent={StyledContextMenu} {...props} />;
};

ContextMenu.displayName = 'ContextMenu';

ContextMenu.propTypes = {
  value: PropTypes.node,
  children: PropTypes.node,
};

ContextMenu.defaultProps = {
  closeWhenOutside: true,
  blockAutoFocus: true,
};

export default ContextMenu;
