import React, { useContext, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import RadioContext from './RadioContext';

export const radioClassNames = {
  disabled: 'radio-disabled',
  checked: 'radio-checked',
};

const InputRadioUnstyled = React.forwardRef(
  (
    {
      productName,
      price,
      numberWrap,
      statusName,
      value,
      disabled,
      children,
      // labelComponent: LabelComponent,
      // inputComponent: InputComponent,
      ...props
    },
    ref
  ) => {
    const { hideControl, className } = props;

    const group = useContext(RadioContext);
    const radioRef = useRef(null);

    useImperativeHandle(ref, () => radioRef.current);

    const stateClasses = {
      [radioClassNames.disabled]: disabled,
      [radioClassNames.checked]: (value ?? '').toString() === (group.value ?? '').toString(),
    };
    return (
      <LabelWrapper>
        <LabelRadio {...props} className={clsx(stateClasses, className)}>
          {productName ? (
            <InputRadio
              type={'radio'}
              name={group.name}
              disabled={disabled || group.disabled}
              value={value}
              className={clsx(stateClasses)}
              checked={group.value ? value === group.value : ''}
              onChange={(e) => group.onChange && group.onChange(e)}
              hidden={hideControl && 'hidden'}
              title={productName + ', ' + price + ', ' + numberWrap + ', ' + statusName}
            />
          ) : (
            <InputRadio
              type={'radio'}
              name={group.name}
              disabled={disabled || group.disabled}
              value={value}
              className={clsx(stateClasses)}
              checked={group.value ? value === group.value : ''}
              onChange={(e) => group.onChange && group.onChange(e)}
              id={value}
              // hidden={hideControl && 'hidden'}
            />
          )}
          {children && typeof children !== 'string'
            ? React.cloneElement(children, { className: clsx(stateClasses) })
            : children}
        </LabelRadio>
      </LabelWrapper>
    );
  }
);

InputRadioUnstyled.displayName = 'radio';

InputRadioUnstyled.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  hideControl: PropTypes.bool,
  className: PropTypes.string,
  labelComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  inputComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  statusName: PropTypes.string,
  productName: PropTypes.string,
  price: PropTypes.string,
  numberWrap: PropTypes.string,
};

InputRadioUnstyled.defaultProps = {
  labelComponent: 'label',
  inputComponent: 'input',
};

export default InputRadioUnstyled;

const LabelRadio = styled.label`
  align-items: center;
  justify-content: center;
  gap: 7px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
const InputRadio = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  z-index: -1;
  clip: rect(0, 0, 0, 0);
`;
const LabelWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
