import useEventCallback from '@ecp/common/src/hooks/useEventCallback';

const useSwiperArrow = ({ onClick, isEdge }) => {
  const handleClick = useEventCallback(
    (value, e) => {
      onClick?.(e);
    },
    { stop: true, extractDataset: false }
  );

  return { handleClick, isEdge };
};

export default useSwiperArrow;
