import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Input from '@ecp/common/src/components/input/Input';
import Dropdown from '@ecp/common/src/components/dropdown/Dropdown';
import Calendar from '@ecp/common/src/components/calendar/Calendar';
import Textarea from '@ecp/common/src/components/input/Textarea';
import { DELIVERY_METHOD } from '@ecp/common/src/const/footer';
import { EMAIL_DOMAIN } from '@ecp/common/src/const/email';

const BulkOrderApplicationForm = ({ formValue, onChangeFormValues, onChangeDropdownValues }) => {
  const { title, company, mobile, emailValue, emailDomain, emailDirectDomain, delivery, deliveryDate, inquiry } =
    formValue;
  const h2Style = {
    fontSize: '20px',
    fontWeight: '500',
    textDecoration: 'none',
    lineHeight: 'inherit',
    backgroundColor: 'unset',
    whiteSpace: 'pre-wrap',
  };

  return (
    <>
      <FlexBoxSpaceBetween width={'100%'} align-items={'flex-end'}>
        {/* <TextBox size={'20px'} weight={'500'}>
          대량 구매 문의 상담 신청
        </TextBox> */}
        <h3 style={h2Style}>대량 구매 문의 상담 신청</h3>
        <TextBox size={'14px'} weight={'350'} required asteriskPosition={'left'}>
          필수 입력 항목입니다.
        </TextBox>
      </FlexBoxSpaceBetween>
      <Spacing top={14} />
      <HorizontalDivider width={'100%'} height={'2px'} color={'#141415'} />
      <FlexBox width={'100%'} height={'530px'}>
        <LabelWrapper>
          <span style={blind}>필수 입력 항목</span>
          <ItemWrapperLabel required htmlFor={'title'}>{'제목'}</ItemWrapperLabel>
          <span style={blind}>필수 입력 항목</span>
          <ItemWrapperLabel required htmlFor={'company'}>{'소속(회사명)'}</ItemWrapperLabel>
          <span style={blind}>필수 입력 항목</span>
          <ItemWrapperLabel required htmlFor={'mobileNum'}>{'휴대전화번호'}</ItemWrapperLabel>
          <span style={blind}>필수 입력 항목</span>
          <ItemWrapperLabel required htmlFor={'email'}>{'이메일'}</ItemWrapperLabel>
          <Spacing top={30} />
          <span style={blind}>필수 입력 항목</span>
          <ItemWrapperLabel required htmlFor={'delivery'}>{'배송방법'}</ItemWrapperLabel>
          <span style={blind}>필수 입력 항목</span>
          <ItemWrapperLabel required htmlFor={'deliveryDate'}>{'희망배송일'}</ItemWrapperLabel>
          <span style={blind}>필수 입력 항목</span>
          <ItemWrapperLabel required htmlFor={'inquiry'}>{'대량구매\n문의내용'}</ItemWrapperLabel>
          {/*
          <ItemWrapper required>{'제목'}</ItemWrapper>
          <ItemWrapper required>{'소속(회사명)'}</ItemWrapper>
          <ItemWrapper required>{'휴대전화번호'}</ItemWrapper>
          <ItemWrapper required>{'이메일'}</ItemWrapper>
          <Spacing top={30} />
          <ItemWrapper required>{'배송방법'}</ItemWrapper>
          <ItemWrapper required>{'희망배송일'}</ItemWrapper>
          <ItemWrapper required>{'대량구매\n문의내용'}</ItemWrapper>
        */}
        </LabelWrapper>
        <ItemContentsWrapper gap={'20px'}>
          <Input
            id={'title'}
            type={'text'}
            width={'430px'}
            placeholder={'제목을 입력해주세요.'}
            value={title}
            onChange={onChangeFormValues}
            maxLength={20}
            data-key={'title'}
          />
          <Input
            id={'company'}
            type={'text'}
            width={'430px'}
            placeholder={'소속을 입력해 주세요.'}
            value={company}
            onChange={onChangeFormValues}
            maxLength={20}
            data-key={'company'}
          />
          <Input
            id={'mobileNum'}
            type={'text'}
            width={'210px'}
            placeholder={'-없이 숫자만 입력해 주세요.'}
            value={mobile}
            onChange={onChangeFormValues}
            maxLength={11}
            onlyNumber
            data-key={'mobile'}
          />
          <FlexBoxColumn gap={'10px'} align-items={'flex-start'}>
            <FlexBox width={'100%'} gap={'6px'}>
              <Input
                id={'email'}
                type={'text'}
                width={'184px'}
                minWidth={'184px'}
                placeholder={'이메일을 입력해주세요.'}
                value={emailValue}
                onChange={onChangeFormValues}
                maxLength={30}
                data-key={'emailValue'}
              />
              <TextBox size={'15px'} weight={'350'}>
                @
              </TextBox>
              <Dropdown
                width={'215px'}
                items={EMAIL_DOMAIN}
                value={emailDomain}
                onChange={(e) => onChangeDropdownValues('emailDomain', e)}
              />
            </FlexBox>
            <Input
              type={'text'}
              width={'184px'}
              minWidth={'184px'}
              placeholder={'직접 입력'}
              disabled={emailDomain.key !== 'custom'}
              value={emailDirectDomain}
              onChange={onChangeFormValues}
              maxLength={30}
              data-key={'emailDirectDomain'}
            />
          </FlexBoxColumn>
          <Dropdown
            id={'delivery'}
            width={'430px'}
            items={DELIVERY_METHOD}
            value={delivery}
            onChange={(e) => onChangeDropdownValues('delivery', e)}
          />
          <Calendar
            id={'deliveryDate'}
            inline={false}
            showPopperArrow={false}
            value={deliveryDate}
            minDate={new Date()}
            dateFormat={'yyyy/MM/dd'}
            placeholderText={'년/월/일'}
            inputStyle={{ width: '210px' }}
            onChange={(e) => onChangeDropdownValues('deliveryDate', e)}
          />
          <Textarea
            id={'inquiry'}
            width={'430px'}
            height={'80px'}
            value={inquiry}
            onChange={onChangeFormValues}
            maxLength={200}
            data-key={'inquiry'}
          />
        </ItemContentsWrapper>
      </FlexBox>
      <HorizontalDivider width={'100%'} />
    </>
  );
};

export default BulkOrderApplicationForm;

BulkOrderApplicationForm.propTypes = {
  formValue: PropTypes.object,
  onChangeFormValues: PropTypes.func,
  onChangeDropdownValues: PropTypes.func,
};

const LabelWrapper = styled(FlexBoxColumn)`
  width: 130px;
  height: 100%;
  background-color: #f7f7f7;
  align-items: flex-start;
  padding: 20px 19px 20px 20px;
  gap: 20px;
`;

// const ItemWrapper = styled(TextBox)`
//   font-size: 15px;
//   font-weight: 500;
//   min-height: 40px;
// `;

const ItemContentsWrapper = styled(FlexBoxColumn)`
  align-items: flex-start;
  padding: 20px 0 20px 20px;
  gap: ${({ gap }) => gap};
  width: 450px;
`;


const ItemWrapperLabel = styled.label`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  min-height: 40px;
  white-space: pre-wrap;
  ${({ required }) => required && rightAsterisk};
`;

const rightAsterisk = css`
  align-items: flex-start;
  &:after {
    content: '*';
    color: #f22214;
  }
`;

const blind = {
  overFlow: 'hidden',
  position: 'absolute',
  clip: 'rect(0,0,0,0)',
  clipPath: 'polygon(0 0, 0 0, 00)',
  width: '1px',
  height: '1px',
  margin: '-1px',
};
ItemWrapperLabel.propTypes = {
  required: PropTypes.bool,
};

ItemWrapperLabel.defaultProps = {
  required: false,
};
