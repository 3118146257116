import React, { cloneElement, useCallback, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { mergeRefs } from 'react-merge-refs';

import clsx from 'clsx';

import useCustomFloating, { placements } from './useCustomFloating';

export const customFloatingPropsKey = 'custom-floating-props';

const FloatingUnstyled = React.forwardRef(
  (
    {
      children,
      rootComponent: Root,
      rootProps,
      arrowComponent: Arrow,
      arrowInnerComponent: ArrowInner,
      closeComponent,
      value,
      arrow: showArrow,
      arrowPadding,
      closeable: showClose,
      placement,
      offset,
      openWhenClicked,
      openWhenHovered,
      openWhenFocused,
      closeWhenOutside,
      closeWhenReference,
      closeWhenScroll,
      open: openProp,
      defaultOpen,
      onOpenChange,
      closeWhenValueClicked,
      blockAutoFocus,
      useShift,
      shiftPadding,
    },
    ref
  ) => {
    const { refs, arrowRef, getReferenceProps, getFloatingProps, ...customFloatingProps } = useCustomFloating({
      placement,
      offset,
      arrowPadding,
      openWhenClicked,
      openWhenHovered,
      openWhenFocused,
      closeWhenOutside,
      closeWhenReference,
      closeWhenScroll,
      openProp,
      defaultOpen,
      onOpenChange,
      blockAutoFocus,
      useShift,
      shiftPadding,
    });

    const {
      open,
      setOpen,
      floatingClassName,
      positionStrategy,
      floatingPosition: { top, left },
      arrowPosition: { arrowX, arrowY },
    } = customFloatingProps;

    useImperativeHandle(ref, () => refs.setFloating?.current);

    const isReactComponentChild = useMemo(() => children && typeof children.type === 'object', [children]);
    const handleClose = () => {
      setOpen(false);
    };

    const handleValueClicked = (e) => {
      if (closeWhenValueClicked && e.target.dataset?.testId === 'tooltip-value') {
        setOpen(false);
      }
    };

    const targetRef = useMemo(() => mergeRefs([refs.setReference, children?.ref]), [refs.setReference, children?.ref]);

    const ChildrenComponent = useMemo(
      () => (
        <>
          {children &&
            cloneElement(children, {
              ref: targetRef,
              ...getReferenceProps({
                ...(isReactComponentChild && {
                  [customFloatingPropsKey]: customFloatingProps,
                }),
              }),
              className: clsx({ [children.props.className]: true, [floatingClassName]: true }),
            })}
        </>
      ),
      [children, customFloatingProps, floatingClassName, getReferenceProps, isReactComponentChild, targetRef]
    );

    const setFloating = useCallback(
      (el) => {
        el && refs.setFloating(el);
      },
      [refs]
    );

    return (
      <>
        {ChildrenComponent}
        {open && (
          <Root
            className={floatingClassName}
            ref={setFloating}
            style={{
              position: positionStrategy,
              top: top,
              left: left,
              whiteSpace: 'pre-line',
            }}
            data-testid="floating-root"
            closeable={showClose}
            {...getFloatingProps()}
            {...rootProps}
            onClick={handleValueClicked}
          >
            {value}
            {showArrow && (
              <>
                <Arrow
                  className={floatingClassName}
                  ref={arrowRef}
                  style={{
                    position: positionStrategy,
                    top: arrowY,
                    left: arrowX,
                  }}
                  data-testid="floating-arrow"
                />
                <ArrowInner
                  className={floatingClassName}
                  style={{
                    position: positionStrategy,
                    top: arrowY,
                    left: arrowX,
                  }}
                  data-testid="floating-arrow-inner"
                />
              </>
            )}
            {showClose &&
              closeComponent &&
              cloneElement(closeComponent, {
                onClick: () => {
                  const { onClick } = closeComponent.props;
                  if (onClick) {
                    onClick();
                  }
                  handleClose();
                },
                'data-testid': 'floating-close',
              })}
          </Root>
        )}
      </>
    );
  }
);

FloatingUnstyled.propTypes = {
  children: PropTypes.node,
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  rootProps: PropTypes.object,
  arrowComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  arrowInnerComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  closeComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  value: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
  offset: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  arrow: PropTypes.bool,
  arrowPadding: PropTypes.number,
  closeable: PropTypes.bool,
  placement: PropTypes.oneOf(placements),
  openWhenClicked: PropTypes.bool,
  openWhenHovered: PropTypes.bool,
  openWhenFocused: PropTypes.bool,
  closeWhenOutside: PropTypes.bool,
  closeWhenReference: PropTypes.bool,
  closeWhenScroll: PropTypes.bool,
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
  closeWhenValueClicked: PropTypes.bool,
  blockAutoFocus: PropTypes.bool,
  useShift: PropTypes.bool,
  shiftPadding: PropTypes.number,
};

FloatingUnstyled.defaultProps = {
  rootComponent: 'div',
  rootProps: {},
  arrowComponent: 'div',
  arrowInnerComponent: 'div',
  shiftPadding: 0,
};
FloatingUnstyled.displayName = 'FloatingUnstyled';

export default FloatingUnstyled;
