import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getMonth, getYear } from 'date-fns';

import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { ReactComponent as IconRightArrow } from '@ecp/common/src/assets/icon/icon__arrow--right.svg';

const IconLeftArrow = styled(IconRightArrow)`
  transform: scaleX(-1);
`;

const ArrowButton = styled(ButtonUnstyled)`
  ${overrideProperties(sizePropTypes, { width: '8px', height: '12px' })};
  border: none;
  background-color: transparent;
`;

const CalendarHeader = ({
  date,
  showMonthYearPicker,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  decreaseYear,
  increaseYear,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
}) => {
  return (
    <FlexBoxSpaceBetween style={{ margin: 'auto' }}>
      {showMonthYearPicker ? (
        <>
          <ArrowButton onClick={decreaseYear} disabled={prevYearButtonDisabled} ariaLabel={'지난 달'}>
            <IconLeftArrow />
          </ArrowButton>
          <div className={'react-datepicker__current-month'}>{`${getYear(date)}년`}</div>
          <ArrowButton onClick={increaseYear} disabled={nextYearButtonDisabled} ariaLabel={'다음 달'}>
            <IconRightArrow />
          </ArrowButton>
        </>
      ) : (
        <>
          <ArrowButton onClick={decreaseMonth} disabled={prevMonthButtonDisabled} ariaLabel={'지난 달'}>
            <IconLeftArrow />
          </ArrowButton>
          <div className={'react-datepicker__current-month'}>{`${getYear(date)}년 ${getMonth(date) + 1}월`}</div>
          <ArrowButton onClick={increaseMonth} disabled={nextMonthButtonDisabled} ariaLabel={'다음 달'}>
            <IconRightArrow />
          </ArrowButton>
        </>
      )}
    </FlexBoxSpaceBetween>
  );
};

CalendarHeader.propTypes = {
  date: PropTypes.instanceOf(Date),
  showMonthYearPicker: PropTypes.bool,
  decreaseMonth: PropTypes.func,
  increaseMonth: PropTypes.func,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
  decreaseYear: PropTypes.func,
  increaseYear: PropTypes.func,
  prevYearButtonDisabled: PropTypes.bool,
  nextYearButtonDisabled: PropTypes.bool,
};

export default CalendarHeader;
