import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import CheckboxUnstyled, { checkboxClassNames } from '@ecp/common/src/components/unstyled/checkbox/CheckboxUnstyled';
import { ReactComponent as FavoriteImage } from '@ecp/common/src/assets/icon/favorite.svg';
const FavoriteCheckBox = ({ checked, value, onChange, title, id }) => {
  return (
    <CheckboxUnstyled
      checkboxComponent={FavoriteCheckBoxImage}
      labelProps={{ style: { paddingLeft: '0px' } }}
      rootProps={{ htmlFor: id }}
      id={id}
      value={value}
      checked={checked}
      onChange={onChange}
      title={title}
    />
  );
};

const FavoriteCheckBoxImage = styled(FavoriteImage)`
  cursor: pointer;
  & path {
    stroke: #595959;
  }
  &.${checkboxClassNames.checked} {
    & path {
      fill: ${theme.color.primary};
      stroke: ${theme.color.stroke};
    }
  }
`;

FavoriteCheckBox.displayName = 'FavoriteCheckBox';

FavoriteCheckBox.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  title: PropTypes.string,
  id: PropTypes.string,
};

export default FavoriteCheckBox;
