import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import { LabelFlexBox } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';

import useCheckbox from './useCheckbox';
import CheckBoxComponent from './default/CheckBoxComponent';

export const checkboxClassNames = {
  disabled: 'checkbox-disabled',
  checked: 'checkbox-checked',
  unchecked: 'checkbox-unchecked',
};

const HiddenCheckbox = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxUnstyled = React.forwardRef((props, ref) => {
  const {
    rootComponent,
    rootProps,
    checkboxComponent,
    label: Content,
    labelProps,
    imageProps,
    value,
    children,
    title,
    playGb,
    playGbAddTitle,
    ...otherProps
  } = props;

  const Root = rootComponent;
  const CheckboxImage = checkboxComponent;

  const { checked, disabled, getInputProps, handleClick } = useCheckbox(otherProps);

  const stateClasses = {
    [checkboxClassNames.disabled]: disabled,
    [checkboxClassNames.checked]: checked,
    [checkboxClassNames.unchecked]: !checked,
  };

  return (
    <Root {...rootProps} style={{ display: 'flex' }} onClick={handleClick}>
      <HiddenCheckbox
        ref={ref}
        className={clsx(stateClasses)}
        value={value}
        {...getInputProps()}
        title={playGb === 'Y' ? (checked ? '재생' + ' ' + playGbAddTitle : '정지' + ' ' + playGbAddTitle) : title}
      />
      <CheckboxImage className={clsx(stateClasses)} checked={checked} {...imageProps} />
      {Content &&
        (typeof Content === 'string' ? (
          <TextBox {...labelProps} title={Content}>
            {Content}
          </TextBox>
        ) : (
          <Content {...labelProps} />
        ))}
      {children}
    </Root>
  );
});

CheckboxUnstyled.propTypes = {
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string, PropTypes.node]),
  rootProps: PropTypes.object,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  checkboxComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.element]),
  labelProps: PropTypes.object,
  imageProps: PropTypes.object,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  hide: PropTypes.bool,
  title: PropTypes.string,
  playGb: PropTypes.string,
  playGbAddTitle: PropTypes.string,
};

CheckboxUnstyled.defaultProps = {
  rootComponent: LabelFlexBox,
  defaultChecked: false,
  checkboxComponent: CheckBoxComponent,
  labelProps: {},
  rootProps: { gap: '10px' },
  label: '',
  dataKey: 'checkbox',
  value: '',
  title: 'checkbox',
  playGb: 'N',
  playGbAddTitle: '',
};
CheckboxUnstyled.displayName = 'CheckboxUnstyled';

export default CheckboxUnstyled;
