import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { addDays, format, startOfToday } from 'date-fns';

import { COUPON_PROPERTY, COUPON_TYPE_CODE } from '@ecp/common/src/const/coupon/couponConst';

const useEmployeeBenefitCoupon = ({
  pathname,
  categoryNumber,
  brandNumber,
  brandCountState,
  brandNameState,
  couponApi,
  myReviewApi,
  myMembershipApi,
  showAlertDialog,
  handleDrawerClose = () => {},
  setInfoChanged = () => {},
  open = true,
}) => {
  const [downloadableCouponCount, setDownloadableCouponCount] = useState(0);
  const [showMembershipCoupon, setShowMembershipCoupon] = useState(false);
  const [birthDayCoupon, setBirthDayCoupon] = useState(null);
  const [firstCouponList, setFirstCouponList] = useState([]);
  const [myNoReviewCount, setMyNoReviewCount] = useState(0);
  const [maxReviewSaveMoney, setMaxReviewSaveMoney] = useState(0);
  const [brandCouponCount, setBrandCouponCount] = useState(0);
  const [brandCount, setBrandCount] = useState(0);
  const [brandListCouponCount, setBrandListCouponCount] = useState(0);
  const [brandName, setBrandName] = useState('');
  const [categoryCouponCount, setCategoryCouponCount] = useState(0);

  const navigate = useNavigate();

  const getDownloadableCouponCount = useCallback(async () => {
    const { result } = await couponApi.getDownloadableCouponCount();
    setDownloadableCouponCount(result);
  }, [couponApi]);

  const getMyPageReviewTotalCount = useCallback(async () => {
    const { result } = await myReviewApi.getMyPageReviewTotalCount();
    if (result) {
      setMyNoReviewCount(result?.myNoEvaluationTotalCount ?? 0);
      setMaxReviewSaveMoney(result?.myEstimatedTotalAsset ?? 0);
    }
  }, [myReviewApi]);

  const getSpecialCoupons = useCallback(async () => {
    const [{ result }, { result: resultList }] = await Promise.all([
      await couponApi.getBirthDayCoupon(),
      await couponApi.getFirstCouponList(),
    ]);
    setBirthDayCoupon(result);
    setFirstCouponList(resultList);
  }, [couponApi]);

  const checkDownloadAvailable = useCallback(async () => {
    const { result } = await myMembershipApi.checkMembershipCouponDownloaded();
    setShowMembershipCoupon(!result);
  }, [myMembershipApi]);

  const handleBirthDayCouponDownloadClick = async () => {
    setInfoChanged(false);
    const { code } = await couponApi.downloadCoupon(birthDayCoupon.promotionNo);
    if (code === '200') {
      showAlertDialog(`${birthDayCoupon.benefitApplyValue}% 할인쿠폰이 정상적으로 발급되었습니다.
    유효기간: ${format(startOfToday(), 'yyyy.MM.dd')} ~ ${format(
        addDays(startOfToday(), birthDayCoupon.validDayCount),
        'yyyy.MM.dd'
      )}`);
      setBirthDayCoupon(null);
      setInfoChanged(true);
    } else {
      showAlertDialog('이미 생일쿠폰을 다운로드 받으셨습니다.');
    }
  };

  const handleFirstPurCouponDownloadClick = async () => {
    setInfoChanged(false);
    if (firstCouponList?.length === 0) return;
    const { code } = await couponApi.downloadAllCoupon(firstCouponList.map(({ promotionNo }) => promotionNo));
    if (code === '200') {
      showAlertDialog(`쿠폰 3종이 정상적으로 발급되었습니다.
    지금 바로 사용해보세요.`);
      setFirstCouponList([]);
      setInfoChanged(true);
    } else {
      showAlertDialog('이미 첫구매쿠폰 3종을 다운로드 받으셨습니다.');
    }
  };

  const getBrandInfo = useCallback(async () => {
    if (!brandNumber) return;
    const { result: couponCount } = await couponApi.getEmployeeBenefitCouponCount(COUPON_TYPE_CODE.BRAND, brandNumber);
    setBrandName(brandNameState);
    setBrandCouponCount(couponCount);
  }, [brandNameState, brandNumber, couponApi]);

  const getBrandListInfo = useCallback(async () => {
    const { result: couponCount } = await couponApi.getEmployeeBenefitCouponCount(COUPON_TYPE_CODE.BRAND, '');
    setBrandCount(brandCountState);
    setBrandListCouponCount(couponCount);
  }, [brandCountState, couponApi]);

  const getCategoryInfo = useCallback(async () => {
    if (!categoryNumber) return;
    const { result: couponCount } = await couponApi.getEmployeeBenefitCouponCount(
      COUPON_TYPE_CODE.CATEGORY,
      categoryNumber
    );
    setCategoryCouponCount(couponCount);
  }, [categoryNumber, couponApi]);

  const handleNavigateButtonClick = useCallback(
    (couponType) => {
      handleDrawerClose();
      navigate(COUPON_PROPERTY[couponType].moveUrl);
    },
    [handleDrawerClose, navigate]
  );

  const showBrandListCoupon = useMemo(() => {
    return pathname.includes('brand') && !brandNumber && brandListCouponCount > 0;
  }, [brandListCouponCount, brandNumber, pathname]);

  const showBrandCoupon = useMemo(() => {
    return pathname.includes('brand') && !!brandNumber && brandCouponCount > 0;
  }, [brandCouponCount, brandNumber, pathname]);

  const showCategoryCoupon = useMemo(() => {
    return pathname.includes('category') && !!categoryNumber && categoryCouponCount > 0;
  }, [categoryCouponCount, categoryNumber, pathname]);

  const showBirthdayCoupon = useMemo(() => {
    return birthDayCoupon;
  }, [birthDayCoupon]);

  const showFirstOrderCoupon = useMemo(() => {
    return firstCouponList?.length > 0;
  }, [firstCouponList?.length]);

  const showDownloadCoupon = useMemo(() => {
    return downloadableCouponCount > 0;
  }, [downloadableCouponCount]);

  const showReviewCoupon = useMemo(() => {
    return myNoReviewCount > 0;
  }, [myNoReviewCount]);

  useEffect(() => {
    (async () => {
      if (open) {
        await Promise.all([
          getDownloadableCouponCount(),
          getSpecialCoupons(),
          getMyPageReviewTotalCount(),
          checkDownloadAvailable(),
          getBrandInfo(),
          getBrandListInfo(),
          getCategoryInfo(),
        ]);
      }
    })();
  }, [
    checkDownloadAvailable,
    getBrandInfo,
    getBrandListInfo,
    getCategoryInfo,
    getDownloadableCouponCount,
    getMyPageReviewTotalCount,
    getSpecialCoupons,
    open,
  ]);

  return {
    downloadableCouponCount,
    myNoReviewCount,
    maxReviewSaveMoney,
    brandCouponCount,
    brandCount,
    brandListCouponCount,
    brandName,
    categoryCouponCount,
    showBrandListCoupon,
    showBrandCoupon,
    showCategoryCoupon,
    showBirthdayCoupon,
    showFirstOrderCoupon,
    showMembershipCoupon,
    showDownloadCoupon,
    showReviewCoupon,
    handleBirthDayCouponDownloadClick,
    handleFirstPurCouponDownloadClick,
    handleNavigateButtonClick,
  };
};

export default useEmployeeBenefitCoupon;
