import styled from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { ReactComponent as Question } from '@ecp/common/src/assets/icon/question.svg';

export const MainContentsBox = styled(FlexBox)`
  width: 100%;
  min-width: 1540px;
  max-width: 1540px;
`;

export const MainContentsTitle = styled(FlexBox)`
  ${overrideProperties(sizePropTypes, {
    width: '100%',
    height: '45px',
  })};

  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.large};
  line-height: ${theme.font.lineHeight.demiLight};
  color: ${theme.color.text.basic};
`;

export const QuestionIcon = styled(Question)`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
