import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { pcBackgroundBand } from '@ecp/common/src/style/theme/defaultStyled';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';

import BrandList from '@fo-components/brand/brandList';

const BrandRecommendList = ({ recommendBrandList }) => (
  <FlexBoxStyle backgroundColor={theme.color.background.sub}>
    <BrandList brandList={recommendBrandList} />
  </FlexBoxStyle>
);

export default BrandRecommendList;

BrandRecommendList.propTypes = {
  recommendBrandList: PropTypes.array,
};

const FlexBoxStyle = styled(FlexBox)`
  min-width: 1540px;
  padding: 50px 0;
  ${pcBackgroundBand};
`;
