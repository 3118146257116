import useEventCallback from '@ecp/common/src/hooks/useEventCallback';

const useSwiperController = ({ togglePlay }) => {
  const handleChange = useEventCallback(
    (value, e) => {
      togglePlay && togglePlay(e);
    },
    { stop: true, extractDataset: false }
  );

  return { handleChange };
};

export default useSwiperController;
