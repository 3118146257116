import { atom } from 'recoil';

export const cartBadgeCountAtom = atom({
  key: 'cartBadgeCountAtom',
  default: {
    count: 0,
  },
});

export const orderInfoState = atom({
  key: 'orderInfoState',
  default: {
    orderTypeCode: '', // 주문유형
    consumerName: '', // 주문자 이름
    consumerCellPhoneNumber1: '', // 주문자 전화번호 1
    consumerCellPhoneNumber2: '', // 주문자 전화번호 2
    consumerCellPhoneNumber3: '', // 주문자 전화번호 3
    consumerEmail: '', // 주문자 이메일
    foreignDeliveryGoodYn: false, // 해외배송상품유무
    personalCustomsClearanceNumber: '', // 개인통관번호
    orderDeliveryList: [
      // 배송지 정보, 배송지별 상품정보
      {
        memberDeliverySequence: '',
        deliveryName: '', //배송지 명
        orderName: '', // 주문자
        orderMemo: '', // 배송 요청사항
        orderNameYn: '', // 받는분

        // TODO 명칭 통일 필요
        receiverName: '', // 배송 받는 분

        telephoneNumber1: '',
        telephoneNumber2: '',
        telephoneNumber3: '',
        cellphoneNumber1: '',
        cellphoneNumber2: '',
        cellphoneNumber3: '',
        zip: '',
        baseAddress: '',
        detailAddress: '',
        deliveryDate: '', // 배송 희망일
        smsReceiver: '', // SMS 받는분
        smsMessage: '',
        orderGoodList: [
          // 상품정보
          {
            goodNumber: '',
            itemNumber: '',
            goodDivisionCode: '',
            goodOrderCount: 0,
          },
        ],
      },
    ],
    ticketGoodList: [
      // 티켓상품정보
      {
        cellphoneNumber1: '', // 티켓상품 받을 번호
        cellphoneNumber2: '',
        cellphoneNumber3: '',
        orderGoodList: [
          // 상품정보
          {
            goodNumber: '',
            itemNumber: '',
            goodTypeCode: '',
            goodDivisionCode: '',
            goodOrderCount: 0,
          },
        ],
      },
    ],
    presentGoodInfo: {
      sendTypeCode: '', // 전송방법(카카오톡, sms)
      presentImageId: '', // 선물하기 이미지
      presentMessage: '', // 선물하기 메시지
      orderDeliveryList: [
        // 받는 분
        {
          receiverName: '',
          orderNameYn: '',
          cellphoneNumber: '',
        },
      ],
      orderGoodList: [
        // 상품정보
        {
          goodTypeCode: '',
          goodNumber: '',
          itemNumber: '',
          goodDivisionCode: '',
          quantity: 0,
        },
      ],
    },
    saveAmountPoint: 0, // 적립금
    welPoint: 0, // 웰포인트
    welfarePoint: 0, // 복지포인트 (웰스토리 임직원)
    deliveryCost: 0, // 배송비
    payMeanSaveYn: true,
    payMeanCode: '', // 결제수단
    bankCode: '', // 무통장입금은행코드
    depositName: '', //입금자명
    orderTotalAmount: 0, // 주문 총 금액
    // depositWithoutBankbook: {
    //   // 무통장입금정보
    //   bankCode: '',
    //   depositName: '',
    // },
    cashReceiptIssuing: {
      // 현금영수증발급번호정보
      cashReceiptIssuingType: '',
      incomeDeductionType: 'tel',
      tel1: '010',
      deductionType: '',
      tel2: '',
      tel3: '',
      cashReceiptCard1: '',
      cashReceiptCard2: '',
      cashReceiptCard3: '',
      cashReceiptCard4: '',
      entrepreneurNumber1: '',
      entrepreneurNumber2: '',
      entrepreneurNumber3: '',
    },
    // 주문 페이지에 정보 추가 시 아래 항목 추가
  },
});

/**
 * 사용자 배송지 목록 관련
 */
export const myDeliveryPlaceListState = atom({
  key: 'myDeliveryPlaceListState',
  default: undefined,
});

export const couponGoodInfo = atom({
  key: 'couponGoodInfo',
  default: {
    goodName: '',
    itemName: '',
    listPrice: 0,
    discountPrice: 0,
    couponDiscountPrice: 0,
    couponApplyPrice: 0,
    promotionNumber: '',
    benefitTypeCode: '',
    benefitValue: 0,
  },
});

export const cartDownloadCouponList = atom({
  key: 'cartDownloadCouponList',
  default: {
    downloadCouponList: [{ couponNumber: '', couponName: '', benefitValue: 0, couponDescription: '' }],
  },
});

export const cartAvailableCouponList = atom({
  key: 'cartAvailableCouponList',
  default: {
    availAbleCouponList: [{ couponNumber: '', couponName: '', benefitValue: 0, couponDescription: '' }],
  },
});
