import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import BirthdayCouponCard from '@ecp/common/src/components/coupon/components/BirthdayCouponCard';
import FirstOrderCouponCard from '@ecp/common/src/components/coupon/components/FirstOrderCouponCard';
import BrandCouponCard from '@ecp/common/src/components/coupon/components/BrandCouponCard';
import CategoryCouponCard from '@ecp/common/src/components/coupon/components/CategoryCouponCard';
import ReviewCouponCard from '@ecp/common/src/components/coupon/components/ReviewCouponCard';
import DownloadCouponCard from '@ecp/common/src/components/coupon/components/DownloadCouponCard';
import MembershipCouponCard from '@ecp/common/src/components/coupon/components/MembershipCouponCard';
import BrandListCouponCard from '@ecp/common/src/components/coupon/components/BrandListCouponCard';
import { COUPON_STYLE_MO, COUPON_STYLE_PC, COUPON_TYPE } from '@ecp/common/src/const/coupon/couponConst';

const CardMessageMap = {
  [COUPON_TYPE.FIRST_ORDER]: FirstOrderCouponCard,
  [COUPON_TYPE.BIRTHDAY]: BirthdayCouponCard,
  [COUPON_TYPE.BRAND]: BrandCouponCard,
  [COUPON_TYPE.BRAND_LIST]: BrandListCouponCard,
  [COUPON_TYPE.CATEGORY]: CategoryCouponCard,
  [COUPON_TYPE.REVIEW]: ReviewCouponCard,
  [COUPON_TYPE.DOWNLOAD]: DownloadCouponCard,
  [COUPON_TYPE.MEMBERSHIP]: MembershipCouponCard,
};

const CouponTemplateCard = ({ component: Component, ...props }) => {
  return <Component {...props} />;
};

CouponTemplateCard.propTypes = {
  component: PropTypes.elementType,
  messageValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pointValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  baseStyle: PropTypes.object,
  handleButtonClick: PropTypes.func,
};

const BenefitCouponCard = ({ couponType, messageValue, countValue, pointValue, mobile, handleButtonClick }) => {
  const baseStyle = useMemo(() => {
    return mobile ? COUPON_STYLE_MO : COUPON_STYLE_PC;
  }, [mobile]);

  return (
    <CouponTemplateCard
      data-ds-contents={couponType}
      component={CardMessageMap[couponType]}
      messageValue={messageValue}
      countValue={countValue}
      pointValue={pointValue}
      baseStyle={baseStyle}
      handleButtonClick={handleButtonClick}
    />
  );
};

BenefitCouponCard.propTypes = {
  couponType: PropTypes.string,
  messageValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pointValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mobile: PropTypes.bool,
  handleButtonClick: PropTypes.func,
};

BenefitCouponCard.defaultProps = {
  couponType: COUPON_TYPE.FIRST_ORDER.name,
  messageValue: '',
  countValue: '',
  pointValue: '',
  mobile: false,
};

export default BenefitCouponCard;
