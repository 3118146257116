export const unescapeHtml = (str = '') => {
  if (!str) return '';
  return str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#40;/g, '(')
    .replace(/&#41;/g, ')')
    .replace(/&#35/g, '#')
    .replace(/&#39/g, "'")
    .replace(/&amp;/g, '&');
};
