const color = {
  main: '#6c60ff',
  hover: '#564dcc',
  hover1: '#f8f7ff',
  hover2: '#babac9',
  hover3: '#bebebe',
  hover4: '#3d3c50',
  error1: '#e10808',
  error2: '#fce7e7',
  sub1: '#333333',
  sub2: '#666666',
  sub3: '#999999',
  sub4: '#aaaaaa',
  sub5: '#bbbbbb',
  sub6: '#cccccc',
  sub7: '#dddddd',
  sub8: '#eeeeee',
  sub9: '#f8f8f8',
  sub10: '#ffffff',
  sub11: '#fafafa',
  sub12: '#888888',
  sub13: '#e3e4e9',
  sub14: '#f7f8fb',
  table1: '#e9eaf0',
  table2: '#f2f3f8',
  table3: '#f8f8fa',
  table4: '#faf9ff',
  menu1: '#4c4b64',
  menu2: '#36354e',
  menu3: '#232237',
  menu4: '#353544',
  menu5: '#a4aed1',
  menu6: '#595877',
  page1: '#373461',
  page2: '#c4c1f0',
  page3: '#e9e8fb',
  page4: '#9d9ac0',
  page5: '#a3a3a3',
  disabled: '#f5f5f5',
};

const font = {
  size: {
    sub: '11px',
    error: '12px',
    small: '13px',
    basic: '14px',
    group: '15px',
    medium: '16px',
    popup: '18px',
    large: '20px',
    title: '22px',
  },
  weight: {
    regular: 400,
    bold: 700,
  },
  lineHeight: {
    regular: '140%',
    bold: '100%',
  },
};

export const boTheme = {
  color,
  font,
};

export default boTheme;
