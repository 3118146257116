import useEventCallback from '@ecp/common/src/hooks/useEventCallback';

const useSwiperPaging = ({
  handlePrev: handlePrevProp,
  handleNext: handleNextProp,
  handlePaging: handlePagingProp,
}) => {
  const handlePrev = useEventCallback(
    (value, e) => {
      handlePrevProp?.(e);
    },
    { stop: true, extractDataset: false }
  );

  const handleNext = useEventCallback(
    (value, e) => {
      handleNextProp?.(e);
    },
    { stop: true, extractDataset: false }
  );

  const handlePaging = useEventCallback(
    (value, e, index) => {
      handlePagingProp?.(index);
    },
    { stop: true, extractDataset: false }
  );

  return { handlePrev, handleNext, handlePaging };
};

export default useSwiperPaging;
