const sendLoginInfoToNethru = ({ uuid, gender, ageGroup }) => {
  try {
    window.Ntm.Event.fireUserDefined('loginSuccess', {
      nth_useruuid: uuid ?? '', // memberNumber
      nth_gender: gender ?? '', // M, F
      nth_age: ageGroup ?? '', // 10, 20, 30
      nth_area: '',
    });
  } catch (e) {
    console.error('Fail to send login info to Nethru.');
    console.error(e);
  }
};

export { sendLoginInfoToNethru };
