import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { useGetRecentlyViewedGoodList } from '@ecp/common/src/hooks/user/useRecentlyViewedGoodList';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';

import { categoryListState } from '@fo-recoil/category/atom';
import { recentlyViewedGoodListState, userInfoState } from '@fo-recoil/common/user/atom';
import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import goodListApi from '@fo-apis/good/goodListApi';
import displayApi from '@fo-apis/display/displayApi';
import Gnb from '@fo-pages/home/gnb/Gnb';
import Footer from '@fo-pages/home/footer/Footer';
import EventMallGnb from '@fo-pages/eventMall/gnb/Gnb';

const HomePage = () => {
  const { isEventMall, getSystemInformationByKey } = useSiteInfo();

  const setCategoryList = useSetRecoilState(categoryListState);
  const userInfo = useRecoilValue(userInfoState);
  const [recentlyViewedList, setRecentlyViewedList] = useRecoilState(recentlyViewedGoodListState);

  useGetRecentlyViewedGoodList(userInfo, recentlyViewedList, setRecentlyViewedList, goodListApi.getGoodDisplayableList);

  useEffect(() => {
    console.debug("siteName: ", getSystemInformationByKey('siteName'), ", isEventMall: ", isEventMall, ", siteTypeCode:",getSystemInformationByKey('siteTypeCode'));
    
    (async () => {
      const { result } = await displayApi.getDisplayCategory({ skipSpinner: true });
      setCategoryList(result);
    })();
  }, [setCategoryList]);

  return (
    <StyledFlexBoxColumn width={'100%'}>
      {isEventMall ? <EventMallGnb /> : <Gnb />}
      <PageWrapper id="contents" tabIndex={0}>
        <Outlet />
      </PageWrapper>
      {isEventMall ? <Footer showInquiry={false} /> : <Footer />}
    </StyledFlexBoxColumn>
  );
};

const StyledFlexBoxColumn = styled(FlexBoxColumn)`
  min-width: 1630px;
  min-height: 100vh;
`;

const PageWrapper = styled(FlexBox)`
  flex: 1;
  align-items: flex-start;
  min-height: calc(100vh - 407px);
  padding-left: 14px;
  margin-left: -14px;
`;
export default HomePage;
