import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import clsx from 'clsx';

import {
  ALL_CONSONANT,
  ENG_CONSONANT_LIST,
  ETC_CONSONANT,
  KOR_CONSONANT_LIST,
} from '@ecp/common/src/utils/good/brandUtil';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

const BrandConsonant = ({ selectedConsonant, handleConsonantChange }) => {
  return (
    <>
      <BrandCategoryWrap>
        {[ALL_CONSONANT, ...KOR_CONSONANT_LIST, ETC_CONSONANT].map((consonant) => (
          <FlexBoxStyle
            type="button"
            key={consonant}
            className={clsx({
              ['active']: (!selectedConsonant && consonant === ALL_CONSONANT) || selectedConsonant === consonant,
            })}
            title={
              (!selectedConsonant && consonant === ALL_CONSONANT) || selectedConsonant === consonant ? '선택됨' : ''
            }
            onClick={() => handleConsonantChange(consonant)}
          >
            {consonant}
          </FlexBoxStyle>
        ))}
      </BrandCategoryWrap>
      <Spacing top={6} />
      <BrandCategoryWrap>
        {ENG_CONSONANT_LIST?.map((consonant) => (
          <FlexBoxStyle
            type="button"
            key={consonant}
            className={clsx({
              ['active']: selectedConsonant === consonant,
            })}
            title={selectedConsonant === consonant ? '선택됨' : ''}
            onClick={() => handleConsonantChange(consonant)}
          >
            {consonant}
          </FlexBoxStyle>
        ))}
      </BrandCategoryWrap>
    </>
  );
};

export default BrandConsonant;

BrandConsonant.propTypes = {
  selectedConsonant: PropTypes.string,
  handleConsonantChange: PropTypes.func,
};

const BrandCategoryWrap = styled(FlexBox)`
  flex-wrap: wrap;
  width: 1540px;
  gap: 6px;
`;

const FlexBoxStyle = styled.button`
  position: relative;
  width: 44px;
  height: 40px;
  background: #f7f7f7;

  &.active {
    background: ${theme.color.primary};
    color: #fff;
  }
`;
