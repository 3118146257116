import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import jwt_decode from 'jwt-decode';

import { saveAccessTokens } from '@ecp/common/src/utils/utils';
import { sendLoginInfoToNethru } from '@ecp/common/src/utils/nethruUtils';
import { SSO_STATUS, useSso } from '@ecp/common/src/hooks/useSso';

import authApi from '@fo-apis/common/authApi';
import knoxApi from '@fo-apis/common/knoxApi';

const SsoRoutes = () => {
  const { connect, ssoLogin, status, resultData } = useSso({
    loginApi: authApi.ssoLogin,
    infoApi: knoxApi.decryptKnoxInfo,
  });
  const [routeStatus, setRouteStatus] = useState('');

  const { pathname } = useLocation();

  const navigateState = useMemo(() => ({ prev: pathname, current: '/login' }), [pathname]);

  const navigate = useNavigate();

  const updateRouteStatusBySuccessResult = useCallback(({ resultCode, accessToken, refreshToken }) => {
    switch (resultCode) {
      case 'SSO-001':
        setRouteStatus('unregistered');
        break;
      case 'SSO-002':
        setRouteStatus('fail');
        break;
      case 'SSO-003':
        setRouteStatus('dormancy');
        break;
      case 'SSO-004':
        setRouteStatus('expired-password');
        break;
      case 'SSO-006':
        setRouteStatus('no-ci-user');
        break;
      default:
        if (accessToken) {
          saveAccessTokens(accessToken, refreshToken);

          const decoded = jwt_decode(accessToken);
          sendLoginInfoToNethru({
            uuid: decoded?.details?.memberNumber,
            gender: decoded?.details?.genderCode,
            ageGroup: decoded?.details?.ageGroup,
          });

          setRouteStatus('registered');
        }
    }
  }, []);

  useEffect(() => {
    connect();
  }, [connect]);

  useEffect(() => {
    switch (status) {
      case SSO_STATUS.READY:
        ssoLogin();
        break;
      case SSO_STATUS.SUCCESS:
        updateRouteStatusBySuccessResult(resultData);
        break;
      case SSO_STATUS.FAIL:
        setRouteStatus('fail');
        break;
      case SSO_STATUS.CLOSED:
        setRouteStatus('closed');
        break;
      default:
        return;
    }
  }, [status, resultData, ssoLogin, updateRouteStatusBySuccessResult]);

  useEffect(() => {
    switch (routeStatus) {
      case 'registered':
        navigate(0);
        break;
      case 'unregistered':
        navigate('login', { replace: true, state: navigateState });
        break;
      case 'fail':
        navigate('login', { replace: true, state: navigateState });
        break;
      case 'dormancy':
        navigate('login', { replace: true, state: { openDormancyAlert: true, ...navigateState } });
        break;
      case 'expired-password':
        navigate('login', {
          replace: true,
          state: { openExpiredPasswordAlert: true, ...navigateState },
        });
        break;
      case 'no-ci-user':
        navigate('login', {
          replace: true,
          state: { openCertificationAlert: true, ...navigateState },
        });
        break;
      case 'closed':
        break;
      default:
        return;
    }
  }, [navigate, navigateState, pathname, routeStatus]);

  return <></>;
};

export default SsoRoutes;
