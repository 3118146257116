import React from 'react';
import PropTypes from 'prop-types';

import FloatingUnstyled from '../unstyled/floating/FloatingUnstyled';
import Paper from '../paper/Paper';

const Popover = ({ children, ...props }) => {
  return (
    <FloatingUnstyled rootComponent={Paper} {...props}>
      {children}
    </FloatingUnstyled>
  );
};

Popover.displayName = 'Popover';

Popover.propTypes = {
  value: PropTypes.node,
  children: PropTypes.node,
};

Popover.defaultProps = {};

export default Popover;
