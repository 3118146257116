import { getRequest, postRequest } from '@fo-apis/axios';

const summary = () =>
  getRequest({
    url: `api/v1/mypage/asset-point/summary`,
  });

const searchAssetDetail = (form, pageable) =>
  postRequest({
    url: `api/v1/mypage/asset-point/detail/search`,
    params: { ...pageable },
    data: { ...form },
  });

export default {
  summary,
  searchAssetDetail,
};
