import React from 'react';
import styled from 'styled-components';

import clsx from 'clsx';

import useSticky from '@ecp/common/src/hooks/useSticky';
import theme from '@ecp/common/src/style/theme/default';
import { zIndex } from '@ecp/common/src/style/constant';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';

import GnbHeader from '@fo-pages/eventMall/gnb/GnbHeader';

const Gnb = () => {
  const { ref, sticky } = useSticky();
  const className = clsx({ sticky });

  return (
    <Container className={className}>
      <GnbWrapper className={className}>
        <GnbHeaderContainer className={className}>
          {!sticky && <Spacing top={15} />}
          <GnbHeader ref={ref} searchPhraseList={null} />
          <HorizontalDivider width="calc(max(100vw, 1630px))" />
        </GnbHeaderContainer>
      </GnbWrapper>
    </Container>
  );
};

export default Gnb;

const Container = styled.div`
  width: calc(max(100vw, 1630px));
  height: 80px;

  z-index: ${zIndex.appBar};

  &.sticky {
    height: 80px;
  }
`;

const GnbWrapper = styled.div`
  width: calc(max(100vw, 1630px));
  height: 100%;

  position: relative;
  background-color: #ffffff;

  &.sticky {
    height: 80px;

    position: fixed;
    top: 0;
  }
`;

const GnbHeaderContainer = styled(FlexBoxColumn)`
  width: 1540px;

  position: absolute;
  top: 0;
  left: calc(max(50% - 770px, 45px));

  background-color: ${theme.color.background.white};
  font-weight: ${theme.font.weight.demiLight};

  &.sticky {
    position: fixed;
    top: 0;
    height: 80px;
    padding: 15px 0 0;
    transition-duration: 0.2s;
  }
`;
