import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CloseIcon from '@ecp/common/src/assets/icon/modal__close.svg';

import { backgroundPropTypes, paddingPropTypes, sizePropTypes } from '../../style/propTypes';
import { overrideProperties } from '../../style/utils';
import { theme } from '../../style/theme/default';

const styledPaperPropTypes = {
  ...sizePropTypes,
  ...paddingPropTypes,
  ...backgroundPropTypes,
};

const StyledPaper = styled.div`
  position: relative;
  ${overrideProperties(styledPaperPropTypes, {
    width: 'fit-content',
    height: 'fit-content',
    padding: 0,
  })}
  ${({ round }) => round && { 'border-radius': '12px' }}
  ${({ outline }) => outline && { border: `1px solid  ${theme.color.line.background}` }}
  ${({ $elevation }) => $elevation && { 'box-shadow': '2px 4px 10px rgba(0, 0, 0, 0.15)' }}

  &:focus-visible {
    outline: none;
  }
}
`;

const Paper = React.forwardRef(({ closable, children, onClose, elevation, ...props }, ref) => (
  <StyledPaper ref={ref} {...props} $elevation={elevation}>
    {children}
    {closable && <CloseButton onClick={onClose} aria-label={'닫기'} />}
  </StyledPaper>
));

Paper.displayName = 'Paper';

Paper.propTypes = {
  ...styledPaperPropTypes,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  round: PropTypes.bool,
  outline: PropTypes.bool,
  elevation: PropTypes.bool,
};

Paper.defaultProps = {
  closable: false,
  round: false,
  outline: false,
  elevation: false,
};

export default Paper;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-color: #ffffff;
  background-image: url(${CloseIcon});
  z-index: 1;
`;
