import { deleteRequest, getRequest, postRequest } from '@fo-apis/axios';

const getIsWish = (goodNumber) =>
  getRequest({
    url: '/api/v1/member/wish',
    params: { goodNumber: goodNumber },
  });

const setWish = (payload) =>
  postRequest(
    {
      url: 'api/v1/member/wish',
      data: { ...payload },
    },
    { skipSpinner: true }
  );

const deleteWishList = (selectedWishList) =>
  deleteRequest({
    url: '/api/v1/member/wish/delete',
    data: selectedWishList,
  });

const getWishList = async ({ pageInfo }) => {
  return await getRequest({
    url: '/api/v1/member/wish/list',
    params: { ...pageInfo },
  });
};

const getGoodWishList = async (goodWishList) => {
  return await getRequest({
    url: '/api/v1/member/wish/good-wish-list',
    params: { goodWishList },
  });
};

const memberWishApi = {
  getIsWish,
  setWish,
  deleteWishList,
  getWishList,
  getGoodWishList,
};

export default memberWishApi;
