import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { getMonth } from 'date-fns';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import CouponBaseCard from '@ecp/common/src/components/coupon/components/CouponBaseCard';
import { COUPON_PROPERTY, COUPON_STYLE_PC } from '@ecp/common/src/const/coupon/couponConst';

const MembershipCouponCard = ({ messageValue, baseStyle, handleButtonClick }) => {
  const CardMessage = useMemo(() => {
    return (
      <FlexBoxColumn style={baseStyle.MESSAGE_STYLE} justify-content={'flex-start'} align-items={'flex-start'}>
        <TextBoxAlignLeft style={baseStyle.MESSAGE} color={theme.color.text.selected}>
          {`${getMonth(new Date()) + 1}월 ${messageValue} 회원`}
        </TextBoxAlignLeft>
        <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'멤버십 혜택 쿠폰이 발급 되었어요'}</TextBoxAlignLeft>
      </FlexBoxColumn>
    );
  }, [baseStyle.MESSAGE, baseStyle.MESSAGE_STYLE, messageValue]);

  return (
    <CouponBaseCard
      baseStyle={baseStyle}
      iconImage={COUPON_PROPERTY.MEMBERSHIP.iconImage}
      cardColor={COUPON_PROPERTY.MEMBERSHIP.cardColor}
      buttonText={COUPON_PROPERTY.MEMBERSHIP.buttonText}
      iconCountValue={messageValue ? COUPON_PROPERTY.MEMBERSHIP.gradeCounts[messageValue.toUpperCase()] : ''}
      iconCountValueStyle={baseStyle.COUNT_LOCATION.membership}
      handleButtonClick={handleButtonClick}
      altText={(getMonth(new Date()) + 1) + '월 ' + messageValue + ' 회원 멤버십 혜택 쿠폰이 발급 되었어요 ,' + COUPON_PROPERTY.MEMBERSHIP.buttonText}
    >
      {CardMessage}
    </CouponBaseCard>
  );
};

MembershipCouponCard.propTypes = {
  messageValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  baseStyle: PropTypes.object,
  handleButtonClick: PropTypes.func,
};

MembershipCouponCard.defaultProps = {
  messageValue: '',
  baseStyle: COUPON_STYLE_PC,
};
export default MembershipCouponCard;
