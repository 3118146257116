import { selector, selectorFamily } from 'recoil';

import { MAIN_FUNCTION_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import { commonMainFunctionListState, mainFunctionListState } from './atom';

export const mainFunctionCustomState = selector({
  key: 'mainCustomFunctionState',
  get: ({ get }) => {
    const mainFunctionList = get(mainFunctionListState);
    const mainFunctionCustom = mainFunctionList
      ? mainFunctionList
          .filter(({ mainFunctionKindCode }) => mainFunctionKindCode === MAIN_FUNCTION_CODE.CUSTOM)
          .shift()
      : [];
    return { ...mainFunctionCustom };
  },
});

export const withMainFunctionQuery = selectorFamily({
  key: 'withMainFunctionQuery',
  get:
    (mainFunctionCode) =>
    ({ get }) => {
      const mainFunctionList = get(mainFunctionListState);

      return mainFunctionList.find(({ mainFunctionKindCode }) => mainFunctionKindCode === mainFunctionCode);
    },
});

export const withMainFunctionUseQuery = selectorFamily({
  key: 'withMainFunctionUseQuery',
  get:
    (mainFunctionCode) =>
    ({ get }) => {
      const mainFunctionList = get(mainFunctionListState);

      return !!mainFunctionList.find(({ mainFunctionKindCode }) => mainFunctionKindCode === mainFunctionCode)
        ?.mainFunctionUse;
    },
});

export const withCommonMainFunctionQuery = selectorFamily({
  key: 'withCommonMainFunctionQuery',
  get:
    (mainFunctionCode) =>
    ({ get }) => {
      const commonMainFunctionList = get(commonMainFunctionListState);

      return commonMainFunctionList.find(({ mainFunctionKindCode }) => mainFunctionKindCode === mainFunctionCode);
    },
});

export const withCommonMainFunctionUseQuery = selectorFamily({
  key: 'withCommonMainFunctionUseQuery',
  get:
    (mainFunctionCode) =>
    ({ get }) => {
      const commonMainFunctionList = get(commonMainFunctionListState);

      return !!commonMainFunctionList.find(({ mainFunctionKindCode }) => mainFunctionKindCode === mainFunctionCode)
        ?.mainFunctionUse;
    },
});
