import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { PopupButton } from '@ecp/common/src/components/button/Button';
import Popup from '@ecp/common/src/components/modal/Popup';
import { COUPON_POPUP_TYPE, COUPON_TYPE_CODE } from '@ecp/common/src/const/coupon/couponConst';
import { ReactComponent as DownloadIcon } from '@ecp/common/src/assets/icon/icon_download_white.svg';

import { useAlertDialog } from '@fo-hooks/common/useModal';
import couponApi from '@fo-apis/coupon/couponApi';
import HorizontalCouponCard from '@fo-components/coupon/HorizontalCouponCard';
import { ERROR_CODE } from '@fo-const/errorConst';

const EmployeeBenefitCouponPopup = ({ open, onClose, type, detailName, detailNumber, setInfoChanged }) => {
  const [couponList, setCouponList] = useState([]);
  const { showAlertDialog } = useAlertDialog();

  const getEmployeeBenefitCouponList = useCallback(async () => {
    const applyType = type === COUPON_POPUP_TYPE.CATEGORY ? COUPON_TYPE_CODE.CATEGORY : COUPON_TYPE_CODE.BRAND;
    const { result: couponList } = await couponApi.getEmployeeBenefitCouponList(applyType, detailNumber);
    setCouponList([
      ...couponList.map((coupon) => ({
        ...coupon,
        applyLimitAmount: coupon.benefitApplyMinAmount,
        maxDiscountAmount: coupon.benefitApplyMaxAmount,
        benefitValue: coupon.benefitApplyValue,
        validStartDate: coupon.validTermStartDate,
        validEndDate: coupon.validTermEndDate,
        downloadableCount: Number(coupon.pidIssueLimitQuantity) - Number(coupon.myCouponCount),
        downloadable: !(
          (coupon.totalIssueQuantityCode === '10' && coupon.possibleIssueQuantity === 0) ||
          (coupon.pidIssueQuantityCode === '10' &&
            Number(coupon.pidIssueLimitQuantity) - Number(coupon.myCouponCount) === 0)
        ),
      })),
    ]);
  }, [detailNumber, type]);

  useEffect(() => {
    (async () => {
      if (open) {
        await getEmployeeBenefitCouponList();
      }
    })();
  }, [getEmployeeBenefitCouponList, open]);

  const handleDownloadClick = useCallback(
    async (promotionNumber) => {
      setInfoChanged(false);
      const { code } = await couponApi.downloadCoupon(promotionNumber);
      if (code === '200') {
        showAlertDialog('쿠폰이 정상적으로 발급되었습니다.');
        await getEmployeeBenefitCouponList();
        setInfoChanged(true);
      } else if (code === ERROR_CODE.COUPON_EXCESS_LIMIT_QUANTITY_ERROR.code) {
        showAlertDialog(ERROR_CODE.COUPON_EXCESS_LIMIT_QUANTITY_ERROR.message);
      } else {
        showAlertDialog('다운로드 불가한 쿠폰입니다.');
      }
    },
    [getEmployeeBenefitCouponList, setInfoChanged, showAlertDialog]
  );
  const handleDownloadAllClick = useCallback(() => {
    setInfoChanged(false);
    showAlertDialog('모든 쿠폰을 다운로드 하시겠습니까?', async () => {
      const { code, message } = await couponApi.downloadAllCoupon(
        couponList.filter(({ downloadable }) => downloadable)?.map(({ promotionNo }) => promotionNo)
      );
      if (code === '200') {
        showAlertDialog('모든 쿠폰이 정상적으로 발급되었습니다.');
        await getEmployeeBenefitCouponList();
        setInfoChanged(true);
      } else {
        showAlertDialog(message);
      }
    });
  }, [couponList, getEmployeeBenefitCouponList, setInfoChanged, showAlertDialog]);

  const availableCouponCount = useMemo(() => couponList?.length, [couponList?.length]);
  return (
    <Popup
      rootStyleProps={{ width: '640px', height: '720px' }}
      header={`${detailName && `${detailName} `}${
        type === COUPON_POPUP_TYPE.CATEGORY ? '카테고리' : '브랜드'
      } 쿠폰 목록`}
      open={open}
      onClose={onClose}
      useCancelButton={false}
      useConfirmButton={false}
    >
      <FlexBoxColumn width={'100%'}>
        <CouponListContainer align-items={'flex-start'}>
          <TextBox>사용 가능 쿠폰 {availableCouponCount}개</TextBox>
          {couponList?.length > 0 &&
            couponList.map((coupon, idx) => (
              <Spacing top={10} key={`${coupon.promotionNo}-${idx}`}>
                <HorizontalCouponCard
                  key={`${coupon.promotionNo}-${idx}`}
                  coupon={coupon}
                  onClick={handleDownloadClick}
                />
              </Spacing>
            ))}
        </CouponListContainer>
        <Spacing bottom={20} />
        {couponList?.some(({ downloadable }) => downloadable) ? (
          <PopupButton type={'Third'} width={'170px'} justify-content={'center'} onClick={handleDownloadAllClick}>
            <TextBox>쿠폰 전체 다운 받기</TextBox>
            <Spacing right={6} />
            <DownloadIcon />
          </PopupButton>
        ) : (
          <PopupButton type={'Third'} disabled width={'170px'} justify-content={'center'}>
            <TextBox>쿠폰 전체 받기 완료</TextBox>
          </PopupButton>
        )}
      </FlexBoxColumn>
    </Popup>
  );
};

export default EmployeeBenefitCouponPopup;

EmployeeBenefitCouponPopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  detailName: PropTypes.string,
  detailNumber: PropTypes.string,
  setInfoChanged: PropTypes.func,
};

const CouponListContainer = styled(FlexBoxColumn)`
  width: 100%;
  height: 555px;
  background-color: #f7f7f7;
  padding: 15px 22px;
  overflow-x: overlay;
`;
