import PropTypes from 'prop-types';

import theme from '@ecp/common/src/style/theme/default';

const sizeProps = {
  small: {
    goodName: 14,
    originalPrice: 13,
    discountRate: 18,
    percent: 14,
    discountPrice: 18,
    won: 16,
    totalReviewScore: 13,
    adult: 0,
  },
  medium: {
    goodName: 15,
    originalPrice: 15,
    discountRate: 21,
    percent: 14,
    discountPrice: 21,
    won: 16,
    totalReviewScore: 13,
    adult: 13,
  },
  large: {
    goodName: 15,
    originalPrice: 14,
    discountRate: 21,
    percent: 14,
    discountPrice: 21,
    won: 16,
    totalReviewScore: 13,
    adult: 13,
  },
  horizontalSmall: {
    goodName: 14,
    originalPrice: 12,
    discountRate: 16,
    percent: 14,
    discountPrice: 16,
    won: 14,
    totalReviewScore: 13,
    adult: 0,
  },
  horizontalMedium: {
    goodName: 15,
    originalPrice: 15,
    discountRate: 21,
    percent: 14,
    discountPrice: 21,
    won: 16,
    totalReviewScore: 13,
    adult: 0,
  },
  horizontalLarge: {
    goodName: 18,
    originalPrice: 18,
    discountRate: 27,
    percent: 14,
    discountPrice: 27,
    won: 27,
    totalReviewScore: 13,
    adult: 13,
  },
  tableSmall: {
    goodName: 13,
    originalPrice: 15,
    discountRate: 18,
    percent: 14,
    discountPrice: 18,
    won: 16,
    totalReviewScore: 13,
    adult: 0,
  },
  tableMedium: {
    goodName: 15,
    originalPrice: 15,
    discountRate: 21,
    percent: 14,
    discountPrice: 21,
    won: 16,
    totalReviewScore: 13,
    adult: 0,
  },
};

const lineHeightProps = {
  small: {
    goodName: theme.font.lineHeight.demiLight,
    originalPrice: '21px',
    discountRate: '29px',
    discountPrice: '29px',
    totalReviewScore: '21px',
  },
  medium: {
    goodName: theme.font.lineHeight.medium,
    originalPrice: '24px',
    discountRate: '34px',
    discountPrice: '34px',
    totalReviewScore: '21px',
  },
  large: {
    goodName: theme.font.lineHeight.medium,
    originalPrice: '22px',
    discountRate: '34px',
    discountPrice: '34px',
    totalReviewScore: '21px',
  },
  horizontalSmall: {
    goodName: theme.font.lineHeight.demiLight,
    originalPrice: '19px',
    discountRate: '26px',
    discountPrice: '26px',
    totalReviewScore: '21px',
  },
  horizontalMedium: {
    goodName: theme.font.lineHeight.demiLight,
    originalPrice: '24px',
    discountRate: '34px',
    discountPrice: '34px',
    totalReviewScore: '21px',
  },
  horizontalLarge: {
    goodName: theme.font.lineHeight.demiLight,
    originalPrice: '29px',
    discountRate: '43px',
    discountPrice: '43px',
    totalReviewScore: '21px',
  },
  tableSmall: {
    goodName: theme.font.lineHeight.demiLight,
    originalPrice: '24px',
    discountRate: '29px',
    discountPrice: '29px',
    totalReviewScore: '21px',
  },
  tableMedium: {
    goodName: theme.font.lineHeight.demiLight,
    originalPrice: '24px',
    discountRate: '34px',
    discountPrice: '34px',
    totalReviewScore: '21px',
  },
};

const extraSmallProps = {
  rootCardProp: {
    width: '195px',
    flexDirection: 'column',
  },
  imageWidth: '195px',
  adultSize: '110px',
  size: sizeProps.small,
  lineHeight: lineHeightProps.small,
  soldOutHeight: '40px',
};

const smallProps = {
  rootCardProp: {
    width: '240px',
    flexDirection: 'column',
  },
  imageWidth: '240px',
  adultSize: '110px',
  size: sizeProps.medium,
  lineHeight: lineHeightProps.medium,
  soldOutHeight: '40px',
};

const mediumProps = {
  rootCardProp: {
    width: '268px',
    flexDirection: 'column',
  },
  imageWidth: '268px',
  adultSize: '110px',
  size: sizeProps.medium,
  lineHeight: lineHeightProps.medium,
  soldOutHeight: '40px',
};

const largeProps = {
  rootCardProp: {
    width: '370px',
    flexDirection: 'column',
  },
  imageWidth: '370px',
  adultSize: '110px',
  size: sizeProps.medium,
  lineHeight: lineHeightProps.medium,
  soldOutHeight: '40px',
};

const extraLargeProps = {
  rootCardProp: {
    width: '500px',
    flexDirection: 'column',
    gap: '40px',
  },
  imageWidth: '500px',
  adultSize: '110px',
  size: sizeProps.large,
  lineHeight: lineHeightProps.large,
  soldOutHeight: '40px',
};

const horizontalExtraSmallProps = {
  rootCardProp: {
    width: '100%',
    flexDirection: 'row',
  },
  imageWidth: '100px',
  adultSize: '75px',
  size: sizeProps.horizontalSmall,
  lineHeight: lineHeightProps.horizontalSmall,
  soldOutHeight: '40px',
};

const horizontalSmallProps = {
  rootCardProp: {
    width: '100%',
    flexDirection: 'row',
  },
  imageWidth: '160px',
  adultSize: '110px',
  size: sizeProps.horizontalMedium,
  lineHeight: lineHeightProps.horizontalMedium,
  soldOutHeight: '40px',
};

const horizontalMediumProps = {
  rootCardProp: {
    width: '100%',
    flexDirection: 'row',
  },
  imageWidth: '240px',
  adultSize: '110px',
  size: sizeProps.horizontalMedium,
  lineHeight: lineHeightProps.horizontalMedium,
  soldOutHeight: '40px',
};

const horizontalLargeProps = {
  rootCardProp: {
    width: 'fit-content',
    flexDirection: 'row',
    gap: '40px',
  },
  imageWidth: '370px',
  adultSize: '110px',
  size: sizeProps.horizontalLarge,
  lineHeight: lineHeightProps.horizontalLarge,
  soldOutHeight: '40px',
};

const tableSmallProps = {
  rootCardProp: {
    width: '580px',
    flexDirection: 'row',
  },
  imageWidth: '100px',
  adultSize: '75px',
  size: sizeProps.tableSmall,
  lineHeight: lineHeightProps.tableSmall,
  height: '130px',
  leftWidth: '370px',
  rightWidth: '196px',
  soldOutHeight: '40px',
};

const tableMediumProps = {
  rootCardProp: {
    width: '100%',
    flexDirection: 'row',
  },
  imageWidth: '160px',
  adultSize: '110px',
  size: sizeProps.tableMedium,
  lineHeight: lineHeightProps.tableMedium,
  height: '200px',
  leftWidth: '1013px',
  rightWidth: '252px',
  soldOutHeight: '40px',
};

const gridMediumProps = {
  rootCardProp: {
    width: '374px',
    flexDirection: 'row',
  },
  imageWidth: '160px',
  adultSize: '110px',
  size: sizeProps.horizontalMedium,
  lineHeight: lineHeightProps.horizontalMedium,
  soldOutHeight: '40px',
};

const gridSmallProps = {
  rootCardProp: {
    width: '80px',
    flexDirection: 'row',
  },
  imageWidth: '80px',
  adultSize: '60px',
  size: sizeProps.horizontalSmall,
  lineHeight: lineHeightProps.horizontalSmall,
  soldOutHeight: '40px',
};

const cardProps = {
  extraSmall: extraSmallProps,
  small: smallProps,
  medium: mediumProps,
  large: largeProps,
  extraLarge: extraLargeProps,
  horizontalExtraSmall: horizontalExtraSmallProps,
  horizontalSmall: horizontalSmallProps,
  horizontalMedium: horizontalMediumProps,
  horizontalLarge: horizontalLargeProps,
  tableSmall: tableSmallProps,
  tableMedium: tableMediumProps,
  gridSmall: gridSmallProps,
  gridMedium: gridMediumProps,
};

const goodPriceBoxPropTypes = {
  type: PropTypes.oneOf([
    // vertical type
    'extraSmall',
    'small',
    'medium',
    'large',
    'extraLarge',
    // horizontal type
    'horizontalExtraSmall',
    'horizontalSmall',
    'horizontalMedium',
    'horizontalLarge',
    // table type
    'tableSmall',
    'tableMedium',
    // grid type
    'gridSmall',
    'gridMedium',
  ]).isRequired,
  originalPrice: PropTypes.number,
  discountRate: PropTypes.number,
  discountPrice: PropTypes.number,
};

const goodCardPropTypes = {
  ...goodPriceBoxPropTypes,
  itemDecorators: PropTypes.array,
  onClick: PropTypes.func,
  onWishClick: PropTypes.func,
  goodName: PropTypes.string,
  thumbnailImageUrl01: PropTypes.string,
  thumbnailImageUrl02: PropTypes.string,
  thumbnailImageUrl03: PropTypes.string,
  totalReviewScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  reviewCount: PropTypes.number,
  evaluationDivisionCode: PropTypes.string,
  buyCount: PropTypes.number,
  buyCountDisplay: PropTypes.bool,
  flagList: PropTypes.array,
  hideBestFlag: PropTypes.bool,
  adultGood: PropTypes.bool,
  goodNumber: PropTypes.string,
  wish: PropTypes.bool,
  soldOut: PropTypes.bool,
  clickLogLink: PropTypes.string,
  handleViewGoodSoldOutChangeList: PropTypes.func,
  handleOrderGoodSoldOutChangeList: PropTypes.func,
  handleBestGoodSoldOutChangeList: PropTypes.func,
  handleBestOrderGoodSoldOutChangeList: PropTypes.func,
  handelBestViewGoodSoldOutChangeList: PropTypes.func,
  handleSubDisplayCategoryGoodSoldOutChangeList: PropTypes.func,

};

export { cardProps, goodPriceBoxPropTypes, goodCardPropTypes };
