import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import RadioGroup from '@ecp/common/src/components/radio/RadioGroup';
import { Radio } from '@ecp/common/src/components/radio/Radio';
import { PRIVACY_POLICY_DESCRIPTION, PRIVACY_POLICY_TIP } from '@ecp/common/src/const/footer';

const BulkOrderAgreement = ({ formValue, onChangeAgreement }) => {
  const { agree } = formValue;

  return (
    <FlexBox width={'100%'} height={'320px'} style={{ lineHeight: '24px' }}>
      <LabelWrapper>
        <ItemWrapper>{'개인정보를\n위한 이용자\n동의사항'}</ItemWrapper>
      </LabelWrapper>
      <ItemContentsWrapper gap={'10px'}>
        <TextBox
          width={'430px'}
          height={'229px'}
          size={'13px'}
          weight={'350'}
          style={{
            lineHeight: '20.8px',
            backgroundColor: '#F7F7F7',
            padding: '20px',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: PRIVACY_POLICY_DESCRIPTION }} />
        </TextBox>
        <TextBox color={theme.color.text.guide} size={theme.font.size.small}>
          {PRIVACY_POLICY_TIP}
        </TextBox>
        <TextBox size={'14px'} weight={'350'} color={'#5A5A5A'}>
          위 내용에 동의 하십니까?
        </TextBox>
        <RadioGroup defaultValue={agree} style={{ gap: '40px' }}>
          <Radio key={0} value={1} onChange={onChangeAgreement} data-key={'agree'}>
            동의합니다.
          </Radio>
          <Radio key={1} value={0} onChange={onChangeAgreement} data-key={'agree'}>
            동의하지 않습니다.
          </Radio>
        </RadioGroup>
      </ItemContentsWrapper>
    </FlexBox>
  );
};

export default BulkOrderAgreement;

BulkOrderAgreement.propTypes = {
  formValue: PropTypes.object,
  onChangeAgreement: PropTypes.func,
};

const LabelWrapper = styled(FlexBoxColumn)`
  width: 130px;
  height: 100%;
  background-color: #f7f7f7;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
`;

const ItemWrapper = styled(TextBox)`
  font-size: 15px;
  font-weight: 500;
  min-height: 40px;
`;

const ItemContentsWrapper = styled(FlexBoxColumn)`
  align-items: flex-start;
  padding: 20px 0 20px 20px;
  gap: ${({ gap }) => gap};
  width: 450px;
`;
