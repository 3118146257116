import { useEffect, useMemo } from 'react';

import {
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useFloatingParentNodeId,
  useFloatingTree,
  useInteractions,
} from '@floating-ui/react';
import isEmpty from 'lodash-es/isEmpty';
import findLast from 'lodash-es/findLast';

import useControlled from '../../../hooks/useControlled';

export const modalPlacementClassNames = {
  right: 'right',
  left: 'left',
  top: 'top',
  bottom: 'bottom',
  center: 'center',
};
export const modalTypeClassName = {
  normal: 'normal',
  inner: 'inner',
};

export const placements = ['bottom', 'left', 'right', 'top', 'center'];

const useModal = ({
  placement: placementProp,
  openWhenClicked,
  closeWhenOutside = true,
  openProp,
  defaultOpen = false,
  onOpenChange,
  innerModal = false,
  onClose,
}) => {
  const { value: open, setValueWhenUncontrolled: setOpen } = useControlled({
    value: openProp,
    defaultValue: defaultOpen,
  });
  const tree = useFloatingTree();

  const parentNodeId = useFloatingParentNodeId();
  const parentId = parentNodeId ?? findLast(tree?.nodesRef?.current || [], (item) => item?.context?.open)?.id;
  const nodeId = useFloatingNodeId(parentId);

  const { strategy, context, placement, refs } = useFloating({
    nodeId,
    placement: isEmpty(placementProp) || !placements.includes(placementProp) ? 'center' : placementProp,
    open,
    onOpenChange: (changedValue) => {
      setOpen(changedValue);
      onOpenChange?.(changedValue);
      !changedValue && onClose?.(changedValue);
    },
    whileElementsMounted: autoUpdate,
  });

  const propsList = [
    useClick(context, { enabled: !!openWhenClicked }),
    useDismiss(context, {
      outsidePress: !!closeWhenOutside,
      bubbles: {
        escapeKey: false,
        outsidePress: !!closeWhenOutside,
      },
    }),
  ];
  const { getReferenceProps, getFloatingProps } = useInteractions(propsList);

  const modalClassNames = useMemo(
    () => [modalPlacementClassNames[placement], innerModal ? modalTypeClassName.inner : modalTypeClassName.normal],
    [innerModal, placement]
  );

  useEffect(() => {
    if (open) {
      refs.floating.current?.focus?.();
    }
  }, [open, refs.floating]);

  return {
    nodeId,
    open,
    setOpen,
    refs,
    getReferenceProps,
    getModalProps: getFloatingProps,
    modalClassNames,
    positionStrategy: strategy,
  };
};

export default useModal;
