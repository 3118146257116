import React, { useCallback, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { extractDataProps } from '@ecp/common/src/utils/utils';
import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import boTheme from '@ecp/common/src/style/theme/bo';
import { defaultProps, FlexBox, FlexBoxColumn, flexPropTypes } from '@ecp/common/src/layouts/flex/styled';
import InputUnstyled, {
  inputClassNames,
  inputUnstyledPropTypes,
} from '@ecp/common/src/components/unstyled/input/InputUnstyled';
import { ReactComponent as ClearSvg } from '@ecp/common/src/assets/icon/input__clear_dark.svg';

const Input = React.forwardRef(
  (
    {
      clearable,
      clearableComponent: ClearableComponent,
      countCheck,
      countCheckComponent: CountCheckComponent,
      suffixText = '',
      height,
      width,
      minWidth,
      hidden,
      error,
      disableIgnoringError,
      onClearClick,
      ...props
    },
    ref
  ) => {
    const { value, disabled, readOnly, searchClear, endAdornment, errorMessage, onChange, maxLength } = props;

    const inputRef = useRef(null);
    useImperativeHandle(ref, () => inputRef.current);

    const { ignoreError } = useIgnorableError({ error, value, disableIgnoringError });

    const dataProps = extractDataProps(props);
    const handleClearClick = useCallback(
      (e) => {
        if (!searchClear && (disabled || readOnly)) return;

        e.target.value = '';
        onChange?.(e);
        onClearClick?.();
      },
      [disabled, onChange, onClearClick, readOnly, searchClear]
    );

    return (
      <FlexBoxColumn width={width} align-items={'flex-start'} style={hidden ? { display: 'none' } : {}}>
        <InputUnstyled
          rootComponent={StyledInputRoot}
          rootProps={{ height, width, minWidth }}
          inputComponent={StyledInput}
          error={!ignoreError && error}
          ref={inputRef}
          {...props}
          endAdornment={
            <FlexBox gap="10px" width="fit-content">
              <span>{suffixText}</span>
              {countCheck && <CountCheckComponent value={value} maxLength={maxLength} />}
              {clearable && !!value && (searchClear || (!disabled && !readOnly)) && (
                <ClearableComponent {...dataProps} onMouseDown={handleClearClick} />
              )}
              {endAdornment}
            </FlexBox>
          }
        />
        {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </FlexBoxColumn>
    );
  }
);

const Count = ({ value, maxLength }) => {
  const count = value?.length || 0;

  return (
    <FlexBox width="max-content" align-items="flex-end">
      <StyledCount>{count}</StyledCount>
      <StyledMaxCount>/{maxLength}</StyledMaxCount>
    </FlexBox>
  );
};

Count.propTypes = {
  value: PropTypes.string,
  maxLength: PropTypes.number,
};

const ClearIcon = styled(ClearSvg)`
  cursor: pointer;
  width: 14px;
  height: 14px;
`;

export const StyledInputRoot = styled.form`
  display: flex;
  position: relative;
  justify-content: space-between;
  ${overrideProperties(flexPropTypes, defaultProps)};

  padding: 7px 10px;
  background-color: ${boTheme.color.sub10};
  border: 1px solid ${boTheme.color.sub6};
  border-radius: 3px;
  gap: 1px;
  min-width: ${({ minWidth = '180px' }) => minWidth};

  &,
  & input,
  & textarea {
    font-weight: ${boTheme.font.weight.regular};
    font-size: ${boTheme.font.size.small};
    line-height: 17px;
  }

  ${overrideProperties(sizePropTypes, {
    width: 'fit-content',
    height: '28px',
  })};

  &.${inputClassNames.disabled} {
    background-color: ${boTheme.color.sub8};
  }

  &.${inputClassNames.selected} {
    border: 1px solid ${boTheme.color.main};
  }

  &.${inputClassNames.readOnly} {
    background-color: ${boTheme.color.sub8};
  }

  &.${inputClassNames.error} {
    border: 1px solid ${boTheme.color.error1};
  }
`;

export const StyledInput = styled.input`
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  padding: 0;

  &::placeholder {
    color: ${boTheme.color.sub4};
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

export const StyledErrorMessage = styled.div`
  margin-top: 6px;
  height: fit-content;
  font-weight: ${boTheme.font.weight.regular};
  font-size: ${boTheme.font.size.small};
  line-height: 13px;

  color: ${boTheme.color.error1};
`;

const StyledCount = styled(FlexBox)`
  font-weight: ${boTheme.font.weight.bold};
  font-size: ${boTheme.font.size.sub};
  color: ${boTheme.color.sub1};
  line-height: 130%;
`;

const StyledMaxCount = styled(FlexBox)`
  font-weight: ${boTheme.font.weight.regular};
  font-size: ${boTheme.font.size.sub};
  line-height: 130%;
  color: ${boTheme.color.sub2};
`;

Input.displayName = 'Input';

export const inputBasePropTypes = {
  ...inputUnstyledPropTypes,
  ...sizePropTypes,
  /** 초기화 버튼 사용 여부*/
  clearable: PropTypes.bool,
  clearableComponent: PropTypes.elementType,
  maxLength: PropTypes.number,
  countCheck: PropTypes.bool,
  countCheckComponent: PropTypes.elementType,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffixText: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  autoComplete: PropTypes.string,
  disableIgnoringError: PropTypes.bool,
  searchClear: PropTypes.bool,
};

Input.propTypes = inputBasePropTypes;

Input.defaultProps = {
  clearable: true,
  clearableComponent: ClearIcon,
  maxLength: 524288,
  countCheck: false,
  countCheckComponent: Count,
  placeholder: '입력하세요.',
  autoComplete: 'on',
  onFocus: () => {},
  hidden: false,
  disableIgnoringError: false,
};

export default Input;
