import { useMemo, useState } from 'react';

import { A11y, Autoplay, EffectCreative, FreeMode, Grid, Navigation, Scrollbar, Thumbs } from 'swiper';
import round from 'lodash-es/round';

const useSwipe = (props, ref, onClick) => {
  const {
    itemList,
    handleSwiper: handleSwiperProp = () => {},
    onChange = () => {},
    thumbs = {},
    initialSlide = 0,
    carouselProps: { loop, normalizeSlideIndex = true, slidesPerGroup = 1, slidesPerView = 1, freeMode },
    handleFirstToPrev,
    handleLastToNext,
    arrowProps,
    hideUnusableArrow,
    controllerComponent,
    hideUnusableControl,
    handleIsLocked,
    touchSlide = true,
  } = props;

  const totalSlideCount = useMemo(() => itemList.length, [itemList.length]);
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const [current, setCurrent] = useState({
    isFirst: !loop && !freeMode && initialSlide === 0,
    isLast: !loop && !freeMode && initialSlide === totalSlideCount - slidesPerView,
  });
  const [isLocked, setIsLocked] = useState(false);

  const handleSlideChange = (swiper) => {
    if (touchSlide) {
      const currentIndex = swiper.realIndex;
      setCurrentSlide(currentIndex);
      setCurrent({
        isFirst: !loop && !freeMode && swiper.isBeginning,
        isLast: !loop && !freeMode && swiper.isEnd,
      });
      onChange?.(swiper, currentIndex);
    }
  };

  const handleSwiper = (swiper) => {
    const currentIndex = swiper.realIndex;
    setCurrentSlide(currentIndex);
    handleSwiperProp?.(swiper);
  };

  const handlePrev = () => {
    if (!normalizeSlideIndex) {
      ref.current.swiper.slideTo(currentSlide - 1);
    } else {
      ref.current.swiper.slidePrev();
      if (currentSlide === 0) {
        handleFirstToPrev?.();
      }
    }
  };

  const handleNext = () => {
    const mappedSlidesPerView = typeof slidesPerView === 'number' ? slidesPerView : 1;
    const isNotLastItem = totalSlideCount - mappedSlidesPerView > ref.current.swiper.realIndex;

    if (loop || isNotLastItem) {
      ref.current.swiper.slideNext();
    } else if (!isNotLastItem) {
      handleLastToNext?.();
    }
  };

  const handlePaging = (index) => {
    ref.current.swiper.slideTo(index);
  };

  const togglePlay = (event) => {
    if (event.target.checked) {
      ref.current.swiper.autoplay.stop();
    } else {
      ref.current.swiper.autoplay.start();
    }
  };

  const getControlProps = (controlProps) => ({
    ...controlProps,
    current: currentSlide + 1,
    total: totalSlideCount,
    togglePlay,
  });

  const handleLock = (swiper) => {
    handleIsLocked?.(!!swiper?.isLocked);
    setIsLocked(!!swiper?.isLocked);
  };

  const getCarouselProps = ({ autoplay, delay, ...carouselProps }) => ({
    ...carouselProps,
    autoplay: autoplay ? { delay, disableOnInteraction: false } : false,
    modules: [Scrollbar, A11y, Autoplay, EffectCreative, FreeMode, Navigation, Thumbs, Grid],
    initialSlide,
    onSlideChange: handleSlideChange,
    onSwiper: handleSwiper,
    onLock: handleLock,
    onUnlock: handleLock,
    thumbs,
    navigation: false,
    a11y: { enabled: true, slideLabelMessage: null },
  });

  const getSlideProps = ({ width, height, display, alignItems }) => ({ style: { width, height, display, alignItems } });

  const getPagingProps = (pagingProps) => ({
    ...pagingProps,
    slidesPerView,
    current: round((currentSlide + (isNaN(slidesPerView) ? 2 : slidesPerView - 1)) / slidesPerGroup),
    total: round(totalSlideCount / slidesPerGroup),
  });

  const handleItemClick = (e, index, item) => {
    if (index !== currentSlide) {
      ref.current.swiper.slideTo(index);
    }
    e.stopPropagation();
    onClick?.(e, index, item);
  };

  const showArrow = useMemo(
    () => arrowProps && !(!!hideUnusableArrow && isLocked),
    [arrowProps, hideUnusableArrow, isLocked]
  );

  const showControl = useMemo(
    () => !!controllerComponent && !(!!hideUnusableControl && isLocked),
    [controllerComponent, hideUnusableControl, isLocked]
  );

  return {
    selectedIndex: currentSlide,
    totalSlideCount,
    showArrow,
    showControl,
    setCurrentSlide,
    handleItemClick,
    getControlProps,
    getCarouselProps,
    getSlideProps,
    getPagingProps,
    handlePrev,
    handleNext,
    handlePaging,
    ...current,
  };
};

export default useSwipe;
