import React from 'react';
import styled from 'styled-components';
import { usePromiseTracker } from 'react-promise-tracker';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay, FloatingPortal } from '@floating-ui/react';

import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Loading from '@ecp/common/src/components/progress/Loading';

const LoadingSpinner = () => {
  const { promiseInProgress } = usePromiseTracker({ delay: 500 });

  return (
    <FloatingPortal>
      {promiseInProgress && (
        <RemoveScroll>
          <FloatingOverlay
            style={{
              display: 'grid',
              background: 'rgba(25, 25, 25, 0.65)',
              placeItems: 'center',
              zIndex: zIndex.spinner,
            }}
          >
            <FlexBoxColumn gap={'30px'}>
              <Loading />
              <StyledTextBox>{`페이지를 열고 있어요\n조금만 기다려주세요`}</StyledTextBox>
            </FlexBoxColumn>
          </FloatingOverlay>
        </RemoveScroll>
      )}
    </FloatingPortal>
  );
};

export default LoadingSpinner;

const StyledTextBox = styled(TextBox)`
  font-size: ${theme.font.size.title};
  line-height: ${theme.font.lineHeight.demiLight};
  color: ${theme.color.text.white};
  text-align: center;
`;
