import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

const FADE_OUT = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const FADE_IN = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledToast = styled(FlexBoxSpaceBetween)`
  position: relative;
  width: max-content;
  height: max-content;
  margin-top: 10px;
  padding: 10px;
  color: #141415;
  font-size: 13px;
  border: 1px solid #141415;
  background-color: #ffffff;
  animation: 0.3s forwards ${({ isClosing }) => (isClosing ? FADE_OUT : FADE_IN)};
`;

const StyledClose = styled(TooltipCloseIcon)`
  cursor: pointer;
`;
const Toast = (props) => {
  const { content, duration } = props;
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const setExistTimeout = setTimeout(() => {
      setIsClosing(true);
      clearTimeout(setExistTimeout);
    }, 300 + (duration ?? 1500));
  });

  const handleClose = () => {
    setIsClosing(true);
  };

  return (
    <StyledToast closable={true} isClosing={isClosing}>
      {content}
      <Spacing left={30} />
      <StyledClose width="14px" height="14px" onClick={handleClose} />
    </StyledToast>
  );
};

Toast.propTypes = {
  content: PropTypes.string,
  duration: PropTypes.number,
};
export default Toast;
