import { getRequest, postRequest, putRequest } from '@fo-apis/axios';

const prefix = '/api/v1/event';

const getEventData = (eventNo) =>
  getRequest({
    url: `${prefix}/${eventNo}`,
  });

const postEventAttendance = (payload = {}) =>
  postRequest({
    url: `${prefix}/attendance`,
    data: { ...payload },
  });

const postCoupon = (payload = {}) =>
  postRequest({
    url: `${prefix}/coupon`,
    data: { ...payload },
  });

const applyEvent = (payload = {}) =>
  postRequest({
    url: `${prefix}/apply`,
    data: { ...payload },
  });

const getTopEventList = () =>
  getRequest({
    url: `${prefix}/top`,
    params: {},
  });

const getEventList = (query, state, pageParams) => {
  return getRequest({
    url: `${prefix}`,
    params: { query, state, ...pageParams },
  });
};

//LINE :: 이벤트 댓글 저장
const postEventCommentsData = (payload = {}) =>
  postRequest({
    url: `${prefix}/create-event-comment`,
    data: payload,
  });

//LINE :: 이벤트 댓글 신고/삭제
const putEventCommentsList = (payload) =>
  putRequest({
    url: `${prefix}/set-event-comments-list`,
    data: payload,
  });

const getEventResultList = (param) =>
  getRequest({
    url: `${prefix}/result`,
    params: {
      page: param.currentPage - 1,
      size: param.countPerPage,
    },
  });

const getEventResultInfo = (eventNo) =>
  getRequest({
    url: `${prefix}/result/${eventNo}`,
  });

const getEventCommentsList = (eventNo, pageParams) =>
  getRequest({
    url: `${prefix}/comments-list/${eventNo}`,
    params: { ...pageParams },
  });

const getNewestEvent = () => getRequest({ url: `${prefix}/newest-event` });

const eventApi = {
  getEventData,
  postEventAttendance,
  applyEvent,
  getTopEventList,
  getEventList,
  postEventCommentsData,
  putEventCommentsList,
  getEventResultList,
  getEventResultInfo,
  getEventCommentsList,
  getNewestEvent,
  postCoupon,
};

export default eventApi;
