import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import Badge from '@ecp/common/src/components/badge/Badge';

const GnbIconPopupButton =  React.forwardRef(
  ({ iconType: Icon, badgeContent, label, selected, onClick, showZero, ...props }, ref) => {
    const color = useMemo(
      () => ({
        icon: selected ? theme.color.icon.current : theme.color.icon.normal,
        label: selected ? theme.color.primary : theme.color.text.normal,
      }),
      [selected]
    );
    return (
      <Link style={{ textDecoration: 'none' }} onClick={onClick} {...props}>
          <Wrapper ref={ref}>
            <Badge
              badgeContent={badgeContent}
              position={{ top: -8, right: -8 }}
              size={{ width: '16px', height: '16px' }}
              showZero={showZero}
            >
              <Icon fill={color.icon} stroke={color.icon} width={'20px'} height={'20px'} />
            </Badge>
            <Label color={color.label}>{label}</Label>
          </Wrapper>
      </Link>
    );
  }
);
export default GnbIconPopupButton;

GnbIconPopupButton.propTypes = {
  iconType: PropTypes.elementType,
  badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  selected: PropTypes.bool,
  showZero: PropTypes.bool,
  to: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

GnbIconPopupButton.defaultProps = {
  selected: false,
  showZero: false,
};

GnbIconPopupButton.displayName = 'GnbIconPopupButton';

const Wrapper = styled(FlexBoxColumn)`
  width: fit-content;
  height: 36px;
  gap: 3px;
  cursor: pointer;
`;

const Label = styled.span.attrs(({ color, style }) => ({ style: { ...style, color } }))`
  font-size: 11px;
  line-height: 11px;
  font-weight: ${theme.font.weight.demiLight};
`;
