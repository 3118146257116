import React from 'react';
import PropTypes from 'prop-types';

import useBadge from './useBadge';

const BadgeUnstyled = React.forwardRef((props, ref) => {
  const {
    rootComponent: Root,
    badgeComponent: Badge,
    rootProps,
    badgeProps,
    children,
    onBadgeClick,
    ...otherProps
  } = props;

  const { badgeContent, max, displayValue, invisible } = useBadge(props);

  const newProps = {
    ...otherProps,
    badgeContent,
    invisible,
    max,
  };

  const handleBadgeClick = (event) => {
    if (onBadgeClick) {
      event.stopPropagation();
      onBadgeClick();
    }
  };

  return (
    <Root {...newProps} {...rootProps} ref={ref} rootProps>
      {children}
      {!invisible && (
        <Badge {...newProps} {...badgeProps} onClick={handleBadgeClick}>
          {displayValue}
        </Badge>
      )}
    </Root>
  );
});

BadgeUnstyled.propTypes = {
  badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  invisible: PropTypes.bool,
  max: PropTypes.number,
  showZero: PropTypes.bool,
  children: PropTypes.node,
  rootComponent: PropTypes.elementType,
  badgeComponent: PropTypes.elementType,
  onBadgeClick: PropTypes.func,
  rootProps: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  badgeProps: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

BadgeUnstyled.defaultProps = {
  rootComponent: 'span',
  badgeComponent: 'span',
  badgeContent: 0,
  max: 99,
  showZero: false,
};

BadgeUnstyled.displayName = 'BadgeUnstyled';

export default BadgeUnstyled;
