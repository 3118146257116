import React from 'react';
import { Route, Routes } from 'react-router-dom';

import WpayMemberRegPage from '@fo-pages/interface/wpay/WpayMemberRegPage';
import WpayMyPage from '@fo-pages/interface/wpay/WpayMyPage';
import WpayPaymentAuth from '@fo-pages/interface/wpay/WpayPaymentAuthPage';
import WpayPaymentAuthReturn from '@fo-pages/interface/wpay/WpayPaymentAuthReturn';
import WpayMemberReturnPage from '@fo-pages/interface/wpay/WpayMemberReturnPage';
import MobiliansForm from '@fo-pages/interface/mobilians/MobiliansForm';
import MobiliansClosePage from '@fo-pages/interface/mobilians/MobiliansClosePage';
import MobiliansOkUrlPage from '@fo-pages/interface/mobilians/MobiliansOkUrlPage';
import InicisClosePage from '@fo-pages/interface/inicis/InicisClosePage';
import PaycoReturnPage from '@fo-pages/interface/payco/PaycoReturnPage';

const InterfaceRoutes = () => (
  <Routes>
    <Route path="wpay-member-reg" element={<WpayMemberRegPage />} />
    <Route path="wpay-my-page" element={<WpayMyPage />} />
    <Route path="wpay-payment-auth" element={<WpayPaymentAuth />} />
    <Route path="wpay-payment-auth-return" element={<WpayPaymentAuthReturn />} />
    <Route path="wpay-return" element={<WpayMemberReturnPage />} />
    <Route path="mobilians-information" element={<MobiliansForm />} />
    <Route path="mobilians-close" element={<MobiliansClosePage />} />
    <Route path="mobilians-ok-url" element={<MobiliansOkUrlPage />} />
    <Route path="inicis-close" element={<InicisClosePage />} />
    <Route path="payco-return" element={<PaycoReturnPage />} />
  </Routes>
);
export default InterfaceRoutes;
