import { getRequest } from '@fo-apis/axios';

const prefix = '/api/v1/exhibition';

const getExhibitionList = async () => {
  return await getRequest({
    url: `${prefix}`,
  });
};

const getExhibition = async (exhibitionNumber) => {
  return await getRequest({
    url: `${prefix}/${exhibitionNumber}`,
    params: {},
  });
};

const getIntegrationExhibitionItemList = async (exhibitionNumber) => {
  return await getRequest({
    url: `${prefix}/${exhibitionNumber}/included-item-list`,
    params: {},
  });
};

const getGoodRelationExhibitionList = (goodNumber) =>
  getRequest(
    {
      url: `/api/v1/exhibition/good-related/${goodNumber}`,
    },
    { skipSpinner: true }
  );

const getDisplayCategoryRelatedExhibitionList = async (displayCategoryNumber, skipSpinner) =>
  await getRequest(
    {
      url: `/api/v1/exhibition/display-category/${displayCategoryNumber}`,
    },
    { skipSpinner }
  );

const getMainExhibitionList = async () => await getRequest({ url: `${prefix}/main-exhibition-list` });

const getMainExhibitionGoodList = async (exhibitionNumber) =>
  await getRequest({ url: `${prefix}/main-exhibition-list/${exhibitionNumber}` }, { skipSpinner: true });

const getNewestExhibition = async () => await getRequest({ url: `${prefix}/newest-exhibition` });

const exhibitionApi = {
  getExhibitionList,
  getExhibition,
  getIntegrationExhibitionItemList,
  getGoodRelationExhibitionList,
  getDisplayCategoryRelatedExhibitionList,
  getMainExhibitionList,
  getMainExhibitionGoodList,
  getNewestExhibition,
};

export default exhibitionApi;
