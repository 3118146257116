import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import RadioGroup from '@ecp/common/src/components/radio/RadioGroup';

import { MainContentsBox } from '@fo-components/common/styled';

const MallGroup = ({ items = [] }) => {
  return (
    <Wrapper data-ds-label2={'g_af_bar'}>
      <MainContentsBox>
        <RadioGroup defaultValue={0}>
          {items.map(({ label, url, target }, index) => (
            <MallLinkItem key={index} target={target} rel="noreferrer" href={url}>
              {label}
            </MallLinkItem>
          ))}
        </RadioGroup>
      </MainContentsBox>
    </Wrapper>
  );
};

export default MallGroup;

MallGroup.propTypes = {
  items: PropTypes.array,
};

const Wrapper = styled(FlexBoxCenter)`
  width: calc(max(100vw, 1630px));
  background-color: #f3f3f3;

  .sticky & {
    display: none;
  }
`;

const MallLinkItem = styled.a`
  color: #7a7a7a;
  background-color: transparent;
  padding: 8px 20px;
  border-right: 1px solid #dfdfdf;
  line-height: 20px;
  cursor: pointer;
  font-weight: 500;
`;
