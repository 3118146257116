import { useEffect, useMemo, useRef, useState } from 'react';

import isEqual from 'lodash-es/isEqual';

const useIgnorableError = ({ error, value, disableIgnoringError = false }) => {
  const [errorValue, setErrorValue] = useState();
  const lastError = useRef(false);
  const lastValue = useRef();

  useEffect(() => {
    if (error) {
      setErrorValue(lastValue.current);
    } else {
      setErrorValue(undefined);
    }

    lastError.current = error;
  }, [error]);

  useEffect(() => {
    lastValue.current = value;
  }, [value]);

  const ignoreError = useMemo(
    () => !disableIgnoringError && !isEqual(errorValue, value),
    [disableIgnoringError, errorValue, value]
  );

  return {
    ignoreError,
  };
};

export default useIgnorableError;
