import { atom, selector } from 'recoil';

import { GOOD_PRINT_TYPE, GOOD_SORT_TYPE } from '@ecp/common/src/const/good';

import { withMainCategoryTreeList } from '@fo-recoil/category/selector';
import exhibitionApi from '@fo-apis/exhibition/exhibitionApi';

export const categoryListState = atom({
  key: 'categoryListState',
  default: [],
});

export const categoryExhibitionListMapState = atom({
  key: 'categoryExhibitionListMapState',
  default: selector({
    key: 'defaultCategoryExhibitionListMap',
    get: async ({ get }) => {
      const mainCategories = get(withMainCategoryTreeList);
      try {
        const categoryExhibitions = await Promise.all(
          mainCategories.map(async ({ categoryNumber }) => {
            const { result } = await exhibitionApi.getDisplayCategoryRelatedExhibitionList(categoryNumber, true);
            return { [categoryNumber]: result };
          })
        );

        return categoryExhibitions?.reduce((exhibitionMap, exhibition) => ({ ...exhibitionMap, ...exhibition }), {});
      } catch (e) {
        return {};
      }
    },
  }),
});

export const categoryPageNewGoodCategoryNumberState = atom({
  key: 'categoryPageNewGoodCategoryNumberState',
  default: '',
});

export const categoryPageNewGoodPageParamState = atom({
  key: 'categoryPageNewGoodPageParamState',
  default: {
    sort: GOOD_SORT_TYPE[0],
    countPerPage: GOOD_PRINT_TYPE[1],
  },
});

export const categoryPageNewGoodPageState = atom({
  key: 'categoryPageNewGoodPageState',
  default: 1,
});
