import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBoxCenter, FlexBoxColumn, FlexBoxEnd } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import { BENEFIT_PROPERTY } from '@ecp/common/src/const/benefit/benefitConst';

const BenefitCard = ({ type, handleClick, height }) => {
  return (
    <CardContainer height={height} data-ds-contents={type}>
      <FlexBoxColumn width={'100%'}>
        <FlexBoxEnd width={'100%'} height={'20px'} align-items={'flex-start'}>
          <TypeLabel>{BENEFIT_PROPERTY[type].label}</TypeLabel>
        </FlexBoxEnd>
        <TypeIcon src={BENEFIT_PROPERTY[type].imageSrc} alt={''}/>
        <Spacing top={14} />
        <TitleTextBox>{BENEFIT_PROPERTY[type].title}</TitleTextBox>
        <Spacing top={6} />
        <ContentTextBox>{BENEFIT_PROPERTY[type].content}</ContentTextBox>
      </FlexBoxColumn>
      {BENEFIT_PROPERTY[type].navigateUrl && (
        <FlexBoxEnd width={'100%'} style={{ paddingRight: '14px' }}>
          <TextButton
            type="move"
            onClick={() => {
              handleClick(BENEFIT_PROPERTY[type].navigateUrl);
            }}
          >
            전체보기
          </TextButton>
        </FlexBoxEnd>
      )}
    </CardContainer>
  );
};

export default BenefitCard;

BenefitCard.propTypes = {
  type: PropTypes.string,
  handleClick: PropTypes.func,
  height: PropTypes.string,
};

const TypeIcon = styled.img`
  width: 90px;
  height: 90px;
`;

const TypeLabel = styled(FlexBoxCenter)`
  width: 61px;
  height: 26px;
  background-color: #141415;
  border-radius: 0 0 0 8px;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  align-items: center;
`;

const CardContainer = styled(FlexBoxColumn)`
  width: 190px;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #dfdfdf;
  & + & {
    border-left: 1px solid #dfdfdf;
  }
`;

const TitleTextBox = styled(TextBoxAlignCenter)`
  font-weight: 500;
  font-size: 15px;
  color: #141415;
`;

const ContentTextBox = styled(TextBoxAlignCenter)`
  font-weight: 350;
  font-size: 13px;
  color: #727272;
  line-height: 140%;
`;
