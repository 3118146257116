import { selector } from 'recoil';

import { format } from 'date-fns';

import displaySearchApi from '@fo-apis/display/displaySearchApi';

export const withTrendKeywords = selector({
  key: 'withTrendKeywords',
  get: async () => {
    try {
      const { result } = await displaySearchApi.getTrendSearchTermList();

      return { trendKeyword: result, referenceTime: format(new Date(), 'yyyy.MM.dd HH:mm') };
    } catch (error) {
      return { trendKeyword: [], referenceTime: null };
    }
  },
});
