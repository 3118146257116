import { atom } from 'recoil';

export const recommendBrandListState = atom({
  key: 'recommendBrandListState',
  default: [],
});

export const bestBrandListState = atom({
  key: 'bestBrandListState',
  default: [],
});
