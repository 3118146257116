import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { getAccessToken, getRefreshToken } from '@ecp/common/src/utils/utils';

import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import UserInfoProvider from '@fo-components/common/provider/UserInfoProvider';
import SsoRoutes from '@ecp/fo/src/routes/SsoRoutes';

const ProtectedRoutes = () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();

  const {
    systemInformation: { isPossibleKnoxLogin },
  } = useSiteInfo();

  const { pathname } = useLocation();

  const notLoggedInRoutes = () => {
    switch (isPossibleKnoxLogin) {
      case '':
        return <></>;
      case true:
        return <SsoRoutes />;
      case false:
        return <Navigate to="login" replace state={{ prev: pathname, current: '/login' }} />;
      default:
        return <></>;
    }
  };

  return accessToken && refreshToken ? (
    <UserInfoProvider>
      <Outlet />
    </UserInfoProvider>
  ) : (
    notLoggedInRoutes()
  );
};

export default ProtectedRoutes;
