import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import HighlightText from '@ecp/common/src/text/HighlightText';

const SearchAutoComplete = ({ keyword, keywordList, onSearch }) => (
  <Wrapper gap={'10px'}>
    {keywordList.map((word, index) => (
      <TextBoxWrapper key={index} onClick={() => onSearch(word.keyword)}>
        <HighlightText keyword={keyword} style={{ fontWeight: `${theme.font.weight.bold}` }}>
          {word.keyword}
        </HighlightText>
      </TextBoxWrapper>
    ))}
  </Wrapper>
);

SearchAutoComplete.propTypes = {
  keyword: PropTypes.string,
  keywordList: PropTypes.array,
  onSearch: PropTypes.func,
};

export default SearchAutoComplete;

const TextBoxWrapper = styled(FlexBox)`
  max-width: 398px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  font-weight: ${theme.font.weight.demiLight};
  font-size: ${theme.font.size.basic};
  line-height: ${theme.font.lineHeight.medium};
  color: ${theme.color.text.basic};
  cursor: pointer;
  &:hover {
    color: ${theme.color.text.selected};
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`;

const Wrapper = styled(FlexBoxColumn)`
  min-height: 23px;
  align-items: flex-start;
`;
