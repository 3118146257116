import { useEffect, useState } from 'react';

const useElementFocus = ({ ref }) => {
  const [focused, setIsFocus] = useState(document.activeElement === ref?.current);

  useEffect(() => {
    const refValue = ref?.current;
    const handleFocusIn = () => setIsFocus(true);
    const handleFocusOut = () => setIsFocus(false);

    refValue?.addEventListener('focus', handleFocusIn);
    refValue?.addEventListener('blur', handleFocusOut);

    return () => {
      refValue?.removeEventListener('focus', handleFocusIn);
      refValue?.removeEventListener('blur', handleFocusOut);
    };
  }, [ref]);

  return focused;
};

export default useElementFocus;
