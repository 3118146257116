import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { numberOrStringType } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import useSwiperArrow from '@ecp/common/src/components/swiper/useSwiperArrow';
import { ReactComponent as IconLeftArrow } from '@ecp/common/src/assets/icon/left-rolling.svg';
import { ReactComponent as IconRightArrow } from '@ecp/common/src/assets/icon/right-rolling.svg';

const getArrowProps = ({ padding, type = '15', position = 'outer', direction = 'left', top }) => {
  const reverse = position === 'outer' ? '-' : '';
  switch (type) {
    case '30':
      return {
        [direction]: `${reverse}${padding ?? '40px'}`,
        top: top ?? 'calc(50% - 30px)',
        width: '30px',
        height: '60px',
        'background-size': '30px 60px',
      };
    case '20':
      return {
        [direction]: `${reverse}${padding ?? '40px'}`,
        top: top ?? 'calc(50% - 20px)',
        width: '20px',
        height: '40px',
      };
    case '8':
      return {
        [direction]: `${reverse}${padding ?? '40px'}`,
        top: top ?? 'calc(50% - 8px)',
        width: '8px',
        height: '16px',
      };
    case '10':
      return {
        [direction]: `${reverse}${padding ?? '30px'}`,
        top: top ?? 'calc(50% - 9px)',
        width: '10px',
        height: '18px',
      };
    case '50':
      return {
        [direction]: `${reverse}${padding ?? 0}`,
        top: top ?? 'calc(50% - 25px)',
        width: '50px',
        height: '50px',
        background: 'rgba(0, 0, 0, 0.25)',
        'background-size': '10px 20px',
        padding: '15px 20px',
        stroke: '#ffffff',
      };
    case '15':
    default:
      return {
        [direction]: `${reverse}${padding ?? '45px'}`,
        top: top ?? 'calc(50% - 15px)',
        width: '15px',
        height: '30px',
        'background-size': '15px 30px',
      };
  }
};

export const DisabledStyle = css`
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
`;

export const StyledArrow = styled.span`
  position: absolute;
  z-index: 2;
  background: none;
  border: none;
  display: inline-block;
  content: '';
  cursor: pointer;
  stroke: ${({ color = theme.color.text.placeholder }) => color};
  stroke-width: ${({ strokeWidth = '3px' }) => strokeWidth};
  ${(props) => getArrowProps(props)};
  ${({ isEdge }) => (isEdge ? DisabledStyle : '')};
`;

export const ArrowPrev = ({ onClick, ...props }) => {
  const { handleClick } = useSwiperArrow({ onClick });

  return (
    <StyledArrow direction="left" onClick={handleClick} {...props}>
      <IconLeftArrow />
    </StyledArrow>
  );
};

ArrowPrev.displayName = 'ArrowPrev';

ArrowPrev.propTypes = {
  padding: numberOrStringType,
  type: PropTypes.oneOf(['50', '30', '20', '15', '10', '8']),
  position: PropTypes.oneOf(['outer', 'inner']),
  top: PropTypes.string,
  onClick: PropTypes.func,
  isEdge: PropTypes.bool,
};

export const ArrowNext = ({ onClick, ...props }) => {
  const { handleClick } = useSwiperArrow({ onClick });

  return (
    <StyledArrow direction="right" onClick={handleClick} {...props}>
      <IconRightArrow />
    </StyledArrow>
  );
};
ArrowNext.displayName = 'ArrowNext';

ArrowNext.propTypes = {
  padding: numberOrStringType,
  type: PropTypes.oneOf(['50', '30', '20', '15', '10', '8']),
  position: PropTypes.oneOf(['outer', 'inner']),
  top: PropTypes.string,
  onClick: PropTypes.func,
  isEdge: PropTypes.bool,
};

export const StyledArrowButton = styled.button`
  position: absolute;
  z-index: 2;
  background: none;
  border: none;
  display: inline-block;
  content: '';
  cursor: pointer;
  //stroke: ${({ color = theme.color.text.placeholder }) => color};
  stroke: #858585;
  stroke-width: ${({ strokeWidth = '3px' }) => strokeWidth};
  ${(props) => getArrowProps(props)};
  ${({ isEdge }) => (isEdge ? DisabledStyle : '')};
`;

export const ArrowPrevButton = ({ onClick, ...props }) => {
  const { handleClick } = useSwiperArrow({ onClick });

  return (
    <StyledArrowButton direction="left" onClick={handleClick} {...props} aria-label={'이전 슬라이드'}>
      <IconLeftArrow />
    </StyledArrowButton>
  );
};

ArrowPrevButton.displayName = 'ArrowPrevButton';

ArrowPrevButton.propTypes = {
  padding: numberOrStringType,
  type: PropTypes.oneOf(['50', '30', '20', '15', '10', '8']),
  position: PropTypes.oneOf(['outer', 'inner']),
  top: PropTypes.string,
  onClick: PropTypes.func,
  isEdge: PropTypes.bool,
};

export const ArrowNextButton = ({ onClick, ...props }) => {
  const { handleClick } = useSwiperArrow({ onClick });

  return (
    <StyledArrowButton direction="right" onClick={handleClick} {...props} aria-label={'다음 슬라이드'}>
      <IconRightArrow />
    </StyledArrowButton>
  );
};
ArrowNextButton.displayName = 'ArrowNextButton';

ArrowNextButton.propTypes = {
  padding: numberOrStringType,
  type: PropTypes.oneOf(['50', '30', '20', '15', '10', '8']),
  position: PropTypes.oneOf(['outer', 'inner']),
  top: PropTypes.string,
  onClick: PropTypes.func,
  isEdge: PropTypes.bool,
};
