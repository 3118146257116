import { format, parse } from 'date-fns';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import { BENEFIT_TYPE, DEVICE_TYPE, PROMOTION_TYPE } from '@ecp/common/src/const/coupon/couponConst';

const getCouponDetailByType = ({ promotionTypeCode, targetDeviceCode, benefitValue, benefitTypeCode }) => {
  switch (promotionTypeCode) {
    case PROMOTION_TYPE.CART:
      return {
        badgeText: '장바구니할인',
        badgeColor: theme.color.point.basic,
        rightSideColor:
          targetDeviceCode === DEVICE_TYPE.ALL
            ? theme.color.background.coupon.cart
            : theme.color.background.coupon.default,
        benefitValue: benefitValue?.toLocaleString(),
        benefitValueType: benefitTypeCode === BENEFIT_TYPE.PERCENT ? '%' : '원',
      };
    case PROMOTION_TYPE.DELIVERY:
      return {
        badgeText: '배송비할인',
        badgeColor: theme.color.icon.badge,
        rightSideColor:
          targetDeviceCode === DEVICE_TYPE.ALL
            ? theme.color.background.coupon.delivery
            : theme.color.background.coupon.default,
        benefitValue: '무료배송',
      };
    case PROMOTION_TYPE.OFFLINT_EVENT: 
      return {
        badgeText: '오프라인행사',
        badgeColor: theme.color.icon.current,
        rightSideColor:
          targetDeviceCode === DEVICE_TYPE.ALL
          ? theme.color.background.coupon.good
          : theme.color.background.coupon.default,
        benefitValue: benefitValue?.toLocaleString(),
        benefitValueType: benefitTypeCode === BENEFIT_TYPE.PERCENT ? '%' : '원',
      }
    default:
      return {
        badgeText: '상품할인',
        badgeColor: theme.color.icon.current,
        rightSideColor:
          targetDeviceCode === DEVICE_TYPE.ALL
            ? theme.color.background.coupon.good
            : theme.color.background.coupon.default,
        benefitValue: benefitValue?.toLocaleString(),
        benefitValueType: benefitTypeCode === BENEFIT_TYPE.PERCENT ? '%' : '원',
      };
  }
};

const getCouponDetailByDevice = ({ targetDeviceCode }) => {
  switch (targetDeviceCode) {
    case DEVICE_TYPE.PC:
      return { applyDevice: 'PC전용' };
    case DEVICE_TYPE.MOBILE:
      return { applyDevice: '모바일전용' };
    case DEVICE_TYPE.APP:
      return { applyDevice: '앱전용' };
    default:
      return { applyDevice: 'all' };
  }
};

const getApplyLimit = ({ benefitTypeCode, applyLimitAmount, maxDiscountAmount, multiLine = false }) => {
  const applyLimitText = applyLimitAmount > 0 ? `${NumberFormat(applyLimitAmount)}원 이상 구매시 ` : '';
  const maxDiscountText = maxDiscountAmount > 0 ? `최대 ${NumberFormat(maxDiscountAmount)}원 할인` : '';
  if (multiLine) return benefitTypeCode === BENEFIT_TYPE.PERCENT ? { applyLimitText, maxDiscountText } : applyLimitText;
  return benefitTypeCode === BENEFIT_TYPE.PERCENT ? applyLimitText.concat(maxDiscountText) : applyLimitText;
};

const getAvailableDate = ({ validTermDivisionCode, validDayCount, validStartDate, validEndDate }) => {
  if (validTermDivisionCode === '20') {
    return `${format(parse(validStartDate, 'yyyyMMdd', new Date()), 'yyyy.MM.dd')}~${format(
      parse(validEndDate, 'yyyyMMdd', new Date()),
      'yyyy.MM.dd'
    )}`;
  }
  return `발급일로부터 ${validDayCount}일 이내`;
};

export { getCouponDetailByType, getCouponDetailByDevice, getApplyLimit, getAvailableDate };
