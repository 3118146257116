import AssetSavePointIconSrc from '@ecp/common/src/assets/icon/icon__asset-savepoint.svg';
import AssetSupportIconSrc from '@ecp/common/src/assets/icon/icon__asset-support.svg';
import AssetWelPointIconSrc from '@ecp/common/src/assets/icon/icon__asset-welpoint.svg';
import AssetCouponIconSrc from '@ecp/common/src/assets/icon/icon__asset-coupon.svg';

export const ASSET_RESOURCE_DIVISION_CODE = {
  WEL_POINT: '22',
  SAVE_MONEY: '23',
  SUPPORT: '24',
  COUPON: '41',
};

export const ASSET_RESOURCE_DIVISION_NAME = {
  22: '웰포인트',
  23: '적립금',
  24: '복지포인트',
  41: '쿠폰',
};

export const ASSET_POINT_SUMMARY_ORDER = [
  ASSET_RESOURCE_DIVISION_CODE.COUPON,
  ASSET_RESOURCE_DIVISION_CODE.SAVE_MONEY,
  ASSET_RESOURCE_DIVISION_CODE.WEL_POINT,
  ASSET_RESOURCE_DIVISION_CODE.SUPPORT,
];

export const ASSET_SUMMARY_ICON_TYPE = {
  22: AssetWelPointIconSrc,
  23: AssetSavePointIconSrc,
  24: AssetSupportIconSrc,
  41: AssetCouponIconSrc,
};

export const ASSET_GUIDE = {
  [ASSET_RESOURCE_DIVISION_CODE.SAVE_MONEY]: [
    '보유하신 적립금은 1,000원 이상 보유 시 10원 단위로 사용 가능합니다.',
    '적립금은 결제 페이지에서 적립금 사용을 클릭하여 잔액을 확인하신 후 사용 가능합니다.',
    '적립금은 구매 시, 상품 리뷰작성 시, 적립 이벤트 등에 따라 유효기간이 상이하오니 이 점 확인하시고 사용하시길 바랍니다.',
    '적립금은 본 쇼핑몰에서만 사용 가능하며, 현금이나 기타 재화로 환급하거나 타인에게 양도할 수 없습니다.',
    '적립금은 사용한 주문이 취소된 경우 사용한 적립금은 자동 환원되나, 유효기간이 경과하면 사용할 수 없습니다.',
    '적립금은 회원을 탈퇴하는 경우 자동 소멸되며, 재가입하더라도 복구되지 않습니다.',
  ],
  [ASSET_RESOURCE_DIVISION_CODE.WEL_POINT]: [
    '웰포인트는 복리후생과 사내 참여를 독려하기 위한 이벤트 참여시 지급하는 것으로 결제 시 현금처럼 사용하실 수 있습니다.',
    '웰포인트는 결제 페이지에서 웰포인트 사용을 클릭하여 잔액을 확인하신 후 1P=1원 단위로 100% 사용하실 수 있습니다.',
    '웰포인트는 프로모션, 이벤트 등에 따라 유효기간이 상이하오니 이 점 확인하시고 사용하시길 바랍니다.',
    '웰포인트는 본 쇼핑몰에서만 사용 가능하며, 현금이나 기타 재화로 환급하거나 타인에게 양도할 수 없습니다.',
    '웰포인트를 사용한 주문이 취소된 경우 사용한 웰포인트는 자동 환원되나, 유효기간이 경과하면 사용할 수 없습니다.',
    '웰포인트는 회원을 탈퇴하는 경우 자동 소멸되며, 재가입하더라도 복구되지 않습니다.',
  ],
  [ASSET_RESOURCE_DIVISION_CODE.SUPPORT]: [
    '복지포인트는 복리후생을 위해 지급하는 포인트이며, 결제 시 현금처럼 사용하실 수 있습니다.',
    '복지포인트는 결제 페이지에서 복지포인트 사용을 클릭하여 잔액을 확인하신 후 결제 금액의 최대 50%까지 사용 가능합니다.',
    '복지포인트는 3월 1일 재직자 기준 연간 200만P를 지급합니다. 단, 3월 1일 이후 입사 및 복직한 경우에는 9월 1일 재직을 기준으로 100만P가 지급됩니다.',
    '복지포인트의 사용 가능한 유효기간은 3월~익년 2월 말까지이며, 이월되지 않고 자동 소멸됩니다.',
    '복지포인트를 사용한 주문이 취소된 경우 사용한 복지포인트는 자동 환원되나, 유효기간이 경과하면 사용할 수 없습니다.',
    '복지포인트는 회원을 탈퇴하는 경우 자동 소멸되며, 재가입하더라도 복구되지 않습니다.',
  ],
};
