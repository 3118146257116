import React, { useCallback, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import useInput from './useInput';

export const inputClassNames = {
  disabled: 'input-disabled',
  selected: 'input-selected',
  readOnly: 'input-readOnly',
  accent: 'input-accent',
  error: 'input-error',
  empty: 'input-empty',
};

export const inputUnstyledPropTypes = {
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  inputComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  rootProps: PropTypes.object,
  placeholder: PropTypes.string,
  onEnter: PropTypes.func,
};

const InputUnstyled = React.forwardRef((props, ref) => {
  const {
    startAdornment,
    endAdornment,
    rootProps,
    rootComponent: Root,
    inputComponent: InputComponent,
    onFocus,
    ...inputProps
  } = props;
  const inputRef = useRef(null);

  const { selected, disabled, readOnly, accent, error, empty, getInputProps } = useInput({
    ref: inputRef,
    props: inputProps,
  });

  const stateClasses = {
    [inputClassNames.disabled]: disabled,
    [inputClassNames.selected]: selected,
    [inputClassNames.readOnly]: readOnly,
    [inputClassNames.accent]: accent,
    [inputClassNames.error]: error,
    [inputClassNames.empty]: empty,
  };

  useImperativeHandle(ref, () => inputRef.current);

  const handleRootClick = useCallback(() => {
    inputRef.current.focus();
    onFocus?.();
  }, [inputRef, onFocus]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <Root className={clsx(stateClasses)} {...rootProps} onSubmit={handleSubmit} onClick={handleRootClick}>
      {startAdornment}
      <InputComponent ref={inputRef} className={clsx(stateClasses)} {...getInputProps()} />
      {endAdornment}
    </Root>
  );
});

InputUnstyled.propTypes = {
  ...inputUnstyledPropTypes,
};

InputUnstyled.defaultProps = {
  rootComponent: 'form',
  inputComponent: 'input',
};

InputUnstyled.displayName = 'InputUnstyled';

export default InputUnstyled;
