import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';

import { FlexBox, FlexBoxColumn } from '../../layouts/flex/styled';
import { PopupButton } from '../button/Button';
import { flexCenter } from '../../style/theme/defaultStyled';
import Modal from './Modal';
import { placements } from '../unstyled/floating/useModal';

const MessageBox = styled.div`
  ${flexCenter};
  width: 100%;
  height: fit-content;
  flex: 1;
  padding: 0 0 20px 0;
  font-weight: ${theme.font.weight.demiLight};
  font-size: ${theme.font.size.table};
  line-height: ${theme.font.lineHeight.demiLight};
  white-space: pre-wrap;
  word-break: keep-all;
  text-align: center;
`;

export const StructurePaper = ({ width, title, content, bottom }) => (
  <FlexBoxColumn width={width} gap={'30px'}>
    {title && (
      <FlexBox height={'32px'}>
        {title}
        <button></button>
      </FlexBox>
    )}
    <MessageBox>{content}</MessageBox>
    <FlexBox justify-content={'center'} gap={'10px'}>
      {bottom}
    </FlexBox>
  </FlexBoxColumn>
);

StructurePaper.displayName = 'StructurePaper';

StructurePaper.propTypes = {
  width: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  bottom: PropTypes.node,
  onClose: PropTypes.func,
};

export const AlertDialog = ({ message, children, open, onClose, confirmButtonText = '확인' }) => {
  const handleCloseClick = () => onClose?.();

  const bottomComponent = () => (
    <PopupButton width={'120px'} onClick={handleCloseClick}>
      {confirmButtonText}
    </PopupButton>
  );

  const AlertComponent = ({ onClose }) => {
    const handleClose = () => onClose?.();

    return <StructurePaper width={'300px'} content={message} bottom={bottomComponent({ onClose: handleClose })} />;
  };

  return (
    <Modal placement={'center'} value={AlertComponent} closeWhenOutside={false} zIndex={zIndex.dialog} open={open}>
      {children}
    </Modal>
  );
};

AlertDialog.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  confirmButtonText: PropTypes.string,
  placement: PropTypes.oneOf(placements),
};

export const ConfirmDialog = ({
  message,
  children,
  open,
  onConfirm,
  onClose,
  confirmButtonText = '확인',
  closeButtonText = '취소',
}) => {
  const bottomComponent = () => (
    <>
      <PopupButton type={'Secondary'} width={'120px'} onClick={onClose}>
        {closeButtonText}
      </PopupButton>
      <PopupButton width={'120px'} onClick={onConfirm}>
        {confirmButtonText}
      </PopupButton>
    </>
  );

  const ConfirmComponent = ({ onClose }) => {
    const handleClose = () => onClose?.();

    return <StructurePaper width={'300px'} content={message} bottom={bottomComponent({ onClose: handleClose })} />;
  };

  return (
    <Modal placement={'center'} value={ConfirmComponent} closeWhenOutside={false} zIndex={zIndex.dialog} open={open}>
      {children}
    </Modal>
  );
};

ConfirmDialog.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  placement: PropTypes.oneOf(placements),
  confirmButtonText: PropTypes.string,
  closeButtonText: PropTypes.string,
};
