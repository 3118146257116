export const DELIVERY_METHOD = [
  {
    label: '개별 배송',
    key: 'individual',
    value: 'individualDelivery',
  },
  {
    label: '한곳으로 일괄 배송',
    key: 'bundle',
    value: 'bundleDelivery',
  },
  {
    label: '기타',
    key: 'etc',
    value: 'etc',
  },
];

export const BULK_ORDER_TITLE =
  '고객의 건강하고 풍요로운 삶을 위한 쇼핑공간\n웰스토리몰과 함께 더욱 큰 성공을 누리실 수 있습니다.';

export const BULK_ORDER_DESCRIPTION =
  '삼성웰스토리는 차별화된 서비스로 고객만족을 위해 오늘도 최선을 다하고 있습니다.\n' +
  '대량구매 문의를 통해 웰스토리 몰에서 판매되고 있는 엄선된 최고 품질의 상품들을 더욱 큰 혜택으로\n' +
  '만나보실 수 있습니다.\n' +
  '아래 신청란에 기재해 주시면 최대한 빠른 시간내에 즉시 응대해 드리도록 하겠습니다.';

export const PRIVACY_POLICY_DESCRIPTION =
  '1. 개인정보의 수집 및 이용 목적 - 대량구매\n' +
  '2. 수집하는 개인정보의 항목\n' +
  ' : 소속(회사명), 휴대전화번호, 이메일, 대량구매 문의 내용\n' +
  '3. 개인정보의 보유 및 이용기간 - <span style="color:#FF5624;font-size:14pt;font-weight: bold;text-decoration: underline;">3개월 후 일괄 파기</span>';

export const PRIVACY_POLICY_TIP =
  '※ 문의과정에서 본 동의를 거부할 권리가 있으며 단, 거부할 경우 문의 관련된 기능을 사용할 수 없습니다.';

export const WELSTORY_URL = 'https://www.samsungwelstory.com';
