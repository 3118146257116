import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useStaticImageHostname } from '@ecp/common/src/hooks/interface/useHostname';

const WpayMemberRegForm = ({ memData }) => {
  const memForm = useRef(null);
  const hostname = useStaticImageHostname();

  useEffect(() => {
    if (memData.mid) {
      memForm.current.submit();
    }
  }, [memData]);

  return (
    <div style={{ display: 'none' }}>
      {/* wpay 회원가입 */}
      <form
        name="form_postsubmit_mem"
        id="form_postsubmit_mem"
        action={`${process.env.REACT_APP_INICIS_WPAY_HOST}/stdwpay/su/memreg`}
        method="post"
        ref={memForm}
      >
        <input type="hidden" name="mid" id="mid" value={memData.mid} />
        <input type="hidden" name="userId" id="userId" value={memData.userId} />
        <input type="hidden" name="ci" id="ci" value={memData.ci} />
        <input type="hidden" name="userNm" id="userNm" value={memData.userNm} />
        <input type="hidden" name="hNum" id="hNum" value={memData.hNum} />
        <input type="hidden" name="hCorp" id="hCorp" value={memData.hCorp} />
        <input type="hidden" name="birthDay" id="birthDay" value={memData.birthDay} />
        <input type="hidden" name="socialNo2" id="socialNo2" value={memData.socialNo2} />
        <input type="hidden" name="frnrYn" id="frnrYn" value={memData.frnrYn} />
        <input type="hidden" name="returnUrl" id="returnUrl" value={memData.returnUrl} />
        <input type="hidden" name="signature" id="signature" value={memData.signature} />
        <input type="hidden" name="titleBarColor" id="titleBarColor" value="#eeeeee" />
        <input
          type="hidden"
          name="tiltleBarBiImgUrl"
          value={`${hostname}/contents/static/images/welstorypay_logo.png`}
        />
        <input
          type="hidden"
          name="content"
          id="content"
          value="가맹점에서 간편하게 클릭한번으로<br>구매 가능한 결제서비스 입니다."
        />
        <input type="hidden" name="authBtnColor" id="authBtnColor" value="#333333" />
        <input type="hidden" name="authBtnTextcolor" id="authBtnTextcolor" value="#ffffff" />
        <input type="hidden" name="closeBtnType" id="closeBtnType" value="A" />
      </form>
    </div>
  );
};

export default WpayMemberRegForm;

WpayMemberRegForm.propTypes = {
  memData: PropTypes.object,
};

WpayMemberRegForm.displayName = 'WpayMemberRegForm';
