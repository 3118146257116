import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import { COUPON_STYLE_PC } from '@ecp/common/src/const/coupon/couponConst';
import { ReactComponent as DownloadIcon } from '@ecp/common/src/assets/icon/icon__app-download.svg';

const CouponBaseCard = ({
  children,
  cardColor,
  buttonText,
  iconImage,
  iconPointValue,
  iconPointUnit,
  iconPointValueStyle,
  iconCountValue,
  iconCountUnit,
  iconCountValueStyle,
  downloadable,
  baseStyle,
  handleButtonClick,
  //altText,
}) => {
  return (
    <Wrapper style={baseStyle.CARD} cardColor={cardColor}>
      <MessageWrapper>{children}</MessageWrapper>
      <ButtonWrapper onClick={handleButtonClick}>
        <TextBox style={baseStyle.BUTTON}>{buttonText}</TextBox>
        {downloadable ? (
          <FlexBox>
            <Spacing right={6} />
            <DownloadIcon />
          </FlexBox>
        ) : (
          ' >'
        )}
      </ButtonWrapper>
      <IconWrapper style={baseStyle.ICON}>
        <IconPointTextWrapper style={iconPointValueStyle || baseStyle.POINT_LOCATION.default}>
          <FlexBox justify-content={'center'} align-items={'flex-end'}>
            <TextBox style={baseStyle.POINT}>{iconPointValue ? iconPointValue : ''}</TextBox>
            <TextBox style={baseStyle.POINT_UNIT}>{iconPointValue ? iconPointUnit : ''}</TextBox>
          </FlexBox>
        </IconPointTextWrapper>
        <IconCountTextWrapper style={iconCountValueStyle || baseStyle.COUNT_LOCATION.default}>
          <FlexBox justify-content={'center'} align-items={'baseline'}>
            <TextBox style={baseStyle.COUNT}>{iconCountValue ? iconCountValue : ''}</TextBox>
            <TextBox style={baseStyle.COUNT_UNIT}>{iconCountValue ? iconCountUnit : ''}</TextBox>
          </FlexBox>
        </IconCountTextWrapper>
        <img style={baseStyle.ICON} src={iconImage} alt={''}></img>
      </IconWrapper>
    </Wrapper>
  );
};

CouponBaseCard.propTypes = {
  children: PropTypes.object,
  cardColor: PropTypes.string,
  buttonText: PropTypes.string,
  iconImage: PropTypes.string,
  iconPointValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconPointUnit: PropTypes.string,
  iconPointValueStyle: PropTypes.object,
  iconCountValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconCountUnit: PropTypes.string,
  iconCountValueStyle: PropTypes.object,
  downloadable: PropTypes.bool,
  baseStyle: PropTypes.object,
  handleButtonClick: PropTypes.func,
  altText: PropTypes.string,
};

CouponBaseCard.defaultProps = {
  cardColor: '#FCF8D3',
  buttonText: '',
  iconPointValue: '',
  iconPointUnit: '원',
  iconPointValueStyle: undefined,
  iconCountValue: '',
  iconCountUnit: '개',
  iconCountValueStyle: undefined,
  downloadable: false,
  baseStyle: COUPON_STYLE_PC,
};

export default CouponBaseCard;

const Wrapper = styled(FlexBoxColumn)`
  background-color: ${({ cardColor }) => cardColor};
  border-radius: 12px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: flex-start;
`;

const ButtonWrapper = styled(TextButton)`
  height: 30px;
  border-radius: 30px;
  padding: 0 14px;
  background-color: #ffffff;
  z-index: 2;
`;

const MessageWrapper = styled(FlexBox)`
  z-index: 2;
  color: #141415;
`;

const IconWrapper = styled(FlexBox)`
  position: absolute;
  justify-content: center;
  align-items: center;
`;

const IconPointTextWrapper = styled(TextBox)`
  position: absolute;
`;

const IconCountTextWrapper = styled(TextBox)`
  position: absolute;
`;
