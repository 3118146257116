import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import StyledImg from '@ecp/common/src/components/image/StyledImg';

import { FlexBox } from '../../layouts/flex/styled';
import TextLink, { textLinkProps } from '../../text/TextLink';

export const StyledMenuItem = styled(TextLink)`
  width: 100%;
  min-height: 19px;
`;

const ContextMenuItem = ({ image: { url, width, height, radius, border, boxSizing, alt } = {}, ...props }) => {
  return (
    <FlexBox gap={'10px'} justify-content={'flex-start'}>
      {url && (
        <StyledImg
          src={url}
          width={width}
          height={height}
          radius={radius}
          border={border}
          style={{ boxSizing: boxSizing }}
          alt={alt}
        />
      )}
      <StyledMenuItem {...props} />
    </FlexBox>
  );
};

ContextMenuItem.displayName = 'ContextMenuItem';

ContextMenuItem.propTypes = {
  ...textLinkProps,
  image: PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    radius: PropTypes.string,
    border: PropTypes.string,
    boxSizing: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

export default ContextMenuItem;
