import React, { useState } from 'react';

import { FlexBox, FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Popup from '@ecp/common/src/components/modal/Popup';
import { TextButton } from '@ecp/common/src/components/button/TextButton';

import { ReactComponent as EmailIcon } from '@fo-assets/icon/icon__email.svg';

const EmailCollectionPopupButton = () => {
  const [open, setOpen] = useState(false);

  const handlePopupClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Popup
        header="이메일 무단수집거부"
        open={open}
        useCancelButton={false}
        onConfirmClick={handlePopupClose}
        onClose={handlePopupClose}
        rootStyleProps={{ width: '520px' }}
      >
        <FlexBoxCenter width={'100%'} gap={'21px'} flex-direction={'column'}>
          <FlexBox gap={'20px'}>
            <EmailIcon />
            <TextBox size={'18px'} weight={'500'} style={{ lineHeight: '28px' }}>
              웰스토리는 이메일 주소의 무단 수집을
              <br />
              거부합니다.
            </TextBox>
          </FlexBox>
          <TextBox size={'14px'} weight={'350'} style={{ textAlign: 'center', lineHeight: '22px' }}>
            본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나
            <br />그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부하며,
            <br />
            이를 위반 시 정보통신망법에 의해 형사 처벌됨을 유념하시기 바랍니다.
            <br />[ 게시일 2007년 9월 3일 ]
          </TextBox>
        </FlexBoxCenter>
      </Popup>
      <TextButton
        textProps={{ weight: '350' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        이메일 무단수집거부
      </TextButton>
    </>
  );
};

export default EmailCollectionPopupButton;
