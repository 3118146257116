import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';

import { userInfoState } from '@fo-recoil/common/user/atom';
import { withCartBadgeCount } from '@fo-recoil/order/selector';
import authApi from '@fo-apis/common/authApi';
import orderApi from '@fo-apis/order/orderApi';

const UserInfoProvider = ({ children }) => {
  const setMemberInfo = useSetRecoilState(userInfoState);
  useEffect(() => {
    (async () => {
      const { result } = await authApi.info();
      setMemberInfo(result);
    })();
  }, [setMemberInfo]);

  const setCartBadgeCount = useSetRecoilState(withCartBadgeCount);
  useEffect(() => {
    (async () => {
      const { result } = await orderApi.getCartCount();
      setCartBadgeCount(result);
    })();
  }, [setCartBadgeCount]);

  return <>{children}</>;
};

export default UserInfoProvider;

UserInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
