import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { extractDataProps } from '@ecp/common/src/utils/utils';
import useIgnorableError from '@ecp/common/src/hooks/useIgnorableError';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import {
  defaultProps,
  FlexBox,
  FlexBoxColumn,
  flexPropTypes,
  FlexBoxButton,
} from '@ecp/common/src/layouts/flex/styled';
import InputUnstyled, {
  inputClassNames,
  inputUnstyledPropTypes,
} from '@ecp/common/src/components/unstyled/input/InputUnstyled';
import { ReactComponent as ClearSvg } from '@ecp/common/src/assets/icon/input__clear.svg';

export const inputBasePropTypes = {
  ...inputUnstyledPropTypes,
  ...sizePropTypes,
  /** 초기화 버튼 사용 여부*/
  clearable: PropTypes.bool,
  clearableComponent: PropTypes.elementType,
  maxLength: PropTypes.number,
  countCheck: PropTypes.bool,
  countCheckComponent: PropTypes.elementType,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  suffixText: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  outlineAble: PropTypes.bool,
};

const ClearIcon = styled(ClearSvg)`
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

export const StyledInputRoot = styled.form.attrs(({ minWidth = '193px', style }) => ({
  style: {
    ...style,
    minWidth,
  },
}))`
  display: flex;
  position: relative;
  justify-content: space-between;
  ${overrideProperties(flexPropTypes, defaultProps)};

  padding: 1px 14px;
  background-color: ${theme.color.background.white};
  border: 1px solid ${theme.color.line.disabled};
  gap: 1px;

  &,
  & input,
  & textarea {
    font-weight: ${theme.font.weight.demiLight};
    font-size: ${theme.font.size.basic};
    line-height: 20px;
  }

  ${overrideProperties(sizePropTypes, {
    width: 'fit-content',
    height: '40px',
  })};

  &.${inputClassNames.disabled} {
    color: ${theme.color.secondary};
    background-color: ${theme.color.background.disabled};
    border: 1px solid ${theme.color.line.disabled};
  }

  &.${inputClassNames.selected} {
    border: 1px solid ${theme.color.line.selected};
  }

  &.${inputClassNames.readOnly} {
    background-color: ${theme.color.background.basic};
  }

  &.${inputClassNames.empty} {
    color: ${theme.color.text.placeholder};
  }

  &.${inputClassNames.error} {
    border: 1px solid ${theme.color.line.error};
  }
`;

export const StyledInput = styled.input`
  flex: 1;
  border: none;
  ${({ outlineAble }) => (!outlineAble ? 'outline: none;' : '')}
  background-color: transparent;
  width: 100%;
  padding: 0;

  &.${inputClassNames.disabled} {
    color: ${theme.color.secondary};
  }

  &.${inputClassNames.empty} {
    color: ${theme.color.text.newPlaceholder};
  }

  &::placeholder {
    color: ${theme.color.text.guide};
  }
`;

export const StyledErrorMessage = styled.div`
  font-weight: ${theme.font.weight.demiLight};
  font-size: ${theme.font.size.error};
  color: ${theme.color.text.error};
  line-height: 19px;
  height: 19px;
`;

const StyledCount = styled(FlexBox)`
  font-weight: ${theme.font.weight.demiLight};
  line-height: ${theme.font.lineHeight.demiLight};
  font-size: ${theme.font.size.basic};
  color: ${theme.color.text.placeholder};
  width: max-content;
  align-items: flex-end;
`;

export const Count = ({ value, maxLength, wrapperComponent: WrapperComponent }) => {
  const count = value?.length || 0;

  return (
    <WrapperComponent title="입력한 글자수/제한 글자수">
      {count}/{maxLength}
    </WrapperComponent>
  );
};

Count.propTypes = {
  value: PropTypes.string,
  maxLength: PropTypes.number,
  wrapperComponent: PropTypes.elementType,
};

Count.defaultProps = {
  wrapperComponent: StyledCount,
};

const Input = React.forwardRef(
  (
    {
      clearable,
      clearableComponent: ClearableComponent,
      countCheck,
      countCheckComponent: CountCheckComponent,
      suffixText = '',
      height,
      width,
      minWidth,
      error,
      ...props
    },
    ref
  ) => {
    const { value, disabled, readOnly, endAdornment, errorMessage, onChange, maxLength } = props;

    const inputRef = useRef(null);
    useImperativeHandle(ref, () => inputRef.current);

    const { ignoreError } = useIgnorableError({ error, value });

    const dataProps = extractDataProps(props);
    const handleClearClick = (e) => {
      if (disabled || readOnly) return;

      e.target.value = '';
      onChange(e);
    };

    return (
      <FlexBoxColumn width={width} align-items={'flex-start'} gap={'6px'}>
        <InputUnstyled
          rootComponent={StyledInputRoot}
          rootProps={{ height, width, minWidth }}
          inputComponent={StyledInput}
          error={!ignoreError && error}
          ref={inputRef}
          {...props}
          endAdornment={
            <FlexBoxButton gap="10px" width="fit-content" flex="0" type="button" aria-label="입력 텍스트 삭제">
              <span>{suffixText}</span>
              {countCheck && <CountCheckComponent value={value} maxLength={maxLength} />}
              {clearable && !!value && !disabled && !readOnly && (
                <ClearableComponent {...dataProps} onClick={handleClearClick} />
              )}
              {endAdornment}
            </FlexBoxButton>
          }
        />
        {!ignoreError && error && errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      </FlexBoxColumn>
    );
  }
);

Input.displayName = 'Input';

Input.propTypes = inputBasePropTypes;

Input.defaultProps = {
  clearable: true,
  clearableComponent: ClearIcon,
  maxLength: 524288,
  countCheck: false,
  countCheckComponent: Count,
  placeholder: '입력해 주세요',
  autoComplete: 'on',
  onFocus: () => {},
  outlineAble: false,
};

export default Input;
