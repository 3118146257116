import React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import useNavigateChangeCallback from '@ecp/common/src/hooks/useNavigateChangeCallback';
import { AlertDialog, ConfirmDialog } from '@ecp/common/src/components/modal/Dialog';

import { alertDialogState, confirmDialogState } from '@fo-recoil/common/atom';

const DialogProvider = () => {
  const alertDialogValue = useRecoilValue(alertDialogState);
  const resetAlertDialogValue = useResetRecoilState(alertDialogState);

  const confirmDialogValue = useRecoilValue(confirmDialogState);
  const resetConfirmDialogValue = useResetRecoilState(confirmDialogState);

  useNavigateChangeCallback(resetAlertDialogValue);
  useNavigateChangeCallback(resetConfirmDialogValue);

  const handleAlertClose = () => {
    alertDialogValue.onConfirm();
    resetAlertDialogValue();
  };

  const handleConfirmClick = () => {
    confirmDialogValue.onConfirm();
    resetConfirmDialogValue();
  };

  const handleConfirmCloseClick = () => {
    confirmDialogValue.onClose();
    resetConfirmDialogValue();
  };

  return (
    <>
      <AlertDialog
        open={alertDialogValue.open}
        message={alertDialogValue.message}
        onClose={handleAlertClose}
        confirmButtonText={alertDialogValue.confirmButtonText}
      />
      <ConfirmDialog
        open={confirmDialogValue.open}
        message={confirmDialogValue.message}
        onConfirm={handleConfirmClick}
        onClose={handleConfirmCloseClick}
        confirmButtonText={confirmDialogValue.confirmButtonText}
        closeButtonText={confirmDialogValue.closeButtonText}
      />
    </>
  );
};

export default DialogProvider;
