import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Loading from '@ecp/common/src/components/progress/Loading';

const SuspenseSpinner = ({ message, spinnerProps, ...props }) => (
  <FlexBoxCenter flex-direction={'column'} gap={'30px'} {...props}>
    <Loading {...spinnerProps} />
    {!!message && <StyledTextBox>{message}</StyledTextBox>}
  </FlexBoxCenter>
);

export default SuspenseSpinner;

SuspenseSpinner.propTypes = {
  message: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  spinnerProps: PropTypes.object,
};

const StyledTextBox = styled(TextBox)`
  font-size: ${theme.font.size.title};
  line-height: ${theme.font.lineHeight.demiLight};
  color: ${theme.color.text.basic};
  text-align: center;
`;
