import React from 'react';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { InnerButton } from '@ecp/common/src/components/button/Button';
import { ReactComponent as IsmspSrc } from '@ecp/common/src/assets/image/image__ismsp.svg';

import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import IsmspPopupButton from '@fo-pages/home/footer/popup/IsmspPopupButton';
import PaymentGuaranteePopupButton from '@fo-pages/home/footer/popup/PamentGuaranteePopupButton';

const FooterBottom = () => {
  const {
    systemInformation: { pcFooterImgFilePath, customerCenterTel, customerCenterGuide },
    operationTime,
    lunchTime,
  } = useSiteInfo();

  const handleBusinessInfoClick = () => {
    window.open('https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1428521591');
  };

  return (
    <FlexBoxSpaceBetween width={'1540px'} height={'270px'} align-items={'flex-start'}>
      <FlexBox gap={'20px'} align-items={'flex-start'}>
        <ImageBox
          imageSrc={pcFooterImgFilePath}
          width={'80px'}
          height={'57px'}
          backgroundPositionY={'top'}
          background-size={'contain'}
        />
        <FlexBoxColumn align-items={'flex-start'}>
          <FlexBox justify-content={'flex-start'} gap={'20px'}>
            <StyledTextBox>대표이사 정해린</StyledTextBox>
            <StyledTextBox>경기도 용인시 용구대로 2442-1 </StyledTextBox>
            <StyledTextBox>문의전화 {customerCenterTel}</StyledTextBox>
            <StyledTextBox>이메일 welmall.sei@samsung.com </StyledTextBox>
          </FlexBox>
          <FlexBox justify-content={'flex-start'} gap={'20px'}>
            <StyledTextBox>호스팅 사업자 : 삼성SDS(주)</StyledTextBox>
            <StyledTextBox gap={'5px'}>
              사업자등록번호 142-85-21591
              <InnerButton
                width={'66px'}
                height={'20px'}
                style={{ padding: '0', borderRadius: '0' }}
                onClick={handleBusinessInfoClick}
              >
                <TextBox size={'12px'} weight={350}>
                  <StyledAtag
                    href="https://www.ftc.go.kr/bizCommPopView.do"
                    target="_blank"
                    title="새 창 열림"
                    rel="noreferrer"
                  >
                    사업자 확인
                  </StyledAtag>
                </TextBox>
              </InnerButton>
            </StyledTextBox>
            <StyledTextBox>통신판매업 신고번호 (제2013-용인기흥-00548호)</StyledTextBox>
          </FlexBox>
          <FlexBox justify-content={'flex-start'} gap={'20px'}>
            <StyledTextBox>부가통신사업자 제2-01-16-0057호</StyledTextBox>
            <StyledTextBox>건강기능식품판매업 제2004-0350118호</StyledTextBox>
          </FlexBox>
          <FlexBox justify-content={'flex-start'} gap={'20px'}>
            <StyledTextBox>© SAMSUNG WELSTORY INC ALL RIGHTS RESERVED</StyledTextBox>
          </FlexBox>
          <Spacing top={20} bottom={6}>
            <TextBox size={'13px'} weight={'500'}>
              우리은행 채무자지급보증 안내
            </TextBox>
            <StyledTextBox>
              웰스토리몰은 전자상거래 법률에 따라 고객님의 현금결제금액에 대한 안전거래를 보장하고 있습니다.
            </StyledTextBox>
          </Spacing>
          <FlexBox gap={'30px'}>
            <PaymentGuaranteePopupButton />
            <FlexBox gap={'6px'}>
              <IsmspSrc width={'38px'} height={'29px'} />
              <IsmspPopupButton />
            </FlexBox>
          </FlexBox>
        </FlexBoxColumn>
      </FlexBox>
      <FlexBoxColumn align-items={'flex-start'}>
        <TextBox weight={'350'} height={'22px'}>
          고객센터
        </TextBox>
        <TextBox size={'28px'} weight={'500'} height={'45px'}>
          {customerCenterTel}
        </TextBox>
        <Spacing top={6} />
        <TextBox weight={'350'} color={theme.color.text.guide}>
          {operationTime} {lunchTime}
          <br />
          {customerCenterGuide}
        </TextBox>
      </FlexBoxColumn>
    </FlexBoxSpaceBetween>
  );
};

export default FooterBottom;

const StyledTextBox = styled(TextBox)`
  font-size: 13px;
  font-weight: 350;
  color: #727272;
`;

const StyledAtag = styled.a`
  font-size: 12px;
  font-weight: 350;
  color: inherit;
  text-decoration: none;
  line-height: inherit;
  background-color: unset;
  white-space: pre-wrap;
`;
