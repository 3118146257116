import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { alertDialogState, confirmDialogState } from '@fo-recoil/common/atom';
import { setRecoilExternalState } from '@fo-recoil/RecoilExternalStatePortal';

export const useAlertDialog = () => {
  const setAlertDialogValue = useSetRecoilState(alertDialogState);

  const showAlertDialog = useCallback(
    (message, onConfirm = () => {}, confirmButtonText) => {
      setAlertDialogValue({
        open: true,
        message,
        onConfirm,
        confirmButtonText,
      });
    },
    [setAlertDialogValue]
  );

  return { showAlertDialog };
};

export const useConfirmDialog = () => {
  const setConfirmDialogValue = useSetRecoilState(confirmDialogState);

  const showConfirmDialog = useCallback(
    (message, onConfirm, onClose = () => {}, confirmButtonText, closeButtonText) => {
      setConfirmDialogValue({
        open: true,
        message,
        onConfirm,
        onClose,
        confirmButtonText,
        closeButtonText,
      });
    },
    [setConfirmDialogValue]
  );

  return { showConfirmDialog };
};

export const showExternalAlertDialog = (message, onConfirm = () => {}, confirmButtonText, severity) => {
  setRecoilExternalState(alertDialogState, {
    open: true,
    message,
    onConfirm,
    confirmButtonText,
    severity,
  });
};
