import { postRequest } from '@fo-apis/axios';

const decryptKnoxInfo = (payload) =>
  postRequest(
    {
      url: 'api/v1/knox/user-info/decrypt',
      data: payload,
    },
    { skipError: true }
  );

const getKnoxUserInfo = (payload) =>
  postRequest(
    {
      url: 'api/v1/knox/get-user-info',
      data: { ...payload },
    },
    { skipError: true }
  );

const getKnoxUserInfoByEmail = (payload) =>
  postRequest(
    {
      url: 'api/v1/knox/get-user-info?email',
      data: { ...payload },
    },
    { skipError: true }
  );

const knoxApi = {
  decryptKnoxInfo,
  getKnoxUserInfo,
  getKnoxUserInfoByEmail,
};

export default knoxApi;
