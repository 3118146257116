import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import CouponBaseCard from '@ecp/common/src/components/coupon/components/CouponBaseCard';
import { COUPON_PROPERTY, COUPON_STYLE_PC } from '@ecp/common/src/const/coupon/couponConst';

const DownloadCouponCard = ({ countValue, baseStyle, handleButtonClick }) => {
  const CardMessage = useMemo(() => {
    return (
      <FlexBoxColumn style={baseStyle.MESSAGE_STYLE} justify-content={'flex-start'} align-items={'flex-start'}>
        <FlexBox>
          <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'지금 '}</TextBoxAlignLeft>
          <TextBoxAlignLeft style={baseStyle.MESSAGE} color={theme.color.text.selected}>
            {`${countValue}개`}
          </TextBoxAlignLeft>
          <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'의 쿠폰을'}</TextBoxAlignLeft>
        </FlexBox>
        <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'받으실 수 있어요'}</TextBoxAlignLeft>
      </FlexBoxColumn>
    );
  }, [baseStyle.MESSAGE, baseStyle.MESSAGE_STYLE, countValue]);

  return (
    <CouponBaseCard
      baseStyle={baseStyle}
      iconImage={COUPON_PROPERTY.DOWNLOAD.iconImage}
      cardColor={COUPON_PROPERTY.DOWNLOAD.cardColor}
      buttonText={COUPON_PROPERTY.DOWNLOAD.buttonText}
      iconCountValue={countValue}
      iconCountValueStyle={baseStyle.COUNT_LOCATION.download}
      handleButtonClick={handleButtonClick}
      altText={'지금' + countValue + '개의 쿠폰을 받으실 수 있어요, ' + COUPON_PROPERTY.REVIEW.buttonText}
    >
      {CardMessage}
    </CouponBaseCard>
  );
};

DownloadCouponCard.propTypes = {
  countValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  baseStyle: PropTypes.object,
  handleButtonClick: PropTypes.func,
};

DownloadCouponCard.defaultProps = {
  countValue: '',
  baseStyle: COUPON_STYLE_PC,
};
export default DownloadCouponCard;
