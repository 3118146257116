import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { positionPropTypes, sizePropTypes } from '../../style/propTypes';
import BadgeUnstyled from '../unstyled/badge/BadgeUnstyled';
import { flexCenter } from '../../style/theme/defaultStyled';
import { theme } from '../../style/theme/default.js';
import { overrideProperties } from '../../style/utils';

const DefaultBadgeRoot = styled.div`
  position: relative;
  width: fit-content;
  height: 20px;
  margin: 0;
  padding: 0;
`;

const DefaultBadge = styled.span`
  ${flexCenter};
  position: absolute;
  border-radius: 50%;
  background-color: ${theme.color.primary};
  color: ${theme.color.text.white};
  font-size: ${theme.font.size.tiny};
  font-weight: ${theme.font.weight.medium};

  ${overrideProperties(positionPropTypes, {})};
  ${overrideProperties(sizePropTypes, {})};
`;

const Badge = React.forwardRef(({ position, size, ...props }, ref) => {
  return <BadgeUnstyled {...props} badgeProps={{ ...position, ...size }} ref={ref} />;
});

export const BadgePropTypes = {
  position: PropTypes.shape(positionPropTypes),
  size: PropTypes.shape(sizePropTypes),
  badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  rootComponent: PropTypes.elementType,
  badgeComponent: PropTypes.elementType,
};

Badge.propTypes = BadgePropTypes;

Badge.defaultProps = {
  rootComponent: DefaultBadgeRoot,
  badgeComponent: DefaultBadge,
};

Badge.displayName = 'Badge';

export default Badge;
