import { useRef, useCallback, useEffect, startTransition } from 'react';
import { useLocation, useNavigationType, NavigationType } from 'react-router-dom';

const useRouterScrollTop = (promiseInProgress) => {
  const scrollMap = useRef({});
  const progressing = useRef(false);

  const { key: locationKey,state } = useLocation();
  const navType = useNavigationType();
  const handleScroll = useCallback(() => {
    window.scrollY > 0 && (scrollMap.current[locationKey] = window.scrollY);
  }, [locationKey]);

  const lazyRunner = useCallback((callback) => {
    requestAnimationFrame(() => {
      const messageChannel = new MessageChannel();
      messageChannel.port1.onmessage = callback;
      messageChannel.port2.postMessage(undefined);
    });
  }, []);

  useEffect(() => {
    progressing.current = promiseInProgress ?? false;
  }, [promiseInProgress]);

  useEffect(() => {
    const lastTop = scrollMap.current[locationKey];
    delete scrollMap.current[locationKey];

    const scroller = (progressing, top) => () => {
      const intervalId = setInterval(() => {
        if (!progressing.current) {
          startTransition(() => {
            document.getElementById('root').getBoundingClientRect();
            window.scrollTo({ top });
            clearInterval(intervalId);
          });
        }
      }, 900);
    };

    if (navType === NavigationType.Push) {
      if (state === null) {
        window.scrollTo({ top: 0 });
      } else {
        lastTop && lazyRunner(scroller(progressing, lastTop));
      }
    } else {
      lastTop && lazyRunner(scroller(progressing, lastTop));
    }

    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [handleScroll, navType, locationKey, lazyRunner]);
};

export default useRouterScrollTop;
