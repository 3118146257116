import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useStaticImageHostname } from '@ecp/common/src/hooks/interface/useHostname';
import WpayMyPageForm from '@ecp/common/src/components/interface/WpayMyPageForm';
import { NEED_TO_REGISTER } from '@ecp/common/src/const/interface/wpayResultCode';

const WpayMyPageComp = ({ wpayApi, showAlertDialog }) => {
  const navigate = useNavigate();
  const [myPageData, setMyPageData] = useState({});
  const [firstRender, setFirstRender] = useState(false);
  const hostname = useStaticImageHostname();

  useEffect(() => {
    setFirstRender(true);
  }, []);

  useEffect(() => {
    if (!firstRender) return;
    wpayApi.getMyPageParams().then(({ result, message, code }) => {
      if (code === NEED_TO_REGISTER) {
        showAlertDialog(message, () => {
          navigate('/interface/wpay-member-reg');
        });
      } else if (message) {
        showAlertDialog(message, () => {
          window.close();
        });
      } else {
        setMyPageData(result);
      }
    });
  }, [firstRender, navigate, showAlertDialog, wpayApi]);

  return <WpayMyPageForm myPageData={myPageData} hostname={hostname} />;
};

export default WpayMyPageComp;

WpayMyPageComp.propTypes = {
  showAlertDialog: PropTypes.func,
  wpayApi: PropTypes.object,
};
