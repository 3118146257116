import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { TextBoxButton } from '@ecp/common/src/text/TextBoxButton';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { NoneDecorationLink } from '@ecp/common/src/text/TextLink';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import { ReactComponent as IconCategory } from '@fo-assets/icon/icon__gnb-category.svg';

const GnbCategory = ({ open, onCategory, sticky }) => {
  const {
    systemInformation: { pcGnbImgFilePath },
  } = useSiteInfo();
  const color = useMemo(() => (open ? theme.color.primary : theme.color.third), [open]);
  const openGb = useMemo(() => (open ? '닫기' : '열기'), [open]);

  return (
    <CategoryText id={'gnb_category'} data-ds-label2={'g_category'} type="button" title={openGb}>
      <IconCategory fill={color} onClick={onCategory} />
      {sticky ? (
        <Link to={''}>
          <Logo data-ds-label2={'g_logo'} src={pcGnbImgFilePath} alt={'웰스토리몰'} />
        </Link>
      ) : (
        <TextBox weight={theme.font.weight.bold} size={theme.font.size.label} color={color} onClick={onCategory}>
          카테고리
        </TextBox>
      )}
    </CategoryText>
  );
};

GnbCategory.propTypes = {
  open: PropTypes.bool,
  onCategory: PropTypes.func,
  sticky: PropTypes.bool,
};

export default GnbCategory;

const CategoryText = styled(TextBoxButton)`
  gap: 10px;
  cursor: pointer;
  background-color: ${theme.color.background.white};
  align-items: center;
  width: 174px;

  .sticky & {
    top: -25px;
    visibility: visible;
  }
`;

const Logo = styled(StyledImg)`
  width: 145px;
  height: 30px;
`;

const Link = styled(NoneDecorationLink)`
  display: flex;
`;
