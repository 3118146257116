import React, { useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import RadioContext from './RadioContext';
import useRadioGroup from './useRadioGroup';
import { unstyledAttr } from '../constant';

const RadioGroupUnstyled = React.forwardRef(
  ({ rootComponent: RootComponent, disabled, children, value, defaultValue, onChange, onClick, ...props }, ref) => {
    const { getGroupProps, ref: radioGroupRef } = useRadioGroup({
      value,
      defaultValue,
      onChange,
    });

    useImperativeHandle(ref, () => radioGroupRef.current);

    const handleClick = (e) => {
      if (disabled) return;

      onClick?.(e);
      e.stopPropagation();
    };

    return (
      <RadioContext.Provider value={getGroupProps()}>
        <RootComponent ref={radioGroupRef} {...props} onClick={handleClick}>
          {React.Children.map(children, (child, index) =>
            React.isValidElement(child)
              ? React.cloneElement(child, {
                  // to let Radio knows when to apply margin(Inline|Block)Start
                  ...(index === 0 && { [unstyledAttr.firstChild]: '' }),
                  ...(index === React.Children.count(children) - 1 && {
                    [unstyledAttr.lastChild]: '',
                  }),
                })
              : child
          )}
        </RootComponent>
      </RadioContext.Provider>
    );
  }
);

RadioGroupUnstyled.displayName = 'RadioGroupUnstyled';

RadioGroupUnstyled.propTypes = {
  rootComponent: PropTypes.elementType,
  children: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

RadioGroupUnstyled.defaultProps = {
  rootComponent: 'div',
};

export default RadioGroupUnstyled;
