import { css } from 'styled-components';

import { theme } from './default';

export const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const textEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
`;

export const demiLightCss = css`
  font-weight: ${theme.font.weight.demiLight};
  line-height: ${theme.font.lineHeight.demiLight};
`;

export const mediumCss = css`
  font-weight: ${theme.font.weight.medium};
  line-height: ${theme.font.lineHeight.medium};
`;
export const boldCss = css`
  font-weight: ${theme.font.weight.bold};
  line-height: ${theme.font.lineHeight.bold};
`;

export const boScrollCss = css`
  ::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #8583a5;
    border-radius: 6px;
    min-height: 15px;
    min-width: 15px;
  }

  ::-webkit-scrollbar-track {
    background-color: #eeeeee;
    background-position: center;
    border: 4px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-radius: 6px;
  }
`;

export const pcBackgroundBand = css`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};

  &::before {
    position: absolute;
    height: 100%;
    width: max(45px, calc(50vw - 777px));
    left: min(-45px, calc(777px - 50vw));
    top: 0;
    content: '';
    background-color: ${({ backgroundColor }) => backgroundColor};
  }

  &::after {
    position: absolute;
    height: 100%;
    width: max(45px, calc(50vw - 777px));
    right: min(-45px, calc(777px - 50vw));
    top: 0;
    content: '';
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`;
