import { useCallback } from 'react';

import useMousePress from '../../../hooks/element/useMousePress';

const useButton = ({ onClick = () => {}, disabled, ...props }, ref) => {
  const pressed = useMousePress({ ref });

  const getRootProps = useCallback(
    () => ({ ...props, onClick: disabled ? () => {} : onClick, disabled }),
    [disabled, onClick, props]
  );

  return {
    pressed,
    getRootProps,
  };
};

export default useButton;
