import React, { useLayoutEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import isEmpty from 'lodash-es/isEmpty';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { maxSizePropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import noImagePath from '@ecp/common/src/assets/image/image__no_image.png';

const StyledImg = ({ src = noImagePath, alt, ...props }) => {
  const handleError = (e) => {
    e.currentTarget.src = noImagePath;
  };

  const imageSrc = useMemo(() => (isEmpty(src) ? noImagePath : src), [src]);

  return <StyledImage src={imageSrc} alt={alt} {...props} onError={handleError} />;
};

StyledImg.propTypes = {
  $flip: PropTypes.bool,
  radius: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StyledImg.defaultProps = {
  $flip: false,
  radius: 'unset',
  alt: '',
};

export const LazyImg = ({ src = noImagePath, width, height, alt, loading, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  useLayoutEffect(() => {
    setIsLoading(true);
  }, [src]);

  const handleError = (e) => {
    e.currentTarget.src = noImagePath;
  };

  const handleLoad = () => {
    setIsLoading(false);
  };

  const imageSrc = useMemo(() => (isEmpty(src) ? noImagePath : src), [src]);

  return (
    <>
      <StyledImage
        src={imageSrc}
        width={width}
        height={height}
        alt={alt}
        loading={loading}
        {...props}
        onError={handleError}
        onLoad={handleLoad}
      />
      {isLoading && (
        <ImagePlaceholder src={noImagePath} width={width} height={height} alt={alt} loading={loading} {...props} />
      )}
    </>
  );
};

LazyImg.propTypes = {
  $flip: PropTypes.bool,
  radius: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  loading: PropTypes.string,
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

LazyImg.defaultProps = {
  $flip: false,
  radius: 'unset',
  loading: 'lazy',
  alt: '',
};

export default StyledImg;

const StyledImage = styled.img`
  transform: ${({ $flip }) => ($flip ? 'scaleY(-1)' : 'unset')};
  ${overrideProperties(
    { ...sizePropTypes, ...maxSizePropTypes, border: PropTypes.string, padding: PropTypes.string },
    { border: 'none', padding: '0px' }
  )};
  border-radius: ${({ radius }) => radius};
  object-fit: ${({ objectFit = 'fill' }) => objectFit};
  cursor: ${({ cursor = 'pointer' }) => cursor};
`;

const ImagePlaceholder = styled(StyledImage)`
  position: absolute;
  top: 0;
  left: 0;
`;
