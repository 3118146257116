import React, { useState } from 'react';

import { FlexBoxCenter, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Popup from '@ecp/common/src/components/modal/Popup';
import { InnerButton } from '@ecp/common/src/components/button/Button';

const PaymentGuaranteePopupButton = () => {
  const [open, setOpen] = useState(false);

  const handlePopupClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Popup
        header="서비스 가입 사실 확인"
        open={open}
        useCancelButton={false}
        onConfirmClick={handlePopupClose}
        onClose={handlePopupClose}
        rootStyleProps={{ width: '640px', height: '949px' }}
      >
        <FlexBoxCenter width={'100%'}>
          <ImageBox
            imageSrc={`${process.env.REACT_APP_CLOUDFRONT_URL}/contents/250f63f2aa774ad5bdb0676723e036ea/a5e8d3f2fccd418d8db2b4ee0f4c588a.jpg`}
            width={'580px'}
            height={'767px'}
          />
        </FlexBoxCenter>
      </Popup>
      <InnerButton
        width={'113px'}
        height={'20px'}
        style={{ padding: '0', borderRadius: '0' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <TextBox size={'12px'} weight={350}>
          서비스 가입사실 확인
        </TextBox>
      </InnerButton>
    </>
  );
};

export default PaymentGuaranteePopupButton;
