import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useNavigateBack from '@ecp/common/src/hooks/useNavigateBack';
import { FlexBoxCenter, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { PageButton } from '@ecp/common/src/components/button/Button';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import { ReactComponent as NotFoundIcon } from '@ecp/common/src/assets/icon/icon__error-not-found.svg';

const PageNotFoundPage = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  const handleGoBackClick = () => {
    navigateBack();
  };
  const handleGoMainClick = () => {
    navigate('/');
  };
  const handleGoCsClick = () => {
    navigate('/customer-service/faq');
  };

  return (
    <Container>
      <NotFoundIcon />
      <Spacing top={20} />
      <TextBox size={'28px'} weight={'500'}>
        요청하신 페이지를 찾을 수 없습니다.
      </TextBox>
      <Spacing top={10} />
      <TextBox size={'14px'} weight={'350'} style={{ textAlign: 'center' }}>
        방문하시려는 페이지 주소가 잘못 입력되었거나, <br />
        페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
        <br />
        입력하신 주소가 정확한지 다시 한번 확인 부탁 드립니다.
      </TextBox>
      <Spacing top={50} />
      <FlexBoxCenter gap={'10px'}>
        <PageButton type={'Default'} onClick={handleGoBackClick}>
          이전 페이지로 이동
        </PageButton>
        <PageButton type={'Primary'} onClick={handleGoMainClick}>
          메인으로 가기
        </PageButton>
      </FlexBoxCenter>
      <Spacing top={30} />
      <TextButton type={'move'} onClick={handleGoCsClick}>
        고객센터바로가기
      </TextButton>
    </Container>
  );
};

export default PageNotFoundPage;

const Container = styled(FlexBoxColumn)`
  height: 100vh;
  justify-content: center;
`;
