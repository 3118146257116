export const insertCartLog = async ({ itemList }) => {
  try {
    itemList?.forEach(({ goodNumber, goodName, brandNumber, brandName }) => {
      window?.Ntm?.Event?.fireUserDefined?.('insertcart', {
        prdm: `[${goodNumber}] ${goodName}`,
        brdm: `[${brandNumber}] ${brandName}`,
      });
    });
  } catch (error) {
    console.error(error);
  }
};

export const insertOrderLog = async ({ orderSuccessList }) => {
  try {
    orderSuccessList?.forEach(
      ({
        orderNumber, // 주문번호
        goodNumber, // 상품번호
        goodName, // 상품명
        brandNumber, // 브랜드번호
        brandName, // 브랜드명
        standardCategoryNumber, // 카테고리명
        orderQuantity, // 주문수량
        supplyPrice, // 공급가
        salePrice, // 판매단가
        totalDiscountAmount, // 총 할인 금액
        totalPayAmount, // 총결제금액
        totalPointAmount, // 총 포인트 금액
        payMeanName, // 결제수단명
        goodCouponNumber, // 상품쿠폰번호
        goodCouponName, // 상품쿠폰명
        cartCouponNumber, // 장바구니쿠폰번호
        cartCouponName, // 장바구니쿠폰명
        deliveryCouponNumber, // 배송비쿠폰번호
        deliveryCouponName, // 배송비쿠폰명
        goodCouponAmount, // 상품쿠폰금액
        cartCouponAmount, // 장바구니쿠폰금액
        deliCouponAmount, // 배송비쿠폰금액
        savePointAmount, // 적립금
        welPointAmount, // 웰포인트
        welfarePointAmount, // 복지포인트
      }) => {
        if (window.Ntm) {
          window.Ntm.Event.fireUserDefined?.('orderSuccess', {
            ord_id: orderNumber, //주문번호
            prdm: `[${goodNumber}] ${goodName}`, //상품코드
            brdm: `[${brandNumber}] ${brandName}`, //브랜드코드
            catecd: `[${standardCategoryNumber}]`, //카테고리코드
            cnt: orderQuantity, //수량
            cp_total: totalDiscountAmount, //쿠폰할인금액 합산
            pt_total: totalPointAmount, //포인트 합산
            pay_type: payMeanName, // 카드/이체/무통장/휴대폰결제/웰스토리페이/카카오페이/토스페이/네이버페이/SSG페이/삼성페이
            price: supplyPrice, //단가
            dis_price: salePrice, //판매가
            fin_price: totalPayAmount, //결제액
            cp_yn: goodCouponAmount + cartCouponAmount + deliCouponAmount > 0 ? 'Y' : 'N', //쿠폰적용여부 Y/N
            cp_prd: goodCouponNumber ? `[${goodCouponNumber}] ${goodCouponName}` : '', //'[쿠폰코드] 상품쿠폰명'
            cp_cart: cartCouponNumber ? `[${cartCouponNumber}] ${cartCouponName}` : '', //'[쿠폰코드] 장바구니쿠폰명'
            cp_dlvr: deliveryCouponNumber ? `[${deliveryCouponNumber}] ${deliveryCouponName}` : '', //'[쿠폰코드] 배송비쿠폰명'
            cp_prd_dis: goodCouponAmount, //상품쿠폰할인금액
            cp_cart_dis: cartCouponAmount, //장바구니쿠폰할인금액
            cp_dlvr_dis: deliCouponAmount, //배송비쿠폰할인금액
            pt_save: savePointAmount, //적립금
            pt_wel: welPointAmount, //웰포인트
            pt_welfare: welfarePointAmount, //복지포인트
          });
          console.log('orderSuccess_dom');
        } else {
          window.addEventListener('ntn_build', () => {
            window.Ntm.Event.fireUserDefined('orderSuccess', {
              ord_id: orderNumber, //주문번호
              prdm: `[${goodNumber}] ${goodName}`, //상품코드
              brdm: `[${brandNumber}] ${brandName}`, //브랜드코드
              catecd: `[${standardCategoryNumber}]`, //카테고리코드
              cnt: orderQuantity, //수량
              cp_total: totalDiscountAmount, //쿠폰할인금액 합산
              pt_total: totalPointAmount, //포인트 합산
              pay_type: payMeanName, // 카드/이체/무통장/휴대폰결제/웰스토리페이/카카오페이/토스페이/네이버페이/SSG페이/삼성페이
              price: supplyPrice, //단가
              dis_price: salePrice, //판매가
              fin_price: totalPayAmount, //결제액
              cp_yn: goodCouponAmount + cartCouponAmount + deliCouponAmount > 0 ? 'Y' : 'N', //쿠폰적용여부 Y/N
              cp_prd: goodCouponNumber ? `[${goodCouponNumber}] ${goodCouponName}` : '', //'[쿠폰코드] 상품쿠폰명'
              cp_cart: cartCouponNumber ? `[${cartCouponNumber}] ${cartCouponName}` : '', //'[쿠폰코드] 장바구니쿠폰명'
              cp_dlvr: deliveryCouponNumber ? `[${deliveryCouponNumber}] ${deliveryCouponName}` : '', //'[쿠폰코드] 배송비쿠폰명'
              cp_prd_dis: goodCouponAmount, //상품쿠폰할인금액
              cp_cart_dis: cartCouponAmount, //장바구니쿠폰할인금액
              cp_dlvr_dis: deliCouponAmount, //배송비쿠폰할인금액
              pt_save: savePointAmount, //적립금
              pt_wel: welPointAmount, //웰포인트
              pt_welfare: welfarePointAmount, //복지포인트
            });
            console.log('orderSuccess_listen');
          });
        }
      }
    );
  } catch (error) {
    console.error(error);
  }
};
