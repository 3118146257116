import React, { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxColumn,
  HorizontalDivider,
  ImageBox,
  VerticalDivider,
  FlexBoxATag,
} from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import StarRatingIcon from '@ecp/common/src/components/icon/StarRatingIcon';
import { BADGE_TYPE, PD_EVALUATION_DIVI } from '@ecp/common/src/const/good';
import adultPath from '@ecp/common/src/assets/icon/icon__adult-certification.svg';

import { withAdultCertification } from '@fo-recoil/common/user/selector';
import { historyState } from '@fo-recoil/common/atom';
import {useAlertDialog, useConfirmDialog} from '@fo-hooks/common/useModal';
import memberWishApi from '@fo-apis/member/memberWishApi';
import recopickApi from '@fo-apis/interface/recopickApi';
import GoodBadge from '@fo-components/good/GoodBadge';
import GoodBenefitChip from '@fo-components/good/GoodBenefitChip';
import { cardProps, goodCardPropTypes, goodPriceBoxPropTypes } from '@fo-const/good/goodCardConst';
import goodApi from "@fo-apis/good/goodApi";

const GoodOriginalPrice = ({ type, originalPrice, discountPrice }) => {
  const showOriginalPrice = useMemo(
    () => !!originalPrice && originalPrice > discountPrice,
    [discountPrice, originalPrice]
  );

  return (
    <>
      {showOriginalPrice && (
        <TextBox
          size={cardProps[type].size.originalPrice}
          lineHeight={cardProps[type].lineHeight.originalPrice}
          color={theme.color.text.disabled}
          decoration={'line-through'}
          hoverDecoration={'line-through'}
        >
          <span style={blind}>정가</span>
          {Number(originalPrice).toLocaleString()}원
        </TextBox>
      )}
    </>
  );
};

GoodOriginalPrice.propTypes = {
  type: PropTypes.string,
  originalPrice: PropTypes.number,
  discountPrice: PropTypes.number,
};

const blind = {
  overFlow: 'hidden',
  position: 'absolute',
  clip: 'rect(0,0,0,0)',
  clipPath: 'polygon(0 0, 0 0, 00)',
  width: '1px',
  height: '1px',
  margin: '-1px',
};

const GoodPriceBox = ({
  type,
  originalPrice,
  discountRate,
  discountPrice,
  'align-items': alignItems = 'flex-start',
}) => {
  const isHorizontalType = useMemo(() => type.includes('horizontal'), [type]);

  return (
    <FlexBoxColumn align-items={alignItems} width="100%">
      {!isHorizontalType && (
        <GoodOriginalPrice type={type} originalPrice={originalPrice} discountPrice={discountPrice} />
      )}
      <FlexBox gap={'10px'} align-items={'baseline'}>
        {!!discountRate && discountRate > 0 && (
          <TextBox
            size={cardProps[type].size.discountRate}
            lineHeight={cardProps[type].lineHeight.discountRate}
            weight={theme.font.weight.bold}
            color={theme.color.primary}
            align-items={'baseline'}
          >
            <span style={blind}>할인율</span>
            {discountRate}
            <TextBox size={cardProps[type].size.percent} weight={theme.font.weight.bold}>
              %
            </TextBox>
          </TextBox>
        )}
        <TextBox
          size={cardProps[type].size.discountPrice}
          lineHeight={cardProps[type].lineHeight.discountPrice}
          weight={theme.font.weight.bold}
          align-items={'baseline'}
        >
          <span style={blind}>판매가</span>
          {Number(discountPrice).toLocaleString()}
          <TextBox size={cardProps[type].size.won}>원</TextBox>
        </TextBox>
        {isHorizontalType && (
          <GoodOriginalPrice type={type} originalPrice={originalPrice} discountPrice={discountPrice} />
        )}
      </FlexBox>
    </FlexBoxColumn>
  );
};

GoodPriceBox.propTypes = {
  ...goodPriceBoxPropTypes,
  'align-items': PropTypes.string,
};

const StyledFullWidthFlexBoxColumn = styled(FlexBoxColumn)`
  flex-shrink: 0;
`;

const GoodTablePriceBox = (props) => {
  const { type, originalPrice, discountRate, discountPrice, 'align-items': alignItems = 'flex-end' } = props;
  return (
    <>
      <VerticalDivider height={cardProps[type].height} flex-shrink={'0'} />
      <StyledFullWidthFlexBoxColumn align-items={alignItems} width={cardProps[type].rightWidth}>
        {!!originalPrice && (
          <GoodOriginalPrice type={type} originalPrice={originalPrice} discountPrice={discountPrice} />
        )}
        <FlexBox gap={'10px'} align-items={'baseline'}>
          {!!discountRate && discountRate > 0 && (
            <TextBox
              size={cardProps[type].size.discountRate}
              lineHeight={cardProps[type].lineHeight.discountRate}
              weight={theme.font.weight.bold}
              color={theme.color.primary}
              align-items={'baseline'}
            >
              {discountRate}
              <TextBox size={cardProps[type].size.percent} weight={theme.font.weight.bold}>
                %
              </TextBox>
            </TextBox>
          )}
          <TextBox
            size={cardProps[type].size.discountPrice}
            lineHeight={cardProps[type].lineHeight.discountPrice}
            weight={theme.font.weight.bold}
            align-items={'baseline'}
          >
            {Number(discountPrice).toLocaleString()}
            <TextBox size={cardProps[type].size.won}>원</TextBox>
          </TextBox>
        </FlexBox>
      </StyledFullWidthFlexBoxColumn>
    </>
  );
};
GoodTablePriceBox.propTypes = {
  ...goodPriceBoxPropTypes,
  'align-items': PropTypes.string,
};

const GoodImage = ({ adultGood, soldOut, type, imgPath }) => {
  const isAdultVerified = useRecoilValue(withAdultCertification) || !adultGood;
  // const showAdultMessage = useMemo(() => !isAdultVerified && !!cardProps[type].size.adult, [isAdultVerified, type]);

  return (
    <FlexBox className={'good-card__image-box'}>
      <ImageBox
        clickable
        width={cardProps[type].imageWidth}
        height={cardProps[type].imageWidth}
        imageSrc={imgPath}
        radius={'8px'}
        border={`1px solid ${theme.color.line.basic}`}
        backgroundPositionY={'center'}
        justify-content={'center'}
        align-items={'center'}
      >
        {!isAdultVerified && (
          <ImageBox
            clickable
            width={cardProps[type].imageWidth}
            height={cardProps[type].imageWidth}
            imageSrc={adultPath}
            backgroundPositionY={'center'}
            backgroundPositionX={'center'}
            justify-content={'center'}
            align-items={'center'}
          ></ImageBox>
        )}
        {/* <TextBox
          size={cardProps[type].size.adult}
          padding={`calc(${cardProps[type].adultSize} + 6px) 0 0 0`}
          height={'21px'}
        >
          {!isAdultVerified ? '19세 이상만 구매 가능' : ''}
        </TextBox> */}
      </ImageBox>
      {soldOut && (
        <SoldOut height={cardProps[type]?.soldOutHeight}>
          <TextBox color={theme.color.text.white} weight={theme.font.weight.medium}>
            품절
          </TextBox>
        </SoldOut>
      )}
    </FlexBox>
  );
};

const SoldOut = styled(FlexBoxCenter)`
  position: absolute;
  background-color: rgba(20, 20, 21, 0.5);
  width: 100%;
  height: ${({ height }) => height};
  bottom: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

GoodImage.propTypes = {
  adultGood: PropTypes.bool,
  soldOut: PropTypes.bool,
  type: PropTypes.string,
  imgPath: PropTypes.string,
};

const GoodCardImage = ({ onClick, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, handleBestGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handelBestViewGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList, ...props }) => {
  const { showConfirmDialog } = useConfirmDialog();
  const navigate = useNavigate();

  const {
    type,
    itemDecorators,
    thumbnailImageUrl01,
    thumbnailImageUrl02,
    thumbnailImageUrl03,
    adultGood,
    goodNumber,
    wish,
    soldOut,
    clickLogLink,
    onWishClick,
    goodName,
    isEventMall,
  } = props;

  const history = useRecoilValue(historyState);

  const imgPath = useMemo(() => {
    switch (type) {
      case 'extraLarge':
        return thumbnailImageUrl01;
      case 'small':
      case 'medium':
      case 'large':
      case 'horizontalMedium':
      case 'horizontalLarge':
        return thumbnailImageUrl02 || thumbnailImageUrl01;
      case 'extraSmall':
      case 'horizontalExtraSmall':
      case 'horizontalSmall':
      case 'tableSmall':
      case 'tableMedium':
      case 'gridSmall':
      case 'gridMedium':
      default:
        return thumbnailImageUrl03 || thumbnailImageUrl02 || thumbnailImageUrl01;
    }
  }, [thumbnailImageUrl01, thumbnailImageUrl02, thumbnailImageUrl03, type]);

  const decorators = useMemo(() => {
    const itemDecoratorList = Array.isArray(itemDecorators) ? itemDecorators : [];
    return [
      ...itemDecoratorList,
      !isEventMall ? { type: BADGE_TYPE.FAVORITE, badgeContent: wish } : { type: null, badgeContent: null },
    ];
  }, [itemDecorators, wish]);

  const getChangeHandler = useCallback(
    (type) => {
      switch (type) {
        case BADGE_TYPE.FAVORITE:
          return async (wish) => {
            const { result: isWish } = await memberWishApi.setWish({ goodNumber, wish });
            if (isWish) {
              showConfirmDialog(
                '선택하신 상품이 위시리스트에 담겼습니다. 계속 쇼핑하시겠습니까?',
                () => {
                  navigate('/my-page/wishlist');
                  onWishClick?.();
                },
                () => {},
                '위시 가기',
                '계속 쇼핑하기'
              );
              if (goodNumber) {
                await recopickApi.createRecommendationItemLog('like', { goodNumber }, history?.prev, history?.current);
              }
            }
          };
        default:
          return null;
      }
    },
    [goodNumber, history, navigate, onWishClick, showConfirmDialog]
  );
  const { handleClick } = useGoodCardClick({ onClick, clickLogLink, props, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, handleBestGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handelBestViewGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList,});

  return (
    <FlexBoxATag
      width={cardProps[type].imageWidth}
      height={cardProps[type].imageWidth}
      onClick={handleClick}
      aria-label={goodName}
    >
      {decorators.reduce(
        (accumulator, item) => (
          <GoodBadge
            imageWidth={cardProps[type].imageWidth}
            cardType={type}
            {...item}
            title={goodName}
            onChange={getChangeHandler(item.type)}
          >
            {accumulator}
          </GoodBadge>
        ),
        <GoodImage adultGood={adultGood} soldOut={soldOut} type={type} imgPath={imgPath} />
      )}
    </FlexBoxATag>
  );
};

GoodCardImage.propTypes = {
  ...goodCardPropTypes,
};

const useGoodCardClick = ({ onClick, props, clickLogLink, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, handleBestGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handelBestViewGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { showAlertDialog } = useAlertDialog();
  const { goodNumber } = props;
  const handleClick = async (e) => {
    e.stopPropagation();
    e.preventDefault(); //이게 있어야 온클릭 a태그 클릭시 #이 url에 안생김

    if (onClick) {
      onClick(e, goodNumber);
    } else {
      if (clickLogLink) {
        const url = new URL(clickLogLink);
        const queryParams = new URLSearchParams(url?.search);
        const { code } = await goodApi.getStockCheck(goodNumber);

        if(code) {
          showAlertDialog("죄송합니다.\n해당 상품은 품절되었습니다.", () => {
            if(handleViewGoodSoldOutChangeList!== undefined) {  // main 많이 본 상품
              handleViewGoodSoldOutChangeList(state.displayGroupNumber);
            } else if(handleOrderGoodSoldOutChangeList !== undefined) { // main 많이 구매한 상품
              handleOrderGoodSoldOutChangeList(state.displayGroupNumber);
            } else if(handleBestGoodSoldOutChangeList !== undefined) { // 카테고리 화면
              handleBestGoodSoldOutChangeList();
            } else if(handleBestOrderGoodSoldOutChangeList !== undefined) { // best 구매Top
              handleBestOrderGoodSoldOutChangeList();
            } else if(handelBestViewGoodSoldOutChangeList !== undefined) {  // best 조회Top
              handelBestViewGoodSoldOutChangeList();
            } else if(handleSubDisplayCategoryGoodSoldOutChangeList !== undefined) {  // 카테고리 서브 카테고리 화면
              handleSubDisplayCategoryGoodSoldOutChangeList();
            }
          });
        } else {
          navigate(`/good/${goodNumber}?recopick=${queryParams.get('method')}&product_type=R`);
        }
      } else {
        const { code } = await goodApi.getStockCheck(goodNumber);

        if(code) {
          showAlertDialog("죄송합니다.\n해당 상품은 품절되었습니다.", () => {
            if(handleViewGoodSoldOutChangeList!== undefined) {  // main 많이 본 상품
              handleViewGoodSoldOutChangeList(state.displayGroupNumber);
            } else if(handleOrderGoodSoldOutChangeList !== undefined) { // main 많이 구매한 상품
              handleOrderGoodSoldOutChangeList(state.displayGroupNumber);
            } else if(handleBestGoodSoldOutChangeList !== undefined) {  // 카테고리 화면
              handleBestGoodSoldOutChangeList();
            } else if(handleBestOrderGoodSoldOutChangeList !== undefined) { // best 구매Top
              handleBestOrderGoodSoldOutChangeList();
            } else if(handelBestViewGoodSoldOutChangeList !== undefined) {  // best 조회Top
              handelBestViewGoodSoldOutChangeList();
            } else if(handleSubDisplayCategoryGoodSoldOutChangeList !== undefined) {  // 카테고리 서브 카테고리 화면
              handleSubDisplayCategoryGoodSoldOutChangeList();
            }
          });
        } else {
          navigate(`/good/${goodNumber}`);
        }
      }
    }
    if (clickLogLink) {
      await recopickApi.createRecommendationClickLog(clickLogLink);
    }
  };
  return { handleClick };
};

const GoodCard = ({ onClick, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, handleBestGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handelBestViewGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList, ...props }) => {
  const {
    type,
    goodName,
    goodNumber,
    originalPrice,
    discountRate,
    discountPrice,
    totalReviewScore,
    reviewCount,
    evaluationDivisionCode,
    buyCount,
    buyCountDisplay,
    flagList,
    hideBestFlag,
    clickLogLink,
    isEventMall,
    isPriceOpen,
  } = props;

  const priceBoxProps = {
    type,
    originalPrice,
    discountRate,
    discountPrice,
  };

  const filteredFlagList = useMemo(() => {
    return flagList?.filter((it) => !(hideBestFlag && it === 'BEST'));
  }, [flagList, hideBestFlag]);

  const isTableType = useMemo(() => type.includes('table'), [type]);
  const { handleClick } = useGoodCardClick({ onClick, clickLogLink, props, handleViewGoodSoldOutChangeList, handleOrderGoodSoldOutChangeList, handleBestGoodSoldOutChangeList, handleBestOrderGoodSoldOutChangeList, handelBestViewGoodSoldOutChangeList, handleSubDisplayCategoryGoodSoldOutChangeList });

  const showRating = useMemo(() => evaluationDivisionCode !== PD_EVALUATION_DIVI.UNAVAILABLE, [evaluationDivisionCode]);

  return (
    <>
      <GoodCardRoot type={type} onClick={handleClick} data-ds-contents={goodNumber}>
        <GoodCardImage type={type} handleViewGoodSoldOutChangeList={handleViewGoodSoldOutChangeList} handleOrderGoodSoldOutChangeList={handleOrderGoodSoldOutChangeList} handleBestGoodSoldOutChangeList={handleBestGoodSoldOutChangeList} handelBestViewGoodSoldOutChangeList={handelBestViewGoodSoldOutChangeList} handleBestOrderGoodSoldOutChangeList={handleBestOrderGoodSoldOutChangeList} handleSubDisplayCategoryGoodSoldOutChangeList={handleSubDisplayCategoryGoodSoldOutChangeList} {...props} />
        <FlexBoxColumn width={isTableType ? 'auto' : '100%'} flex={isTableType ? 1 : null} gap={'6px'}>
          <StyledTitle size={cardProps[type].size.goodName} lineHeight={cardProps[type].lineHeight.goodName}>
            {goodName}
          </StyledTitle>
          <Spacing top={4}></Spacing>
          {(isPriceOpen==undefined ? true : isPriceOpen) && !isTableType && <GoodPriceBox {...priceBoxProps} />}
          {totalReviewScore !== undefined && (showRating || buyCountDisplay) && (
            <FlexBox width={'100%'} height={'21px'} gap={'6px'}>
              {showRating && (
                <>
                  <StarRatingIcon size={'12px'} fillPercent={100} />
                  <TextBox
                    size={cardProps[type].size.totalReviewScore}
                    lineHeight={cardProps[type].lineHeight.totalReviewScore}
                    color={theme.color.text.guide}
                  >
                    <span style={blind}>별점 5점 만점 중 </span>
                    {totalReviewScore?.toFixed?.(1)}
                    <span style={blind}>리뷰 수</span>({reviewCount})
                  </TextBox>
                </>
              )}
              {showRating && buyCountDisplay && (
                <Spacing left={4} right={4}>
                  <VerticalDivider height={'13px'} />
                </Spacing>
              )}
              {buyCountDisplay && (
                <TextBox
                  size={cardProps[type].size.totalReviewScore}
                  lineHeight={cardProps[type].lineHeight.totalReviewScore}
                  color={theme.color.text.guide}
                >
                  구매 {Number(buyCount).toLocaleString()}
                </TextBox>
              )}
            </FlexBox>
          )}
          {!isEventMall && filteredFlagList?.length > 0 && (
            <FlexBox width={'100%'} gap={'4px'} flex-wrap={'wrap'}>
              {filteredFlagList?.map((item, index) => (
                <GoodBenefitChip key={index} item={item} />
              ))}
            </FlexBox>
          )}
        </FlexBoxColumn>
        {isTableType && <GoodTablePriceBox {...priceBoxProps} />}
      </GoodCardRoot>
      {isTableType && <HorizontalDivider width={cardProps[type].rootCardProp.width} />}
    </>
  );
};

GoodCard.propTypes = {
  ...goodCardPropTypes,
};

GoodCard.defaultProps = {
  itemDecorators: [],
  type: 'small',
};

export default GoodCard;

export const BasicGoodImage = ({ size = 'medium', ...props }) => {
  const type = useMemo(
    () =>
      ({
        extraSmall: 'gridSmall',
        small: 'horizontalExtraSmall',
        large: 'horizontalExtraSmall',
        medium: 'small',
      }[size]),
    [size]
  );

  return <GoodCardImage {...props} type={type} />;
};

BasicGoodImage.propTypes = {
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large', '']),
  src: PropTypes.string,
  adultGood: PropTypes.bool,
};

const GoodCardRoot = styled(FlexBox)`
  height: 100%;
  gap: 14px;
  ${({ type }) => cardProps[type].rootCardProp};
  cursor: pointer;
`;

const StyledTitle = styled.div`
  width: 100%;
  height: max-content;
  font-size: ${({ size }) => `${size}px`};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${theme.color.text.basic};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
