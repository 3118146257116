export const getBase64ImageInfo = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const img = new Image();
      img.onload = () => {
        resolve({ url: reader.result, width: img.width, height: img.height });
      };
      img.src = reader.result;
    };
    reader.onerror = (err) => reject(err);
  });

export const getFilePath = (filePath) => {
  if (!filePath) return '';
  return `${process.env.REACT_APP_CLOUDFRONT_URL}/${filePath.startsWith('/') ? filePath.substring(1) : filePath}`;
};
