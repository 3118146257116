import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';

import { FloatingOverlay, FloatingPortal } from '@floating-ui/react';

import { zIndex } from '@ecp/common/src/style/constant';

const InvisibleLoading = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    <FloatingPortal>
      {promiseInProgress && (
        <FloatingOverlay
          style={{
            display: 'grid',
            background: 'transparent',
            placeItems: 'center',
            zIndex: zIndex.spinner,
          }}
        />
      )}
    </FloatingPortal>
  );
};

export default InvisibleLoading;
