import { useCallback, useRef, useState } from 'react';

const useHover = () => {
  const hoverRef = useRef(null);
  const [value, setValue] = useState(false);

  const handleMouseEnter = useRef(() => setValue(true));
  const handleMouseLeave = useRef(() => setValue(false));

  const callbackRef = useCallback(
    (node) => {
      if (hoverRef.current) {
        hoverRef.current.removeEventListener('mouseover', handleMouseEnter.current);
        hoverRef.current.removeEventListener('mouseout', handleMouseLeave.current);
      }

      hoverRef.current = node;

      if (hoverRef.current) {
        hoverRef.current.addEventListener('mouseover', handleMouseEnter.current);
        hoverRef.current.addEventListener('mouseout', handleMouseLeave.current);
      }
    },
    [handleMouseEnter, handleMouseLeave]
  );

  return [callbackRef, value];
};

export default useHover;
