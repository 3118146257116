import React from 'react';

import WpayPaymentAuthComp from '@ecp/common/src/components/interface/WpayPaymentAuthComp';

import { useAlertDialog } from '@fo-hooks/common/useModal';
import wpayApi from '@fo-apis/interface/wpayApi';

const WpayPaymentAuthPage = () => {
  const { showAlertDialog } = useAlertDialog();
  return <WpayPaymentAuthComp wpayApi={wpayApi} showAlertDialog={showAlertDialog} />;
};

export default WpayPaymentAuthPage;
