import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { textEllipsis } from '@ecp/common/src/style/theme/defaultStyled';

import { ClickableCss, FlexBox, flexPropTypes } from '../layouts/flex/styled';
import { overrideProperties } from '../style/utils';
import { backgroundPropTypes, fontPropTypes } from '../style/propTypes';
import theme from '../style/theme/default';

export const textBoxProps = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  decoration: PropTypes.string,
  hoverColor: PropTypes.string,
  hoverDecoration: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  underlineOnHover: PropTypes.bool,
  required: PropTypes.bool,
  asteriskPosition: PropTypes.string,
  ellipsis: PropTypes.bool,
  wordBreak: PropTypes.string,
  wordSpacing: PropTypes.string,
  ariaHidden: PropTypes.string,
  ...flexPropTypes,
};

const leftAsterisk = css`
  align-items: flex-start;
  &:before {
    content: '*';
    color: #f22214;
  }
`;

const rightAsterisk = css`
  align-items: flex-start;
  &:after {
    content: '*';
    color: #f22214;
  }
`;

const ellipsisCss = css`
  display: inline-block;
  ${textEllipsis};
`;

const StyledTextBox = styled(FlexBox)`
  ${overrideProperties(fontPropTypes, {
    'font-size': `${theme.font.size.basic}`,
    'font-weight': 300,
    color: 'inherit',
    'text-decoration': 'none',
    'line-height': 'inherit',
  })}

  ${overrideProperties(backgroundPropTypes, {})}
  ${ClickableCss};
  white-space: pre-wrap;
  ${({ required, asteriskPosition }) => required && (asteriskPosition === 'left' ? leftAsterisk : rightAsterisk)};
  ${({ ellipsis }) => ellipsis && ellipsisCss};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ wordBreak }) => wordBreak && `word-break: ${wordBreak}`};
  ${({ wordSpacing }) => wordSpacing && `word-spacing: ${wordSpacing}`};

  &:hover {
    text-decoration: ${({ hoverDecoration }) => (hoverDecoration ? hoverDecoration : 'none')};
    font-weight: ${({ hoverFontWeight }) => (hoverFontWeight ? hoverFontWeight : 'none')};
    ${({ hoverColor }) => hoverColor && { color: hoverColor }};
  }
`;

export const TextBox = React.forwardRef(
  (
    {
      size,
      weight = 300,
      height,
      color,
      hoverColor,
      decoration,
      required,
      children,
      asteriskPosition = 'right',
      ellipsis,
      ariaHidden,
      ...props
    },
    ref
  ) => {
    return (
      <StyledTextBox
        ref={ref}
        font-size={size}
        font-weight={weight.toString()}
        line-height={height}
        color={color}
        hoverColor={hoverColor}
        text-decoration={decoration}
        required={required}
        asteriskPosition={asteriskPosition}
        ellipsis={ellipsis}
        aria-hidden={ariaHidden}
        {...props}
      >
        {children}
      </StyledTextBox>
    );
  }
);

TextBox.displayName = 'TextBox';

TextBox.propTypes = {
  ...textBoxProps,
};

TextBox.defaultProps = {
  height: 'inherit',
  asteriskPosition: 'right',
};

export const TextBoxAlignLeft = styled(TextBox)`
  text-align: left;
  justify-content: flex-start;
`;

export const TextBoxAlignCenter = styled(TextBox)`
  text-align: center;
  justify-content: center;
`;

export const TextBoxAlignRight = styled(TextBox)`
  text-align: right;
  justify-content: flex-end;
`;

export const TextBoxHeight = styled(TextBox)`
  height: 100%;
`;
