import React from 'react';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { SwiperEdgeBanner, SwiperMainDefaultProps } from '@ecp/common/src/components/swiper/SwiperTemplate';
import { SwiperBannerPopupItem } from '@ecp/common/src/components/swiper/component/SwiperItem';
import { MAIN_FUNCTION_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import { stripBannerAllianceState } from '@fo-recoil/banner/atom';
import useBanner from '@fo-hooks/display/useBanner';

const AllianceBannerSwiper = ({ width, height, handleDrawerClose }) => {
  const [bannerItemList, setBannerItemList] = useRecoilState(stripBannerAllianceState);

  const { mainFunction: { mainFunctionUse } = {}, handleClick } = useBanner(
    MAIN_FUNCTION_CODE.ALLIANCE_BANNER,
    bannerItemList,
    setBannerItemList
  );

  return (
    mainFunctionUse &&
    bannerItemList.length > 0 && (
      <FlexBoxColumn align-items={'flex-start'}>
        <Spacing top={30} />
        <TitleTexBox>제휴 혜택</TitleTexBox>
        <SubTitleTextBox>임직원 분들께 드리는 제휴 혜택입니다.</SubTitleTextBox>
        <Spacing top={14} />
        <SwiperEdgeBanner
          itemList={bannerItemList}
          width={width}
          height={height}
          itemComponent={SwiperBannerPopupItem}
          onClick={(e, index, item) => {
            handleClick(e, index, item);
            handleDrawerClose();
          }}
          carouselProps={{ ...SwiperMainDefaultProps.carouselProps }}
          arrowProps={{ type: '15', position: 'inner', padding: '14px' }}
          itemProps={{ width, height }}
        />
      </FlexBoxColumn>
    )
  );
};

AllianceBannerSwiper.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  handleDrawerClose: PropTypes.func,
};

AllianceBannerSwiper.defaultProps = {
  width: '380px',
  height: '160px',
};

const TitleTexBox = styled(TextBox)`
  font-size: 21px;
  font-weight: 500;
  line-height: 160%;
`;

const SubTitleTextBox = styled(TextBox)`
  font-size: 15px;
  font-weight: 350;
  line-height: 160%;
`;

export default AllianceBannerSwiper;
