import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import theme from '@ecp/common/src/style/theme/default';
import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import { zIndex } from '@ecp/common/src/style/constant';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxColumn,
  HorizontalDivider,
  VerticalDivider,
} from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { NoneDecorationLink } from '@ecp/common/src/text/TextLink';
import Paper from '@ecp/common/src/components/paper/Paper';
import { ReactComponent as Arrow } from '@ecp/common/src/assets/icon/icon__arrow--right.svg';

import { withMainCategoryTreeList } from '@fo-recoil/category/selector';
import { categoryExhibitionListMapState } from '@fo-recoil/category/atom';
import Exhibition from '@fo-components/exhibition/Exhibition';
import SuspenseSpinner from '@fo-components/common/SuspenseSpinner';

const CategoryBoard = ({ sticky, gnbList, onClose }) => {
  const mainCategories = useRecoilValue(withMainCategoryTreeList);
  const [largeCategory, setLargeCategory] = useState(mainCategories[0]);

  const handleSelect = useCallback(async (selectedLargeCategory) => {
    setLargeCategory(selectedLargeCategory);
  }, []);

  return (
    <RemoveScroll>
      <CategoryOverlay style={{ position: sticky && 'fixed', top: sticky && '80px' }}>
        <>
          <CategoryWrapper>
            <FlexBox width={'1030px'} gap={'30px'} height={'100%'} padding={'12px 20px'} align-items={'flex-start'}>
              <CategoryList isLargeCategory categoryList={mainCategories} onSelect={handleSelect} onClick={onClose} />
            </FlexBox>
            <VerticalDivider />
            <FlexBox width={'509px'} height={'100%'} padding={'30px 0 0 50px'}>
              <Paper closable width={'100%'} height={'100%'} padding={'50px 0 0 0'} onClose={onClose}>
                <TextBox size={theme.font.size.medium} weight={theme.font.weight.medium}>
                  카테고리 BEST 기획전
                </TextBox>
                <Spacing top={14} />
                <Suspense
                  fallback={
                    <SuspenseSpinner
                      width={'380px'}
                      height={'380px'}
                      spinnerProps={{ width: '40px', height: '40px' }}
                    />
                  }
                >
                  <CategoryExhibition largeCategory={largeCategory} hideCategory={onClose} />
                </Suspense>
              </Paper>
            </FlexBox>
          </CategoryWrapper>
          <HorizontalDivider minWidth={'1540px'} />
          <GnbMenuWrapper>
            <FlexBox width={'1540px'} height={'50px'} padding={'0 20px'}>
              {gnbList.map((gnbMenu, index) => (
                <React.Fragment key={gnbMenu.title + index}>
                  {index !== 0 && (
                    <>
                      <Spacing left={'14px'} />
                      <VerticalDivider height={'14px'} />
                      <Spacing left={'14px'} />
                    </>
                  )}
                  <MenuText to={gnbMenu.link} onClick={onClose}>
                    {gnbMenu.title}
                  </MenuText>
                </React.Fragment>
              ))}
            </FlexBox>
          </GnbMenuWrapper>
        </>
      </CategoryOverlay>
    </RemoveScroll>
  );
};

CategoryBoard.propTypes = {
  sticky: PropTypes.bool,
  gnbList: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const CategoryExhibition = ({ largeCategory, hideCategory }) => {
  const navigate = useNavigate();
  const categoryExhibitionListMap = useRecoilValue(categoryExhibitionListMapState);

  const firstCategoryExhibition = useMemo(() => {
    const [firstExhibition] = categoryExhibitionListMap?.[largeCategory.categoryNumber] || [{}];
    return { ...firstExhibition };
  }, [categoryExhibitionListMap, largeCategory.categoryNumber]);

  const item = useMemo(() => {
    const { exhibitionNumber, badgeName01, badgeName02, exhibitionName, exhibitionSubName, commonImageUrl } =
      firstCategoryExhibition;
    let displayTypes = [];
    if (badgeName01) {
      displayTypes.push({ name: badgeName01 });
    }
    if (badgeName02) {
      displayTypes.push({ name: badgeName02 });
    }

    return {
      exhibitionNumber,
      title: exhibitionName,
      subTitle: exhibitionSubName,
      src: commonImageUrl,
      displayTypes,
    };
  }, [firstCategoryExhibition]);

  const handleExhibitionClick = useCallback(() => {
    navigate(`/exhibition/${firstCategoryExhibition.exhibitionNumber}`);
    hideCategory();
  }, [firstCategoryExhibition.exhibitionNumber, hideCategory, navigate]);

  return (
    <Exhibition
      data-ds-label2={'g_ctg_best'}
      width={'380px'}
      chipPlacement={'bottom'}
      itemProps={{ exhibitionNumber: item.exhibitionNumber, width: '380px', height: '380px', radius: '8px' }}
      item={item}
      onClick={handleExhibitionClick}
    />
  );
};

CategoryExhibition.propTypes = {
  largeCategory: PropTypes.object,
  hideCategory: PropTypes.func,
};

const CategoryList = ({ isLargeCategory, categoryList, onSelect, onClick }) => {
  const [selectedCategory, setSelectedCategory] = useState(categoryList[0]);

  const showSubCategories = useMemo(
    () =>
      !!selectedCategory?.subList?.length &&
      categoryList.find(({ categoryNumber }) => selectedCategory?.categoryNumber === categoryNumber),
    [categoryList, selectedCategory?.categoryNumber, selectedCategory?.subList?.length]
  );

  const handleHover = (category) => {
    setSelectedCategory(category);
    handleSelect(category);
  };

  const handleSelect = (category) => {
    onSelect?.(category);
  };

  return (
    <>
      <CategoryListWrapper>
        {categoryList.map((category, index) => (
          <CategoryItem
            isLargeCategory={isLargeCategory}
            key={category.categoryNumber + index}
            category={category}
            selected={selectedCategory?.categoryNumber === category.categoryNumber}
            onHover={handleHover}
            onClick={onClick}
          />
        ))}
      </CategoryListWrapper>
      {showSubCategories && (
        <CategoryList key={selectedCategory.categoryNumber} categoryList={selectedCategory.subList} onClick={onClick} />
      )}
    </>
  );
};

CategoryList.propTypes = {
  isLargeCategory: PropTypes.bool,
  categoryList: PropTypes.array,
  onSelect: PropTypes.func,
  onClick: PropTypes.func,
};

const CategoryItem = ({ isLargeCategory, category, selected, onHover, onClick }) => {
  const { categoryNumber, categoryName, subList = [] } = category;
  const navigate = useNavigate();

  const textStyle = useMemo(
    () =>
      selected
        ? { color: theme.color.text.selected, weight: theme.font.weight.medium }
        : { color: theme.color.text.basic, weight: theme.font.weight.demiLight },
    [selected]
  );

  const handleHover = () => {
    onHover?.(category);
  };

  const handleClick = () => {
    navigate(`/category/${isLargeCategory ? '' : 'sub/'}${categoryNumber}`);
    onClick?.();
  };

  return (
    <FlexBox width={'210px'}>
      <Spacing left={10} />
      <TextBox
        clickable
        width={'165px'}
        height={'28px'}
        {...textStyle}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        {categoryName}
      </TextBox>
      {!!subList.length && selected && <Arrow width={'5px'} height={'8px'} color={theme.color.primary} />}
    </FlexBox>
  );
};

CategoryItem.propTypes = {
  category: PropTypes.shape({
    categoryNumber: PropTypes.string,
    categoryName: PropTypes.string,
    subList: PropTypes.array,
  }),
  isLargeCategory: PropTypes.bool,
  selected: PropTypes.bool,
  onHover: PropTypes.func,
  onClick: PropTypes.func,
};

const CategoryOverlay = styled(FloatingOverlay)`
  width: 100vw;
  height: 100vh;
  min-width: 1630px;
  background: rgba(25, 25, 25, 0.8);
  z-index: ${zIndex.appBar};
`;

const CategoryWrapper = styled(Paper)`
  ${flexCenter};
  align-items: flex-start;
  width: 100%;
  min-height: 300px;
  min-width: 1540px;
  height: 620px;
  background-color: ${theme.color.background.white};
`;

const GnbMenuWrapper = styled(FlexBoxCenter)`
  background-color: ${theme.color.background.basic};
  width: 100%;
  min-width: 1540px;
`;

const CategoryListWrapper = styled(FlexBoxColumn)`
  align-items: flex-start;
  height: 100%;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.color.line.background};
  }
`;

const MenuText = styled(NoneDecorationLink)`
  position: relative;
  cursor: pointer;
  color: ${theme.color.text.sub};
  font-weight: ${theme.font.weight.medium};
`;

export default CategoryBoard;
