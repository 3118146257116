import { useCallback, useRef, useState } from 'react';

const useControlled = ({ value: valueProp, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);
  const { current: isControlled } = useRef(valueProp !== undefined);
  const returnValue = isControlled ? valueProp : value;

  const setValueWhenUncontrolled = useCallback((newValue) => !isControlled && setValue(newValue), [isControlled]);
  return { value: returnValue, setValueWhenUncontrolled };
};

export default useControlled;
