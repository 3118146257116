import PriceIcon from '@ecp/common/src/assets/icon/icon__benefit-price.svg';
import MembershipIcon from '@ecp/common/src/assets/icon/icon__benefit-membership.svg';
import CouponIcon from '@ecp/common/src/assets/icon/icon__benefit-coupon.svg';
import ReviewIcon from '@ecp/common/src/assets/icon/icon__benefit-review.svg';
import ExhibitionIcon from '@ecp/common/src/assets/icon/icon__benefit-exhibition.svg';
import EventIcon from '@ecp/common/src/assets/icon/icon__benefit-event.svg';

export const BENEFIT_TYPE = {
  PRICE: 'PRICE',
  MEMBERSHIP: 'MEMBERSHIP',
  COUPON: 'COUPON',
  REVIEW: 'REVIEW',
  EXHIBITION: 'EXHIBITION',
  EVENT: 'EVENT',
};

export const BENEFIT_PROPERTY = {
  PRICE: {
    label: '상시혜택',
    imageSrc: PriceIcon,
    title: '더 좋은 혜택',
    content: `더 낮은 가격으로 임직원을
위한 혜택가 기본 제공`,
  },
  MEMBERSHIP: {
    label: '월별혜택',
    imageSrc: MembershipIcon,
    title: '멤버십 혜택',
    content: `매월 1일 등급별 쿠폰 및
구매 적립금 혜택 제공`,
    navigateUrl: '/my-page/membership',
  },
  COUPON: {
    label: '조건혜택',
    imageSrc: CouponIcon,
    title: '할인쿠폰',
    content: `첫 구매, 생일, 상품, 
장바구니 쿠폰 등 다양한
할인 쿠폰 제공`,
    navigateUrl: '/my-page/coupon',
  },
  REVIEW: {
    label: '참여혜택',
    imageSrc: ReviewIcon,
    title: '리뷰 작성 적립금',
    content: `리뷰 작성 시 주문 상품당
최대 150원 적립금,  프리미엄
상품평 채택 시 추가 적립금
1000원+스벅 아메리카노 혜택`,
    navigateUrl: '/my-page/review',
  },
  EXHIBITION: {
    label: '기획혜택',
    imageSrc: ExhibitionIcon,
    title: '특별한 기획전',
    content: `웰스토리 몰 특급 MD들이
엄선해서 진행하는 다양한
상품 기획전`,
    navigateUrl: '/exhibition',
  },
  EVENT: {
    label: '참여혜택',
    imageSrc: EventIcon,
    title: '다양한 이벤트',
    content: `쿠폰, 적립금을 받을 수 있는
다양한 이벤트`,
    navigateUrl: '/event',
  },
};

export const MAX_REVIEW_SAVE_MONEY = 150;
