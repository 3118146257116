import React from 'react';
import PropTypes from 'prop-types';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxSpaceBetween, VerticalDivider } from '@ecp/common/src/layouts/flex/styled';
import TextLink from '@ecp/common/src/text/TextLink';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import { WELSTORY_URL } from '@ecp/common/src/const/footer';
import { ReactComponent as FaqIcon } from '@ecp/common/src/assets/icon/icon__faq.svg';
import { ReactComponent as InquiryIcon } from '@ecp/common/src/assets/icon/icon__inquiry.svg';

import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import PolicyPopupButton from '@fo-components/system/PolicyPopupButton';
import {
  ADDITIONAL_TYPE_CD_PRIVACY,
  ADDITIONAL_TYPE_CD_TERM,
  PRIVACY_POLICY_TITLE,
  TERM_OF_USE_TITLE,
} from '@fo-components/system/const';
import AppDownloadPopupButton from '@fo-pages/home/footer/popup/AppDownloadPopupButton';
import EmailCollectionPopupButton from '@fo-pages/home/footer/popup/EmailCollectionPopupButton';
import BulkOrderPopupButton from './popup/bulkOrder/BulkOrderPopupButton';

const FooterHeader = ({ showInquiry }) => {
  const {
    isMultimall,
    systemInformation: { footerAppDownloadUseYn },
  } = useSiteInfo();
  const handleWelstorySiteClick = () => {
    window.open(WELSTORY_URL);
  };

  return (
    <FlexBoxSpaceBetween width={'1540px'} height={'56px'}>
      <FlexBox gap={'20px'} data-ds-label2={'f_info'}>
        {!isMultimall && <BulkOrderPopupButton isIcon={false} />}
        <PolicyPopupButton
          title={TERM_OF_USE_TITLE}
          additionalTypeCd={ADDITIONAL_TYPE_CD_TERM}
          buttonProps={{ weight: '350' }}
          textColor={{ color: 'black' }}
        />
        <PolicyPopupButton
          title={PRIVACY_POLICY_TITLE}
          additionalTypeCd={ADDITIONAL_TYPE_CD_PRIVACY}
          textColor={{ color: theme.color.primary }}
        />
        <EmailCollectionPopupButton />
        <TextButton textProps={{ weight: '350' }} onClick={handleWelstorySiteClick}>
          <a href="https://www.samsungwelstory.com/" target="_blank" rel="noreferrer" title="새 창 열림">
            기업사이트
          </a>
        </TextButton>
        {!isMultimall && footerAppDownloadUseYn === 'Y' && <AppDownloadPopupButton />}
      </FlexBox>
      {showInquiry && (
        <FlexBox gap={'14px'} width={'310px'} data-ds-label2={'f_cs'}>
          <TextLink gap={'6px'} to={'/customer-service/faq'}>
            <FaqIcon />
            FAQ
          </TextLink>
          <VerticalDivider height="14px" />
          <TextLink gap={'6px'} to={'/customer-service/qna'}>
            <InquiryIcon />
            1:1문의
          </TextLink>
        </FlexBox>
      )}
    </FlexBoxSpaceBetween>
  );
};

export default FooterHeader;

FooterHeader.propTypes = {
  showInquiry: PropTypes.bool,
};

FooterHeader.defaultProps = {
  showInquiry: true,
};
