import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { paddingPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import RadioUnstyled, { radioClassNames } from '@ecp/common/src/components/unstyled/input/RadioUnstyled';
import InputRadioUnstyled from '@ecp/common/src/components/unstyled/input/InputRadioUnstyled';

export const InputComponent = styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(0,0,0,0);
  width: 1px;
  height: 1px;
  margin: -1px;
`;

export const LabelComponent = styled.label`
  &:before {
    content: '';
    background: ${theme.color.background.white};
    border-radius: 100%;
    border: 2px solid ${theme.color.line.disabled};
    display: inline-block;
    width: 22px;
    height: 22px;
    position: relative;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
    vertical-align: middle;
    box-sizing: border-box;
  }

  ${({ hideControl }) => {
    if (hideControl) {
      return {
        '&:before': {
          display: 'none',
        },
      };
    }
  }}
  &.${radioClassNames.checked} {
    &:before {
      background-color: ${theme.color.primary};
      border: 2px solid ${theme.color.primary};
      box-shadow: inset 0 0 0 4px white;
    }
  }

  &.${radioClassNames.checked}.${radioClassNames.disabled} {
    &:before {
      box-shadow: inset 0 0 0 4px white;
      background: #bdbdbd;
    }
  }

  &.${radioClassNames.disabled} {
    &:before {
      background: #a3a3a3;
      border-color: #bdbdbd;
    }
  }
`;

export const Radio = (props) => {
  const { children } = props;
  return (
    <RadioUnstyled inputComponent={InputComponent} labelComponent={LabelComponent} {...props}>
      {children}
    </RadioUnstyled>
  );
};

Radio.displayName = 'Radio';

Radio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hideControl: PropTypes.bool,
  statusName: PropTypes.string,
  productName: PropTypes.string,
  price: PropTypes.string,
  numberWrap: PropTypes.string,
};

export const InputRadio = (props) => {
  const { children } = props;
  return (
    <InputRadioUnstyled inputComponent={InputComponent} labelComponent={LabelComponent} {...props}>
      {children}
    </InputRadioUnstyled>
  );
};

InputRadio.displayName = 'InputRadio';

InputRadio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hideControl: PropTypes.bool,
  statusName: PropTypes.string,
  productName: PropTypes.string,
  price: PropTypes.string,
  numberWrap: PropTypes.string,
};

export const DefaultRadioButtonDefaultSize = {
  height: '40px',
  width: '94px',
};

const StyledRadioButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.color.background.white};
  ${overrideProperties(paddingPropTypes, { padding: '10px 14px' })};
  cursor: pointer;
  position: relative;

  &:before {
    border: 1px solid ${theme.color.line.background};
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    display: table;
    border-collapse: collapse;
  }

  &.${radioClassNames.disabled} {
    cursor: default;
  }

  ${overrideProperties(sizePropTypes, DefaultRadioButtonDefaultSize)};

  &.${radioClassNames.checked} {
    color: ${theme.color.primary};
    background: ${theme.color.background.white};
    font-weight: ${theme.font.weight.medium};

    &:before {
      border: 1px solid ${theme.color.primary};
      content: '';
      position: absolute;
      height: 100%;
      z-index: 2;
      display: table;
      border-collapse: collapse;
    }
  }
`;

export const DefaultRadioButton = (props) => <StyledRadioButton {...props} />;

DefaultRadioButton.displayName = 'DefaultRadioButton';

DefaultRadioButton.propTypes = {
  ...sizePropTypes,
};

const StyledNewRadioButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${theme.color.background.basic};
  ${overrideProperties(paddingPropTypes, { padding: '10px 14px' })};
  cursor: pointer;
  position: relative;

  &:before {
    border: 1px solid ${theme.color.line.background};
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    display: table;
    border-collapse: collapse;
  }

  &.${radioClassNames.disabled} {
    cursor: default;
  }

  ${overrideProperties(sizePropTypes, DefaultRadioButtonDefaultSize)};

  &.${radioClassNames.checked} {
    color: ${theme.color.text.white};
    background: ${theme.color.sub_darkgray};
    font-weight: ${theme.font.weight.medium};
    text-decoration: underline 2px;
    text-underline-offset: 3px;

    &:before {
      border: 1px solid ${theme.color.sub_darkgray};
      content: '';
      position: absolute;
      height: 100%;
      z-index: 2;
      display: table;
      border-collapse: collapse;
    }
  }
`;

export const DefaultNewRadioButton = (props) => <StyledNewRadioButton {...props} />;

DefaultNewRadioButton.displayName = 'DefaultNewRadioButton';

DefaultNewRadioButton.propTypes = {
  ...sizePropTypes,
};

export const LabelRadio = (props) => {
  const { children } = props;
  return (
    <RadioUnstyled inputComponent={LabelRadioInputComponent} labelComponent={LabelRadioLabelComponent} {...props}>
      {children}
    </RadioUnstyled>
  );
};

LabelRadio.displayName = 'LabelRadio';

LabelRadio.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hideControl: PropTypes.bool,
  statusName: PropTypes.string,
  productName: PropTypes.string,
  price: PropTypes.string,
  numberWrap: PropTypes.string,
};

export const LabelRadioInputComponent = styled.input`
  position: absolute;
  z-index: -1;
`;

export const LabelRadioLabelComponent = styled.label`
  &:before {
    content: '';
    background: ${theme.color.background.white};
    border-radius: 100%;
    border: 2px solid ${theme.color.line.disabled};
    display: inline-block;
    width: 22px;
    height: 22px;
    position: relative;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
    vertical-align: middle;
    box-sizing: border-box;
  }

  ${({ hideControl }) => {
    if (hideControl) {
      return {
        '&:before': {
          //display: 'none',
        },
      };
    }
  }}
  &.${radioClassNames.checked} {
    &:before {
      background-color: ${theme.color.primary};
      border: 2px solid ${theme.color.primary};
      box-shadow: inset 0 0 0 4px white;
    }
  }

  &.${radioClassNames.checked}.${radioClassNames.disabled} {
    &:before {
      box-shadow: inset 0 0 0 4px white;
      background: #bdbdbd;
    }
  }

  &.${radioClassNames.disabled} {
    &:before {
      background: #a3a3a3;
      border-color: #bdbdbd;
    }
  }

  display: flex;
`;
