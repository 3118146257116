import { getYear, getMonth } from 'date-fns';

import { CUSTOM_EMAIL_DOMAIN, EMAIL_DOMAIN } from '@ecp/common/src/const/email';

export const extractDataProps = (props) =>
  Object.keys(props).reduce((accumulator, key) => {
    if (key.startsWith('data-')) {
      return {
        ...accumulator,
        [key]: props[key],
      };
    }

    return accumulator;
  }, {});

export const getEmailIdAndDomain = (email) => {
  const emailValue = email ?? '';
  const [id = '', domainValue = ''] = emailValue.split('@');

  const predefined = EMAIL_DOMAIN.filter(({ value }) => value === domainValue);
  const domain = predefined.length > 0 ? predefined[0] : { ...CUSTOM_EMAIL_DOMAIN, value: domainValue };

  return [id, domain];
};

export const NumberFormat = (value) => {
  const numberFormatter = new Intl.NumberFormat();
  if (!value || isNaN(value) || isNaN(parseFloat(value))) {
    return numberFormatter.format(0);
  }
  return numberFormatter.format(value); // "1,234,567,890"
};

export const getPercentSize = (size = '') => {
  return size.includes('%') ? '100%' : size;
};

export const fileSizeFormat = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${units[i]}`;
};

export const onCopyToClipboard = async (text, setCopiedText) => {
  if (!navigator?.clipboard) {
    console.warn('Clipboard not supported.(http):', text);
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    return true;
  }
  try {
    await navigator.clipboard.writeText(text);
    setCopiedText?.(text);
    return true;
  } catch (error) {
    console.warn('Copy failed', error);
    setCopiedText?.(null);
    return false;
  }
};

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const SELECT_MENU_KEY = 'select_menu_key';
const ADULT_VERIFIED_KEY = 'isAdultVerified';

export const saveLocalStorageItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageItem = (key) => {
  return localStorage.getItem(key);
};

export const removeLocalStorageItem = (key) => {
  localStorage.removeItem(key);
};

export const saveSessionStorageItem = (key, value) => {
  sessionStorage.setItem(key, value);
};

export const getSessionStorageItem = (key) => {
  return sessionStorage.getItem(key);
};

export const getAccessToken = () => {
  return getLocalStorageItem(ACCESS_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return getLocalStorageItem(REFRESH_TOKEN_KEY);
};

export const saveAccessTokens = (accessToken, refreshToken) => {
  saveLocalStorageItem(ACCESS_TOKEN_KEY, accessToken);
  saveLocalStorageItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const clearAccessTokens = () => {
  removeLocalStorageItem(ACCESS_TOKEN_KEY);
  removeLocalStorageItem(REFRESH_TOKEN_KEY);
  removeLocalStorageItem(ADULT_VERIFIED_KEY);
};

export const saveSelectMenu = (menuItem) => {
  removeLocalStorageItem(SELECT_MENU_KEY);
  saveLocalStorageItem(SELECT_MENU_KEY, menuItem);
};

export const getSelectMenu = () => {
  return JSON.parse(getLocalStorageItem(SELECT_MENU_KEY));
};

export const clearSelectMenu = () => {
  return removeLocalStorageItem(SELECT_MENU_KEY);
};

export const getContentUrl = (groupId, fileName) => {
  let host = window.location.origin;
  if (host.indexOf('localhost') >= 0) host = 'https://devbo.welstorymall.com';

  return `${host}/contents/${groupId}/${fileName}`;
};

export const getContentUrlWithFilePath = (filePath) => {
  let host = window.location.origin;
  if (host.indexOf('localhost') >= 0) host = 'https://devbo.welstorymall.com';

  return `${host}/${filePath}`;
};

export const getFileUrl = (path, groupId, fileName) => {
  let host = window.location.origin;
  if (host.indexOf('localhost') >= 0) host = 'https://devbo.welstorymall.com';

  return `${host}/${path}/${groupId}/${fileName}`;
};

const isValidUrl = (url) => {
  try {
    const newUrl = new URL(url);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch {
    return false;
  }
};

export const openWindow = (isMobileApp, url, target = '_self', agentType = 'MOBILE_WEB') => {
  if (isMobileApp) {
    if (!isValidUrl(url)) {
      url = window.location.protocol + '//' + window.location.host + url;
    }

    if (target === '_blank') {
      if (agentType === 'ANDROID_APP') {
        window.Welstorymall?.openMobileBrowser(url);
      } else if (agentType === 'IOS_APP') {
        window.webkit?.messageHandlers?.openMobileBrowser?.postMessage(url);
      }
    } else {
      window.location.href = url;
    }
  } else {
    window.open(url, target);
  }
};

export const saveMarketingPopupSetting = () => {
  const localStorageKey = 'marketing_popup_setting';

  let marketingAgreementPopupInfo = JSON.parse(getLocalStorageItem(localStorageKey));
  const toDayMonth = `${getYear(new Date())}${getMonth(new Date()) + 1}`;

  if (!marketingAgreementPopupInfo) {
    marketingAgreementPopupInfo = {};
    marketingAgreementPopupInfo.last_login_month = toDayMonth; // 마지막 로그인 월
    marketingAgreementPopupInfo.is_popup_opened = 'false'; // last_login_month에 팝업 open 여부
  } else if (marketingAgreementPopupInfo.last_login_month != toDayMonth) {
    marketingAgreementPopupInfo.last_login_month = toDayMonth;
    marketingAgreementPopupInfo.is_popup_opened = 'false';
  }

  saveLocalStorageItem(localStorageKey, JSON.stringify(marketingAgreementPopupInfo));

  
}

export const getExpiredLocalStorage = (key) => {
  let item = localStorage.getItem(key);
  if (item === null) return null;

  item = JSON.parse(item);

  let now = new Date();
  if (now.getTime() > item.expireTime) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
}

export const setExpiredLocalStorage = (key, value, time) => {
  let now = new Date();
  let item = {
    value: value,
    expireTime : now.getTime() + (time * 1000 * 60) ,
  };

  localStorage.setItem(key, JSON.stringify(item));
}
