import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn, VerticalDivider, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Popup from '@ecp/common/src/components/modal/Popup';

import commonApi from '@fo-apis/common/commonApi';

const CardInterestFreeGuidePopup = ({ open, onClose }) => {
  const [cardList, setCardList] = useState([]);

  useEffect(() => {
    (async () => {
      const { result } = await commonApi.getCommonCodes('CD0001');
      setCardList(result?.codeDetails);
    })();
  }, []);

  return (
    <Popup
      header={'카드 무이자 할부 안내'}
      open={open}
      onClose={onClose}
      useCancelButton={false}
      useConfirmButton={false}
    >
      <FlexBoxColumn width="460px">
        <HorizontalDivider height={'2px'} color={'#141415'} />
        {cardList?.map((card, index) => (
          <FlexBoxColumn key={index}>
            <FlexBox width={'100%'}>
              <FlexBoxColor height="46px" width="150px">
                <Spacing left={30} />
                <TextBox size={14} weight="500">
                  {card.codeName}
                </TextBox>
              </FlexBoxColor>
              <FlexBox height="46px" width="155px" justify-content={'center'}>
                <TextBox size={14} weight="350">
                  {card.groupCode1}
                </TextBox>
              </FlexBox>
              <VerticalDivider width={'1px'} height={'46px'} color={'#DFDFDF'} />
              <FlexBox height="46px" width="155px" justify-content={'center'}>
                <TextBox size={14} weight="350" color={'#727272'}>
                  {card.groupCode2}
                </TextBox>
              </FlexBox>
            </FlexBox>
            <HorizontalDivider />
          </FlexBoxColumn>
        ))}
      </FlexBoxColumn>
    </Popup>
  );
};

CardInterestFreeGuidePopup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CardInterestFreeGuidePopup;

const FlexBoxColor = styled(FlexBox)`
  background: #f7f7f7;
`;
