import isEmpty from 'lodash-es/isEmpty';

const toParamString = (pathname) => {
  if (!isEmpty(pathname) && pathname.includes('/')) {
    const paths = pathname.split('/').filter((v) => v);
    if (paths.length >= 0 && !isEmpty(paths[0])) {
      return paths.map((v, i) => `param${i}=${v}`).join('&');
    }
  }
  return '';
};

const toPathname = (paramString) => {
  if (!isEmpty(paramString) && paramString.includes('&')) {
    return paramString
      .split('&')
      .filter((v) => v)
      .map((param) => param.replace(/^param\d*=/i, ''))
      .join('/');
  }
  return '';
};

export default { toParamString, toPathname };
