export const PD_GOOD_CMPS_DIVI = {
  NORMAL: '10', // 일반상품
  GROUP: '20', // 그룹상품
};

export const PD_GOOD_TYPE = {
  NORMAL: '10', // 일반상품
  LIQUOR: '13', // 주류
  RENTAL: '14', // 렌탈
  REGULAR_DELIVERY: '15', // 정기배송
  SET: '17', // 세트상품
  GIFT_CARD: '20', // 상품권
  OVERSEAS: '60', // 해외직구
  TICKET: '50', // 티켓
  PARK_POINT: '16', // 파크상품권 파크포인트
  GIFTISHOW: '80', // E상품권 기프티쇼
};

export const PD_PRICE_TYPE = {
  NORMAL: '00', // 정상가
  MODIFY: '10', //	가격수정
  SALE: '20', // 세일가
};

export const PD_BENEFIT_TYPE = {
  RATE: '10', // 율
  AMT: '20', // 금액
  FIXED_AMT: '30', // 정찰금액구매
  CNT: '40', // 지급수량
};

export const PD_EVALUATION_DIVI = {
  ALL: '10', //	모두가능
  REVIEW: '20', //	상품평만
  STAR_RATING: '30', //	별점만
  UNAVAILABLE: '40', //	모두불가
};

export const PD_BUYING_CUSTOMER_TYPE = {
  PURCHASED: '10', // 구매
  UNPURCHASED: '20', // 미구매
  REPURCHASED: '30', // 재구매
};

export const PD_DESC_DIVI = {
  TECHNICAL: '10', // 기술서
  MO_TECHNICAL: '20', // 모바일 기술서
  MD_NOTICE: '30', // MD공지
  COMPANY_NOTICE: '40', // 업체공지
};

export const PD_GOOD_PRGS_STAT = {
  SALE: '10', // 판매진행
  SUSPEND: '20', // 판매중지
  SOLD_OUT: '25', // 품절
  STOP: '30', // 영구판매중지
};
export const PD_GOOD_PRGS_STAT_CD = 'PV0003'; // 판매상태코드
export const PD_GOOD_PRGS_STAT_CD_SALE = '10'; // 판매진행
export const PD_GOOD_PRGS_STAT_CD_SUSPEND = '20'; // 판매중지
export const PD_GOOD_PRGS_STAT_CD_SOLDOUT = '25'; // 품절
export const PD_GOOD_PRGS_STAT_CD_STOP = '30'; // 영구판매중지

// 배송비형태코드
export const PD_DELI_COST_FORM_CD = 'PV0071'; // 배송비형태코드
export const PD_DELI_COST_FORM = {
  PAID: '10', // 유료배송
  INDIVIDUAL: '20', // 개별배송
  FREE: '30', // 무료배송
  REFER: '40', // 상세참조
  CONDITIONAL: '50', // 조건부무료
  CO: '60', // 착불배송
};

// 배송형태코드
export const PD_DELI_FORM_CD = 'PV0013'; // 배송형태코드
export const PD_DELI_FORM = {
  COMPANY: '10', //	업체배송
  PARCEL: '11', //	택배
  DIRECT: '21', //	직송
  VIA_CENTER: '30', //	센터경유
  REGISTERED: '50', //	등기배송
  ON_SITE: '60', //	현장수령
};

// 배송상품구분코드
export const PD_DELI_DIVI_CD = 'PV0007'; // 배송상품구분코드
export const PD_DELI_DIVI_CD_RENTAL = '80'; // 랜탈

// 상담 상태 코드
export const CS_COUNSEL_STAT = {
  REQ: '10', // 요청
  RECEIPT: '20', // 접수
  ING: '30', // 처리중
  FINISH: '35', // 상담완료
  APPR_FINISH: '40', // 승인완료
  PRETREATED: '91', // 기처리
};

export const OD_ORDER_MAX_COUNT_STANDARD_CD = 'PV0059'; // 주문가능기준코드
export const OD_ORDER_MAX_COUNT_STANDARD = {
  ORDER: '10', // 주문단위
  MEMBER: '20', // 회원단위
  ONLY_ORDER_MEMBER: '30', //	주문최대1+회원단위
  DAILY: '40', //	일별단위
  AMOUNT: '50', //	금액단위
};

export const PR_TARGET_DEVICE = {
  ALL: '10',
  PC: '20',
  MOBILE: '30',
  APP: '40',
};

export const PR_AVAILABLE_TERM_DIVISION = {
  DAY: '10',
  PERIOD: '20',
};

export const PR_ISSUE_QUANTITY = {
  LIMIT: '10',
  INFINITE: '20',
};

export const GOOD_SORT_TYPE = [
  {
    key: '1',
    label: '판매순',
    value: 'ORD_CNT,DESC',
    default: true,
  },
  {
    key: '2',
    label: '신상품순',
    value: 'REG_DTIME,DESC',
    default: false,
  },
  {
    key: '3',
    label: '높은할인순',
    value: 'DISCOUNT_RATE,DESC',
    default: false,
  },
  {
    key: '4',
    label: '낮은가격순',
    value: 'DISCOUNT_PRICE,ASC',
    default: false,
  },
  {
    key: '5',
    label: '높은가격순',
    value: 'DISCOUNT_PRICE,DESC',
    default: false,
  },
  {
    key: '6',
    label: '리뷰많은순',
    value: 'GOOD_EVAL_CNT,DESC',
    default: false,
  },
  {
    key: '7',
    label: '조회순',
    value: 'GOOD_INQ_NUM,DESC',
    default: false,
  },
];

export const GOOD_PRINT_TYPE = [
  {
    key: '30',
    label: '30개',
    value: 30,
    default: false,
  },
  {
    key: '60',
    label: '60개',
    value: 60,
    default: true,
  },
  {
    key: '90',
    label: '90개',
    value: 90,
    default: false,
  },
  {
    key: '120',
    label: '120개',
    value: 120,
    default: false,
  },
];

export const BEST_TAB_INFO = [
  {
    tabName: '실시간 인기 상품',
  },
  {
    tabName: '많이 본 상품',
  },
  {
    tabName: '많이 구매한 상품',
  },
  {
    tabName: '브랜드 인기 상품',
  },
];

export const BEST_CATEGORY_ALL = {
  displayGroupNumber: 'ALL',
  name: '전체',
  hasNewGood: true,
};

export const BADGE_TYPE = {
  LARGE: 'LARGE',
  PERCENT: 'PERCENT',
  SMALL: 'SMALL',
  RELATED: 'RELATED',
  FAVORITE: 'FAVORITE',
  DELETE: 'DELETE',
  BOTTOM: 'BOTTOM',
  CHECKBOX: 'CHECKBOX',
  RECENT: 'RECENT',
};

export const GOOD_DETAIL_REVIEW_SORT = [
  {
    key: 'registrationDtime',
    label: '최신순',
    value: 'reg_dtime,desc',
  },
  {
    key: 'totalScoreDesc',
    label: '높은평점순',
    value: 'total_score,desc',
  },
  {
    key: 'totalScoreAsc',
    label: '낮은평점순',
    value: 'total_score,asc',
  },
];

export const GOOD_INITIAL_FILTER = {
  label: '상품 전체',
  key: '',
  subGoodIndex: '',
};

export const GOOD_QNA_PRIVACY_POLICY_DESCRIPTION =
  '1. 개인정보의 수집 및 이용 목적\n' +
  '- 상품문의\n' +
  '2. 수집하는 개인정보의 항목\n' +
  '- 제목, 내용, 휴대전화번호, 이메일\n' +
  '3. 개인정보의 보유 및 이용기간\n';

export const GOOD_QNA_PRIVACY_POLICY_IMPORTANT_DESCRIPTION = '- 회원탈퇴 시까지';

export const GOOD_CARD_INTEREST_FREE_GUIDE = [
  {
    card: '삼성카드',
    period: '2~5개월',
    price: '5만원 이상',
  },
  {
    card: '현대카드',
    period: '2~3개월',
    price: '5만원 이상',
  },
  {
    card: '롯데카드',
    period: '2~5개월',
    price: '5만원 이상',
  },
  {
    card: 'KB국민카드',
    period: '2~3개월',
    price: '5만원 이상',
  },
  {
    card: '신한카드',
    period: '2~3개월',
    price: '5만원 이상',
  },
  {
    card: '비씨카드',
    period: '2~4개월',
    price: '5만원 이상',
  },
  {
    card: '우리카드',
    period: '2~4개월',
    price: '5만원 이상',
  },
  {
    card: 'NH농협카드',
    period: '2~4개월',
    price: '5만원 이상',
  },
  {
    card: '하나카드',
    period: '2~3개월',
    price: '5만원 이상',
  },
  {
    card: '전북카드',
    period: '2~3개월',
    price: '5만원 이상',
  },
  {
    card: '광주카드',
    period: '2~7개월',
    price: '5만원 이상',
  },
];

export const GOOD_PRICE_FILTER_LIST = [
  {
    key: '1',
    label: '10,000원 미만',
    min: '',
    max: 10000,
  },
  {
    key: '2',
    label: '10,000원 ~ 30,000원',
    min: 10000,
    max: 30000,
  },
  {
    key: '3',
    label: '30,000원 ~ 100,000원',
    min: 30000,
    max: 100000,
  },
  {
    key: '4',
    label: '100,000원 ~ 300,000원',
    min: 100000,
    max: 300000,
  },
  {
    key: '5',
    label: '300,000원 ~ 1,000,000원',
    min: 300000,
    max: 1000000,
  },
  {
    key: '6',
    label: '1,000,000원 ~ 3,000,000원',
    min: 1000000,
    max: 3000000,
  },
  {
    key: '7',
    label: '3,000,000원 이상',
    min: 3000000,
    max: '',
  },
];

export const GOOD_RECOMMENDATION_TAB = {
  ORDER: { tabName: '다른 고객이 많이 구매한 상품', type: 'ORDER', 'data-ds-label2': 'reco_tab' },
  CATEGORY: { tabName: '카테고리 인기상품', type: 'CATEGORY', 'data-ds-label2': 'reco_tab' },
  VIEW: { tabName: '다른 고객이 많이 본 상품', type: 'VIEW', 'data-ds-label2': 'reco_tab' },
  DELIVERY: { tabName: '배송비 절약 상품', type: 'DELIVERY', 'data-ds-label2': 'reco_tab' },
};

export const PD_GOOD_CERT_INFO = [
  { key: '0', isShowCertNumber: false },
  { key: '1', isShowCertNumber: true, certNumberLabel: '안전인증번호' },
  { key: '2', isShowCertNumber: true, certNumberLabel: '안전확인신고번호' },
  { key: '3', isShowCertNumber: false },
  { key: '4', isShowCertNumber: false },
  { key: '5', isShowCertNumber: true, certNumberLabel: '인증/등록번호' },
  { key: '6', isShowCertNumber: true, certNumberLabel: '신고/승인번호' },
  { key: '7', isShowCertNumber: true, certNumberLabel: '안전인증번호' },
  {
    key: '8',
    isShowCertNumber: true,
    certNumberLabel: '안전확인신고번호',
  },
  { key: '9', isShowCertNumber: false },
  { key: '10', isShowCertNumber: true, certNumberLabel: '안전인증번호' },
  {
    key: '11',
    isShowCertNumber: true,
    certNumberLabel: '안전확인신고번호',
  },
  { key: '12', isShowCertNumber: false },
  { key: '13', isShowCertNumber: true, certNumberLabel: '안전인증번호' },
  {
    key: '14',
    isShowCertNumber: true,
    certNumberLabel: '안전확인신고번호',
  },
  { key: '15', isShowCertNumber: false },
  {
    key: '16',
    isShowCertNumber: true,
    certNumberLabel: '인증/등록번호',
  },
  {
    key: '17',
    isShowCertNumber: true,
    certNumberLabel: '신고/승인번호',
  },
  {
    key: '18',
    isShowCertNumber: true,
    certNumberLabel: '허가/인증/신고번호',
  },
  { key: '19', isShowCertNumber: false },
];

export const DIALOG_MESSAGE = {
  WISH_SUCCESS: '선택하신 상품이 위시리스트에 담겼습니다. 계속 쇼핑하시겠습니까?',
  GO_TO_WISH: '위시 가기',
  CONTINUE_SHOPPING: '계속 쇼핑하기',
  OPTION_REQUIRED: '옵션을 선택해 주세요.',
  NOT_ONLY_ADDITIONAL: '추가옵션만 구매할 수 없습니다.',
  CART_SUCCESS: '상품이 장바구니에 담겼습니다.',
  CART_FAIL: '장바구니 저장을 실패하였습니다.',
  GO_TO_CART: '장바구니가기',
  CART_MAX_COUNT: '장바구니 최대 보관 수량을 초과했습니다. 장바구니에 등록된 상품을 삭제해 주세요.',
  RENTAL_CONFIRM: '렌탈 신청 후, 계약 미 진행시 환불됩니다. 렌탈 신청을 진행하시겠습니까?',
  REGULAR_DELIVERY_PERIOD: '배송주기를 선택해 주세요.',
  REGULAR_DELIVERY_TOTAL: '배송횟수를 선택해 주세요.',
  REGULAR_DELIVERY_TIME: '배송시간을 선택해 주세요.',
  REGULAR_DELIVERY_START_TIME: '배송시작일을 선택해 주세요.',
  ITEM_SOLD_OUT: '죄송합니다. 해당 상품이 품절되었습니다.',
};

export const dayOfWeekMapper = {
  0: '일',
  1: '월',
  2: '화',
  3: '수',
  4: '목',
  5: '금',
  6: '토',
};

const DAY_OF_WEEK_CODE = {
  SUN: '11',
  MON: '12',
  TUE: '13',
  WED: '14',
  THUR: '15',
  FRI: '16',
  SAT: '17',
};

export const dayOfWeekCodeToCalendarCode = {
  [DAY_OF_WEEK_CODE.SUN]: 0,
  [DAY_OF_WEEK_CODE.MON]: 1,
  [DAY_OF_WEEK_CODE.TUE]: 2,
  [DAY_OF_WEEK_CODE.WED]: 3,
  [DAY_OF_WEEK_CODE.THUR]: 4,
  [DAY_OF_WEEK_CODE.FRI]: 5,
  [DAY_OF_WEEK_CODE.SAT]: 6,
};

export const MAX_ORDER_COUNT = 999;

export const PCCC_URL = 'https://unipass.customs.go.kr/csp/persIndex.do';
