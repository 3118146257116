import React from 'react';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as MaintenanceIcon } from '@ecp/common/src/assets/icon/icon__error-maintenance.svg';

const MaintenancePage = () => {
  return (
    <Container>
      <MaintenanceIcon />
      <Spacing top={20} />
      <TextBox size={'28px'} weight={'500'} height={'44.8px'} style={{ textAlign: 'center' }}>
        삼성웰스토리몰 서버 점검 안내
      </TextBox>
      <Spacing top={10} />
      <TextBox size={'14px'} weight={'350'} height={'22.4px'} style={{ textAlign: 'center' }}>
        불편을 끼쳐드려서 죄송합니다.
        <br />
        온라인 쇼핑몰 및 모바일 앱 시스템 안정화를 위해 서비스 점검 중입니다.
        <br />
        점검시간 동안 서비스 이용이 일시 중단되오니 고객님의 깊은 양해 부탁 드립니다.
      </TextBox>
      <Spacing top={50} />
      <MessageContainer>
        <MessageWrapper>
          <TextBox size={'15px'} weight={'350'} height={'24px'}>
            • 점검시간
          </TextBox>
          <TextBox size={'15px'} weight={'350'} height={'24px'} color={'#5A5A5A'}>
            2022.08.15 24:00 ~ 2022.08.16 06:00
          </TextBox>
        </MessageWrapper>
        <MessageWrapper>
          <TextBox size={'15px'} weight={'350'} height={'24px'}>
            • 고객센터
          </TextBox>
          <TextBox size={'15px'} weight={'350'} height={'24px'} color={'#5A5A5A'}>
            080-288-5252 / welmall.sei@samsung.com
          </TextBox>
        </MessageWrapper>
      </MessageContainer>
    </Container>
  );
};

export default MaintenancePage;

const Container = styled(FlexBoxColumn)`
  height: 100vh;
  justify-content: center;
`;

const MessageContainer = styled(FlexBoxColumn)`
  width: 494px;
  height: 118px;
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 30px 50px;
  box-sizing: border-box;
  align-items: flex-start;
  gap: 10px;
`;

const MessageWrapper = styled(FlexBox)`
  gap: 35px;
`;
