import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import noImagePath from '@ecp/common/src/assets/image/image__no_image.png';

import { overrideProperties } from '../../style/utils';
import {
  backgroundPropTypes,
  flexItemPropTypes,
  fontPropTypes,
  maxSizePropTypes,
  minSizePropTypes,
  sizePropTypes,
} from '../../style/propTypes';
import { theme } from '../../style/theme/default';

export const flexPropTypes = {
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([0])]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([0])]),
  'align-items': PropTypes.string,
  'justify-content': PropTypes.string,
  'flex-direction': PropTypes.string,
  'flex-wrap': PropTypes.string,
  border: PropTypes.string,
  'border-radius': PropTypes.string,
  cursor: PropTypes.string,
  ...minSizePropTypes,
  ...sizePropTypes,
  ...maxSizePropTypes,
  ...flexItemPropTypes,
  ...backgroundPropTypes,
  ...fontPropTypes,
};

export const defaultProps = {
  gap: 0,
  padding: 0,
  'grid-gap': 0,
  'align-items': 'center',
  'justify-content': 'flex-start',
  'flex-direction': 'row',
  'flex-wrap': 'nowrap',
  'align-self': 'auto',
  'background-color': 'unset',
  'border-radius': '0px',
};

export const FlexBoxCss = css`
    display: flex;
    ${overrideProperties(flexPropTypes, defaultProps)};

    &:focus-visible {
        outline: none;
    }
`;

export const FlexBox = styled.div`
    position: relative;
    ${FlexBoxCss};
`;
FlexBox.displayName = 'FlexBox';

FlexBox.propTypes = flexPropTypes;
FlexBox.defaultProps = defaultProps;

export const FlexBoxButtonCss = css`
    display: flex;
    ${overrideProperties(flexPropTypes, defaultProps)};

    &:focus-visible {
        outline: solid 1px #000000;
    }
`;

export const FlexBoxButton = styled.button`
  position: relative;
  text-underline-offset: 3px;
  ${FlexBoxButtonCss};
`;
FlexBoxButton.displayName = 'FlexBoxButton';

FlexBoxButton.propTypes = flexPropTypes;
FlexBoxButton.defaultProps = defaultProps;

export const FlexBoxH3 = styled.h3`
  position: relative;
  ${FlexBoxCss};
`;
FlexBoxH3.displayName = 'FlexBoxH3';

FlexBoxH3.propTypes = flexPropTypes;
FlexBoxH3.defaultProps = defaultProps;

export const FlexBoxInput = styled.input`
    position: relative;
    ${FlexBoxButtonCss};
`;
FlexBoxInput.displayName = 'FlexBoxInput';

FlexBoxInput.propTypes = flexPropTypes;
FlexBoxInput.defaultProps = defaultProps;

export const FlexBoxSpaceBetween = styled(FlexBox)`
    justify-content: space-between;
`;
FlexBoxSpaceBetween.displayName = 'FlexBoxSpaceBetween';

export const FlexBoxButtonSpaceBetween = styled(FlexBoxButton)`
    justify-content: space-between;
`;
FlexBoxButtonSpaceBetween.displayName = 'FlexBoxButtonSpaceBetween';

export const FlexBoxColumn = styled(FlexBox)`
    flex-direction: column;
`;
FlexBoxColumn.displayName = 'FlexBoxColumn';

export const FlexBoxCenter = styled(FlexBox)`
    justify-content: center;
`;
FlexBoxCenter.displayName = 'FlexBoxCenter';

export const FlexBoxButtonCenter = styled(FlexBoxButton)`
    justify-content: center;
`;
FlexBoxButtonCenter.displayName = 'FlexBoxButtonCenter';

export const FlexBoxAlignCenter = styled(FlexBox)`
    align-items: center;
`;
FlexBoxAlignCenter.displayName = 'FlexBoxAlignCenter';

export const FlexBoxEnd = styled(FlexBox)`
    justify-content: flex-end;
`;
FlexBoxAlignCenter.displayName = 'FlexBoxAlignCenter';

const Divider = styled(FlexBox)`
    background-color: ${({ color = theme.color.line.background }) => color};
`;

export const VerticalDivider = styled(Divider)`
    ${overrideProperties({ ...sizePropTypes, ...minSizePropTypes }, { height: '100%', width: '1px' })};
`;
VerticalDivider.displayName = 'VerticalDivider';

export const GridVerticalDivider = ({ width, color, rowCount }) => (
  <>
    {[...new Array(rowCount)].map((count, index) => (
      <VerticalDivider key={`grid_vertical_divider__${index}`} height={'100%'} width={width} color={color} />
    ))}
  </>
);

GridVerticalDivider.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  rowCount: PropTypes.number,
};

export const HorizontalDivider = styled(Divider)`
    ${overrideProperties({ ...sizePropTypes, ...minSizePropTypes }, { height: '1px', width: '100%' })};
`;
HorizontalDivider.displayName = 'HorizontalDivider';

export const GridHorizontalDivider = ({ height, color, columCount }) => (
  <>
    {[...new Array(columCount)].map((count, index) => (
      <HorizontalDivider key={`grid_horizontal_divider__${index}`} width={'100%'} height={height} color={color} />
    ))}
  </>
);

GridHorizontalDivider.propTypes = {
  height: PropTypes.string,
  color: PropTypes.string,
  columCount: PropTypes.number,
};

export const ClickableCss = css`
    ${({ clickable }) => clickable && { cursor: 'pointer' }}
`;

export const ImageBox = styled(FlexBox)`
    background-image: url(${({ imageSrc = noImagePath }) => imageSrc}),
    url(${({ imageSrc }) =>
            imageSrc?.endsWith?.('.png') || imageSrc?.endsWith?.('.svg') || imageSrc?.startsWith?.('data:image/png')
                    ? undefined
                    : noImagePath});
    background-position: center;
    background-position-y: ${({ backgroundPositionY = 'center' }) => backgroundPositionY};
    background-size: ${(props) => (props['background-size'] ? props['background-size'] : 'cover')};
    background-repeat: no-repeat;
    border-radius: ${({ radius }) => radius};
    ${ClickableCss};
`;

ImageBox.displayName = 'ImageBox';

export const LabelFlexBox = styled.label`
    position: relative;
    ${FlexBoxCss};
`;

LabelFlexBox.displayName = 'LabelFlexBox';

LabelFlexBox.propTypes = flexPropTypes;
LabelFlexBox.defaultProps = defaultProps;

export const LabelFlexBoxSpaceBetween = styled(LabelFlexBox)`
    justify-content: space-between;
`;
LabelFlexBoxSpaceBetween.displayName = 'LabelFlexBoxSpaceBetween';

export const LabelFlexBoxColumn = styled(LabelFlexBox)`
    flex-direction: column;
`;
LabelFlexBoxColumn.displayName = 'LabelFlexBoxColumn';

export const LabelFlexBoxCenter = styled(LabelFlexBox)`
    justify-content: center;
`;
LabelFlexBoxCenter.displayName = 'LabelFlexBoxCenter';

export const LabelFlexBoxAlignCenter = styled(LabelFlexBox)`
    align-items: center;
`;
LabelFlexBoxAlignCenter.displayName = 'LabelFlexBoxAlignCenter';

export const LabelFlexBoxColumnReverse = styled(LabelFlexBox)`
  flex-direction: column-reverse;
`;
LabelFlexBoxColumnReverse.displayName = 'LabelFlexBoxColumnReverse';

/********************************
 * 웹호환성 해결을 위해 div -> a 태그로 변환
 ********************************/

export const FlexBoxATag = styled.a.attrs({ href: '#' })`
  position: relative;
  text-decoration: none;
  pointer-event: none;
  cursor: default;
  ${FlexBoxCss};
`;

FlexBoxATag.displayName = 'FlexBoxATag';

FlexBoxATag.propTypes = flexPropTypes;
FlexBoxATag.defaultProps = {
  //href: '#',
  ...defaultProps,
};

export const FlexBoxSpaceBetweenA = styled(FlexBoxATag)`
  justify-content: space-between;
`;
FlexBoxSpaceBetweenA.displayName = 'FlexBoxSpaceBetweenA';

export const FlexBoxColumnATag = styled(FlexBoxATag)`
  flex-direction: column;
`;
FlexBoxColumnATag.displayName = 'FlexBoxColumnATag';

export const FlexBoxCenterATag = styled(FlexBoxATag)`
  justify-content: center;
`;
FlexBoxCenterATag.displayName = 'FlexBoxCenterATag';

export const SpanTitle = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  margin: -1px;
`;

export const DivTitle = styled.div`
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  margin: -1px;
`;

FlexBoxATag.displayName = 'FlexBoxATag';

export const FlexBoxColumnButton = styled(FlexBoxButton)`
  flex-direction: column;
`;
FlexBoxColumnButton.displayName = 'FlexBoxColumnButton';
