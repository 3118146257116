import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Tooltip from '@ecp/common/src/components/tooltip/Tooltip';
import { ReactComponent as QuestionIcon } from '@ecp/common/src/assets/icon/question.svg';
import SearchControlCheck from '@ecp/common/src/pages/search/SearchControlCheck';

const SearchKeywordHistoryTabOnOff = ({ isOn, handleClick }) => {
  return (
    <Wrapper>
      <Tooltip
        arrow
        placement={'top-start'}
        openWhenClicked
        rootProps={{ maxWidth: '400px' }}
        value={
          <TextBox size={theme.font.size.error} color={theme.color.text.sub}>
            {`최근 1달 이내의 검색어를 저장합니다.\n※ 저장을 원하지 않으실 경우 검색어 저장을 OFF 해주세요.`}
          </TextBox>
        }
      >
        <QuestionIcon width={'18px'} height={'18px'} />
      </Tooltip>
      <Spacing right={6} />
      <SearchControlCheck isOn={isOn} handleClick={handleClick}/>
    </Wrapper>
  );
};

const Wrapper = styled(FlexBox)`
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

export default SearchKeywordHistoryTabOnOff;

SearchKeywordHistoryTabOnOff.propTypes = {
  isOn: PropTypes.bool,
  handleClick: PropTypes.func,
};
