import { useEffect } from 'react';
import * as Netfunnel from '@ecp/common/src/utils/netfunnel.d.ts';
import { setExpiredLocalStorage } from '@ecp/common/src/utils/utils';

const useNetfunnelComplete = () => {
    // 넷퍼넬 유효시간 3시간으로 변경
    useEffect(()=> {       
        if (Netfunnel.NetFunnel_cookieExist()) {
            setExpiredLocalStorage("netfunnel_pass", "true", 180);
            Netfunnel.NetFunnel_Complete(()=>{});
            console.log('set netfunnel_pass');
        }    
    },[]);
};

export default useNetfunnelComplete;
