import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import useRecentlyViewedGoodList from '@ecp/common/src/hooks/user/useRecentlyViewedGoodList';
import useScrollDirection from '@ecp/common/src/hooks/useScrollDirection';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxCenter, FlexBoxColumn, ImageBox } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import Badge from '@ecp/common/src/components/badge/Badge';
import Drawer from '@ecp/common/src/components/modal/Drawer';

import { recentlyViewedGoodListState, userInfoState } from '@fo-recoil/common/user/atom';
import { withAdultCertification } from '@fo-recoil/common/user/selector';
import RecentGood from './RecentGood';
import { ReactComponent as TopArrowIcon } from '@fo-assets/icon/icon__top_arrow.svg';
import { ReactComponent as MainTimeIcon } from '@fo-assets/icon/icon__main-time--small.svg';

const SideFloatingArea = () => {
  const userInfo = useRecoilValue(userInfoState);
  const adultCertification = useRecoilValue(withAdultCertification);
  const [recentlyViewedList, setRecentlyViewedList] = useRecoilState(recentlyViewedGoodListState);
  const scrollDirection = useScrollDirection();
  const { recentlyViewedGoodList, floatingThumbnail } = useRecentlyViewedGoodList(
    userInfo,
    recentlyViewedList,
    adultCertification,
    setRecentlyViewedList
  );

  const handleTopClick = () => {
    window.scroll({ top: 0 });
  };

  return (
    <Wrapper>
      <FlexBoxColumn width={'62px'} height={'156px'} gap={'14px'}>
        <RecentItemWrapper>
          <Drawer placement={'right'} value={<RecentGood recentlyViewedGoodList={recentlyViewedGoodList} />}>
            <Badge
              showZero
              max={9}
              position={{ top: 0, right: 0 }}
              size={{ width: '20px', height: '20px' }}
              badgeContent={recentlyViewedGoodList?.length}
            >
              <CircleWrapper data-ds-label2={'float_rece_view_pd'}>
                {recentlyViewedGoodList?.[0] ? (
                  <ImageBox imageSrc={floatingThumbnail} width={'100%'} height={'100%'} />
                ) : (
                  <MainTimeIcon color={theme.color.line.sub} width={'34px'} height={'32px'} />
                )}
              </CircleWrapper>
            </Badge>
          </Drawer>
          <TextBox size={'12px'}>최근 본 상품</TextBox>
        </RecentItemWrapper>
        {'top' !== scrollDirection && (
          <CircleWrapper data-ds-label2={'float_top'} onClick={handleTopClick}>
            <TopArrowIcon />
          </CircleWrapper>
        )}
      </FlexBoxColumn>
    </Wrapper>
  );
};

export default SideFloatingArea;

const Wrapper = styled.div`
  position: fixed;
  top: calc(100vh - 196px);
  left: calc(100vw - 100px);
  display: block;
`;

const RecentItemWrapper = styled(FlexBoxColumn)`
  height: 80px;
  gap: 43px;
`;

const CircleWrapper = styled(FlexBoxCenter)`
  width: 60px;
  height: 60px;
  border: 1px solid ${theme.color.line.background};
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  background-color: ${theme.color.background.white};
  overflow: hidden;
`;
