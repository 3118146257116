export const ERROR_CODE = {
  ALREADY_REPORTED_REVIEW: {
    code: 'PD_00001',
    message: '이미 신고된 리뷰입니다.',
  },
  EXCESS_PERSONAL_LIMIT_QUANTITY_ERROR: {
    code: 'MB_4001',
    message: '개인별 자원발급 한도 초과입니다.',
  },
  EXCESS_LIMIT_QUANTITY_ERROR: {
    code: 'MB_4002',
    message: '총 자원발급 한도 초과입니다.',
  },
  COUPON_EXPIRED: {
    code: 'MB_4003',
    message: '사용 기간이 지났습니다.',
  },
  COUPON_EXCESS_LIMIT_QUANTITY_ERROR: {
    code: 'CP_0001',
    message: '한정 수량 쿠폰이 모두 소진되었습니다.',
  },
  COUPON_NOT_EXIST: {
    code: 'CP_0004',
    message: '없는 쿠폰번호입니다.\n 다시 입력해주세요.',
  },
  COUPON_ALREADY_REGISTERED: {
    code: 'CP_0005',
    message: '이미 등록된 쿠폰입니다.',
  },
  COUPON_ISSUE_FAIL: {
    code: 'CP_0006',
    message: '쿠폰 발행에 실패했습니다.',
  },
};
