import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import qs from 'qs';

const PaycoReturnPage = () => {
  const location = useLocation();

  useEffect(() => {
    const data = qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (s) => s });
    window.opener.postMessage(data, '*');
    window.close();
  }, [location.search]);
};

export default PaycoReturnPage;
