import isEmpty from 'lodash-es/isEmpty';

import useElementFocus from '../../../hooks/element/useElementFocus';
import useControlled from '../../../hooks/useControlled';

const useInput = ({ ref, props }) => {
  const { value, onEnter, onChange, maxLength, ...otherProps } = props;
  const { defaultValue, disabled, readOnly, error, empty: emptyProp, onlyNumber, onlyAlphabet } = otherProps;
  const focused = useElementFocus({ ref });

  const { value: controlledValue, setValueWhenUncontrolled } = useControlled({
    value,
    defaultValue,
  });

  const empty = emptyProp || !value;
  const accent = !isEmpty(value) && !disabled && !readOnly;
  const selected = focused && !disabled && !readOnly;

  const handleChange = (e) => {
    if (onlyNumber && onlyAlphabet && e.target.value.match?.(/[^0-9a-zA-Z]/g)) {
      e.stopPropagation();
      return;
    }

    if (onlyNumber && !onlyAlphabet && e.target.value.match?.(/[^0-9]/g)) {
      e.stopPropagation();
      return;
    }

    if (!onlyNumber && onlyAlphabet && e.target.value.match?.(/[^a-zA-Z]/g)) {
      e.stopPropagation();
      return;
    }

    if (maxLength && maxLength < e.target.value.length) {
      e.target.value = e.target.value.substring?.(0, maxLength);
    }

    setValueWhenUncontrolled(e.target.value);

    onChange?.(e);
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      onEnter?.(e);
    }
  };

  const getInputProps = () => ({
    ...otherProps,
    value: controlledValue,
    onChange: handleChange,
    onKeyUp: handleEnter,
  });

  return {
    selected,
    disabled,
    readOnly,
    accent,
    empty,
    error,
    getInputProps,
  };
};

export default useInput;
