import { axiosRawClient, getRequest, postRequest, putRequest } from '@fo-apis/axios';

const login = (payload) =>
  postRequest(
    {
      url: 'api/v1/auth/login',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );

const reissue = (token) =>
  axiosRawClient({
    method: 'POST',
    url: 'api/v1/auth/reissue',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const logout = () =>
  postRequest({
    method: 'POST',
    url: 'api/v1/auth/logout',
  });

const info = () =>
  getRequest({
    url: 'api/v1/auth/info',
  });

const findIdWithCellNo = ({ username, cellNo }) => {
  return postRequest({
    url: `api/v1/auth/recovery/id?cellNo`,
    data: {
      username,
      cellNo,
    },
  });
};

const findIdWithEmail = ({ username, email }) => {
  return postRequest({
    url: `api/v1/auth/recovery/id?email`,
    data: {
      username,
      email,
    },
  });
};

const issueTempPassword = (payload) => {
  return postRequest({
    url: `api/v1/auth/recovery/password`,
    data: payload,
  });
};

const changePasswordAfterRecovery = ({ password, newPassword, userId }) => {
  return putRequest({
    url: `api/v1/auth/recovery/password`,
    data: {
      password,
      newPassword,
      userId,
    },
  });
};

const ssoLogin = (payload) =>
  postRequest(
    {
      url: 'api/v1/auth/sso',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );

const mobileLogin = (payload) =>
  postRequest(
    {
      url: 'api/v1/auth/mobile',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );

const confirmPassword = (payload) => {
  return postRequest(
    {
      url: 'api/v1/auth/confirm-password',
      data: payload,
    },
    { skipError: true, skipSpinner: true }
  );
};

const signUp = (payload) =>
  postRequest({
    url: 'api/v1/auth/member/sign-up',
    data: payload,
  });

const knoxSignUp = (payload) =>
  postRequest({
    url: 'api/v1/auth/member/knox-sign-up',
    data: payload,
  });

  /* 보안취약점 대상 api이나 미사용 되어졌음으로 주석처리로 비활성화 함 */
// const KnoxSignUpForSso = (payload) =>
//   postRequest({
//     url: 'api/v1/auth/member/knox-sign-up-sso',
//     data: payload,
//   });
const associateSignUp = (payload) =>
  putRequest({
    url: 'api/v1/auth/member/associate-sign-up',
    data: payload,
  });

const validateId = (id) => getRequest({ url: `api/v1/auth/member/validate-id`, params: { id } });

const getLoginIdByEpUserId = (params) =>
  getRequest({
    url: '/api/v1/auth/member/password-expiry/get-login-id',
    params: { ...params },
  });

const getLoginIdByCi = (payload) =>
  postRequest({
    url: '/api/v1/auth/member/password-expiry/get-login-id',
    data: { ...payload },
  });

const changePasswordLater = (payload) =>
  putRequest({
    url: '/api/v1/auth/member/password-expiry?renewal',
    data: payload,
  });

const changePasswordLaterWithCertInfo = (payload) =>
  putRequest({
    url: '/api/v1/auth/member/password-expiry?renewal-with-cert-info',
    data: payload,
  });

const changePasswordLaterWithSso = (payload) =>
  putRequest({
    url: '/api/v1/auth/member/password-expiry?renewal-with-sso',
    data: payload,
  });

const changeExpiredToNewPassword = (payload) =>
  putRequest({
    url: '/api/v1/auth/member/password-expiry?new',
    data: payload,
  });

const restoreDormancyMember = (payload) =>
  postRequest({
    url: '/api/v1/auth/member/restore-dormancy',
    data: payload,
  });

const validateMember = (payload) =>
  postRequest({
    url: `/api/v1/member/validate`,
    data: { ...payload },
  });

const validateMemberForSignUp = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/sign-up`,
    data: { ...payload },
  });

const validateMemberForSignUpSso = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/sign-up-sso`,
    data: { ...payload },
  });

const validateMultimallEmployee = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/multimall-employee`,
    data: { ...payload },
  });

const validateEmailCertNumber = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/email-cert-number`,
    data: { ...payload },
  });

const validateMemberForCiUpdate = (payload) =>
  postRequest({
    url: `/api/v1/member/validate/ci-update`,
    data: { ...payload },
  });

const getAssociateUserCompanyInfo = (loginId) =>
  getRequest(
    {
      url: `/api/v1/member/associate/company-info`,
      params: { loginId },
    },
    { skipError: true }
  );

const getMultimallUserCompanyInfo = (params) =>
  getRequest(
    {
      url: `/api/v1/member/multimall/company-info`,
      params: { ...params },
    },
    { skipError: true }
  );

const authApi = {
  login,
  logout,
  reissue,
  info,
  findIdWithCellNo,
  findIdWithEmail,
  issueTempPassword,
  changePasswordAfterRecovery,
  ssoLogin,
  mobileLogin,
  confirmPassword,
  signUp,
  knoxSignUp,
  // KnoxSignUpForSso,
  validateId,
  getLoginIdByEpUserId,
  getLoginIdByCi,
  changePasswordLater,
  changePasswordLaterWithCertInfo,
  changePasswordLaterWithSso,
  changeExpiredToNewPassword,
  associateSignUp,
  restoreDormancyMember,
  validateMember,
  validateMemberForSignUp,
  validateMemberForSignUpSso,
  validateMultimallEmployee,
  validateEmailCertNumber,
  validateMemberForCiUpdate,
  getAssociateUserCompanyInfo,
  getMultimallUserCompanyInfo,
};

export default authApi;
