import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { LabelFlexBox } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';

const SearchControlCheck = ({ isOn, handleClick }) => {
  return (
    <LabelFlexBox size={theme.font.size.small} color={theme.color.text.guide} line-height={theme.font.lineHeight.bold} 
      htmlFor="recent_word"
    >
      최근 검색어 저장
      <Spacing right={10} />
      <Check id="recent_word" type="checkbox" isOn={isOn} onClick={() => handleClick(!isOn)} />
    </LabelFlexBox>
  );
};

const Check = styled.input`
  width: 36px;
  height: 20px;
  background-color: ${({ isOn }) => (isOn ? theme.color.primary : theme.color.text.disabled)};
  border-radius: 100px;
  cursor: pointer;
  appearance: none;
  position: relative;
  padding: 2px;
  align-items: center;
  display: flex !important;
  justify-content: ${({ isOn }) => (isOn ? 'flex-end' : 'flex-start')};

  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: scale(0.8);
  }
  /* 선택 안했을 때 원*/
  &:after {
    content: '';
    position: relative;
    display: flex !important;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${theme.color.background.white};
    /*transition: all 0.2s ease-in-out;*/
  }
  &:checked {
    border-color: ${({ isOn }) => (isOn ? theme.color.primary : theme.color.text.disabled)};
  }
  &:checked::before {
    position: absolute;
    display: flex !important;
    align-items: center;
    background-color: ${theme.color.background.white};
  }
  &:checked::after {
    display: flex !important;
    position: relative;
    background-color: ${theme.color.background.white};
  }
  &:disabled {
    border-color: ${theme.color.text.disabled};
    opacity: 0.7;
    cursor: not-allowd;
  }
  &:disabled:before {
    border-color: ${theme.color.text.disabled};
  }
  &:disabled + span {
    opacity: 0.7;
    cursor: not-allowd;
  }
  &:focus-visible {
    outline-offset: max(2px, 0.1em);
    outline: max(2px, 0.1em) solid ${theme.color.primary};
  }
  &:enabled:hover {
    box-shadow: 0 0 0 max(4px, 0.2em) ${theme.color.primary};;
  }
`;
export default SearchControlCheck;

SearchControlCheck.propTypes = {
  isOn: PropTypes.bool,
  handleClick: PropTypes.func,
};
