import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { recommendBrandListState } from '@fo-recoil/brand/atom';
import brandApi from '@fo-apis/good/brandApi';
const useRecommendBrandList = () => {
  const [recommendBrandList, setRecommendBrandList] = useRecoilState(recommendBrandListState);

  useEffect(() => {
    (async () => {
      if (recommendBrandList.length === 0) {
        const { result } = await brandApi.getRecommendationBrandList();
        setRecommendBrandList(result || []);
      }
    })();
  }, [recommendBrandList.length, setRecommendBrandList]);
  return recommendBrandList;
};
export default useRecommendBrandList;
