import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { ReactComponent as Question } from '@ecp/common/src/assets/icon/question.svg';

export const BadgeText = styled(TextBox)`
  padding: 4px 7px;
  height: 20px;
  line-height: 100%;
  border: ${({ badgeColor }) => (badgeColor ? `1px solid ${badgeColor}` : '1px solid black')};
  color: ${({ badgeColor }) => (badgeColor ? `${badgeColor}` : 'black')};
  font-size: 12px;
`;

export const CouponNameText = styled(TextBox)`
  height: 24px;
  font-size: 15px;
  line-height: 160%;
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.line.emphasis};
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HelpIcon = styled(Question)`
  min-width: 18px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
