import React, { useImperativeHandle, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { TextBox, textBoxProps } from './TextBox';
import { theme } from '../style/theme/default';

export const textLinkProps = {
  ...textBoxProps,
  to: PropTypes.string,
  decorationOnHover: PropTypes.bool,
};

export const StyledTextLink = styled(TextBox)`
  &:hover {
    cursor: pointer;
    ${({ decorationOnHover }) =>
      decorationOnHover
        ? {
            color: `${theme.color.primary}`,
            'text-decoration': 'underline',
          }
        : ''};
  }
`;

const TextLink = React.forwardRef(({ to, decorationOnHover, ...props }, ref) => {
  const linkRef = useRef(null);

  useImperativeHandle(ref, () => linkRef.current);

  return (
    <NoneDecorationLink to={to}>
      <StyledTextLink ref={linkRef} decorationOnHover={decorationOnHover} {...props} />
    </NoneDecorationLink>
  );
});

TextLink.displayName = 'TextLink';

TextLink.propTypes = textLinkProps;

TextLink.defaultProps = {
  decorationOnHover: true,
};

export default TextLink;

export const NoneDecorationLink = styled(Link).attrs(({ to, style }) => ({
  style: { ...style, cursor: to ? 'pointer' : 'unset' },
}))`
  color: ${theme.color.text.basic};
  text-decoration: none;
`;
