import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import StyledImg from '@ecp/common/src/components/image/StyledImg';

const BrandList = ({ brandList }) => {
  const navigate = useNavigate();
  const handleBrandClick = (brandNumber) => {
    navigate('/brand/' + brandNumber);
  };

  return (
    <FlexBox width={'100%'} gap={'20px'} align-items={'flex-start'} data-ds-label2={'brand'}>
      {brandList?.map((brand) => (
        <FlexBoxColumn key={brand.brandNumber} width={'240px'} gap={'14px'} align-items={'flex-start'}>
          <BrandImage src={brand.pcImageUrl} onClick={() => handleBrandClick(brand.brandNumber)} alt={''}/> 
          <BrandName onClick={() => handleBrandClick(brand.brandNumber)}>{brand.brandName}</BrandName>
        </FlexBoxColumn>
      ))}
    </FlexBox>
  );
};

BrandList.propTypes = {
  brandList: PropTypes.array,
};

export default BrandList;

const BrandImage = styled(StyledImg)`
  width: 240px;
  height: 220px;
  border-radius: 12px;
  border: ${`1px solid ${theme.color.line.background}`};
  object-fit: cover;
`;

const BrandName = styled.div`
  width: 100%;
  font-size: ${theme.font.size.title};
  line-height: ${theme.font.lineHeight.demiLight};
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.text.basic};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
