import { useEffect, useRef } from 'react';

const useScrollTop = (dependencies = []) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current?.scroll({ top: 0 });
  }, [dependencies]);

  return {
    ref,
  };
};

export default useScrollTop;
