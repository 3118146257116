import { getRequest, postRequest } from '@fo-apis/axios';

const getTrendGoodList = async ({ type = 'REAL', limit = 10, skipSpinner = true } = {}) => {
  return await getRequest(
    {
      url: `/api/v1/display/search/trend`,
      params: {
        type,
        limit,
      },
    },
    { skipSpinner }
  );
};
const getTrendGoodListByRanking = async (ranking = 1, type = 'REAL', limit = 10) => {
  return await getRequest({
    url: `/api/v1/display/search/trend/${ranking}`,
    params: {
      type,
      limit,
    },
  });
};

const getTrendSearchTermList = async (skipSpinner = true) => {
  return await getRequest(
    {
      url: `/api/v1/display/search/terms`,
    },
    { skipSpinner }
  );
};

const getAutoCompleteSearchTermList = async (searchTerm) => {
  return await postRequest(
    {
      url: `/api/v1/display/search/auto-complete`,
      data: { searchTerm },
    },
    { skipSpinner: true }
  );
};

const getGoodListBySearchTerm = async (body, params) => {
  return await postRequest({
    url: `/api/v1/display/search/good-list`,
    params: {
      ...params,
    },
    data: body,
  });
};

const getGoodInfoBySearchTerm = async (body) => {
  return await postRequest({
    url: `/api/v1/display/search/good-info`,
    data: body,
  });
};

const displaySearchApi = {
  getTrendGoodList,
  getTrendGoodListByRanking,
  getTrendSearchTermList,
  getAutoCompleteSearchTermList,
  getGoodListBySearchTerm,
  getGoodInfoBySearchTerm,
};

export default displaySearchApi;
