import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import isEmpty from 'lodash-es/isEmpty';
import isEqual from 'lodash-es/isEqual';

import { initialCurrentCategory } from '@ecp/common/src/utils/good/goodSearchUtil';

const useNavigateWithFilter = (infoTable, callback, showAlertDialog) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchCondition, setSearchCondition] = useState(
    infoTable?.getFilterInfo(infoTable?.initSearchCondition, searchParams)
  );
  const [pageParam, setPageParam] = useState(infoTable?.getPageInfo(infoTable?.initPageParam, searchParams));
  const [currentCategory, setCurrentCategory] = useState(initialCurrentCategory);

  const hasBrand = useMemo(
    () => searchCondition?.brandList && !isEmpty(searchCondition?.brandList),
    [searchCondition?.brandList]
  );

  const hasPrice = useMemo(
    () => searchCondition?.price && (!!searchCondition?.price?.min || !!searchCondition?.price?.max),
    [searchCondition?.price]
  );

  const handleRefresh = useCallback(() => {
    setSearchCondition(infoTable?.initSearchCondition);
    setPageParam((prev) => ({ ...prev, page: 1 }));
    setCurrentCategory(initialCurrentCategory);
  }, [infoTable?.initSearchCondition]);

  const handleRefreshExceptSearchTerm = useCallback(() => {
    setSearchCondition((prev) => ({
      ...infoTable?.initSearchCondition,
      searchTerm: prev.searchTerm,
      typoTerm: prev.typoTerm,
      noTypoCorrection: prev.noTypoCorrection,
    }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, [infoTable?.initSearchCondition]);

  const handlePageChange = useCallback((pageNumber) => {
    setPageParam((prev) => ({ ...prev, page: pageNumber }));
  }, []);

  const handleViewTypeChange = useCallback(() => {
    setPageParam(({ listView, ...prev }) => ({ ...prev, listView: !listView }));
  }, []);

  const handleCountPerPageChange = useCallback(
    (countPerPage = {}) => {
      if (!countPerPage || pageParam.countPerPage === countPerPage.value) {
        return;
      }
      setPageParam((prev) => ({ ...prev, page: 1, countPerPage: countPerPage.value }));
    },
    [pageParam]
  );

  const handleSortChange = useCallback(
    (sort = {}) => {
      if (!sort?.value || pageParam?.sort?.value === sort?.value) {
        return;
      }
      setPageParam((prev) => ({ ...prev, page: 1, sort: sort }));
    },
    [pageParam]
  );

  const fetchNextPage = useCallback(() => {
    setPageParam((prev) => ({ ...prev, page: prev?.page + 1 }));
  }, []);

  const handleSmallCategoryChange = useCallback(
    (smallCategoryNumber) => {
      navigate({
        pathname: `/category/sub/${smallCategoryNumber}`,
        search: `?${infoTable?.getQueryString(infoTable?.initSearchCondition, { ...pageParam, page: 1 })}`,
      });
    },
    [infoTable, navigate, pageParam]
  );

  const handleDetailCategoryChange = useCallback(
    (detailCategoryNumber) => {
      navigate({
        pathname: `/category/sub/${detailCategoryNumber}`,
        search: `?${infoTable?.getQueryString(infoTable?.initSearchCondition, { ...pageParam, page: 1 })}`,
      });
    },
    [infoTable, navigate, pageParam]
  );

  const handleChangeFilter = useCallback((updated) => {
    setSearchCondition((prev) => ({ ...prev, ...updated }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, []);

  const handleBrandFilterChange = useCallback(
    (value, selected, brandTotalCount) => {
      const allBrandSelected = selected && searchCondition?.brandList?.length + 1 === brandTotalCount;
      if (allBrandSelected) {
        showAlertDialog('전체 브랜드를 선택 하셨습니다.', () => {
          setSearchCondition((prev) => ({ ...prev, brandList: [] }));
          setPageParam((prev) => ({ ...prev, page: 1 }));
        });
        return;
      }
      if (selected) {
        setSearchCondition((prev) => ({ ...prev, brandList: [...prev.brandList, value] }));
        setPageParam((prev) => ({ ...prev, page: 1 }));
        return;
      }
      setSearchCondition((prev) => ({ ...prev, brandList: prev.brandList.filter((it) => it !== value) }));
      setPageParam((prev) => ({ ...prev, page: 1 }));
    },
    [searchCondition?.brandList?.length, showAlertDialog]
  );

  const handlePriceFilterChange = useCallback(
    (changedPriceCondition) => {
      setSearchCondition((prev) => {
        if (prev.price?.min === changedPriceCondition?.min && prev.price?.max === changedPriceCondition?.max) {
          return {
            ...prev,
            price: infoTable?.initSearchCondition.price,
          };
        } else
          return {
            ...prev,
            price: { ...prev.price, ...changedPriceCondition },
          };
      });
      setPageParam((prev) => ({ ...prev, page: 1 }));
    },
    [infoTable?.initSearchCondition.price]
  );

  const handleResearchFilterChange = useCallback((value) => {
    setSearchCondition((prev) => ({ ...prev, researchTerm: value }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, []);

  const handleCategoryFilterChange = useCallback((updatedCategory) => {
    setSearchCondition((prev) => ({
      ...prev,
      categoryNumber: updatedCategory.categoryNumber,
    }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, []);

  const handleTabFilterChange = useCallback((value) => {
    setPageParam((prev) => ({ ...prev, page: 1, hyundaiGoodYn: value }));
  }, []);

  const handleDeleteBrandFilter = useCallback((value) => {
    setSearchCondition((prev) => ({ ...prev, brandList: prev.brandList.filter((it) => it !== value) }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, []);

  const handleDeletePriceFilter = useCallback(() => {
    setSearchCondition((prev) => ({ ...prev, price: infoTable?.initSearchCondition.price }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, [infoTable?.initSearchCondition.price]);

  const handleDeleteResearchTermFilter = useCallback(() => {
    setSearchCondition((prev) => ({ ...prev, researchTerm: infoTable?.initSearchCondition.researchTerm }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, [infoTable?.initSearchCondition.researchTerm]);

  const handleDeleteCategoryFilter = useCallback(() => {
    setSearchCondition((prev) => ({
      ...prev,
      categoryNumber: infoTable?.initSearchCondition.categoryNumber,
    }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, [infoTable?.initSearchCondition.categoryNumber]);

  const handleApplyFilter = useCallback((changedFilter) => {
    setSearchCondition((prev) => ({
      ...prev,
      ...changedFilter,
    }));
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, []);

  const handleDeleteChip = useCallback(({ type, value }) => {
    if (type === 'brand') {
      setSearchCondition((prev) => ({ ...prev, brandList: prev.brandList.filter((it) => it !== value) }));
    } else if (type === 'price') {
      setSearchCondition((prev) => ({ ...prev, price: {} }));
    }
    setPageParam((prev) => ({ ...prev, page: 1 }));
  }, []);

  useEffect(() => {
    const currentQueryString = window.location?.search;
    const updatedQueryString = `?${infoTable?.getQueryString(searchCondition, pageParam)}`;
    if (currentQueryString === updatedQueryString) {
      return;
    }
    navigate(
      {
        pathname: infoTable?.pathname,
        search: updatedQueryString,
      },
      {
        replace: true,
      }
    );
  }, [searchCondition, pageParam, navigate, infoTable]);

  const searchParamsSize = useMemo(() => (searchParams ? [...new Set(searchParams.keys())].length : 0), [searchParams]);

  useEffect(() => {
    if (searchParamsSize > 0) {
      const filterInfo = infoTable?.getFilterInfo(infoTable?.initSearchCondition, searchParams);
      const pageInfo = infoTable?.getPageInfo(infoTable?.initPageParam, searchParams);
      setSearchCondition((prev) => {
        if (isEqual(prev, filterInfo)) {
          return prev;
        }

        return filterInfo;
      });
      setPageParam((prev) => {
        if (isEqual(prev, pageInfo)) {
          return prev;
        }

        return pageInfo;
      });
      const depthNumber = (categoryNumber) => {
        const { large, middle, small, current } = categoryNumber;
        if (!current) {
          return 0;
        }

        if (current === large) {
          return 1;
        }

        if (current === middle) {
          return 2;
        }

        if (current === small) {
          return 3;
        }

        return 4;
      };
      if (filterInfo?.categoryNumber) {
        setCurrentCategory({
          depthNumber: depthNumber(filterInfo.categoryNumber),
          categoryNumber: { ...filterInfo.categoryNumber },
        });
      }
      callback && callback(filterInfo, pageInfo, pageParam);
    } else {
      handleRefresh();
    }
  }, [searchParams, callback, handleRefresh, searchParamsSize, infoTable]);

  return {
    searchCondition,
    setSearchCondition,
    pageParam,
    setPageParam,
    currentCategory,
    setCurrentCategory,
    hasBrand,
    hasPrice,
    handleRefresh,
    handleRefreshExceptSearchTerm,
    handlePageChange,
    handleCountPerPageChange,
    handleSortChange,
    fetchNextPage,
    handleSmallCategoryChange,
    handleDetailCategoryChange,
    handleChangeFilter,
    handleBrandFilterChange,
    handlePriceFilterChange,
    handleResearchFilterChange,
    handleCategoryFilterChange,
    handleTabFilterChange,
    handleDeleteBrandFilter,
    handleDeletePriceFilter,
    handleDeleteResearchTermFilter,
    handleDeleteCategoryFilter,
    handleApplyFilter,
    handleDeleteChip,
    handleViewTypeChange,
  };
};
export default useNavigateWithFilter;
