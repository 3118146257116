import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { ReactComponent as SearchSvg } from '@ecp/common/src/assets/icon/icon__search.svg';
import { ReactComponent as InitializationSvg } from '@ecp/common/src/assets/icon/icon__initialization.svg';

import InputButton, { inputBasePropTypes } from './InputButton';

const mainStyle = css`
    flex: 0 0 50px;
    height: 50px;
    width: 500px;
    background-color: ${theme.color.background.white};
    border: 3px solid ${theme.color.primary};
    border-radius: 50px;
    padding: 15px 20px;
`;

const normalStyle = css`
    flex: 0 0 36px;
    height: 36px;
    width: 560px;
    background-color: transparent;
    padding-bottom: 12px;
    border-bottom: 2px solid ${theme.color.line.emphasis};
`;

const StyledSearchInputRoot = styled(FlexBoxSpaceBetween)`
    ${({ type }) => (type === 'main' ? mainStyle : normalStyle)}

    & input {
        font-weight: 350;
        font-size: ${theme.font.size.basic};
        line-height: 28px;
    }
`;

const StyledInputInitialization = styled(FlexBoxSpaceBetween)`
    position: absolute;
    right: -72px;
    top: 2px;

    cursor: pointer;
    flex: 0 0 52px;
    font-size: ${theme.font.size.small};
    user-select: none;
`;

const SearchInput = React.forwardRef(
  ({ enableInitialize, endAdornment, type, onSearchClick, onInitializeClick, ...props }, ref) => {
    const handleSearchClick = (e) => {
      e.stopPropagation();
      onSearchClick?.(e);
    };

    return (
      <FlexBox gap="20px" width="fit-content">
        <InputButton
          {...props}
          ref={ref}
          rootProps={{ type }}
          rootComponent={StyledSearchInputRoot}
          endAdornment={
            <>
              <SearchIcon onClick={handleSearchClick} className={'search-input__search-icon'} />
              {endAdornment}
            </>
          }
        />
        {enableInitialize && (
          <StyledInputInitialization onClick={onInitializeClick}>
            <InitializationSvg width={10} height={13} />
            <Spacing left={6} />
            <div>초기화</div>
          </StyledInputInitialization>
        )}
      </FlexBox>
    );
  }
);

SearchInput.propTypes = {
  type: PropTypes.oneOf(['main', 'normal']),
  enableInitialize: PropTypes.bool,
  onSearchClick: PropTypes.func,
  autoFocus: PropTypes.bool,
  ...inputBasePropTypes,
};

SearchInput.defaultProps = {
  type: 'normal',
  enableInitialize: false,
};

SearchInput.displayName = 'SearchInput';

export default SearchInput;

const SearchIcon = styled(SearchSvg)`
    cursor: pointer;
    flex: 0 0 22px;
    width: 22px;
    height: 22px;
`;
