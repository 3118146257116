import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getLabelByPrice } from '@ecp/common/src/utils/good/goodSearchUtil';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import Chip from '@ecp/common/src/components/chip/Chip';

import { ReactComponent as RefreshIcon } from '@fo-assets/image/good/good_reset_img.svg';

const SelectedSearchCondition = ({
  padding,
  backgroundColor,
  searchCondition,
  categoryNumberToName,
  brandNumberToName,
  handleResetFilter,
  handleDeleteSearchAgainKeyword,
  handleDeleteCategoryFilter,
  handleDeleteBrandFilter,
  handleDeletePriceFilter,
}) => {
  const { researchTerm = '', categoryNumber = '', brandList = [], price = {} } = searchCondition;
  const hasBrandFilter = useMemo(() => brandList.length > 0, [brandList.length]);
  const hasPriceFilter = useMemo(() => price?.min || price?.max, [price?.min, price?.max]);
  const priceLabel = useMemo(() => {
    if (price?.label) {
      return price?.label;
    }
    return getLabelByPrice(price?.min, price?.max);
  }, [price]);
  const categoryChipName = useMemo(() => {
    if (!categoryNumber || !categoryNumberToName) {
      return;
    }
    const detailName = categoryNumberToName[categoryNumber.detail];
    const smallName = categoryNumberToName[categoryNumber.small];
    const middleName = categoryNumberToName[categoryNumber.middle];
    const largeName = categoryNumberToName[categoryNumber.large];
    return detailName || smallName || middleName || largeName;
  }, [categoryNumber, categoryNumberToName]);

  return (
    <>
      {(researchTerm || categoryChipName || hasBrandFilter || hasPriceFilter) && (
        <Wrapper padding={padding} backgroundColor={backgroundColor}>
          <FlexBox>
            <TextBox size={theme.font.size.table} weight={theme.font.weight.medium}>
              선택한 검색 조건
            </TextBox>
            <Spacing right={10} />
            <FlexBox onClick={handleResetFilter}>
              <RefreshIcon width="11px" height="21px" />
              <Spacing right={6} />
              <TextButton
                size={theme.font.size.small}
                weight={theme.font.weight.demiLight}
                color={theme.color.text.sub}
              >
                초기화
              </TextButton>
            </FlexBox>
            <Spacing right={20} />
          </FlexBox>
          <FlexBox>
            <FlexBoxCenter gap={'6px'} flex-wrap={'wrap'}>
              {researchTerm && (
                <Chip type={'rescan'} onClose={handleDeleteSearchAgainKeyword}>
                  {researchTerm}
                </Chip>
              )}
              {categoryChipName && (
                <Chip type={'category'} onClose={handleDeleteCategoryFilter}>
                  {categoryChipName}
                </Chip>
              )}
              {hasBrandFilter &&
                brandList.map((value, index) => (
                  <Chip type={'brand'} key={`brand-chip__${index}`} onClose={() => handleDeleteBrandFilter(value)}>
                    {brandNumberToName[value]}
                  </Chip>
                ))}
              {hasPriceFilter && (
                <Chip type={'price'} onClose={handleDeletePriceFilter}>
                  {priceLabel}
                </Chip>
              )}
            </FlexBoxCenter>
          </FlexBox>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled(FlexBoxCenter)`
  width: 100%;
  flex-wrap: wrap;
  background-color: ${({ backgroundColor }) => backgroundColor || ''};
  padding: ${({ padding }) => padding};
`;

export default SelectedSearchCondition;

SelectedSearchCondition.propTypes = {
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  searchCondition: PropTypes.object,
  categoryNumberToName: PropTypes.object,
  brandNumberToName: PropTypes.object,
  handleResetFilter: PropTypes.func,
  handleDeleteSearchAgainKeyword: PropTypes.func,
  handleDeleteCategoryFilter: PropTypes.func,
  handleDeleteBrandFilter: PropTypes.func,
  handleDeletePriceFilter: PropTypes.func,
};
