import React from 'react';
import styled from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import defaultChecked from '@ecp/common/src/assets/icon/checkbox--checked.svg';
import defaultDisabled from '@ecp/common/src/assets/icon/checkbox--disabled.svg';
import defaultUnchecked from '@ecp/common/src/assets/icon/checkbox--unchecked.svg';

const CheckBoxComponent = (props) => {
  return <DefaultCheckbox {...props} />;
};

const DefaultCheckbox = styled.div`
  ${overrideProperties(sizePropTypes, { width: '22px', height: '22px' })}
  background: url(${defaultChecked}) no-repeat;
  background-size: ${({ width }) => width || '22px'};
  box-sizing: border-box;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }

  &.checkbox-checked {
    background-image: url(${defaultChecked});
  }

  &.checkbox-unchecked {
    background-image: url(${defaultUnchecked});
  }

  &.checkbox-disabled {
    background-color: #dfdfdf;

    &:hover {
      cursor: not-allowed;
    }

    &.checkbox-checked {
      background-image: url(${defaultDisabled});
    }

    &.checkbox-unchecked {
      background-image: none;
    }
  }
`;

export default CheckBoxComponent;
