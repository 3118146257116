import { getRequest, postRequest } from '@fo-apis/axios'; // , putRequest

// 작성 가능한 리뷰 리스트
const getMyNoEvaluationList = async (pageParams) =>
  await getRequest({
    url: `/api/v1/mypage/my-no-evaluation-list`,
    params: { ...pageParams },
  });

// 작성한 리뷰 리스트
const getMyGoodEvaluationList = async (pageParams) =>
  await getRequest({
    url: `/api/v1/mypage/my-good-evaluation-list`,
    params: { ...pageParams },
  });

// 작성 가능한 리뷰 리스트 총건수, 작성한 리뷰 리스트 총건수
const getMyPageReviewTotalCount = async () =>
  await getRequest({
    url: `/api/v1/mypage/my-evaluation-total-count`,
    params: {},
  });

// 작성한 리뷰 삭제
const deleteMyEvaluation = async (goodEvaluationNumber, goodNumber) =>
  // putRequest({
  //   url: `/api/v1/mypage/delete-my-evaluation`,
  //   params: { goodEvalNo },
  // });
  await postRequest({
    url: `/api/v1/good-review/${goodEvaluationNumber}`,
    data: { goodNumber },
  });
const postMyGoodEvaluation = async (data) =>
  await postRequest({
    url: '/api/v1/mypage/create-good-review',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const myReviewApi = {
  getMyNoEvaluationList,
  getMyGoodEvaluationList,
  getMyPageReviewTotalCount,
  deleteMyEvaluation,
  postMyGoodEvaluation,
};
export default myReviewApi;
