import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { theme } from '@ecp/common/src/style/theme/default';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { zIndex as zIndexConst } from '@ecp/common/src/style/constant';
import { floatingClassNames, placements } from '@ecp/common/src/components/unstyled/floating/useCustomFloating';
import FloatingUnstyled from '@ecp/common/src/components/unstyled/floating/FloatingUnstyled';
import ShareUrlIcon from '@ecp/common/src/assets/icon/share__url.svg';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

export const defaultArrowSize = 8;
export const defaultArrowPosition = (-1 * defaultArrowSize) / 2;
export const StyledTooltip = styled.div`
  width: max-content;
  border: 1px solid ${theme.color.text.basic};
  padding: ${({ closeable }) => (closeable ? '14px 28px 14px 14px' : '14px')};
  background-color: ${theme.color.background.white};
  max-width: ${({ maxWidth = '300px' }) => maxWidth};
  z-index: ${({ zIndex = zIndexConst.tooltip }) => zIndex};

  &:focus-visible {
    outline: none;
  }
`;

export const StyledArrowCss = css`
  &.${floatingClassNames.right} {
    transform: rotate(225deg);
  }
  &.${floatingClassNames.left} {
    transform: rotate(45deg);
  }
  &.${floatingClassNames.top} {
    transform: rotate(135deg);
  }
  &.${floatingClassNames.bottom} {
    transform: rotate(315deg);
  }
`;
export const StyledArrow = styled.div`
  background: transparent;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: ${theme.color.text.basic};
  width: ${() => `${defaultArrowSize}px`};
  height: ${() => `${defaultArrowSize}px`};
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${theme.color.text.basic};

  ${StyledArrowCss}
  &.${floatingClassNames.right} {
    left: ${() => `${defaultArrowPosition - 1}px`};
  }
  &.${floatingClassNames.left} {
    right: ${() => `${defaultArrowPosition - 1}px`};
  }
  &.${floatingClassNames.top} {
    bottom: ${() => `${defaultArrowPosition - 1}px`};
  }
  &.${floatingClassNames.bottom} {
    top: ${() => `${defaultArrowPosition - 1}px`};
  }
`;
export const StyledArrowInner = styled.div`
  width: 0;
  height: 0;
  border-right-width: ${() => `${defaultArrowSize}px`};
  border-right-style: solid;
  border-right-color: ${theme.color.background.white};
  border-bottom-width: ${() => `${defaultArrowSize}px`};
  border-bottom-style: solid;
  border-bottom-color: transparent;

  ${StyledArrowCss}
  &.${floatingClassNames.right} {
    left: ${() => `${defaultArrowPosition}px`};
  }
  &.${floatingClassNames.left} {
    right: ${() => `${defaultArrowPosition}px`};
  }
  &.${floatingClassNames.top} {
    bottom: ${() => `${defaultArrowPosition}px`};
  }
  &.${floatingClassNames.bottom} {
    top: ${() => `${defaultArrowPosition}px`};
  }
`;

export const StyledClose = styled(TooltipCloseIcon)`
  border: none;
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  position: inherit;
  top: 14px;
  right: 14px;
  cursor: pointer;
`;

const Tooltip = ({ children, ...props }) => {
  return (
    <FloatingUnstyled
      blockAutoFocus
      rootComponent={StyledTooltip}
      arrowComponent={StyledArrow}
      arrowInnerComponent={StyledArrowInner}
      closeComponent={<StyledClose />}
      offset={3 - defaultArrowPosition}
      arrowPadding={14}
      useShift
      shiftPadding={10}
      {...props}
    >
      {children}
    </FloatingUnstyled>
  );
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  children: PropTypes.node,
  placement: PropTypes.oneOf(placements),
};

Tooltip.defaultProps = {
  closeWhenOutside: true,
};

export default Tooltip;

export const StyleShareToolTip = styled(StyledTooltip)`
  padding: 10px;
  border-color: ${theme.color.line.background};
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;

export const StyleShareArrow = styled(StyledArrow)`
  border-right-color: ${theme.color.line.background};
  border-top-color: ${theme.color.line.background};
`;

export const StyledShareIcon = styled.div`
  background: url(${ShareUrlIcon}) no-repeat;
  ${overrideProperties(sizePropTypes, { width: '30px', height: '30px' })};
  cursor: pointer;
`;

StyledShareIcon.displayName = 'StyledShareIcon';
