import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useStaticImageHostname } from '@ecp/common/src/hooks/interface/useHostname';

const WpayMyPageForm = ({ myPageData }) => {
  const myPageForm = useRef(null);
  const hostname = useStaticImageHostname();

  useEffect(() => {
    if (myPageData.mid) {
      myPageForm.current.submit();
    }
  }, [myPageData]);

  return (
    <div style={{ display: 'none' }}>
      {/* wpay myPage */}
      <form
        name="form_postsubmit_mypage"
        id="form_postsubmit_mypage"
        action={`${process.env.REACT_APP_INICIS_WPAY_HOST}/stdwpay/su/mypage`}
        method="post"
        ref={myPageForm}
      >
        <input type="hidden" name="mid" id="mid" value={myPageData.mid} />
        <input type="hidden" name="wpayUserKey" id="wpayUserKey" value={myPageData.wpayUserKeyEnc} />
        <input type="hidden" name="cancelReturnUrl" id="cancelReturnUrl" value={myPageData.cancelReturnUrlEncoded} />
        <input type="hidden" name="signature" id="signature" value={myPageData.signature} />

        <input type="hidden" name="titleBarColor" id="titleBarColor" value="#eeeeee" />
        <input
          type="hidden"
          name="tiltleBarBiImgUrl"
          value={`${hostname}/contents/static/images/welstorypay_logo.png`}
        />
        <input
          type="hidden"
          name="content"
          id="content"
          value="가맹점에서 간편하게 클릭한번으로<br>구매 가능한 결제서비스 입니다."
        />
        <input type="hidden" name="authBtnColor" id="authBtnColor" value="#333333" />
        <input type="hidden" name="authBtnTextcolor" id="authBtnTextcolor" value="#ffffff" />
        <input type="hidden" name="closeBtnType" id="closeBtnType" value="A" />
      </form>
    </div>
  );
};

export default WpayMyPageForm;

WpayMyPageForm.propTypes = {
  myPageData: PropTypes.object,
};
WpayMyPageForm.displayName = 'WpayMyPage';
