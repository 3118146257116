import { useState, useCallback, useEffect } from 'react';

const initInfo = {
  gradeInfo: {
    memberGradeNumber: '',
    memberGradeName: '',
  },
  nextGradeInfo: {
    memberNumber: '',
    currentDate: '',
    expectedGrade: {
      memberGradeNumber: '',
      memberGradeName: '',
      standardBuyCount: 0,
      standardBuyAmount: 0.0,
    },
    amountOfCurrentMonth: 0,
    countOfCurrentMonth: 0,
    nextGradeOfExpectedGrade: {
      memberGradeNumber: '',
      memberGradeName: '',
      standardBuyCount: 4,
      standardBuyAmount: 30.0,
    },
    needAmount: 0,
    needCount: 0,
    isReachedToTopGrade: false,
  },
};

const useMemberGrade = ({ myMembershipApi, memberNumber }) => {
  const [gradeInfo, setGradeInfo] = useState({ ...initInfo.gradeInfo });
  const [nextGradeInfo, setNextGradeInfo] = useState({ ...initInfo.nextGradeInfo });

  const getCurrentGradeInfo = useCallback(async () => {
    const { result } = await myMembershipApi.getUserCurrentGradeInfo();
    setGradeInfo(result);
  }, [myMembershipApi]);

  const getNextGradeInfo = useCallback(async () => {
    const { result } = await myMembershipApi.getUserNextGradeInfo();
    setNextGradeInfo(result);
  }, [myMembershipApi]);

  useEffect(() => {
    memberNumber &&
      (async () => {
        await getCurrentGradeInfo();
        await getNextGradeInfo();
      })();
  }, [getCurrentGradeInfo, getNextGradeInfo, memberNumber]);

  return {
    gradeInfo,
    nextGradeInfo,
  };
};

export default useMemberGrade;
