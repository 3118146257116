import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { MAIN_FUNCTION_CODE } from '@ecp/common/src/const/display/mainTemplateConst';
import { BANNER_CONTENTS_DIVISION_CODE } from '@ecp/common/src/const/display/bannerConst';

import { bannerMainFunctionState } from '@fo-recoil/banner/atom';
import displayApi from '@fo-apis/display/displayApi';

const useBanner = (mainFunctionKindCode, bannerList, setBannerList, maxExposureCount = 20) => {
  const navigate = useNavigate();
  const [bannerMainFunction, setBannerMainFunction] = useRecoilState(bannerMainFunctionState);

  useEffect(() => {
    (async () => {
      if (!bannerList?.length) {
        const { result } = await displayApi.getBannerList(mainFunctionKindCode);
        setBannerList(result?.bannerList.slice(0, maxExposureCount));
        setBannerMainFunction((prev) => ({ ...prev, [mainFunctionKindCode]: result?.mainFunction }));
      }
    })();
  }, [bannerList?.length, mainFunctionKindCode, maxExposureCount, setBannerList, setBannerMainFunction]);

  const handleClick = (e, index, item) => {
    const targetUrl = item.bannerPcLinkUrl;
    if (!targetUrl) {
      return;
    }
    if (item.bannerPcLinkBlank) {
      window.open(targetUrl, '_blank');
    } else if (item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC) {
      window.location.href = targetUrl;
    } else {
      navigate(targetUrl);
    }
  };

  const dsLabel = useMemo(() => {
    switch (mainFunctionKindCode) {
      case MAIN_FUNCTION_CODE.AD_BANNER:
        return 'g_ad_banner';
      case MAIN_FUNCTION_CODE.STRIP_BANNER_ONE:
        return 'banner_1';
      case MAIN_FUNCTION_CODE.STRIP_BANNER_TWO:
        return 'banner_2';
      case MAIN_FUNCTION_CODE.STRIP_BANNER_COMMON:
      default:
        return 'common_banner';
    }
  }, [mainFunctionKindCode]);

  return { handleClick, mainFunction: bannerMainFunction?.[mainFunctionKindCode], dsLabel };
};

export default useBanner;
