import { useCallback } from 'react';

import useEventCallback from '@ecp/common/src/hooks/useEventCallback';

import useControlled from '../../../hooks/useControlled';

const useCheckbox = (props) => {
  const { checked: checkedProp, defaultChecked, onChange, disabled, ...others } = props;

  const { value: checked, setValueWhenUncontrolled } = useControlled({
    value: checkedProp,
    defaultValue: defaultChecked,
  });

  const handleChange = useCallback(
    (event) => {
      setValueWhenUncontrolled(event.target.checked);
      onChange?.(event);
    },
    [onChange, setValueWhenUncontrolled]
  );

  const handleClick = useEventCallback(() => {}, { stop: true, extractDataset: false });

  const getInputProps = (otherProps = {}) => ({
    ...others,
    ...otherProps,
    checked,
    disabled,
    type: 'checkbox',
    onChange: handleChange,
    onClick: handleClick,
  });

  return {
    checked,
    disabled,
    getInputProps,
    handleClick,
  };
};

export default useCheckbox;
