import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import CouponBaseCard from '@ecp/common/src/components/coupon/components/CouponBaseCard';
import { COUPON_PROPERTY, COUPON_STYLE_PC } from '@ecp/common/src/const/coupon/couponConst';

const FirstOrderCouponCard = ({ baseStyle, handleButtonClick }) => {
  const CardMessage = useMemo(() => {
    return (
      <FlexBoxColumn style={baseStyle.MESSAGE_STYLE} justify-content={'flex-start'} align-items={'flex-start'}>
        <FlexBoxColumn justify-contnet={'flex-start'} align-items={'flex-start'}>
          <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'첫 구매에 쓰시라고'}</TextBoxAlignLeft>
          <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'무료배송 & 할인쿠폰을 쏙 넣어드려요'}</TextBoxAlignLeft>
        </FlexBoxColumn>
      </FlexBoxColumn>
    );
  }, [baseStyle.MESSAGE, baseStyle.MESSAGE_STYLE]);

  return (
    <CouponBaseCard
      downloadable
      baseStyle={baseStyle}
      iconImage={COUPON_PROPERTY.FIRST_ORDER.iconImage}
      buttonText={COUPON_PROPERTY.FIRST_ORDER.buttonText}
      handleButtonClick={handleButtonClick}
      altText={'첫 구매에 쓰시라고 무료배송 & 할인쿠폰을 쏙 넣어드려요 ,' + COUPON_PROPERTY.FIRST_ORDER.buttonText}
    >
      {CardMessage}
    </CouponBaseCard>
  );
};

FirstOrderCouponCard.propTypes = {
  baseStyle: PropTypes.object,
  handleButtonClick: PropTypes.func,
};

FirstOrderCouponCard.defaultProps = {
  baseStyle: COUPON_STYLE_PC,
  handleButtonClick: () => {},
};

export default FirstOrderCouponCard;
