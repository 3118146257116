import theme from '@ecp/common/src/style/theme/default';
import FirstIcon from '@ecp/common/src/assets/icon/icon-first-order.gif';
import BirthdayIcon from '@ecp/common/src/assets/icon/icon-birthday.gif';
import ReviewIcon from '@ecp/common/src/assets/icon/icon-review.gif';
import BrandCategoryIcon from '@ecp/common/src/assets/icon/icon-brand-category.gif';
import DownloadIcon from '@ecp/common/src/assets/icon/icon-download.gif';
import MembershipIcon from '@ecp/common/src/assets/icon/icon-membership.gif';

export const EMPTY_COUPON_CODE_MSG = '쿠폰번호를 입력해주세요.';
export const NOT_EMPTY_GUIDE_MSG = '공백없이 입력해주세요.';
export const NO_RESULT_COUPON_LIST_MESSAGE = '쿠폰 내역이 없습니다.';
export const TYPE_COUPON_NUMBER_GUIDE_MSG = '보유하신 쿠폰번호를 입력해주세요.';
export const REGISTER_COMPLETE = '쿠폰이 정상적으로 등록되었습니다.';

export const MY_COUPON_LIST_NOTICE_MESSAGE = [
  '사용하신 쿠폰은 취소나 반품시 환원되는 것을 기준으로 하나, 부분취소나 부분반품의 경우에는 재발행 혹은 복구 되지 않습니다.',
  '유효기간이 경과한 쿠폰은 사용하실 수 없으며 재발급되지 않습니다.',
  '쿠폰 사용 조건에 따라 최소 구매 금액과 최대 할인 금액이 있으며, 각 쿠폰별 적용 상품에 할인 가능합니다.',
  '주문서 페이지에서 직접 할인 쿠폰을 선택한 후 할인 적용을 받을 수 있습니다.',
  '순금/골드 바/돌 반지/상품권 등 환금성 카테고리 및 일부 상품 등에는 쿠폰 적용이 제외됩니다.',
  '첫구매 쿠폰/생일 쿠폰/멤버십 쿠폰의 경우, 다운로드 후 기간 중 ID당 1회 사용 가능합니다.',
  '당사 정책에 따라 사전 통보 없이 쿠폰 회수 또는 조기 종료될 수 있습니다.',
];
export const COUPON_SORT_TYPE = {
  ISSUED_DESC: 'OC_YM_DTIME,DESC',
  DEADLINE_ASC: 'AVAL_END_DTIME,ASC',
};

export const COUPON_TYPE = {
  FIRST_ORDER: 'FIRST_ORDER',
  BIRTHDAY: 'BIRTHDAY',
  REVIEW: 'REVIEW',
  BRAND: 'BRAND',
  BRAND_LIST: 'BRAND_LIST',
  CATEGORY: 'CATEGORY',
  DOWNLOAD: 'DOWNLOAD',
  MEMBERSHIP: 'MEMBERSHIP',
};

export const COUPON_PROPERTY = {
  FIRST_ORDER: {
    buttonText: '쿠폰 다운받기',
    cardColor: '#FCF8D3',
    iconImage: FirstIcon,
  },
  BIRTHDAY: {
    buttonText: '쿠폰 다운받기',
    cardColor: '#FCF8D3',
    iconImage: BirthdayIcon,
  },
  REVIEW: {
    buttonText: '리뷰 작성 하러 가기',
    cardColor: '#E4ECFF',
    iconImage: ReviewIcon,
    moveUrl: '/my-page/review',
  },
  BRAND: {
    buttonText: '쿠폰 확인 하러 가기',
    cardColor: '#ECECEC',
    iconImage: BrandCategoryIcon,
  },
  BRAND_LIST: {
    buttonText: '쿠폰 확인 하러 가기',
    cardColor: '#ECECEC',
    iconImage: BrandCategoryIcon,
  },
  CATEGORY: {
    buttonText: '쿠폰 확인 하러 가기',
    cardColor: '#ECECEC',
    iconImage: BrandCategoryIcon,
  },
  DOWNLOAD: {
    buttonText: '쿠폰 확인 하러 가기',
    cardColor: '#F0F6DD',
    iconImage: DownloadIcon,
    moveUrl: '/my-page/coupon',
  },
  MEMBERSHIP: {
    buttonText: '쿠폰 확인 하러 가기',
    cardColor: '#FBEBFD',
    iconImage: MembershipIcon,
    moveUrl: '/my-page/membership',
    gradeCounts: {
      SILVER: 1,
      GOLD: 2,
      PLATINUM: 3,
      SVIP: 5,
    },
  },
};

export const COUPON_STYLE_PC = {
  CARD: { width: '380px', height: '220px', padding: '30px' },
  MESSAGE_STYLE: { width: '100%' },
  MESSAGE: {
    fontSize: theme.font.size.label,
    fontWeight: 700,
    lineHeight: theme.font.lineHeight.demiLight,
  },
  DISCOUNT_SPACING: '10px',
  DISCOUNT: { fontSize: theme.font.size.large, fontWeight: theme.font.weight.medium },
  DISCOUNT_UNIT: { fontSize: theme.font.size.label, fontWeight: theme.font.weight.medium },
  BUTTON: { fontSize: theme.font.size.small, fontWeight: 700 },
  POINT: { fontSize: theme.font.size.small, fontWeight: 700 },
  POINT_UNIT: { fontSize: theme.font.size.error, fontWeight: 350 },
  POINT_LOCATION: {
    review: { top: '40px', right: '14px', color: theme.color.text.white },
  },
  COUNT: { fontSize: theme.font.size.title, fontWeight: 700 },
  COUNT_UNIT: {
    fontSize: theme.font.size.basic,
    fontWeight: 350,
  },
  COUNT_LOCATION: {
    default: { bottom: '40px', right: '54px', color: theme.color.text.white },
    download: { bottom: '42px', right: '48px', color: theme.color.text.white },
    membership: { bottom: '14px', right: '52px', color: '#88341A' },
    review: { bottom: '12px', right: '42px', color: theme.color.text.basic },
  },
  ICON: { width: '134px', height: '134px', bottom: '8px', right: '8px' },
};

export const COUPON_STYLE_MO = {
  CARD: { width: '260px', height: '160px', padding: '14px' },
  MESSAGE_STYLE: { width: '114px' },
  MESSAGE: {
    fontSize: theme.font.size.small,
    fontWeight: theme.font.weight.medium,
    lineHeight: theme.font.lineHeight.medium,
  },
  DISCOUNT_SPACING: '14px',
  DISCOUNT: { fontSize: theme.font.size.popup, fontWeight: 700 },
  DISCOUNT_UNIT: { fontSize: theme.font.size.basic, fontWeight: theme.font.weight.medium },
  BUTTON: { fontSize: theme.font.size.small, fontWeight: 700 },
  POINT: { fontSize: theme.font.size.tiny, fontWeight: 700 },
  POINT_UNIT: { fontSize: '9px', fontWeight: 350 },
  POINT_LOCATION: {
    review: { top: '25px', right: '10px', color: theme.color.text.white },
  },
  COUNT: { fontSize: theme.font.size.error, fontWeight: 700 },
  COUNT_UNIT: { fontSize: '9px', fontWeight: 400 },
  COUNT_LOCATION: {
    default: { bottom: '26px', right: '40px', color: theme.color.text.white },
    download: { bottom: '28px', right: '34px', color: theme.color.text.white },
    membership: { bottom: '7px', right: '36px', color: '#88341A' },
    review: { bottom: '6px', right: '32px', color: theme.color.text.basic },
  },
  ICON: { width: '95px', height: '95px', bottom: '10px', right: '6px' },
};

export const COUPON_POPUP_TYPE = {
  BRAND_LIST: 'brand-list',
  BRAND: 'brand',
  CATEGORY: 'category',
};

export const COUPON_TYPE_CODE = {
  BRAND: '14',
  CATEGORY: '15',
};

export const PROMOTION_TYPE = {
  GOOD: '021',
  CART: '022',
  DELIVERY: '023',
  DISCOUNT: '081',
  SAVE: '011',
  OFFLINT_EVENT: '091'
};
export const BENEFIT_TYPE = {
  PERCENT: '10',
  AMOUNT: '20',
  PRICE: '30',
};

export const DEVICE_TYPE = {
  ALL: '10',
  PC: '20',
  MOBILE: '30',
  APP: '40',
};
