import React, { useContext, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import RadioContext from './RadioContext';

export const radioClassNames = {
  disabled: 'radio-disabled',
  checked: 'radio-checked',
};

const RadioUnstyled = React.forwardRef(
  ({ productName, price, numberWrap, statusName, value, disabled, children, labelComponent: LabelComponent, inputComponent: InputComponent, ...props }, ref) => {
    const { hideControl, className } = props;

    const group = useContext(RadioContext);
    const radioRef = useRef(null);

    useImperativeHandle(ref, () => radioRef.current);

    const stateClasses = {
      [radioClassNames.disabled]: disabled,
      [radioClassNames.checked]: (value ?? '').toString() === (group.value ?? '').toString(),
    };
    return (
      <LabelComponent {...props} className={clsx(stateClasses, className)}>
        {productName ? (
          <InputComponent
            type={'radio'}
            name={group.name}
            disabled={disabled || group.disabled}
            value={value}
            className={clsx(stateClasses)}
            checked={group.value ? value === group.value : ''}
            onChange={(e) => group.onChange && group.onChange(e)}
            hidden={hideControl && 'hidden'}
            title={productName+', '+price+', '+numberWrap+', '+statusName}
          />
        ) : (
          <InputComponent
            type={'radio'}
            name={group.name}
            disabled={disabled || group.disabled}
            value={value}
            className={clsx(stateClasses)}
            checked={group.value ? value === group.value : ''}
            onChange={(e) => group.onChange && group.onChange(e)}
            id={value}
            // hidden={hideControl && 'hidden'}
          />
        )
        }
        {children && typeof children !== 'string'
          ? React.cloneElement(children, { className: clsx(stateClasses) })
          : children}
      </LabelComponent>
    );
  }
);

RadioUnstyled.displayName = 'radio';

RadioUnstyled.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  hideControl: PropTypes.bool,
  className: PropTypes.string,
  labelComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  inputComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.string]),
  statusName: PropTypes.string,
  productName: PropTypes.string,
  price: PropTypes.string,
  numberWrap: PropTypes.string,
};

RadioUnstyled.defaultProps = {
  labelComponent: 'label',
  inputComponent: 'input',
};

export default RadioUnstyled;
