import { getRequest, postRequest } from '@fo-apis/axios';

const getMobiliansInformation = () => {
  return getRequest({
    url: '/api/v1/interfaces/mobilians/information',
  });
};

const checkMobiliansForged = (data) =>
  postRequest({
    url: '/api/v1/interfaces/mobilians/isForged',
    data: { ...data },
  });

const getMobiliansHpInformation = (data) => {
  return postRequest({
    url: '/api/v1/interfaces/mobilians',
    data: { ...data },
  })
}

const mobiliansApi = {
  getMobiliansInformation,
  checkMobiliansForged,
  getMobiliansHpInformation,
};

export default mobiliansApi;
