import { selector } from 'recoil';

import {
  cartAvailableCouponList,
  cartBadgeCountAtom,
  cartDownloadCouponList,
  couponGoodInfo,
  myDeliveryPlaceListState,
  orderInfoState,
} from './atom';

export const withCartBadgeCount = selector({
  key: 'withCartBadgeCount',
  get: ({ get }) => {
    const { count } = get(cartBadgeCountAtom);
    return count;
  },
  set: ({ set }, count) => set(cartBadgeCountAtom, { count }),
});

export const cartDownloadCouponResult = selector({
  key: 'cartDownloadCouponResult',
  get: ({ get }) => {
    return get(cartDownloadCouponList);
  },
});

export const cartAvailableCouponResult = selector({
  key: 'cartAvailableCouponResult',
  get: ({ get }) => {
    return get(cartAvailableCouponList);
  },
});

export const couponGoodInfoResult = selector({
  key: 'couponGoodInfoResult',
  get: ({ get }) => {
    return get(couponGoodInfo);
  },
});
/**
 * 배송지 수정 selector
 * @type orderInfoState.orderDeliveryList
 */
export const modifyOrderDeliveryCreateOrder = selector({
  key: 'modifyOrderDeliveryCreateOrder',
  get: ({ get }) => {
    const orderInfo = get(orderInfoState);
    return orderInfo.orderDeliveryList;
  },
  set: ({ set }, newDeliveryLocation) => {
    set(orderInfoState, (prev) => {
      const newDeliveryList = [];
      const { orderDeliveryList } = prev;

      for (const deliveryLocation of orderDeliveryList) {
        let curDeliveryLocation = {
          ...deliveryLocation,
        };

        if (newDeliveryLocation.memberDeliverySequence === deliveryLocation.memberDeliverySequence) {
          curDeliveryLocation = {
            ...curDeliveryLocation,
            ...newDeliveryLocation,
          };
        }
        newDeliveryList.push(curDeliveryLocation);
      }

      return { ...prev, orderDeliveryList: newDeliveryList };
    });
  },
});

export const myBaseDeliveryPlace = selector({
  key: 'myBaseDeliveryPlace',
  get: ({ get }) => {
    return get(myDeliveryPlaceListState)?.find(({ base }) => base);
  },
});
