import { useCallback, useId, useRef } from 'react';

import useControlled from '../../../hooks/useControlled';

const useRadioGroup = (props = {}) => {
  const { defaultValue, value: valueProp, disabled, onChange } = props;
  const { value, setValueWhenUncontrolled } = useControlled({
    value: valueProp,
    defaultValue,
  });

  const ref = useRef(null);
  const name = useId();
  const handleChange = useCallback(
    (event) => {
      setValueWhenUncontrolled(event.target.value);

      onChange?.(event);
    },
    [setValueWhenUncontrolled, onChange]
  );

  const getGroupProps = useCallback(
    (props = {}) => ({
      ...props,
      value,
      name,
      disabled: disabled,
      onChange: handleChange,
    }),
    [value, name, disabled, handleChange]
  );
  return {
    value,
    getGroupProps,
    handleChange,
    ref,
  };
};

export default useRadioGroup;
