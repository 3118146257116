import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { positionPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import FavoriteCheckBox from '@ecp/common/src/components/checkbox/FavoriteCheckBox';
import Badge from '@ecp/common/src/components/badge/Badge';
import { BADGE_TYPE } from '@ecp/common/src/const/good';

import BgDelete from '@fo-assets/image/common/button/btn_image_delete.svg';

const FavoriteDecorator = ({ badgeContent: valueProp, onChange, ...badgeProps }) => {
  const [value, setValue] = useState(valueProp);

  useEffect(() => {
    setValue(valueProp);
  }, [valueProp]);

  const handleChange = useCallback(
    (e) => {
      e.stopPropagation();
      onChange?.(e.target.checked);
      setValue(e.target.checked);
    },
    [onChange, setValue]
  );

  const randomComponentId = (id) => {
    return id.concat(Math.floor(Math.random() * (9999 - 1) + 1));
  };

  return (
    <DecoratorRoot {...badgeProps}>
      <FavoriteCheckBox
        id={randomComponentId('wish')}
        checked={value}
        onChange={handleChange}
        title={badgeProps.title}
      />
    </DecoratorRoot>
  );
};
FavoriteDecorator.propTypes = {
  badgeContent: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

const DeleteButton = styled.button`
  position: absolute;
  right: 4px;
  top: 4px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url(${BgDelete}) no-repeat 50%;
  font-size: 0;
  line-height: 0;
  cursor: pointer;
`;

const DeleteDecorator = ({ onClick, ...badgeProps }) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <DecoratorRoot {...badgeProps}>
      <DeleteButton onClick={handleClick} />
    </DecoratorRoot>
  );
};
DeleteDecorator.propTypes = {
  badgeContent: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

const GoodBadge = React.forwardRef(({ type, cardType, imageWidth: size, rootProps, ...props }, ref) => {
  let sizeProps = {};
  let positionProps = {};
  let otherProps = {};
  let badgeComponent;

  switch (type) {
    case BADGE_TYPE.LARGE:
      sizeProps = { width: '50px', height: '50px' };
      positionProps = { top: 0, left: 0 };
      badgeComponent = LargeDecorator;
      break;
    case BADGE_TYPE.PERCENT:
      sizeProps = { width: '50px', height: '50px' };
      positionProps = { top: 0, left: 0 };
      badgeComponent = PercentDecorator;
      otherProps = { max: 100 };
      break;
    case BADGE_TYPE.SMALL:
      sizeProps = { width: '30px', height: '30px' };
      positionProps = { top: 0, left: 0 };
      badgeComponent = SmallDecorator;
      break;
    case BADGE_TYPE.FAVORITE:
      sizeProps = { width: '20px', height: '20px' };
      positionProps = cardType === 'gridSmall' ? { bottom: '8px', right: '8px' } : { bottom: '10px', right: '10px' };
      badgeComponent = FavoriteDecorator;
      break;
    case BADGE_TYPE.DELETE:
      sizeProps = { width: '18px', height: '18px' };
      positionProps = { top: '4px', right: '4px' };
      badgeComponent = DeleteDecorator;
      break;
    case BADGE_TYPE.BOTTOM:
      sizeProps = { width: '98px', height: '30px' };
      positionProps = { bottom: '1px', left: '1px' };
      badgeComponent = BottomDecorator;
      break;
    case BADGE_TYPE.RECENT:
      sizeProps = { width: '26px', height: '18px' };
      positionProps = { top: 0, right: 0 };
      badgeComponent = RecentDecorator;
      break;
  }

  const newProps = {
    ...otherProps,
    badgeComponent,
    size: sizeProps,
    position: positionProps,
  };
  return <Badge {...props} rootProps={{ width: size, height: size, ...rootProps }} {...newProps} ref={ref} />;
});

GoodBadge.displayName = 'GoodBadge';
GoodBadge.propTypes = {
  type: PropTypes.string,
  cardType: PropTypes.string,
  imageWidth: PropTypes.string,
  badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  rootProps: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

const DefaultBadgeRoot = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  ${overrideProperties(sizePropTypes, {})};
`;

GoodBadge.defaultProps = {
  rootComponent: DefaultBadgeRoot,
  cardType: '',
};

export default GoodBadge;

const DecoratorRoot = styled(FlexBox)`
  position: absolute;
  justify-content: center;
  ${overrideProperties(positionPropTypes, {})};
  ${overrideProperties(sizePropTypes, {})};
`;

const CornerDecorator = styled(DecoratorRoot)`
  border-radius: 8px 0 8px;
  color: ${theme.color.text.white};
  font-size: ${theme.font.size.table};
  font-weight: ${theme.font.weight.bold};
`;

const LargeDecorator = styled(CornerDecorator)`
  background: ${({ badgeContent }) =>
    typeof badgeContent === 'number' && badgeContent > 3 ? theme.color.third : theme.color.primary};
`;

const SmallDecorator = styled(CornerDecorator)`
  background: ${({ badgeContent }) =>
    typeof badgeContent === 'number' && badgeContent > 3 ? theme.color.third : theme.color.primary};
`;

const PercentDecorator = styled(CornerDecorator)`
  background: ${theme.color.primary};
  &::after {
    content: '%';
    font-size: ${theme.font.size.error};
    font-weight: ${theme.font.weight.medium};
    padding-top: 3px;
  }
`;

const RecentDecorator = styled(DecoratorRoot)`
  padding: 3px 1px 0 0;
  border-radius: 0 8px;
  background: ${theme.color.background.basic};
  color: ${theme.color.text.basic};
  border: 1px solid ${theme.color.line.basic};
  font-size: ${theme.font.size.error};
  font-weight: ${theme.font.weight.medium};
`;

const BottomDecorator = styled(CornerDecorator)`
  border-radius: 0 0 8px 8px;
  background: ${theme.color.third};
  opacity: 0.5;
  font-style: normal;
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.error};
  line-height: ${theme.font.lineHeight.demiLight};
  text-align: right;
  color: ${theme.color.text.white};
`;
