import React from 'react';

import WpayMyPageComp from '@ecp/common/src/components/interface/WpayMyPageComp';

import { useAlertDialog } from '@fo-hooks/common/useModal';
import wpayApi from '@fo-apis/interface/wpayApi';

const WpayMyPage = () => {
  const { showAlertDialog } = useAlertDialog();

  return <WpayMyPageComp wpayApi={wpayApi} showAlertDialog={showAlertDialog} />;
};

export default WpayMyPage;
