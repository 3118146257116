import isEmpty from 'lodash-es/isEmpty';
import groupBy from 'lodash-es/groupBy';
import invert from 'lodash-es/invert';

const KOR_CONSONANT_LIST = ['가', '나', '다', '라', '마', '바', '사', '아', '자', '차', '카', '타', '파', '하'];

const ENG_CONSONANT_LIST = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const ALL_CONSONANT = '전체';

const ETC_CONSONANT = '기타';

const initialSearchCondition = {
  searchKeyword: '',
  largeDisplayCategoryNumber: '',
  initialConsonant: '',
  tabIndex: 0,
};

const consonantMap = {
  ['ㄱ']: '가',
  ['ㄴ']: '나',
  ['ㄷ']: '다',
  ['ㄹ']: '라',
  ['ㅂ']: '바',
  ['ㅁ']: '마',
  ['ㅅ']: '사',
  ['ㅇ']: '아',
  ['ㅈ']: '자',
  ['ㅊ']: '차',
  ['ㅋ']: '카',
  ['ㅌ']: '타',
  ['ㅍ']: '파',
  ['ㅎ']: '하',
  ['A']: 'A',
  ['B']: 'B',
  ['C']: 'C',
  ['D']: 'D',
  ['E']: 'E',
  ['F']: 'F',
  ['G']: 'G',
  ['H']: 'H',
  ['I']: 'I',
  ['J']: 'J',
  ['K']: 'K',
  ['L']: 'L',
  ['M']: 'M',
  ['N']: 'N',
  ['O']: 'O',
  ['P']: 'P',
  ['Q']: 'Q',
  ['R']: 'R',
  ['S']: 'S',
  ['T']: 'T',
  ['U']: 'U',
  ['V']: 'V',
  ['W']: 'W',
  ['X']: 'X',
  ['Y']: 'Y',
  ['Z']: 'Z',
  ['ETC']: '기타',
};

const invertConsonantMap = invert(consonantMap);

const fromConsonantMapper = (consonant) => {
  return consonantMap[consonant] || '';
};

const toConsonantMapper = (consonant) => {
  return invertConsonantMap[consonant] || consonant;
};

const hasSearchCondition = (searchCondition) => {
  return (
    !isEmpty(searchCondition.searchKeyword) ||
    !isEmpty(searchCondition.largeDisplayCategoryNumber) ||
    !isEmpty(searchCondition.initialConsonant)
  );
};

const getConsonantFilteredList = (brandGroup, consonantList) => {
  return consonantList.filter((it) => brandGroup[it]?.brandList?.length > 0);
};

const getBrandData = (result) => {
  const brandCountList = result.countList.map((it) => ({
    ...it,
    consonantLabel: fromConsonantMapper(it?.consonant),
  }));
  const brandList = result.brandList.map((it) => ({
    ...it,
    consonantLabel: fromConsonantMapper(it?.initialConsonant),
  }));
  const brandGroup = Object.entries(groupBy(brandList, 'consonantLabel')).reduce((acc, [consonant, brandList]) => {
    acc[consonant] = {
      brandList,
      page: 1,
    };
    return acc;
  }, {});

  return {
    brandTotalCount: result.totalCount,
    brandCountGroup: groupBy(brandCountList, 'consonantLabel'),
    brandGroup,
  };
};

const getConsonantBrandTotalPage = (brandCountGroup, consonant, perPageSize) => {
  const consonantCount = brandCountGroup[consonant]?.reduce((acc, elem) => acc + elem.count, 0);
  return Math.ceil(consonantCount / perPageSize);
};

export {
  KOR_CONSONANT_LIST,
  ENG_CONSONANT_LIST,
  ALL_CONSONANT,
  ETC_CONSONANT,
  initialSearchCondition,
  fromConsonantMapper,
  toConsonantMapper,
  hasSearchCondition,
  getConsonantFilteredList,
  getBrandData,
  getConsonantBrandTotalPage,
};
