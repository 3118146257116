import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import { zIndex } from '@ecp/common/src/style/constant';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import PopupUnstyled from '@ecp/common/src/components/unstyled/floating/PopupUnstyled';
import { PopupButton } from '@ecp/common/src/components/button/Button';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

const Popup = (props) => {
  const {
    open,
    onClose,
    rootStyleProps,
    header,
    headerProps,
    closable,
    closeButtonType,
    useCancelButton,
    cancelButtonText,
    onCancelClick,
    cancelButtonProps,
    useConfirmButton,
    confirmButtonText,
    onConfirmClick,
    confirmButtonProps,
    useScroll,
    bodyRef,
    ...rest
  } = props;

  const bottomButtonProps = {
    useCancelButton,
    cancelButtonText,
    onCancelClick,
    cancelButtonProps,
    useConfirmButton,
    confirmButtonText,
    onConfirmClick,
    confirmButtonProps,
  };

  return (
    <PopupUnstyled
      open={open}
      onClose={onClose}
      rootComponent={StyledPopupRoot}
      rootStyleProps={rootStyleProps}
      headerComponent={header}
      headerProps={headerProps}
      bodyComponent={StyledBodyComponent}
      bodyProps={{ useScroll, ref: bodyRef }}
      bottomButtonComponent={<StyledBottomButtonComponent {...bottomButtonProps} />}
      closable={closable}
      closeComponent={
        closeButtonType === 'Primary' ? (
          <CloseButton aria-label="닫기" type="button">
            <DefaultCloseComponent />
          </CloseButton>
        ) : (
          <></>
        )
      }
      {...rest}
    />
  );
};

export default Popup;

Popup.displayName = 'Popup';

Popup.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  rootStyleProps: PropTypes.object,
  header: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node, PropTypes.string]),
  headerProps: PropTypes.object,
  closable: PropTypes.bool,
  closeButtonType: PropTypes.oneOf(['Primary', 'Secondary']),
  useCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  onCancelClick: PropTypes.func,
  cancelButtonProps: PropTypes.object,
  useConfirmButton: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  onConfirmClick: PropTypes.func,
  confirmButtonProps: PropTypes.object,
  useScroll: PropTypes.bool,
  bodyRef: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.node]),
};

Popup.defaultProps = {
  headerProps: {
    size: '20px',
    weight: '700',
    height: '32px',
    style: { padding: '20px 30px' },
  },
  closable: true,
  closeButtonType: 'Primary',
  useCancelButton: true,
  cancelButtonProps: {
    type: 'Secondary',
  },
  cancelButtonText: '취소',
  useConfirmButton: true,
  confirmButtonProps: {
    type: 'Primary',
  },
  confirmButtonText: '확인',
  useScroll: true,
};

const StyledPopupRoot = React.forwardRef(({ children, ...props }, ref) => {
  const rootRef = useRef(null);

  useImperativeHandle(ref, () => rootRef.current);

  return (
    <RemoveScroll>
      <FloatingOverlay
        style={{
          zIndex: zIndex.popup,
          display: 'grid',
          background: 'rgba(25, 25, 25, 0.8)',
          placeItems: 'center',
        }}
      >
        <PopupWrapper ref={rootRef} {...props}>
          {children}
        </PopupWrapper>
      </FloatingOverlay>
    </RemoveScroll>
  );
});

StyledPopupRoot.displayName = 'StyledPopupRoot';

StyledPopupRoot.propTypes = {
  children: PropTypes.node,
};

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
  height: ${({ height }) => (height ? height : 'fit-content')};
  background-color: ${theme.color.background.white};
  border-radius: 8px;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : '800px')};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '320px')};

  &:focus-visible {
    outline: none;
  }
`;

const StyledBodyComponent = styled(FlexBox)`
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  overflow-y: ${({ useScroll }) => (useScroll ? 'auto' : 'hidden')};
  overflow-x: hidden;
  padding: 0 30px;
`;

export const StyledBottomButtonComponent = (props) => {
  const {
    useCancelButton,
    cancelButtonProps,
    onCancelClick,
    cancelButtonText,
    useConfirmButton,
    confirmButtonProps,
    onConfirmClick,
    confirmButtonText,
    gap = '10px',
    padding = '20px 30px 30px',
  } = props;

  return (
    <>
      {useCancelButton || useConfirmButton ? (
        <FlexBoxCenter gap={gap} padding={padding}>
          {useCancelButton && (
            <PopupButton {...cancelButtonProps} onClick={onCancelClick}>
              {cancelButtonText}
            </PopupButton>
          )}
          {useConfirmButton && (
            <PopupButton {...confirmButtonProps} onClick={onConfirmClick}>
              {confirmButtonText}
            </PopupButton>
          )}
        </FlexBoxCenter>
      ) : (
        <Spacing top={30} />
      )}
    </>
  );
};

StyledBottomButtonComponent.propTypes = {
  useCancelButton: PropTypes.bool,
  cancelButtonProps: PropTypes.object,
  onCancelClick: PropTypes.func,
  cancelButtonText: PropTypes.string,
  useConfirmButton: PropTypes.bool,
  confirmButtonProps: PropTypes.object,
  onConfirmClick: PropTypes.func,
  confirmButtonText: PropTypes.string,
  gap: PropTypes.string,
  padding: PropTypes.string,
};

const DefaultCloseComponent = styled(TooltipCloseIcon)`
  border: none;
  width: 20px;
  height: 20px;
  background-color: ${theme.color.background.white};
  // position: absolute;
  // top: 25px;
  // right: 30px;
  cursor: pointer;
`;
const CloseButton = styled.button`
  border: none;
  width: 20px;
  height: 20px;
  background-color: ${theme.color.background.white};
  position: absolute;
  top: 25px;
  right: 30px;
  cursor: pointer;
`;
