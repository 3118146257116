import NetFunnel from "./netfunnel.debug";

export function NetFunnel_Action(arg0: {}, arg1: () => void) {
    NetFunnel.NetFunnel_Action(arg0, arg1);
};
export function NetFunnel_Complete(arg0: {}, arg1: () => void) {
    NetFunnel.NetFunnel_Complete(arg0, arg1);
};
export function NetFunnel_cookieExist() {
    return NetFunnel.NetFunnel_cookieExist();
}
