import React, { useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import { theme } from '@ecp/common/src/style/theme/default';
import { overrideProperties } from '@ecp/common/src/style/utils';
import { numberOrStringType, sizePropTypes } from '@ecp/common/src/style/propTypes';
import { defaultProps, flexPropTypes } from '@ecp/common/src/layouts/flex/styled';
import ButtonUnstyled, { buttonClassNames } from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';

const buttonDefaultSizeValues = {
  width: 'fit-content',
  height: 'fit-content',
};

export const StyledButton = styled.button`
  ${overrideProperties(flexPropTypes, { ...defaultProps, ...buttonDefaultSizeValues })};
  ${overrideProperties({ minWidth: PropTypes.string }, { minWidth: undefined })};
  ${flexCenter};
  border-radius: ${({ borderRadius = '4px' }) => borderRadius};
  text-align: center;
  color: ${theme.color.background.white};
  border: none;
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.basic};
  cursor: pointer;
  position: relative;

  &:disabled {
    background-color: ${theme.color.background.disabled};
    color: ${theme.color.text.disabled};
    cursor: not-allowed;
  }
`;

const StyledPrimaryButton = styled(StyledButton)`
  background-color: ${theme.color.primary};

  &.${buttonClassNames.pressed} {
    background-color: ${theme.color.primary};

    &:hover:not(:disabled) {
      background-color: ${theme.color.primary};
    }
  }

  &:hover:not(:disabled) {
    background-color: ${theme.color.hover.primary};
  }
`;

const StyledSecondaryButton = styled(StyledButton)`
  background-color: ${theme.color.secondary};

  &:hover:not(:disabled) {
    background-color: ${theme.color.hover.secondary};
  }

  &.${buttonClassNames.pressed} {
    background-color: ${theme.color.secondary};

    &:hover:not(:disabled) {
      background-color: ${theme.color.secondary};
    }
  }
`;

const StyledThirdButton = styled(StyledButton)`
  background-color: ${theme.color.third};

  &:hover:not(:disabled) {
    background-color: ${theme.color.hover.third};
  }
`;

const StyledDefaultButton = styled(StyledButton)`
  color: ${theme.color.text.basic};
  border: 1px solid ${theme.color.secondary};
  background-color: ${theme.color.background.white};

  &:disabled {
    border-color: ${theme.color.line.disabled};
  }

  &:hover:not(:disabled) {
    background-color: ${theme.color.hover.default};
  }

  &.${buttonClassNames.pressed} {
    background-color: ${theme.color.background.white};

    &:hover:not(:disabled) {
      background-color: ${theme.color.background.white};
    }
  }
`;

const pageButtonCss = css`
  font-size: ${theme.font.size.table};
`;

const StyledPagePrimaryButton = styled(StyledPrimaryButton)`
  ${pageButtonCss};
`;

const StyledPageSecondaryButton = styled(StyledSecondaryButton)`
  ${pageButtonCss};
`;

const StyledPageThirdButton = styled(StyledThirdButton)`
  ${pageButtonCss};
`;

const StyledPageDefaultButton = styled(StyledDefaultButton)`
  ${pageButtonCss};
`;

const StyledInnerButton = styled(StyledButton).attrs(({ size = theme.font.size.basic, style }) => ({
  style: {
    ...style,
    fontSize: size,
  },
}))`
  color: ${theme.color.text.basic};
  background-color: ${theme.color.background.white};
  border: 1px solid ${theme.color.text.guide};

  &:hover:not(:disabled) {
    background-color: ${theme.color.hover.default};
  }

  &:disabled {
    color: ${theme.color.text.disabled};
    background-color: ${theme.color.disabled};
    border: none;
  }

  &.${buttonClassNames.pressed} {
    background: ${theme.color.background.white};

    &:hover:not(:disabled) {
      background-color: ${theme.color.background.white};
    }
  }
`;

const popupButtonCss = css`
  font-size: ${theme.font.size.basic};
`;

const StyledPopupPrimaryButton = styled(StyledPrimaryButton)`
  ${popupButtonCss};
`;

const StyledPopupSecondaryButton = styled(StyledSecondaryButton)`
  ${popupButtonCss};
`;

const StyledPopupThirdButton = styled(StyledThirdButton)`
  ${popupButtonCss};
`;

const styledButtonComponents = {
  page: {
    Primary: StyledPagePrimaryButton,
    Secondary: StyledPageSecondaryButton,
    Third: StyledPageThirdButton,
    Default: StyledPageDefaultButton,
  },
  popup: {
    Primary: StyledPopupPrimaryButton,
    Secondary: StyledPopupSecondaryButton,
    Third: StyledPopupThirdButton,
  },
};

export const PageButton = React.forwardRef(({ type = 'Primary', ...props }, ref) => {
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);
  const RootComponent = useMemo(() => styledButtonComponents['page'][type], [type]);
  return <ButtonUnstyled ref={buttonRef} rootComponent={RootComponent} {...props} />;
});

PageButton.displayName = 'PageButton';

PageButton.propTypes = {
  type: PropTypes.oneOf(['Primary', 'Secondary', 'Third', 'Default']),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  ...sizePropTypes,
};

PageButton.defaultProps = {
  type: 'Primary',
  disabled: false,
  width: '180px',
  height: '50px',
};

export const PopupButton = ({ type = 'Primary', ...props }) => {
  const RootComponent = useMemo(() => styledButtonComponents['popup'][type], [type]);
  return <ButtonUnstyled rootComponent={RootComponent} {...props} />;
};

PopupButton.displayName = 'PopupButton';
PopupButton.propTypes = {
  type: PropTypes.oneOf(['Primary', 'Secondary', 'Third']),
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  ...sizePropTypes,
};

PopupButton.defaultProps = {
  type: 'Primary',
  disabled: false,
  width: '180px',
  height: '40px',
};

export const InnerButton = React.forwardRef((props, ref) => {
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);

  return <ButtonUnstyled ref={buttonRef} rootComponent={StyledInnerButton} {...props} />;
});
InnerButton.displayName = 'InnerButton';
InnerButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: numberOrStringType,
  ...sizePropTypes,
};

InnerButton.defaultProps = {
  disabled: false,
  width: '74px',
  height: '40px',
};
