import isEmpty from 'lodash-es/isEmpty';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import { GOOD_SORT_TYPE } from '@ecp/common/src/const/good';

const initialSearchConditionInSearchPage = {
  searchTerm: '',
  researchTerm: '',
  typoTerm: '',
  noTypoCorrection: false,
  categoryNumber: {
    large: '',
    middle: '',
    small: '',
    detail: '',
    current: '',
  },
  brandList: [],
  price: { min: '', max: '', label: '' },
};

const initialPageParamInSearchPage = {
  page: 1,
  countPerPage: 60,
  sort: GOOD_SORT_TYPE[0],
  hyundaiGoodYn: '',
  listView: false,
};

const initialSearchConditionInMoSearchPage = {
  tab: 'CATEGORY',
  searchTerm: '',
  researchTerm: '',
  typoTerm: '',
  noTypoCorrection: false,
  categoryNumber: {
    large: '',
    middle: '',
    small: '',
    detail: '',
    current: '',
  },
  brandList: [],
  price: { min: '', max: '', label: '' },
};

export const initialPageParamInMoSearchPage = {
  page: 1,
  countPerPage: 20,
  sort: GOOD_SORT_TYPE[0],
  hyundaiGoodYn: '',
  listView: false,
};

export const SEARCH_ERROR_CODE = {
  OK: '00',
  TYPO_WORD: '10',
  BANNED_WORD: '20',
  NO_SEARCH_RESULT: '30',
  SERVER_ERROR: '40',
};

export const SEARCH_TAB_TYPE = {
  HISTORY: 'keywordHistory',
  REAL_TIME: 'realTimeKeyword',
};

export const initialCurrentCategory = {
  depthNumber: 0,
  categoryNumber: {
    large: '',
    middle: '',
    small: '',
    detail: '',
    current: '',
  },
};

const getInitialFilterInfo = (initialFilterInfo, searchParams) => ({
  ...initialFilterInfo,
  searchTerm: searchParams.get('searchTerm') || '',
  researchTerm: searchParams.get('researchTerm') || '',
  typoTerm: searchParams.get('typoTerm') || '',
  noTypoCorrection: searchParams.get('noTypoCorrection') === 'true',
  categoryNumber: {
    large: searchParams.get('large') || '',
    middle: searchParams.get('middle') || '',
    small: searchParams.get('small') || '',
    detail: searchParams.get('detail') || '',
    current:
      searchParams.get('detail') ||
      searchParams.get('small') ||
      searchParams.get('middle') ||
      searchParams.get('large') ||
      '',
  },
  brandList: searchParams.get('brandNumber')?.split(',') || [],
  price: {
    min: searchParams.get('min') || '',
    max: searchParams.get('max') || '',
    label: getLabelByPrice(searchParams.get('min') || '', searchParams.get('max') || ''),
  },
});

const getInitialPageInfo = (initialPageInfo, searchParams) => ({
  countPerPage: searchParams.get('countPerPage')
    ? Number(searchParams.get('countPerPage'))
    : initialPageInfo.countPerPage,
  page: searchParams.get('page') ? Number(searchParams.get('page')) : initialPageInfo.page,
  sort: searchParams.get('sort')
    ? GOOD_SORT_TYPE.find(({ key }) => key === searchParams.get('sort'))
    : initialPageInfo.sort,
  hyundaiGoodYn: searchParams.get('hyundaiGoodYn') || initialPageInfo.hyundaiGoodYn,
  listView: searchParams.get('listView') || initialPageInfo.listView,
});

export const getQueryString = (filterInfo, pageInfo) => {
  const searchTerm = filterInfo.searchTerm ? `searchTerm=${encodeURIComponent(filterInfo.searchTerm)}` : '';
  const researchTerm = filterInfo.researchTerm ? `researchTerm=${encodeURIComponent(filterInfo.researchTerm)}` : '';
  const typoTerm = filterInfo.typoTerm ? `typoTerm=${encodeURIComponent(filterInfo.typoTerm)}` : '';
  const noTypoCorrection = filterInfo.noTypoCorrection ? `noTypoCorrection=${filterInfo.noTypoCorrection}` : '';
  const large = filterInfo.categoryNumber?.large ? `large=${filterInfo.categoryNumber.large}` : '';
  const middle = filterInfo.categoryNumber?.middle ? `middle=${filterInfo.categoryNumber.middle}` : '';
  const small = filterInfo.categoryNumber?.small ? `small=${filterInfo.categoryNumber.small}` : '';
  const detail = filterInfo.categoryNumber?.detail ? `detail=${filterInfo.categoryNumber.detail}` : '';
  const brandNumber =
    filterInfo.brandList?.length > 0 ? `brandNumber=${encodeURI(filterInfo.brandList.join(','))}` : '';
  const min = filterInfo.price?.min ? `min=${filterInfo.price.min}` : '';
  const max = filterInfo.price?.max ? `max=${filterInfo.price.max}` : '';

  const countPerPage = pageInfo.countPerPage ? `countPerPage=${pageInfo.countPerPage}` : '';
  const page = pageInfo.page ? `page=${pageInfo.page}` : '';
  const sort = pageInfo.sort ? `sort=${pageInfo.sort.key}` : '';
  const hyundaiGoodYn = pageInfo.hyundaiGoodYn ? `hyundaiGoodYn=${pageInfo.hyundaiGoodYn}` : '';
  const listView = pageInfo.listView ? `listView=${pageInfo.listView}` : '';

  return [
    searchTerm,
    researchTerm,
    typoTerm,
    noTypoCorrection,
    large,
    middle,
    small,
    detail,
    brandNumber,
    min,
    max,
    countPerPage,
    page,
    sort,
    hyundaiGoodYn,
    listView,
  ]
    .filter((it) => !isEmpty(it))
    .join('&');
};

export const getLabelByPrice = (min, max) => {
  if (!min && !max) {
    return '';
  }
  if (!min) {
    return `${NumberFormat(max)}원 미만`;
  }
  if (!max) {
    return `${NumberFormat(min)}원 이상`;
  }
  return `${NumberFormat(min)}원 ~ ${NumberFormat(max)}원`;
};

export const getPriceByRange = (range) => {
  const priceList = range?.replace(/,/g, '').trim().split('~');
  const min = priceList?.[0].trim();
  const max = priceList?.length > 1 ? priceList?.[1].trim() : '';

  return {
    min: min,
    max: max,
    label: getLabelByPrice(min, max),
  };
};

export const getGoodSearchParam = (searchCondition, hyundaiGoodYn) => {
  const minPrice = searchCondition?.price?.min;
  const maxPrice = searchCondition?.price?.max;
  return {
    searchTerm: searchCondition?.searchTerm || '',
    researchTerm: searchCondition?.researchTerm || '',
    brandNumber: searchCondition?.brandList.join(',') ?? '',
    largeCategoryNumber: searchCondition?.categoryNumber?.large || '',
    middleCategoryNumber: searchCondition?.categoryNumber?.middle || '',
    smallCategoryNumber: searchCondition?.categoryNumber?.small || '',
    detailCategoryNumber: searchCondition?.categoryNumber?.detail || '',
    price: !minPrice && !maxPrice ? '' : minPrice + '_' + maxPrice,
    hyundaiGoodYn: hyundaiGoodYn || '',
  };
};

export const getInitialQueryString = ({
  searchTerm = '',
  typoTerm = '',
  noTypoCorrection = false,
  countPerPage = 60,
}) => {
  return getQueryString({ searchTerm, typoTerm, noTypoCorrection }, { countPerPage, page: 1, sort: { key: 1 } });
};

export const getFilterTypeBrandOrPrice = (type, label) => {
  if ('default' === type) {
    if (label === '가격') {
      return 'PRICE';
    } else if (label === '카테고리') {
      return 'CATEGORY';
    } else {
      return 'BRAND';
    }
  }
  if (type === 'price') {
    return 'PRICE';
  } else if (type === 'category') {
    return 'CATEGORY';
  } else {
    return 'BRAND';
  }
};
export const navigateWithFilterInfoTable = {
  pathName: '/search',
  initSearchCondition: initialSearchConditionInSearchPage,
  initPageParam: initialPageParamInSearchPage,
  getQueryString: getQueryString,
  getFilterInfo: getInitialFilterInfo,
  getPageInfo: getInitialPageInfo,
};

export const mobileNavigateWithFilterInfoTable = {
  pathName: '/search/result',
  initSearchCondition: initialSearchConditionInMoSearchPage,
  initPageParam: initialPageParamInMoSearchPage,
  getQueryString: getQueryString,
  getFilterInfo: getInitialFilterInfo,
  getPageInfo: getInitialPageInfo,
};

export default {
  SEARCH_ERROR_CODE,
  SEARCH_TAB_TYPE,
  initialCurrentCategory,
  getLabelByPrice,
  getPriceByRange,
  getGoodSearchParam,
  getQueryString,
  getInitialQueryString,
  getFilterTypeBrandOrPrice,
  navigateWithFilterInfoTable,
  mobileNavigateWithFilterInfoTable,
  initialPageParamInMoSearchPage,
};
