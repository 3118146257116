import { useCallback } from 'react';

import useCustomFloating from '../floating/useCustomFloating';
import useControlled from '../../../hooks/useControlled';

const useDropdown = ({
  items,
  onChange,
  placeholder,
  itemSlot,
  itemsMaxHeight,
  dropdownItemsRoot,
  disabled,
  readOnly,
  placement,
  open: openProp,
  onClick,
  value: valueProp,
  defaultValue: defaultValueProp,
  openBorderColor,
  type,
}) => {
  const { open, setOpen, refs, getReferenceProps, floatingPosition, positionStrategy, floatingClassName } =
    useCustomFloating({
      openProp,
      closeWhenOutside: true,
      openWhenClicked: !disabled && !readOnly,
      placement,
    });

  const { value, setValueWhenUncontrolled } = useControlled({
    value: valueProp,
    defaultValue: defaultValueProp,
  });

  const handleClickLabel = useCallback(() => {
    onClick?.();
  }, [onClick]);

  const handleItemClick = useCallback(
    (item) => {
      setValueWhenUncontrolled(item);
      onChange?.(item);
      setOpen(false);
    },
    [setValueWhenUncontrolled, setOpen, onChange]
  );

  const getLabelProps = useCallback(() => {
    const item = items.find(({ key }) => key === value?.key) || value;
    return { item, placeholder, isFlip: floatingClassName === 'top' };
  }, [value, placeholder, floatingClassName, items]);

  const getSelectProps = useCallback(
    () => ({
      items,
      onItemClick: handleItemClick,
      itemSlot,
      itemsMaxHeight,
      selectRoot: dropdownItemsRoot,
      isFlip: floatingClassName === 'top',
      selectedItems: value ? [value] : [],
      openBorderColor,
      type,
    }),
    [
      items,
      handleItemClick,
      itemSlot,
      itemsMaxHeight,
      dropdownItemsRoot,
      floatingClassName,
      value,
      openBorderColor,
      type,
    ]
  );

  return {
    value,
    handleClickLabel,
    getLabelProps,
    getSelectProps,
    open,
    refs,
    getReferenceProps,
    floatingPosition,
    positionStrategy,
  };
};

export default useDropdown;
