import { useCallback } from 'react';

import { endOfToday, isAfter, isBefore, startOfToday } from 'date-fns';

export const NO_END_DATE = '2999-12-31 23:59';

const useCalendar = ({ startMinDate, startDate, endDate, onStartChange, onEndChange, onAllDateChange }) => {
  const handleStartToday = useCallback(() => {
    const today = startOfToday();

    if (isAfter(today, endDate)) {
      onAllDateChange?.(today, today);
    } else {
      onStartChange?.(today);
    }
  }, [endDate, onAllDateChange, onStartChange]);

  const handleEndToday = useCallback(() => {
    const today = endOfToday();

    if (isAfter(startDate, today)) {
      onAllDateChange?.(today, today);
    } else {
      onEndChange?.(today);
    }
  }, [onAllDateChange, onEndChange, startDate]);

  const handleStartChange = useCallback(
    (date) => {
      if (startMinDate && isBefore(date, startMinDate)) return;
      onStartChange?.(date && isAfter(date, endDate) ? endDate : date);
    },
    [endDate, onStartChange, startMinDate]
  );

  const handleEndChange = useCallback(
    (date) => {
      onEndChange?.(date && isBefore(date, startDate) ? startDate : date);
    },
    [onEndChange, startDate]
  );

  return {
    handleStartToday,
    handleEndToday,
    handleStartChange,
    handleEndChange,
  };
};

export default useCalendar;
