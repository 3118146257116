import PropTypes from 'prop-types';

export const zeroOrStringType = PropTypes.oneOfType([PropTypes.oneOf([0]), PropTypes.string]);
export const numberOrStringType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export const sizePropTypes = {
  width: zeroOrStringType,
  height: zeroOrStringType,
};
export const maxSizePropTypes = {
  maxWidth: zeroOrStringType,
  maxHeight: zeroOrStringType,
};
export const minSizePropTypes = {
  minWidth: zeroOrStringType,
  minHeight: zeroOrStringType,
};

export const paddingPropTypes = {
  'padding-top': zeroOrStringType,
  'padding-right': zeroOrStringType,
  'padding-left': zeroOrStringType,
  'padding-bottom': zeroOrStringType,
  padding: zeroOrStringType,
};

export const backgroundPropTypes = {
  'background-color': PropTypes.string,
  'background-image': PropTypes.string,
  'background-position': PropTypes.string,
  'background-repeat': PropTypes.string,
  'background-size': PropTypes.string,
  background: PropTypes.string,
};

export const positionPropTypes = {
  top: numberOrStringType,
  right: numberOrStringType,
  bottom: numberOrStringType,
  left: numberOrStringType,
};

export const fontPropTypes = {
  'font-size': numberOrStringType,
  'font-weight': numberOrStringType,
  color: PropTypes.string,
  'text-decoration': PropTypes.string,
  'line-height': PropTypes.string,
};

export const flexItemPropTypes = {
  'flex-basis': PropTypes.string,
  'flex-grow': PropTypes.string,
  'flex-shrink': PropTypes.string,
  flex: PropTypes.string,
  order: PropTypes.string,
  'align-self': PropTypes.string,
};
