import React, { useMemo, useEffect, useRef, useImperativeHandle } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { debounce } from 'lodash-es/function';

import { FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { SwiperMenu } from '@ecp/common/src/components/swiper/SwiperTemplate';

import { withLargeCategoryList } from '@fo-recoil/display/best/selector';

const LargeCategorySwiper = React.forwardRef(({ handleClick, hasNewGood, currentCategoryNumber, ...props }, ref) => {
  const swiperRef = useRef(null);
  const largeCategoryList = useRecoilValue(withLargeCategoryList);

  const categories = useMemo(
    () => largeCategoryList.filter((category) => (hasNewGood ? !!category.hasNewGood : true)),
    [hasNewGood, largeCategoryList]
  );

  useImperativeHandle(ref, () => swiperRef?.current);

  useEffect(() => {
    if (!currentCategoryNumber) {
      return;
    }

    const index = categories.findIndex(({ displayGroupNumber }) => displayGroupNumber === currentCategoryNumber);
    if (index < 0) {
      return;
    }

    swiperRef?.current?.swiper?.slideTo(index);
  }, [categories, currentCategoryNumber]);

  const handleChangeCategory = useMemo(
    () =>
      debounce(async ({ previousIndex }, index) => {
        if (previousIndex !== undefined && !isNaN(index) && previousIndex !== index) {
          const categoryNumber = categories[index]?.displayGroupNumber;
          handleClick(categoryNumber, index);
        }
      }, 500),
    [categories, handleClick]
  );

  return (
    <FlexBoxCenter padding={'0 30px'} width={'100%'} {...props}>
      <SwiperMenu
        ref={swiperRef}
        itemList={categories}
        onChange={(e, index) => handleChangeCategory(e, index)}
        width={'1480px'}
      />
    </FlexBoxCenter>
  );
});

LargeCategorySwiper.displayName = 'LargeCategorySwiper';

export default LargeCategorySwiper;

LargeCategorySwiper.propTypes = {
  handleClick: PropTypes.func,
  hasNewGood: PropTypes.bool,
  currentCategoryNumber: PropTypes.string,
};
