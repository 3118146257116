import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { flexCenter } from '@ecp/common/src/style/theme/defaultStyled';
import theme from '@ecp/common/src/style/theme/default';
import useChip from '@ecp/common/src/components/chip/useChip';
import { ReactComponent as ChipClose } from '@ecp/common/src/assets/icon/icon__chip-close.svg';

export const CHIP_TYPE = {
  default: {
    font: theme.color.text.basic,
    border: theme.color.line.disabled,
    background: theme.color.background.white,
    check: {
      color: theme.color.primary,
      fontWeight: '700',
      fontSize: '13px !important',
      'border-color': theme.color.primary,
    },
  },
  brandSelect: {
    font: theme.color.text.sub,
    border: 'transparent',
    background: theme.color.background.sub,
    check: {
      color: theme.color.primary,
      'border-color': theme.color.primary,
      'background-color': theme.color.background.white,
    },
  },
  rescan: {
    font: theme.color.text.basic,
    border: theme.color.line.emphasis,
    background: theme.color.background.white,
    check: {},
  },
  category: {
    font: theme.color.text.category,
    border: theme.color.line.category,
    background: theme.color.background.white,
    check: {},
  },
  brand: {
    font: theme.color.primary,
    border: theme.color.primary,
    background: theme.color.background.white,
    check: {},
  },
  price: {
    font: theme.color.text.price,
    border: theme.color.line.price,
    background: theme.color.background.white,
    check: {},
  },
  hashtag: {
    font: theme.color.text.basic,
    border: theme.color.line.emphasis,
    background: theme.color.background.white,
    check: {
      color: theme.color.primary,
      'border-color': theme.color.primary,
      'background-color': theme.color.background.white,
    },
  },
  title: {
    font: theme.color.text.basic,
    fontSize: theme.font.size.$13,
    border: 'transparent',
    background: 'none',
    style: {
      fontWeight: '700',
      pointerEvents: 'none',
      cursor: 'default',
      padding: 0,
      justifyContent: 'flex-start',
    },
  },
};

export const checkedChipStyle = css`
  ${({ check }) => check}
`;

export const StyledChip = styled.div.attrs(
  ({ width = 'max-content', height = '30px', borderRadius = `calc(${height} / 2)`, style }) => ({
    style: {
      ...style,
      width,
      height,
      borderRadius,
    },
  })
)`
  ${flexCenter};
  gap: 3px;
  font-size: 13px;
  min-width: 50px;
  min-height: 30px;
  border-width: 1px;
  border-style: solid;
  padding: 0 12px;
  cursor: pointer;

  color: ${({ font = theme.color.text.basic }) => font};
  background-color: ${({ background = theme.color.background.white }) => background};
  border-color: ${({ border = theme.color.line.disabled }) => border};

  ${({ checked }) => checked && checkedChipStyle};
`;

export const StyledChipButton = styled.button.attrs(
  ({ width = 'max-content', height = '30px', borderRadius = `calc(${height} / 2)`, style }) => ({
    style: {
      ...style,
      width,
      height,
      borderRadius,
    },
  })
)`
  ${flexCenter};
  gap: 3px;
  font-size: 13px;
  min-width: 50px;
  min-height: 30px;
  border-width: 1px;
  border-style: solid;
  padding: 0 12px;
  cursor: pointer;

  color: ${({ font = theme.color.text.basic }) => font};
  background-color: ${({ background = theme.color.background.white }) => background};
  border-color: ${({ border = theme.color.line.disabled }) => border};
  &:hover {
    background-color: ${theme.color.background.disabled};
  }

  ${({ checked }) => checked && checkedChipStyle};
`;

export const StyledChipATag = styled.a.attrs(
  ({ width = 'max-content', height = '30px', borderRadius = `calc(${height} / 2)`, style }) => ({
    style: {
      ...style,
      width,
      height,
      borderRadius,
    },
  })
)`
  ${flexCenter};
  gap: 3px;
  font-size: 13px;
  min-width: 50px;
  min-height: 30px;
  border-width: 1px;
  border-style: solid;
  padding: 0 12px;
  cursor: pointer;

  color: ${({ font = theme.color.text.basic }) => font};
  background-color: ${({ background = theme.color.background.white }) => background};
  border-color: ${({ border = theme.color.line.disabled }) => border};
  &:hover {
    background-color: ${theme.color.background.disabled};
  }

  ${({ checked }) => checked && checkedChipStyle};
`;

export const StyledChipClose = styled(ChipClose)`
  width: 10px;
  height: 10px;
`;

const Chip = ({ closeIcon: CloseIcon, closable, children, height, width, type, onClick, onClose, ...props }) => {
  const { chipProps, handleClick, handleClose } = useChip({ type, onClick, onClose, ...props });

  return (
    <StyledChipButton
      height={height}
      width={width}
      {...props}
      {...chipProps}
      onClick={handleClick}
      type={'button'}
      title={props.checked ? '선택됨' : ''}
    >
      {children}
      {closable && <CloseIcon {...props} onClick={handleClose} />}
    </StyledChipButton>
  );
};

Chip.displayName = 'Chip';

Chip.propTypes = {
  type: PropTypes.oneOf(['default', 'rescan', 'category', 'brand', 'price', 'hashtag', 'brandSelect', 'title']),
  closeIcon: PropTypes.elementType,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  checked: PropTypes.bool,
  closable: PropTypes.bool,
  ...sizePropTypes,
};

Chip.defaultProps = {
  type: 'default',
  closeIcon: StyledChipClose,
  onClose: () => {},
  closable: true,
};

export default Chip;
