import { useCallback } from 'react';

import { getLocalStorageItem, saveLocalStorageItem } from '@ecp/common/src/utils/utils';

const SEARCH_KEYWORD_LIST_KEY = 'search_keyword_list';
const SEARCH_KEYWORD_SAVE_KEY = 'search_keyword_save';
const SEARCH_RECOMMEND_KEYWORD_LIST_KEY = 'search_recommend_keyword_list';

const useSearchHistory = (userId) => {
  const saveSearchKeywordSave = useCallback(
    (value) => {
      const searchKeywordSave = JSON.parse(getLocalStorageItem(SEARCH_KEYWORD_SAVE_KEY));
      saveLocalStorageItem(SEARCH_KEYWORD_SAVE_KEY, JSON.stringify({ ...searchKeywordSave, [userId]: value }));
    },
    [userId]
  );

  const getSearchKeywordSave = useCallback(() => {
    const searchKeywordSave = JSON.parse(getLocalStorageItem(SEARCH_KEYWORD_SAVE_KEY)) || {};
    if (searchKeywordSave[userId] === null) {
      saveSearchKeywordSave(true);
      return true;
    } else {
      return !!searchKeywordSave[userId];
    }
  }, [saveSearchKeywordSave, userId]);

  const getSearchKeywordList = useCallback(() => {
    const daysAgo = new Date();
    daysAgo.setDate(daysAgo.getDate() - 30);
    const dateAgoStr = daysAgo.toISOString();
    const searchKeywordListMap = JSON.parse(getLocalStorageItem(SEARCH_KEYWORD_LIST_KEY)) || {};
    const searchKeywordList = searchKeywordListMap[userId] || [];

    return searchKeywordList
      .filter(({ searchDate }) => dateAgoStr <= searchDate)
      .sort((a, b) => new Date(b?.searchDate) - new Date(a?.searchDate));
  }, [userId]);

  const addSearchKeyword = (value) => {
    const searchKeywordListMap = JSON.parse(getLocalStorageItem(SEARCH_KEYWORD_LIST_KEY)) || {};
    const updatedKeywordList = [
      { keyword: value, searchDate: new Date().toISOString() },
      ...getSearchKeywordList().filter(({ keyword }) => keyword !== value),
    ];
    saveLocalStorageItem(
      SEARCH_KEYWORD_LIST_KEY,
      JSON.stringify({ ...searchKeywordListMap, [userId]: updatedKeywordList })
    );

    return updatedKeywordList;
  };

  const removeSearchKeyword = (value) => {
    const searchKeywordListMap = JSON.parse(getLocalStorageItem(SEARCH_KEYWORD_LIST_KEY)) || {};
    const updatedKeywordList = [...getSearchKeywordList().filter(({ keyword }) => keyword !== value)];
    saveLocalStorageItem(
      SEARCH_KEYWORD_LIST_KEY,
      JSON.stringify({ ...searchKeywordListMap, [userId]: updatedKeywordList })
    );

    return updatedKeywordList;
  };

  const removeAllSearchKeyword = () => {
    const searchKeywordListMap = JSON.parse(getLocalStorageItem(SEARCH_KEYWORD_LIST_KEY)) || {};
    delete searchKeywordListMap[userId];

    saveLocalStorageItem(SEARCH_KEYWORD_LIST_KEY, JSON.stringify({ ...searchKeywordListMap }));
  };

  const getRecommendSearchKeywordList = () => {
    const searchKeywordListMap = JSON.parse(getLocalStorageItem(SEARCH_RECOMMEND_KEYWORD_LIST_KEY)) || {};

    return searchKeywordListMap[userId]?.slice(0, 10) || [];
  };

  const addRecommendSearchKeywordList = useCallback(
    (list = []) => {
      if (!userId || list?.length === 0) return [];

      const searchKeywordListMap = JSON.parse(getLocalStorageItem(SEARCH_RECOMMEND_KEYWORD_LIST_KEY)) || {};
      const addedKeywordList = list?.slice?.(0, 2).map?.(({ keyword }) => keyword.trim()) || [];
      const originKeywordList = searchKeywordListMap[userId] || [];

      const searchKeywordList = list
        ? [...new Set([...addedKeywordList, ...originKeywordList])].slice(0, 10)
        : originKeywordList;
      saveLocalStorageItem(
        SEARCH_RECOMMEND_KEYWORD_LIST_KEY,
        JSON.stringify({ ...searchKeywordListMap, [userId]: searchKeywordList })
      );

      return searchKeywordList;
    },
    [userId]
  );

  return {
    getSearchKeywordSave,
    saveSearchKeywordSave,
    getSearchKeywordList,
    addSearchKeyword,
    removeSearchKeyword,
    removeAllSearchKeyword,
    getRecommendSearchKeywordList,
    addRecommendSearchKeywordList,
  };
};

export default useSearchHistory;
