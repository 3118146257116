import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { format, parseISO } from 'date-fns';

import { unescapeHtml } from '@ecp/common/src/utils/htmlUtil';
import Popup from '@ecp/common/src/components/modal/Popup';
import { TextButton } from '@ecp/common/src/components/button/TextButton';

import { useAlertDialog } from '@fo-hooks/common/useModal';
import policyApi from '@fo-apis/system/policyApi';
import PolicyPaper from '@fo-components/system/PolicyPaper';
import { PREFIX_HISTORY } from './const';

const NO_DATA_MESSAGE = '조회된 데이터가 없습니다.';

const PolicyPopupButton = ({
  title,
  buttonProps = { weight: '700' },
  buttonType = 'basic',
  additionalTypeCd,
  buttonText: button,
  showHistory = true,
  textColor,
}) => {
  const { showAlertDialog } = useAlertDialog();
  const [open, setOpen] = useState(false);
  const [policy, setPolicy] = useState(null);
  const [dropdownList, setDropdownList] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState(null);

  const searchPolicyContent = useCallback(
    async ({ applyStartDateTime }) => {
      const { result } = await policyApi.getPolicyContent({
        additionalTypeCd,
        applyStartDateTime,
      });
      setPolicy(result ? unescapeHtml(result) : '');
    },
    [setPolicy, additionalTypeCd]
  );

  const buttonText = useMemo(() => (!button ? title : button), [button, title]);

  const handleOpen = useCallback(async () => {
    const { result } = await policyApi.getPolicyHistoryList(additionalTypeCd);
    const convertList = result.map(({ applyStartDateTime, siteNo }) => ({
      label: `${PREFIX_HISTORY}${format(parseISO(applyStartDateTime), 'yyyy.MM.dd')}`,
      key: `${additionalTypeCd} ${siteNo} ${applyStartDateTime}`,
      value: { siteNo, additionalTypeCd, applyStartDateTime },
    }));

    if (convertList.length > 0) {
      setDropdownList(convertList);
      setSelectedHistory(convertList[0]);
      await searchPolicyContent(convertList[0].value);
      setOpen(true);
    } else {
      showAlertDialog(NO_DATA_MESSAGE);
    }
  }, [setOpen, additionalTypeCd, searchPolicyContent, showAlertDialog]);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  const handleSelectBoxChanged = useCallback(
    async (item) => {
      setSelectedHistory(item);
      await searchPolicyContent(item.value);
    },
    [searchPolicyContent]
  );

  return (
    <>
      <Popup header={title} open={open} useCancelButton={false} onClose={handleClose} onConfirmClick={handleClose}>
        <PolicyPaper
          items={dropdownList}
          content={policy}
          dropdownValue={selectedHistory}
          showHistory={showHistory}
          onChange={handleSelectBoxChanged}
        />
      </Popup>
      <TextButton type={buttonType} textProps={{ ...buttonProps }} onClick={handleOpen} style={textColor}>
        {buttonText}
      </TextButton>
    </>
  );
};

export default PolicyPopupButton;

PolicyPopupButton.propTypes = {
  title: PropTypes.string,
  isBold: PropTypes.bool,
  buttonProps: PropTypes.object,
  buttonType: PropTypes.string,
  additionalTypeCd: PropTypes.string,
  buttonText: PropTypes.string,
  showHistory: PropTypes.bool,
  textColor: PropTypes.object,
};
