import React from 'react';
import { useRecoilValue } from 'recoil';

import { FlexBoxCenter, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import StyledImg from '@ecp/common/src/components/image/StyledImg';
import { BULK_ORDER_DESCRIPTION } from '@ecp/common/src/const/footer';

import { getPcBulkpImgFilePath, getPcBulkpPhrase } from '@fo-recoil/system/selector';
import { ReactComponent as BulkOrderIcon } from '@fo-assets/icon/icon__bulk_order.svg';

const BulkOrderDescription = () => {
  const CustomLogo = useRecoilValue(getPcBulkpImgFilePath);
  const CustomPhrase = useRecoilValue(getPcBulkpPhrase);

  return (
    <>
      <FlexBoxCenter
        width={'580px'}
        min-height={144}
        style={{ backgroundColor: '#F7F7F7', padding: '25px 67px 30px' }}
        gap={'30px'}
      >
        <BulkOrderIcon />
        <FlexBoxColumn align-items={'flex-start'}>
          <StyledImg src={CustomLogo} width={'145px'} height={'30px'} alt={'웰스토리몰'}/>
          <TextBox size={'15px'} width={'332px'} weight={'500'} style={{ lineHeight: '24px', wordBreak: 'break-word' }}>
            {CustomPhrase}
          </TextBox>
        </FlexBoxColumn>
      </FlexBoxCenter>
      <Spacing top={20} />
      <TextBox size={'14px'} weight={'350'} style={{ lineHeight: '22.4px' }}>
        {BULK_ORDER_DESCRIPTION}
      </TextBox>
    </>
  );
};

export default BulkOrderDescription;
