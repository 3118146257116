import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { clearAccessTokens } from '@ecp/common/src/utils/utils';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { NoneDecorationLink, StyledTextLink } from '@ecp/common/src/text/TextLink';
import ContextMenu from '@ecp/common/src/components/contextmenu/ContextMenu';
import ContextMenuItem from '@ecp/common/src/components/contextmenu/ContextMenuItem';
import StyledImg from '@ecp/common/src/components/image/StyledImg';
import { withCartBadgeCount } from '@fo-recoil/order/selector';
import { myDeliveryPlaceListState } from '@fo-recoil/order/atom';
import { useConfirmDialog } from '@fo-hooks/common/useModal';
import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import authApi from '@fo-apis/common/authApi';
import { MainContentsBox } from '@fo-components/common/styled';
import GnbIconButton from '@fo-pages/home/gnb/GnbIconButton';
import { ReactComponent as IconMyPage } from '@fo-assets/icon/icon__gnb-mypage.svg';
import { ReactComponent as IconOrder } from '@fo-assets/icon/icon__gnb-order.svg';
import { ReactComponent as IconCart } from '@fo-assets/icon/icon__gnb-cart.svg';
import { ReactComponent as IconCustomerService } from '@fo-assets/icon/icon__gnb-cs.svg';
import BulkOrderPopupButton from '@fo-pages/home/footer/popup/bulkOrder/BulkOrderPopupButton';

const GNB_LINK_URLS = {
  MY_PAGE: '/my-page',
  MEMBER: '/member',
  ORDER: '/my-page/order',
  CART: 'cart',
  CUSTOMER_SERVICE: '/customer-service',
};

const GnbHeader = React.forwardRef((searchPhraseList, ref) => {
  const { showConfirmDialog } = useConfirmDialog();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    isMultimall,
    isCartUse,
    systemInformation: { pcGnbImgFilePath },
  } = useSiteInfo();

  const cartBadgeCount = useRecoilValue(withCartBadgeCount);
  const resetDeliveryPlaceList = useResetRecoilState(myDeliveryPlaceListState);

  const logout = useCallback(async () => {
    await authApi.logout();
    clearAccessTokens();
    resetDeliveryPlaceList();
    navigate('/logout');
  }, [navigate, resetDeliveryPlaceList]);

  const handleLogoutClick = useCallback(() => {
    showConfirmDialog('로그아웃 하시겠습니까?', logout);
  }, [logout, showConfirmDialog]);

  const { isMyPage, isOrder, isCart, isCustomerService } = useMemo(
    () => ({
      isMyPage:
        pathname.endsWith(GNB_LINK_URLS.MEMBER) ||
        (!pathname.includes(GNB_LINK_URLS.ORDER) && pathname.includes(GNB_LINK_URLS.MY_PAGE)),
      isOrder: pathname.includes(GNB_LINK_URLS.ORDER),
      isCart: pathname.includes(GNB_LINK_URLS.CART),
      isCustomerService: pathname.includes(GNB_LINK_URLS.CUSTOMER_SERVICE),
    }),
    [pathname]
  );

  return (
    <Wrapper ref={ref}>
      <FlexBox>
        <NoneDecorationLink to={''}>
          <Logo data-ds-label2={'g_logo'} src={pcGnbImgFilePath} />
        </NoneDecorationLink>
      </FlexBox>
      <FlexBox gap={'15px'}>
        <ContextMenu
          openWhenHovered
          placement={'bottom-end'}
          value={<MyPageMenuItems clickWhenLogout={handleLogoutClick} />}
        >
          <GnbIconButton
            iconType={IconMyPage}
            label={'마이페이지'}
            selected={isMyPage}
            to={GNB_LINK_URLS.MY_PAGE}
            data-ds-label2={'g_my_page'}
          />
        </ContextMenu>
        <GnbIconButton
          iconType={IconOrder}
          label={'주문내역'}
          selected={isOrder}
          to={GNB_LINK_URLS.ORDER}
          data-ds-label2={'g_order_history'}
        />
        {isCartUse && (
          <GnbIconButton
            showZero
            iconType={IconCart}
            badgeContent={cartBadgeCount}
            label={'장바구니'}
            selected={isCart}
            to={GNB_LINK_URLS.CART}
            data-ds-label2={'g_basket'}
          />
        )}
        <GnbIconButton
          iconType={IconCustomerService}
          label={'고객센터'}
          selected={isCustomerService}
          to={GNB_LINK_URLS.CUSTOMER_SERVICE}
          data-ds-label2={'g_cs'}
        />
        {!isMultimall && <BulkOrderPopupButton isIcon={true} />}
      </FlexBox>
    </Wrapper>
  );
});

GnbHeader.displayName = 'GnbHeader';
export default GnbHeader;

const MyPageMenuItems = (props) => {
  const { clickWhenLogout: handleLogoutClick } = props;
  return (
    <FlexBoxColumn gap="6px" width="100%" align-items="flex-start">
      <ContextMenuItem size={'13px'} to="/my-page">
        마이페이지
      </ContextMenuItem>
      <ContextMenuItem size={'13px'} to="/my-page/edit">
        내정보수정
      </ContextMenuItem>
      <StyledTextLink size={'13px'} decorationOnHover onClick={handleLogoutClick}>
        로그아웃
      </StyledTextLink>
    </FlexBoxColumn>
  );
};

MyPageMenuItems.propTypes = {
  clickWhenLogout: PropTypes.func,
};

const Wrapper = styled(MainContentsBox)`
  height: 50px;
  justify-content: space-between;
  min-width: 950px;
`;

const Logo = styled(StyledImg)`
  margin-right: 147px;
  width: 145px;
  height: 30px;
`;
