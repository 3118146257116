import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import isEmpty from 'lodash-es/isEmpty';
import { debounce } from 'lodash-es/function';

import { getInitialQueryString, SEARCH_TAB_TYPE } from '@ecp/common/src/utils/good/goodSearchUtil';
import useSearchHistory from '@ecp/common/src/hooks/useSearchHistory';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import SearchInput from '@ecp/common/src/components/input/SearchInput';
import Popover from '@ecp/common/src/components/popover/Popover';

import { userInfoState } from '@fo-recoil/common/user/atom';
import { useAlertDialog, useConfirmDialog } from '@fo-hooks/common/useModal';
import displaySearchApi from '@fo-apis/display/displaySearchApi';
import GnbSearchBoard from '@fo-pages/home/gnb/search/GnbSearchBoard';
import GnbSearchPhrase from '@fo-pages/home/gnb/search/GnbSearchPhrase';

const GnbSearch = ({ searchPhraseList, searchPraseListIndex }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const userInfo = useRecoilValue(userInfoState);
  const {
    addSearchKeyword,
    getSearchKeywordSave,
    getSearchKeywordList,
    saveSearchKeywordSave,
    removeSearchKeyword,
    removeAllSearchKeyword,
    getRecommendSearchKeywordList,
  } = useSearchHistory(userInfo?.userId);

  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [keywordList, setKeywordList] = useState([]);
  const [keywordHistoryOn, setKeywordHistoryOn] = useState(false);
  const [keywordHistoryList, setKeywordHistoryList] = useState([]);
  const [currentTab, setCurrentTab] = useState(SEARCH_TAB_TYPE.HISTORY);

  const { showAlertDialog } = useAlertDialog();
  const { showConfirmDialog } = useConfirmDialog();

  const showInput = useMemo(() => {
    return searchPhraseList?.length === 0 || open || value;
  }, [open, searchPhraseList?.length, value]);

  const fetchAutoCompleteSearchTermList = async (value) => {
    const { result } = await displaySearchApi.getAutoCompleteSearchTermList(value);
    setKeywordList(result);
    if (result?.length > 0) {
      setOpen(true);
    }
  };

  const handleAutoComplete = useMemo(
    () =>
      debounce(async (value) => {
        await fetchAutoCompleteSearchTermList(value);
      }, 200),
    []
  );

  const handleChange = useCallback(
    async (e) => {
      if (!open && !e?.target?.value) {
        setOpen(true);
      }

      setValue(e?.target?.value ?? '');
      const searchTerm = e?.target?.value?.trim?.();
      if (searchTerm) {
        setOpen(false);
        await handleAutoComplete(searchTerm);
      } else {
        setKeywordList([]);
      }
    },
    [handleAutoComplete, open]
  );
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) {
      setKeywordList([]);
      return;
    }

    setKeywordHistoryOn(getSearchKeywordSave());
    setKeywordHistoryList(getSearchKeywordList());
  }, [getSearchKeywordList, getSearchKeywordSave, open]);

  useEffect(() => {
    if (location.pathname === '/search') {
      setValue(searchParams.get('searchTerm'));
    } else {
      setValue('');
    }
    setOpen(false);
  }, [location.pathname, searchParams]);

  const handleSearch = () => {
    keywordSearch();
  };

  const handleSearchClick = () => {
    keywordSearch();
  };

  const keywordSearch = () => {
    const searchTerm = value?.trim?.();
    if (isEmpty(searchTerm)) {
      showAlertDialog('검색어를 입력 해주세요.');
    } else {
      if (keywordHistoryOn) {
        const updatedList = addSearchKeyword(searchTerm);
        setKeywordHistoryList(updatedList);
      }

      navigate({
        pathname: '/search',
        search: getInitialQueryString({
          searchTerm: searchTerm,
          countPerPage: 60,
        }),
      });

      handleClose();
    }
  };

  const handleDeleteKeywordHistory = (keyword) => {
    const updatedList = removeSearchKeyword(keyword);
    setKeywordHistoryList(updatedList);
  };

  const handleDeleteAllKeywordHistory = () => {
    showConfirmDialog('검색어를 전체 삭제 하시겠습니까?', () => {
      removeAllSearchKeyword();
      setKeywordHistoryList([]);
      setValue('');
      setOpen(true);
      setCurrentTab(SEARCH_TAB_TYPE.REAL_TIME);
    });
  };

  const handleHistoryOnOff = (value) => {
    saveSearchKeywordSave(value);
    setKeywordHistoryOn(value);
  };

  const handleClickSearchPhrase = ({ pcUrl, target }) => {
    window.open(pcUrl, target);
  };

  const handleChangeMode = async () => {
    if (value) {
      setOpen(false);
      await fetchAutoCompleteSearchTermList(value);
      return;
    }

    if (!open) {
      setOpen(true);
      const showHistoryFirst = getSearchKeywordSave() && getSearchKeywordList()?.length > 0;
      setCurrentTab(showHistoryFirst ? SEARCH_TAB_TYPE.HISTORY : SEARCH_TAB_TYPE.REAL_TIME);
    }
  };

  const recommendKeywordList = useMemo(() => getRecommendSearchKeywordList(), [getRecommendSearchKeywordList]);

  return (
    <>
      {showInput ? (
        <Popover
          closeWhenOutside
          closeWhenScroll
          placement={'bottom-start'}
          offset={{ mainAxis: 11, crossAxis: 0 }}
          open={open}
          value={
            <GnbSearchBoard
              keyword={value}
              keywordList={keywordList}
              recommendKeywordList={recommendKeywordList}
              keywordHistoryOn={keywordHistoryOn}
              keywordHistoryList={keywordHistoryList}
              currentTab={currentTab}
              onClose={handleClose}
              setKeywordHistoryList={setKeywordHistoryList}
              handleDeleteKeywordHistory={handleDeleteKeywordHistory}
              handleDeleteAllKeywordHistory={handleDeleteAllKeywordHistory}
              handleHistoryOnOff={handleHistoryOnOff}
              handleChangeTab={setCurrentTab}
            />
          }
          onOpenChange={handleClose}
          blockAutoFocus
        >
          <FlexBox width={'500px'}>
            <SearchInput
              clearable
              autoFocus
              type={'main'}
              placeholder={'검색어를 입력해주세요.'}
              title={'검색어'}
              value={value}
              onChange={handleChange}
              onSearchClick={handleSearchClick}
              onEnter={handleSearch}
              onClick={handleChangeMode}
              suffixText={'검색'}
            />
          </FlexBox>
        </Popover>
      ) : (
        <GnbSearchPhrase
          searchPhraseList={searchPhraseList}
          searchPraseListIndex={searchPraseListIndex}
          handleChangeMode={handleChangeMode}
          handleClickSearch={handleClickSearchPhrase}
        />
      )}
    </>
  );
};

export default GnbSearch;

GnbSearch.propTypes = {
  searchPhraseList: PropTypes.array,
  searchPraseListIndex: PropTypes.number,
};
