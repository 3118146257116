import { useEffect, useMemo, useRef } from 'react';

import {
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import isEmpty from 'lodash-es/isEmpty';

import useControlled from '@ecp/common/src/hooks/useControlled';

export const floatingClassNames = {
  right: 'right',
  left: 'left',
  top: 'top',
  bottom: 'bottom',
};

export const placements = [
  'bottom-end',
  'bottom-start',
  'bottom',
  'left-end',
  'left-start',
  'left',
  'right-end',
  'right-start',
  'right',
  'top-end',
  'top-start',
  'top',
];

const useCustomFloating = ({
  placement: placementProp,
  offset: offsetProp,
  arrowPadding,
  openWhenClicked,
  openWhenHovered,
  openWhenFocused,
  closeWhenOutside,
  closeWhenReference,
  closeWhenScroll,
  openProp,
  defaultOpen = false,
  onOpenChange,
  blockAutoFocus,
  useShift = false,
  shiftPadding = 0,
}) => {
  const arrowRef = useRef(null);
  const { value: open, setValueWhenUncontrolled: setOpen } = useControlled({
    value: openProp,
    defaultValue: defaultOpen,
  });

  const {
    x,
    y,
    refs,
    strategy,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    context,
    placement,
  } = useFloating({
    placement: isEmpty(placementProp) || !placements.includes(placementProp) ? 'right' : placementProp,
    open,
    onOpenChange: (v) => {
      setOpen(v);
      onOpenChange?.(v);
    },
    middleware: [
      ...(useShift ? [shift({ padding: shiftPadding })] : []),
      offset(offsetProp),
      arrow({ element: arrowRef, padding: arrowPadding }),
      flip(),
    ],
    whileElementsMounted: (...args) => autoUpdate(...args, { animationFrame: true }),
  });

  const propsList = [
    useClick(context, { enabled: !!openWhenClicked }),
    useHover(context, {
      delay: {
        open: 300,
        close: 0,
      },
      enabled: !!openWhenHovered,
      handleClose: safePolygon({ restMs: 25, buffer: 3 }),
    }),
    useFocus(context, { enabled: !!openWhenFocused }),
    useDismiss(context, {
      outsidePress: !!closeWhenOutside,
      referencePress: !!closeWhenReference,
      ancestorScroll: !!closeWhenScroll,
      referencePressEvent: 'click',
    }),
  ];
  const { getReferenceProps, getFloatingProps } = useInteractions(propsList);

  const floatingClassName = useMemo(() => floatingClassNames[placement.split('-')[0]], [placement]);

  useEffect(() => {
    if (open && !blockAutoFocus) {
      refs.floating.current?.focus?.();
    }
  }, [blockAutoFocus, open, refs.floating]);

  return {
    open,
    setOpen,
    refs,
    getReferenceProps,
    getFloatingProps,
    floatingClassName,
    floatingPosition: {
      top: y,
      left: x,
    },
    positionStrategy: strategy,
    arrowRef,
    arrowPosition: {
      arrowX,
      arrowY,
    },
  };
};

export default useCustomFloating;
