import React, { useState, useEffect, useRef } from 'react';

import { isValidLength } from '@ecp/common/src/utils/InputValidateUtils';
import useEventCallback from '@ecp/common/src/hooks/useEventCallback';
import { FlexBox, FlexBoxColumn, ImageBox, VerticalDivider, LabelFlexBox } from '@ecp/common/src/layouts/flex/styled';
import { TextBox, TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';
import Popup from '@ecp/common/src/components/modal/Popup';
import TabGroup from '@ecp/common/src/components/tab/TabGroup';
import { TabPanel } from '@ecp/common/src/components/tab/TabPanel';
import Input from '@ecp/common/src/components/input/Input';
import { TextButton } from '@ecp/common/src/components/button/TextButton';
import { ReactComponent as DownloadIcon } from '@ecp/common/src/assets/icon/icon__app-download.svg';

import { useAlertDialog } from '@fo-hooks/common/useModal';
import commonApi from '@fo-apis/common/commonApi';
import AndroidQrImg from '@fo-assets/image/image__qr_android.png';
import IosQrImg from '@fo-assets/image/image__qr_ios.png';
import AppleStoreImg from '@fo-assets/image/image__apple_store.png';
import GooglePlayImg from '@fo-assets/image/image__google_play.png';

const MOBILE_NUMBER_ALERT_MSG = '연락처를 정확히 입력해주세요.';
const SEND_SUCCESS_ALERT_MSG = '전송되었습니다.';
const SEND_FAIL_ALERT_MSG = '전송 실패하였습니다.\n다시 시도해주세요.';
const AppDownloadPopupButton = () => {
  const { showAlertDialog } = useAlertDialog();
  const [appDownloadPopupOpen, setAppDownloadPopupOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const handleOnClickSendMsg = async () => {
    if (formValue.mobile.length < validateLength.mobile) {
      showAlertDialog(MOBILE_NUMBER_ALERT_MSG, () => phoneNumRef.current.focus());
      return;
    }
    const { result } = await commonApi.sendAppDownloadSms({ cellphoneNumber: formValue.mobile });
    if (result?.result === 'SUCCESS') {
      showAlertDialog(SEND_SUCCESS_ALERT_MSG);
    } else {
      showAlertDialog(SEND_FAIL_ALERT_MSG);
    }
    setFormValue({ mobile: '' });
  };

  const handlePopupClose = () => {
    setAppDownloadPopupOpen(false);
  };

  useEffect(() => {
    setTabIndex(0);
    setFormValue({ mobile: '' });
  }, [appDownloadPopupOpen]);

  const [formValue, setFormValue] = useState({
    mobile: '',
  });

  const phoneNumRef = useRef(null);

  const validateLength = {
    mobile: 11,
  };

  const handleChangeMobile = useEventCallback(
    (v, data) => {
      if (isValidLength(v, data, validateLength)) {
        setFormValue((prev) => ({ ...prev, [data.key]: v }));
      }
    },
    { stop: true, prevent: true }
  );

  const tabList = [{ tabName: '다운로드 링크 SMS 문자 전송' }, { tabName: 'APP 다운로드 안내' }];

  return (
    <>
      <Popup
        header="APP 다운로드"
        confirmButtonText="SMS 문자 전송"
        open={appDownloadPopupOpen}
        useConfirmButton={tabIndex === 0}
        onCancelClick={handlePopupClose}
        cancelButtonText={tabIndex === 0 ? '취소' : '닫기'}
        onConfirmClick={handleOnClickSendMsg}
        onClose={handlePopupClose}
      >
        <TabGroup
          width="460px"
          height="50px"
          tabItems={tabList}
          deFaultValue={tabIndex}
          onChange={() => setTabIndex((prev) => Math.abs(prev - 1))}
        >
          <TabPanel width="460px">
            <FlexBoxColumn>
              <TextBoxAlignCenter weight="350" height="100px" size="16px" padding="30px">
                <p style={{ lineHeight: '22px' }}>
                  아래에 앱다운로드 링크 SMS 문자 수신을 원하는
                  <br />
                  모바일 번호를 입력 후 <span style={{ fontWeight: 500, lineHeight: '22px' }}>[전송]</span> 버튼을
                  클릭해주세요.
                </p>
              </TextBoxAlignCenter>
              <FlexBox background-color="#F7F7F7" width="460px" height="100px" justify-content="center" gap="10px">
                <LabelFlexBox size="15px" weight="500" htmlFor={'phone'}>
                  휴대전화번호
                </LabelFlexBox>
                <Input
                  ref={phoneNumRef}
                  id="phone"
                  type="text"
                  onlyNumber
                  width={'307px'}
                  placeholder={'-없이 숫자만 입력해주세요 '}
                  value={formValue.mobile}
                  onChange={handleChangeMobile}
                  data-key={'mobile'}
                  title={'대시없이 숫자만 입력해주세요 '}
                />
              </FlexBox>
            </FlexBoxColumn>
          </TabPanel>
          <TabPanel width="460px">
            <FlexBoxColumn>
              <TextBox weight="350" size="16px" padding="30px 0" style={{ textAlign: 'center' }}>
                <p style={{ lineHeight: '22px' }}>
                  앱을 다운로드를 원하시는 기기의 종류를 확인 후 PLAY 스토어 또는
                  <br />
                  APP 스토어에 접속 후, 웰스토리몰 검색 및 설치해주세요.
                </p>
              </TextBox>
              <FlexBox background-color="#F7F7F7" width="460px" height="180px" style={{ border: '1px solid #DFDFDF' }}>
                <FlexBoxColumn width="230px" justify-content="center" gap="20px">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.welstorymall"
                    title="새 창 열림"
                    aria-label="웰스토리몰 APP다운로드 (플레이스토어로 이동합니다.)"
                    style={{ display: 'contents' }}
                  >
                    <ImageBox imageSrc={AndroidQrImg} width={'80px'} height={'80px'} />
                    <ImageBox imageSrc={GooglePlayImg} width={'97px'} height={'20px'} />
                  </a>
                </FlexBoxColumn>
                <VerticalDivider height="140px" />
                <FlexBoxColumn width="230px" justify-content="center" gap="20px">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://itunes.apple.com/us/app/%EC%9B%B0%EC%8A%A4%ED%86%A0%EB%A6%AC%EB%AA%B0/id704167894?mt=8"
                    title="새 창 열림"
                    aria-label="웰스토리몰 APP 다운로드 (앱스토어로 이동합니다.)"
                    style={{ display: 'contents' }}
                  >
                    <ImageBox imageSrc={IosQrImg} width={'80px'} height={'80px'} />
                    <ImageBox imageSrc={AppleStoreImg} width={'91px'} height={'20px'} />
                  </a>
                </FlexBoxColumn>
              </FlexBox>
            </FlexBoxColumn>
          </TabPanel>
        </TabGroup>
      </Popup>
      <TextButton
        type="custom"
        textProps={{ weight: '350' }}
        customIcon={DownloadIcon}
        onClick={() => {
          setAppDownloadPopupOpen(true);
        }}
      >
        APP 다운로드
      </TextButton>
    </>
  );
};

export default AppDownloadPopupButton;
