import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { TextBox, TextBoxAlignCenter } from '@ecp/common/src/text/TextBox';

import { BasicGoodImage } from '@fo-components/good/GoodCard';

const RecentGoodList = ({ title, goodList, emptyListText, onClose }) => {
  const navigate = useNavigate();
  const recentGoodList = useMemo(
    () =>
      goodList.map(({ badgeContent, ...good }) => ({
        ...good,
        itemDecorators: [{ ...(badgeContent && { type: 'RECENT', badgeContent }) }],
      })),
    [goodList]
  );

  const handleGoodClick = (e, goodNumber) => {
    navigate(`/good/${goodNumber}`);
    onClose();
  };

  const handleWishClick = () => {
    onClose();
  };

  return (
    <FlexBoxColumn width={'100%'}>
      <FlexBox width={'100%'}>
        <TextBox size={theme.font.size.title} weight={theme.font.weight.medium}>
          {title}
        </TextBox>
      </FlexBox>
      <Spacing top={10} />
      <ContentWrapper>
        {goodList.length > 0 ? (
          <GoodListWrapper>
            {recentGoodList.map((item, index) => (
              <BasicGoodImage
                key={index}
                {...item}
                onClick={handleGoodClick}
                onWishClick={handleWishClick}
                size={'extraSmall'}
              />
            ))}
          </GoodListWrapper>
        ) : (
          <TextBoxAlignCenter
            width={'100%'}
            height={'350px'}
            size={theme.font.size.label}
            weight={theme.font.weight.demiLight}
            color={theme.color.text.guide}
          >
            {emptyListText}
          </TextBoxAlignCenter>
        )}
      </ContentWrapper>
    </FlexBoxColumn>
  );
};

export default RecentGoodList;

RecentGoodList.propTypes = {
  title: PropTypes.string,
  goodList: PropTypes.array,
  emptyListText: PropTypes.string,
  onClose: PropTypes.func,
};

const GoodListWrapper = styled(FlexBox)`
  flex-wrap: wrap;
  width: 100%;
  max-height: 350px;
  gap: 10px;
  overflow-y: auto;

  & .good-card__image-box:hover {
    & > div {
      outline: 2px solid ${theme.color.hover.third};
      outline-offset: -2px;
    }

    & + [max='99'] {
      padding: 2px 0 0 0;
      margin: 2px;
      width: 24px;
      height: 16px;
      border-radius: 0 6px 0 8px;
      border-top: none;
      border-right: none;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${theme.color.primary};
    border-radius: 5px;
    border: none;
    background-clip: content-box;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-track {
    background: ${theme.color.line.basic};
    border-radius: 5px;
  }
`;

const ContentWrapper = styled(FlexBox)`
  width: 280px;
  min-height: 350px;
  align-items: flex-start;
`;
