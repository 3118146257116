import { getRequest } from '@fo-apis/axios';

const getMainFunctionList = async (mainFunctionDivisionCode) => {
  return await getRequest({
    url: '/api/v1/display/main-function',
    params: { mainFunctionDivisionCode },
  });
};

const getAllianceBarList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/alliance-bar',
  });
};

const getGnbList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/gnb',
  });
};

const getSearchPhraseList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/search-phrase',
  });
};

const getBestTabList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/best',
  });
};

const getEventMallSpeList = async () => {
  return await getRequest({
    url: '/api/v1/display/main-function/event-mall-spe',
  });
};

const mainFunctionApi = {
  getMainFunctionList,
  getAllianceBarList,
  getGnbList,
  getSearchPhraseList,
  getBestTabList,
  getEventMallSpeList,
};

export default mainFunctionApi;
