import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useControlled from '@ecp/common/src/hooks/useControlled';
import { sizePropTypes } from '@ecp/common/src/style/propTypes';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import RadioGroup from '@ecp/common/src/components/radio/RadioGroup';
import Tab, { DefaultTabButton, StyledEmptyTab, InputTab } from '@ecp/common/src/components/tab/Tab';

export const TabGroup = React.forwardRef(
  ({ tabItems, tabSlot: TabSlot, children, onChange, selectedIndex, ...props }, ref) => {
    const { width, defaultValue, value: valueProp, showSingleTab } = props;
    const { value, setValueWhenUncontrolled } = useControlled({
      value: valueProp,
      defaultValue,
    });
    const handleChange = useCallback(
      (event) => {
        setValueWhenUncontrolled(Number(event?.target?.value));
        onChange?.(event);
      },
      [onChange, setValueWhenUncontrolled]
    );

    return (
      <Wrapper width={width}>
        {(showSingleTab || tabItems?.length > 1) && (
          <RadioGroup ref={ref} value={selectedIndex ? selectedIndex : value} onChange={handleChange} {...props}>
            {tabItems.map(({ tabName, width, ...item }, index) =>
              tabName ? (
                <Tab key={index} value={index} width={width} {...item}>
                  <TabSlot
                    firstChild={index === 0}
                    lastChild={index === tabItems?.length - 1}
                    title={index === parseInt(value) ? '선택됨' : ''}
                    htmlFor={index}
                  >
                    {tabName}
                  </TabSlot>
                </Tab>
              ) : (
                <StyledEmptyTab key={'empty' + index}></StyledEmptyTab>
              )
            )}
          </RadioGroup>
        )}
        {children.map
          ? children
              .filter((child) => child)
              .map((child, index) =>
                React.cloneElement(child, {
                  key: index,
                  index: index,
                  selectedTabIndex: selectedIndex ? Number(selectedIndex) : Number(value),
                })
              )
          : React.cloneElement(children, {
              index: 0,
              selectedTabIndex: 0,
            })}
      </Wrapper>
    );
  }
);
TabGroup.displayName = 'TabGroup';

TabGroup.propTypes = {
  ...sizePropTypes,
  rootComponent: PropTypes.elementType,
  sticky: PropTypes.bool,
  undead: PropTypes.bool,
  stickyTop: PropTypes.string,
  stickyLeft: PropTypes.string,
  showSingleTab: PropTypes.bool,
  selectedIndex: PropTypes.number,
};

TabGroup.defaultProps = {
  defaultValue: 0,
  tabSlot: DefaultTabButton,
};

export default TabGroup;

const Wrapper = styled(FlexBoxColumn)`
  height: fit-content;
  align-items: flex-start;
`;

export const InputTabGroup = React.forwardRef(
  ({ tabItems, tabSlot: TabSlot, children, onChange, selectedIndex, ...props }, ref) => {
    const { width, defaultValue, value: valueProp, showSingleTab } = props;
    const { value, setValueWhenUncontrolled } = useControlled({
      value: valueProp,
      defaultValue,
    });
    const handleChange = useCallback(
      (event) => {
        setValueWhenUncontrolled(Number(event?.target?.value));
        onChange?.(event);
      },
      [onChange, setValueWhenUncontrolled]
    );

    return (
      <Wrapper width={width}>
        {(showSingleTab || tabItems?.length > 1) && (
          <RadioGroup ref={ref} value={selectedIndex ? selectedIndex : value} onChange={handleChange} {...props}>
            {tabItems.map(({ tabName, width, ...item }, index) =>
              tabName ? (
                <InputTab key={index} value={index} width={width} {...item}>
                  <TabSlot
                    firstChild={index === 0}
                    lastChild={index === tabItems?.length - 1}
                    title={index === parseInt(value) ? '선택됨' : ''}
                    htmlFor={index}
                  >
                    {tabName}
                  </TabSlot>
                </InputTab>
              ) : (
                <StyledEmptyTab key={'empty' + index}></StyledEmptyTab>
              )
            )}
          </RadioGroup>
        )}
        {children.map
          ? children
              .filter((child) => child)
              .map((child, index) =>
                React.cloneElement(child, {
                  key: index,
                  index: index,
                  selectedTabIndex: selectedIndex ? Number(selectedIndex) : Number(value),
                })
              )
          : React.cloneElement(children, {
              index: 0,
              selectedTabIndex: 0,
            })}
      </Wrapper>
    );
  }
);
InputTabGroup.displayName = 'InputTabGroup';

InputTabGroup.propTypes = {
  ...sizePropTypes,
  rootComponent: PropTypes.elementType,
  sticky: PropTypes.bool,
  undead: PropTypes.bool,
  stickyTop: PropTypes.string,
  stickyLeft: PropTypes.string,
  showSingleTab: PropTypes.bool,
  selectedIndex: PropTypes.number,
};

InputTabGroup.defaultProps = {
  defaultValue: 0,
  tabSlot: DefaultTabButton,
};
