import React, { cloneElement, useCallback } from 'react';
import PropTypes from 'prop-types';

import { FloatingNode, FloatingPortal } from '@floating-ui/react';

import { TextBox } from '@ecp/common/src/text/TextBox';
import usePopup from '@ecp/common/src/components/unstyled/floating/usePopup';

const PopupUnstyled = React.forwardRef(
  (
    {
      open,
      onOpenChange,
      onChange,
      closeWhenOutside,
      onClose,
      rootComponent: RootComponent,
      rootStyleProps,
      headerComponent: HeaderComponent,
      headerProps,
      bodyComponent: BodyComponent,
      bodyProps,
      bottomButtonComponent: BottomButtonComponent,
      bottomRightButtonComponent: BottomRightButtonComponent,
      closable: showClose,
      closeComponent: CloseComponent,
      children,
      showDialog,
    },
    ref
  ) => {
    const { nodeId, getPopupProps, refs } = usePopup({
      open,
      onOpenChange,
      closeWhenOutside,
      onClose,
      showDialog,
    });

    refs.setFloating(ref?.current);

    const close = useCallback(() => {
      onClose?.(false);
    }, [onClose]);

    const handleClose = useCallback(() => {
      close();
    }, [close]);

    const handleHideToday = useCallback(()=> {
      onChange();
    }, [onChange])
    const handleTab = (event) => {
      if (event.key === 'Tab' && !event.shiftKey) {
        refs.floating.current.focus();
      }
    };
    return (
      <FloatingNode id={nodeId}>
        <FloatingPortal id={nodeId} tabIndex={0}>
          {open && (
            <RootComponent ref={refs.floating} style={{ ...rootStyleProps }} {...getPopupProps()}>
              {HeaderComponent &&
                (typeof HeaderComponent === 'string' ? (
                  <TextBox tabIndex={0} {...headerProps}>
                    {HeaderComponent}
                  </TextBox>
                ) : (
                  <HeaderComponent tabIndex={0} {...headerProps} />
                ))}
              {BodyComponent && (
                <BodyComponent tabIndex={0} {...bodyProps}>
                  {children}
                </BodyComponent>
              )}
              {BottomButtonComponent &&
                cloneElement(BottomButtonComponent, {
                  onClick: () => handleClose(),
                  onKeyDown: (e) => handleTab(e),
                })}
              {BottomRightButtonComponent &&
                cloneElement(BottomRightButtonComponent, {
                  onClick: () => handleHideToday(),
                  onKeyDown: (e) => handleTab(e),
                })}
              {showClose &&
                CloseComponent &&
                cloneElement(CloseComponent, {
                  onClick: () => handleClose(),
                  onKeyDown: (e) => handleTab(e),
                })}
            </RootComponent>
          )}
        </FloatingPortal>
      </FloatingNode>
    );
  }
);

PopupUnstyled.propTypes = {
  showDialog: PropTypes.bool,
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  closeWhenOutside: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  rootStyleProps: PropTypes.object,
  headerComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  headerProps: PropTypes.object,
  bodyComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  bodyProps: PropTypes.object,
  bottomButtonComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  bottomRightButtonComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  closable: PropTypes.bool,
  closeComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element]),
  children: PropTypes.node,
};

PopupUnstyled.defaultProps = {
  rootComponent: 'div',
  rootStyleProps: {},
  closeWhenOutside: false,
};

PopupUnstyled.displayName = 'PopupUnstyled';

export default PopupUnstyled;
