import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  ALL_CONSONANT,
  getBrandData,
  hasSearchCondition,
  initialSearchCondition,
  toConsonantMapper,
} from '@ecp/common/src/utils/good/brandUtil';
import { NumberFormat } from '@ecp/common/src/utils/utils';
import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { BENEFIT_MESSAGE } from '@ecp/common/src/const/promotion';

import { employeeBenefitBrandCountState, employeeBenefitMessageState } from '@fo-recoil/main/atom';
import { useAlertDialog } from '@fo-hooks/common/useModal';
import useRecommendBrandList from '@fo-hooks/brand/useRecommendBrandList';
import brandApi from '@fo-apis/good/brandApi';
import couponApi from '@fo-apis/coupon/couponApi';
import { MainContentsTitle } from '@fo-components/common/styled';
import BrandRecommendList from '@fo-pages/brand/components/BrandRecommendList';
import BrandResult from '@fo-pages/brand/components/BrandResult';
import BrandNoResult from '@fo-pages/brand/components/BrandNoResult';
import BrandSearchCondition from '@fo-pages/brand/components/BrandSearchCondition';

const BrandPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const searchInputRef = useRef();
  const categorySwiperRef = useRef();

  const [brandTotalCount, setBrandTotalCount] = useState(0);
  const [brandCountGroup, setBrandCountGroup] = useState({});
  const [brandGroup, setBrandGroup] = useState({});
  const [searchCondition, setSearchCondition] = useState(initialSearchCondition);
  const [searchInputValue, setSearchInputValue] = useState('');

  const { showAlertDialog } = useAlertDialog();
  const recommendBrandList = useRecommendBrandList();

  const setEmployeeBenefitMessageState = useSetRecoilState(employeeBenefitMessageState);
  const setEmployeeBenefitBrandCountState = useSetRecoilState(employeeBenefitBrandCountState);
  const resetEmployeeBenefitMessage = useResetRecoilState(employeeBenefitMessageState);

  useEffect(() => {
    (async () => {
      const { result: couponList } = await couponApi.getBrandBenefitSummary();
      const { brand, count } = couponList;

      if (brand && count > 0) {
        setEmployeeBenefitMessageState(BENEFIT_MESSAGE.BRAND(NumberFormat(count)));
        setEmployeeBenefitBrandCountState(NumberFormat(count));
      }
    })();
    return () => resetEmployeeBenefitMessage();
  }, [resetEmployeeBenefitMessage, setEmployeeBenefitBrandCountState, setEmployeeBenefitMessageState]);

  useEffect(() => {
    setSearchCondition(initialSearchCondition);
  }, [setSearchCondition]);

  const getSearchData = async (data) => {
    const requestParams = {
      searchKeyword: data.searchKeyword,
      largeDisplayCategoryNumber: data.largeDisplayCategoryNumber,
      initialConsonant: toConsonantMapper(data.initialConsonant),
    };
    const { result = [] } = await brandApi.getBrandList(requestParams);
    const { brandTotalCount, brandCountGroup, brandGroup } = getBrandData(result);
    setBrandTotalCount(brandTotalCount);
    setBrandCountGroup(brandCountGroup);
    setBrandGroup(brandGroup);
  };

  const handleSearchKeywordChange = useCallback((e) => {
    setSearchInputValue(e?.target?.value ?? '');
  }, []);

  const handleSearchClick = () => {
    if (!searchInputValue.trim()) {
      showAlertDialog('검색하실 브랜드명을 입력해 주세요.', () => {
        searchInputRef.current.focus();
      });
      return;
    }
    navigate('/brand', { state: { ...state, searchKeyword: searchInputValue } });
  };

  const handleInitializeClick = () => {
    navigate('/brand');
  };

  const handleLargeCategoryChange = (categoryNumber, index) => {
    navigate('/brand', { state: { ...state, categoryNumber, categoryIndex: index } });
  };

  const handleConsonantChange = (consonant) => {
    navigate('/brand', { state: { ...state, consonant } });
  };

  useEffect(() => {
    const search = {
      ...initialSearchCondition,
    };
    if (state && state.consonant) {
      search.initialConsonant = state.consonant === ALL_CONSONANT ? '' : state.consonant;
    } else {
      search.initialConsonant = '';
    }
    if (state && state.categoryNumber) {
      search.largeDisplayCategoryNumber = state.categoryNumber === 'ALL' ? '' : state.categoryNumber;
      categorySwiperRef.current.swiper.slideTo(state.categoryIndex);
    } else {
      search.largeDisplayCategoryNumber = '';
      categorySwiperRef.current.swiper.slideTo(0);
    }
    if (state && state.searchKeyword) {
      search.searchKeyword = state.searchKeyword;
      setSearchInputValue(state.searchKeyword);
    } else {
      search.searchKeyword = '';
    }
    setSearchCondition(search);
    getSearchData(search);
  }, [state]);

  const handleShowMoreBrand = useCallback(
    async (consonant) => {
      const requestParams = {
        searchKeyword: searchCondition.searchKeyword,
        largeDisplayCategoryNumber: searchCondition.largeDisplayCategoryNumber,
        consonant: toConsonantMapper(consonant),
      };
      const { result = [] } = await brandApi.getBrandListByConsonant({
        page: brandGroup[consonant].page,
        size: 20,
        ...requestParams,
      });
      setBrandGroup((prev) => ({
        ...prev,
        [consonant]: { page: prev[consonant].page + 1, brandList: [...prev[consonant].brandList, ...result] },
      }));
    },
    [brandGroup, searchCondition.largeDisplayCategoryNumber, searchCondition.searchKeyword]
  );

  const isNoResult = useMemo(() => {
    return brandTotalCount === 0;
  }, [brandTotalCount]);

  return (
    <FlexBoxColumn width={'1540px'} justify-contents={'center'} align-items={'flex-start'}>
      <Spacing top={50} />
      <MainContentsTitle>브랜드</MainContentsTitle>
      {recommendBrandList.length > 0 && (
        <>
          <Spacing top={10} />
          <TextBox size={theme.font.size.table} weight={theme.font.weight.demiLight} color={theme.color.text.sub}>
            지금 가장 핫한 브랜드의 모든 상품을 만나보세요.
          </TextBox>
          <Spacing top={20} />
          <BrandRecommendList recommendBrandList={recommendBrandList} />
          <Spacing top={50} />
        </>
      )}
      <BrandSearchCondition
        searchInputRef={searchInputRef}
        searchInputValue={searchInputValue}
        categorySwiperRef={categorySwiperRef}
        initialConsonant={searchCondition.initialConsonant}
        hasSearchCondition={hasSearchCondition(searchCondition)}
        handleInitializeClick={handleInitializeClick}
        handleSearchKeywordChange={handleSearchKeywordChange}
        handleSearchClick={handleSearchClick}
        handleLargeCategoryChange={handleLargeCategoryChange}
        handleConsonantChange={handleConsonantChange}
      />
      <Spacing top={30} />
      <FlexBox width={'100%'} align-items={'flex-end'}>
        <TextBox size={theme.font.size.title} weight={theme.font.weight.medium} color={theme.color.primary}>
          {brandTotalCount}
        </TextBox>
        <TextBox size={theme.font.size.title} weight={theme.font.weight.medium}>
          개 브랜드가 있습니다.
        </TextBox>
      </FlexBox>
      <Spacing top={14} />
      <HorizontalDivider color={'#141415'} height={'2px'} />
      {!isNoResult ? (
        <BrandResult
          brandGroup={brandGroup}
          brandCountGroup={brandCountGroup}
          handleShowMoreBrand={handleShowMoreBrand}
        />
      ) : (
        <BrandNoResult />
      )}
    </FlexBoxColumn>
  );
};

export default BrandPage;
