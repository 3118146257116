import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  ENG_CONSONANT_LIST,
  ETC_CONSONANT,
  getConsonantFilteredList,
  KOR_CONSONANT_LIST,
} from '@ecp/common/src/utils/good/brandUtil';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxColumn, HorizontalDivider } from '@ecp/common/src/layouts/flex/styled';

import BrandResultItem from '@fo-pages/brand/components/BrandResultItem';

const BrandResult = ({ brandGroup, brandCountGroup, handleShowMoreBrand }) => {
  const koreanConsonantList = useMemo(() => {
    return getConsonantFilteredList(brandGroup, KOR_CONSONANT_LIST);
  }, [brandGroup]);

  const englishConsonantList = useMemo(() => {
    return getConsonantFilteredList(brandGroup, ENG_CONSONANT_LIST);
  }, [brandGroup]);

  const etcConsonantList = useMemo(() => {
    return getConsonantFilteredList(brandGroup, [ETC_CONSONANT]);
  }, [brandGroup]);

  return (
    <BrandResultListWrap>
      <BrandResultItem
        list={koreanConsonantList}
        brandGroup={brandGroup}
        brandCountGroup={brandCountGroup}
        handleShowMoreBrand={handleShowMoreBrand}
      />
      <BrandResultItem
        list={etcConsonantList}
        brandGroup={brandGroup}
        brandCountGroup={brandCountGroup}
        handleShowMoreBrand={handleShowMoreBrand}
      />
      {[...koreanConsonantList, ...etcConsonantList].length > 0 && englishConsonantList.length > 0 && (
        <Spacing width="100%" top="50px">
          <HorizontalDivider color={'#141415'} height={'2px'} />
        </Spacing>
      )}
      <BrandResultItem
        list={englishConsonantList}
        brandGroup={brandGroup}
        brandCountGroup={brandCountGroup}
        handleShowMoreBrand={handleShowMoreBrand}
      />
    </BrandResultListWrap>
  );
};

BrandResult.propTypes = {
  brandGroup: PropTypes.object,
  brandCountGroup: PropTypes.object,
  handleShowMoreBrand: PropTypes.func,
};

export default BrandResult;

const BrandResultListWrap = styled(FlexBoxColumn)`
  width: 100%;
`;
