import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { overrideProperties } from '@ecp/common/src/style/utils';
import { positionPropTypes, sizePropTypes } from '@ecp/common/src/style/propTypes';
import theme from '@ecp/common/src/style/theme/default';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import {
  FlexBox,
  FlexBoxCenter,
  FlexBoxColumn,
  ImageBox,
  FlexBoxButton,
  FlexBoxATag,
  SpanTitle,
} from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import StyledImg, { LazyImg } from '@ecp/common/src/components/image/StyledImg';
import Badge from '@ecp/common/src/components/badge/Badge';
import { BANNER_CONTENTS_DIVISION_CODE } from '@ecp/common/src/const/display/bannerConst';
import adultPath from '@ecp/common/src/assets/icon/icon__adult-certification.svg';
import { ReactComponent as AdBanner } from '@ecp/common/src/assets/icon/icon__ad-banner.svg';

export const swiperItemPropTypes = {
  item: PropTypes.object,
  itemProps: PropTypes.object,
  selected: PropTypes.bool,
  adultGood: PropTypes.bool,
  ariaLabel: PropTypes.string,
  index: PropTypes.number,
  total: PropTypes.number,
};

const StyledTitle = styled.div`
  position: absolute;
  white-space: pre-wrap;
  top: 171px;
  left: 130px;
  font-size: ${theme.font.size.hero};
  line-height: 120%;
  color: ${theme.color.text.basic};
  max-width: 450px;
  text-align: left;
`;
const StyledSubTitle = styled.div`
  position: absolute;
  white-space: pre-wrap;
  bottom: 171px;
  left: 130px;
  font-size: ${theme.font.size.popup};
  line-height: ${theme.font.lineHeight.medium};
  color: ${theme.color.text.basic};
  max-width: 450px;
  text-align: left;
`;

const StyledSoldOut = styled(FlexBoxCenter)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: ${({ width }) => width};
  height: 60px;
  font-size: ${theme.font.size.table};
  line-height: ${theme.font.lineHeight.medium};
  text-align: left;
  background-color: rgba(0, 0, 0, 0.3);
  color: ${theme.color.text.white};
`;

export const StyledRoot = styled(FlexBox)`
  position: relative;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  user-select: none;
  ${overrideProperties(sizePropTypes, {})};
`;

export const StyledRootATag = styled(FlexBoxATag)`
  position: relative;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  user-select: none;
  ${overrideProperties(sizePropTypes, {})};
`;

export const StyledRootButton = styled(FlexBoxButton)`
  position: relative;
  cursor: ${({ cursor }) => cursor || 'pointer'};
  user-select: none;
  ${overrideProperties(sizePropTypes, {})};
`;

export const StyledFlexBoxColumn = styled(FlexBoxColumn)`
  position: static;
`;

const StyledAdultGoodText = styled(TextBox)`
  position: absolute;
  justify-content: center;
  height: 22px;
  top: 458px;
  width: 100%;
  font-size: ${theme.font.size.basic};
  color: ${theme.color.text.basic};
`;

const SwiperItem = ({ item, itemProps, adultGood, ...props }) => {
  const blockItemProps = useMemo(
    () => ({ src: adultGood ? adultPath : item.src, padding: adultGood ? '130px 150px 170px 150px' : '0px' }),
    [adultGood, item.src]
  );

  return (
    <StyledRoot {...itemProps} {...props}>
      <StyledImg {...blockItemProps} {...itemProps} alt={item.title ? item.title : " "}/>
      {(item.title || item.subTitle) && (
        <StyledFlexBoxColumn>
          {item.title && <StyledTitle>{item.title}</StyledTitle>}
          {item.subTitle && <StyledSubTitle>{item.subTitle}</StyledSubTitle>}
        </StyledFlexBoxColumn>
      )}
      {item.stopSale && <StyledSoldOut {...itemProps}>판매중지</StyledSoldOut>}
      {!item.stopSale && item.soldOut && <StyledSoldOut {...itemProps}>품절</StyledSoldOut>}
      {adultGood && <StyledAdultGoodText>19세 이상만 구매 가능</StyledAdultGoodText>}
    </StyledRoot>
  );
};

SwiperItem.displayName = 'SwiperItem';

SwiperItem.propTypes = swiperItemPropTypes;

const BannerText1 = styled(TextBox)`
  position: absolute;
  top: 170px;
  left: 130px;
  font-size: ${theme.font.size.hero};
  font-weight: ${theme.font.weight.medium};
  line-height: ${theme.font.lineHeight.medium};
`;

const BannerText2 = styled(BannerText1)`
  top: 228px;
  
`;

const BannerText3 = styled(BannerText1)`
  top: 336px;
  font-size: ${theme.font.size.popup};
  width: 500px;
`;

export const SwiperMainItem = ({ item, itemProps, ...props }) => {
  const backgroundColor = useMemo(
    () => (item.bannerBackgroundColorCode ? `#${item.bannerBackgroundColorCode}` : 'none'),
    [item.bannerBackgroundColorCode]
  );
  const fontColor = useMemo(
    () => (item.bannerFontColorCode ? `#${item.bannerFontColorCode}` : theme.color.text.basic),
    [item.bannerFontColorCode]
  );
  const cursor = useMemo(
    () => (item.bannerPcImageUrl || item.bannerCommonImageUrl ? 'pointer' : 'default'),
    [item.bannerCommonImageUrl, item.bannerPcImageUrl]
  );

  const contents = useMemo(
    () =>
      `${item.bannerNumber}${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC
          ? `|${item.bannerPcImageUrl || item.bannerCommonImageUrl}`
          : ''
      }${
          item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.EXHIBITION
              ? `|${item.relationContentsNumber}`
              : ''
      }`,
    [item.bannerCommonImageUrl, item.bannerContentsDivisionCode, item.bannerNumber, item.bannerPcImageUrl, item.relationContentsNumber]
  );
  return (
    <StyledRoot {...itemProps} {...props} data-ds-contents={contents} cursor={cursor}>
      <ImageBox
        imageSrc={item.bannerPcImageUrl || item.bannerCommonImageUrl}
        background-color={backgroundColor}
        {...itemProps}
      />
      <StyledFlexBoxColumn>
        {item.bannerPhrase01 && <BannerText1 color={fontColor}>{item.bannerPhrase01}</BannerText1>}
        {item.bannerPhrase02 && <BannerText2 color={fontColor}>{item.bannerPhrase02}</BannerText2>}
        {item.bannerPhrase03 && <BannerText3 color={fontColor} style={fontColor === theme.color.text.basic ? {fontWeight:'bold'}:{}}>{item.bannerPhrase03}</BannerText3>}
      </StyledFlexBoxColumn>
    </StyledRoot>
  );
};

SwiperMainItem.displayName = 'SwiperMainItem';

SwiperMainItem.propTypes = swiperItemPropTypes;

const StyledBannerTitle = styled(StyledTitle)`
  top: 51px;
  left: 130px;
  line-height: 120%;
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.label};
`;
const StyledBannerSubTitle = styled(StyledSubTitle)`
  bottom: 51px;
  left: 130px;
  line-height: 120%;
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.banner};
`;
export const SwiperLazyItem = ({ item, itemProps, ...props }) => (
  <StyledRoot {...itemProps} {...props}>
    <LazyImg src={item.src} {...itemProps} alt={item.title} />
    {(item.title || item.subTitle) && (
      <StyledFlexBoxColumn>
        {item.title && <StyledBannerTitle>{item.title}</StyledBannerTitle>}
        {item.subTitle && <StyledBannerSubTitle>{item.subTitle}</StyledBannerSubTitle>}
      </StyledFlexBoxColumn>
    )}
  </StyledRoot>
);

SwiperLazyItem.displayName = 'SwiperLazyItem';

SwiperLazyItem.propTypes = swiperItemPropTypes;
export const SwiperBannerItem = ({ item, itemProps, ...props }) => {
  const cursor = useMemo(() => (item.bannerPcLinkUrl ? 'pointer' : 'default'), [item.bannerPcLinkUrl]);
  const contents = useMemo(
    () =>
      `${item.bannerNumber}${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC
          ? `|${item.bannerPcImageUrl || item.bannerCommonImageUrl}`
          : ''
      }`,
    [item.bannerCommonImageUrl, item.bannerContentsDivisionCode, item.bannerNumber, item.bannerPcImageUrl]
  );
  return (
    <StyledRoot {...itemProps} {...props} data-ds-contents={contents} cursor={cursor}>
      <StyledImg src={item.src} {...itemProps} alt={item.title} />
      {(item.title || item.subTitle) && (
        <StyledFlexBoxColumn>
          {item.title && <StyledBannerTitle>{item.title}</StyledBannerTitle>}
          {item.subTitle && <StyledBannerSubTitle>{item.subTitle}</StyledBannerSubTitle>}
        </StyledFlexBoxColumn>
      )}
    </StyledRoot>
  );
};

SwiperBannerItem.displayName = 'SwiperBannerItem';

SwiperBannerItem.propTypes = swiperItemPropTypes;

export const SwiperBannerItemATag = ({ item, itemProps, ...props }) => {
  const cursor = useMemo(() => (item.bannerPcLinkUrl ? 'pointer' : 'default'), [item.bannerPcLinkUrl]);
  const contents = useMemo(
    () =>
      `${item.bannerNumber}${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC
          ? `|${item.bannerPcImageUrl || item.bannerCommonImageUrl}`
          : ''
      }`,
    [item.bannerCommonImageUrl, item.bannerContentsDivisionCode, item.bannerNumber, item.bannerPcImageUrl]
  );
  return (
    <StyledRootATag
      href="#"
      onClick={(e) => {
        e.preventDefault();
      }}
      {...itemProps}
      {...props}
      data-ds-contents={contents}
      cursor={cursor}
    >
      <StyledImg src={item.src} {...itemProps} alt={item.title} />
      {(item.title || item.subTitle) && (
        <StyledFlexBoxColumn>
          {item.title && <StyledBannerTitle>{item.title}</StyledBannerTitle>}
          {item.subTitle && <StyledBannerSubTitle>{item.subTitle}</StyledBannerSubTitle>}
        </StyledFlexBoxColumn>
      )}
    </StyledRootATag>
  );
};

SwiperBannerItemATag.displayName = 'SwiperBannerItemATag';

SwiperBannerItemATag.propTypes = swiperItemPropTypes;

export const SwiperBannerPopupItem = ({ item, itemProps, ...props }) => {
  const cursor = useMemo(
    () => (item.bannerPcImageUrl || item.bannerCommonImageUrl ? 'pointer' : 'default'),
    [item.bannerCommonImageUrl, item.bannerPcImageUrl]
  );
  const contents = useMemo(
    () =>
      `${item.bannerNumber}${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC
          ? `|${item.bannerPcImageUrl || item.bannerCommonImageUrl}`
          : ''
      }`,
    [item.bannerCommonImageUrl, item.bannerContentsDivisionCode, item.bannerNumber, item.bannerPcImageUrl]
  );
  return (
    <StyledRootATag {...itemProps} {...props} cursor={cursor} data-ds-contents={contents} aria-label={item.bannerName}>
      <ImageBox imageSrc={item.bannerPcImageUrl || item.bannerCommonImageUrl} {...itemProps}>
        <SpanTitle>{item.bannerName}</SpanTitle>
      </ImageBox>
    </StyledRootATag>
  );
};

SwiperBannerPopupItem.displayName = 'SwiperBannerPopupItem';

SwiperBannerPopupItem.propTypes = swiperItemPropTypes;

const StyledImage = styled(LazyImg)`
  cursor: pointer;
  user-select: none;

  .swiper-slide-thumb-active & {
    outline: 2px solid ${theme.color.primary};
    outline-offset: -2px;
  }
`;

export const SwiperNavigatorItem = ({ item, itemProps, adultGood, index, total, ...props }) => (
  <StyledRootButton type="button" aria-label={props.ariaLabel + (total > 0 ? ", " + total + "개 슬라이드 중" +  (index+1) + "번째" : "")}>
    <StyledImage
      src={adultGood ? adultPath : item.src}
      padding={adultGood ? '9px' : '0px'}
      {...itemProps}
      alt={item.title ? item.title : " "}
      {...props}
    />
  </StyledRootButton>
);

SwiperNavigatorItem.displayName = 'SwiperNavigatorItem';
SwiperNavigatorItem.defaultProps = {
  ariaLabel: '상품 이미지 크게 보기',
}
SwiperNavigatorItem.propTypes = swiperItemPropTypes;

const LargeDecorator = styled(FlexBox)`
  position: absolute;
  justify-content: center;
  ${overrideProperties(positionPropTypes, {})};
  ${overrideProperties(sizePropTypes, {})};
  border-radius: 12px 0;
  color: ${theme.color.text.white};
  font-size: ${theme.font.size.basic};
  font-weight: ${theme.font.weight.bold};
  background: ${theme.color.primary};

  display: ${({ selected }) => (selected ? 'flex' : 'none')};
`;

const StyledBlackCover = styled.div`
  position: absolute;
  z-index: 2;
  width: inherit;
  height: inherit;
  border-radius: 12px 12px 12px 12px;
  opacity: ${({ selected }) => (selected ? '0' : '1')};
  transition: ${({ selected }) => (selected ? 'opacity 500ms' : 'none')};
  background: rgba(0, 0, 0, 0.6);
`;

const StyledNewSubTitle = styled.div`
  position: absolute;
  white-space: pre-wrap;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.text.white};
  height: 104px;
  padding: 56px 20px 20px 28px;
  font-size: ${theme.font.size.popup};

  display: ${({ selected }) => (selected ? 'block' : 'none')};
`;

const StyledNewTitle = styled.div`
  position: absolute;
  white-space: pre-wrap;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.text.white};
  background: rgba(0, 0, 0, 0.3);
  height: 104px;
  padding: 20px 20px 20px 28px;
  font-size: ${theme.font.size.popup};
  border-radius: 0 0 12px 12px;

  display: ${({ selected }) => (selected ? 'block' : 'none')};
`;

export const SwiperMainExhibitionItem = ({ item, itemProps, selected, ...props }) => (
  <Badge
    type={'LARGE'}
    badgeComponent={LargeDecorator}
    imageWidth={itemProps.width}
    badgeContent="NEW"
    position={{ top: 0, left: 0 }}
    size={{ width: '60px', height: '60px' }}
    selected={item.isNew}
  >
    <StyledRoot {...itemProps} {...props} data-ds-contents={item.exhibitionNo}>
      <StyledBlackCover selected={selected} />
      <StyledImg src={item.src} {...itemProps} alt={item.title} selected={selected} />
      {item.title && <StyledNewTitle selected={selected}>{item.title}</StyledNewTitle>}
      {item.subTitle && <StyledNewSubTitle selected={selected}>{item.subTitle}</StyledNewSubTitle>}
    </StyledRoot>
  </Badge>
);

SwiperMainExhibitionItem.displayName = 'SwiperMainExhibitionItem';

SwiperMainExhibitionItem.propTypes = swiperItemPropTypes;

const StyledMdPickTitle = styled(FlexBox)`
  width: 100%;
  height: 118px;
  padding: 20px 30px;
  font-weight: ${theme.font.weight.medium};
  font-size: ${theme.font.size.large};
  line-height: ${theme.font.lineHeight.medium};
  white-space: pre-wrap;
  color: ${theme.color.text.white};
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const MdPickCarouselItem = ({ item, itemProps, ...props }) => (
  <StyledRoot {...itemProps} {...props} data-ds-contents={item.exhibitionNumber}>
    <StyledImg src={item.src} {...itemProps} alt={''} />
    {item.title && <StyledMdPickTitle>{item.title}</StyledMdPickTitle>}
  </StyledRoot>
);

MdPickCarouselItem.propTypes = swiperItemPropTypes;

const Wrapper = styled(StyledRoot).attrs(({ style }) => ({
  style: {
    ...style,
    fontWeight: theme.font.weight.medium,
    fontSize: theme.font.size.table,
    lineHeight: theme.font.lineHeight.demiLight,
  },
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 23px;
  padding: 0 24px;
  margin: 0 1px;

  &.carousel-item--selected {
    border: 1px solid ${theme.color.primary};
    background-color: ${theme.color.background.white};
    color: ${theme.color.primary};
  }
`;

export const MenuCarouselItem = ({ item, itemProps, ...props }) => (
  <Wrapper {...itemProps} {...props}>
    {item.name}
  </Wrapper>
);

MenuCarouselItem.propTypes = {
  ...swiperItemPropTypes,
  onClick: PropTypes.func,
};

const ExhibitionMenu = styled(StyledRoot)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  line-height: ${theme.font.lineHeight.demiLight};
  font-size: ${theme.font.size.menu};
  font-weight: ${theme.font.weight.medium};
  color: ${theme.color.text.basic};
  background-color: ${theme.color.background.white};
  border: 1px solid ${theme.color.line.background};

  &.carousel-item--selected {
    color: ${theme.color.text.white};
    background-color: ${({ itemColor }) => (itemColor ? `${itemColor}` : `${theme.color.third}`)};
    border: ${({ itemColor }) => (itemColor ? `solid 1px ${itemColor}` : `solid 1px ${theme.color.third}`)};
  }
`;

export const ExhibitionMenuCarouselItem = ({ item, itemProps, ...props }) => (
  <ExhibitionMenu {...itemProps} {...props}>
    {item.name}
  </ExhibitionMenu>
);

ExhibitionMenuCarouselItem.propTypes = {
  ...swiperItemPropTypes,
  onClick: PropTypes.func,
};

const AdText = styled(TextBox)`
  width: 92px;
  color: ${theme.color.text.guide};
  font-size: ${theme.font.size.tiny};
  font-weight: ${theme.font.weight.medium};
  line-height: ${theme.font.lineHeight.medium};
`;

export const SwiperAdBannerItem = ({ item, itemProps, ...props }) => {
  const cursor = useMemo(
    () => (item.bannerPcImageUrl || item.bannerCommonImageUrl ? 'pointer' : 'default'),
    [item.bannerCommonImageUrl, item.bannerPcImageUrl]
  );
  const contents = useMemo(
    () =>
      `${item.bannerNumber}${
        item.bannerContentsDivisionCode === BANNER_CONTENTS_DIVISION_CODE.ETC
          ? `|${item.bannerPcImageUrl || item.bannerCommonImageUrl}`
          : ''
      }`,
    [item.bannerCommonImageUrl, item.bannerContentsDivisionCode, item.bannerNumber, item.bannerPcImageUrl]
  );
  return (
    <StyledRoot {...itemProps} {...props} gap={'6px'} data-ds-contents={contents} cursor={cursor}>
      <ImageBox
        width={'50px'}
        height={'50px'}
        radius={'25px'}
        border={`1px solid ${theme.color.line.background}`}
        imageSrc={item.bannerPcImageUrl || item.bannerCommonImageUrl}
      />
      <FlexBoxColumn align-items={'flex-start'} width={'92px'}>
        <AdText>{item.bannerPhrase01}</AdText>
        <AdText>{item.bannerPhrase02}</AdText>
      </FlexBoxColumn>
      <FlexBoxColumn>
        <Spacing top={17} />
        <AdBanner width={'15px'} height={'11px'} />
      </FlexBoxColumn>
    </StyledRoot>
  );
};

SwiperAdBannerItem.propTypes = {
  item: PropTypes.object,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
};

export const SwiperItemButton = ({ item, itemProps, adultGood, ...props }) => {
  const blockItemProps = useMemo(
    () => ({ src: adultGood ? adultPath : item.src, padding: adultGood ? '130px 150px 170px 150px' : '0px' }),
    [adultGood, item.src]
  );

  return (
    <StyledRootButton {...itemProps} {...props}>
      <StyledImg {...blockItemProps} {...itemProps} alt={item.title ? item.title : " "}/>
      {(item.title || item.subTitle) && (
        <StyledFlexBoxColumn>
          {item.title && <StyledTitle>{item.title}</StyledTitle>}
          {item.subTitle && <StyledSubTitle>{item.subTitle}</StyledSubTitle>}
        </StyledFlexBoxColumn>
      )}
      {item.stopSale && <StyledSoldOut {...itemProps}>판매중지</StyledSoldOut>}
      {!item.stopSale && item.soldOut && <StyledSoldOut {...itemProps}>품절</StyledSoldOut>}
      {adultGood && <StyledAdultGoodText>19세 이상만 구매 가능</StyledAdultGoodText>}
    </StyledRootButton>
  );
};

SwiperItemButton.displayName = 'SwiperItemButton';

SwiperItemButton.propTypes = swiperItemPropTypes;

export default SwiperItem;

export const StyledComponent = {
  StyledTitle,
  StyledSubTitle,
  StyledRoot,
  StyledFlexBoxColumn,
  StyledBannerTitle,
  StyledRootButton,
};
