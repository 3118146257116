import React from 'react';
import PropTypes from 'prop-types';

import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import SearchInput from '@ecp/common/src/components/input/SearchInput';

import LargeCategorySwiper from '@fo-components/good/LargeCategorySwiper';
import BrandConsonant from '@fo-pages/brand/components/BrandConsonant';

const BrandSearchCondition = ({
  searchInputRef,
  searchInputValue,
  categorySwiperRef,
  initialConsonant,
  hasSearchCondition,
  handleInitializeClick,
  handleSearchKeywordChange,
  handleSearchClick,
  handleLargeCategoryChange,
  handleConsonantChange,
}) => {
  return (
    <>
      <FlexBoxCenter width={'100%'}>
        <SearchInput
          ref={searchInputRef}
          value={searchInputValue}
          placeholder={'검색하실 브랜드명을 입력해주세요'}
          enableInitialize={hasSearchCondition}
          onInitializeClick={handleInitializeClick}
          onChange={handleSearchKeywordChange}
          onSearchClick={handleSearchClick}
          onEnter={handleSearchClick}
        />
      </FlexBoxCenter>
      <Spacing top={30} />
      <LargeCategorySwiper ref={categorySwiperRef} handleClick={handleLargeCategoryChange} />
      <Spacing top={50} />
      <BrandConsonant selectedConsonant={initialConsonant} handleConsonantChange={handleConsonantChange} />
    </>
  );
};

export default BrandSearchCondition;

BrandSearchCondition.propTypes = {
  searchInputRef: PropTypes.object,
  searchInputValue: PropTypes.string,
  categorySwiperRef: PropTypes.object,
  initialConsonant: PropTypes.string,
  hasSearchCondition: PropTypes.bool,
  handleInitializeClick: PropTypes.func,
  handleSearchKeywordChange: PropTypes.func,
  handleSearchClick: PropTypes.func,
  handleLargeCategoryChange: PropTypes.func,
  handleConsonantChange: PropTypes.func,
};
