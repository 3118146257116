export const getEcpErrorMessage = (error) => {
  const { data: responseData } = error.response;
  let message = `Status Code: ${responseData.httpStatusCode}\nError Code: ${responseData.errorCode}\nError Message: ${responseData.defaultMessage}\n`;
  if (responseData.param && responseData.param.length > 0) {
    responseData.param.forEach((param, index) => {
      const [field, , alertMessage] = param.split(':');
      message += `\n${index + 1}. ${field}은(는) ${alertMessage}`;
    });
  }
  return message;
};

export const getUnknownErrorMessage = (error) => {
  return `Status Code: ${error.response.status}\n${error.message}`;
};
