import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { color, theme } from '@ecp/common/src/style/theme/default';
import { FlexBox } from '@ecp/common/src/layouts/flex/styled';
import { TextBox } from '@ecp/common/src/text/TextBox';
import { TextBoxButton } from '@ecp/common/src/text/TextBoxButton';
import ButtonUnstyled from '@ecp/common/src/components/unstyled/button/ButtonUnstyled';
import { ReactComponent as IconRight } from '@ecp/common/src/assets/icon/icon__arrow--right.svg';
import { ReactComponent as IconBelow } from '@ecp/common/src/assets/icon/icon__arrow--below.svg';

export const StyledButton = styled(FlexBox)`
  align-items: center;
`;

const textButtonCss = css`
  gap: 6px;

  &:hover {
    color: ${({ hoverColor = theme.color.primary }) => hoverColor};
    background-color: ${({ hoverBackgroundColor = '' }) => hoverBackgroundColor};
    cursor: pointer;
  }
`;

const StyledTextButton = styled(StyledButton)`
  ${textButtonCss};
`;

const StyledIconBelow = styled(IconBelow).attrs(({ open, style }) => ({
  style: {
    ...style,
    transform: open ? 'rotate(180deg)' : 'none',
  },
}))`
  transition: transform 0.3s;
`;

export const TextButton = React.forwardRef(({ type, textProps, customIcon: Icon, children, open, buttonYn, ariaLabel, ...props }, ref) => {
  const buttonRef = useRef(null);

  useImperativeHandle(ref, () => buttonRef.current);

  const textDefaultValues = {
    size: (type === 'summary' || type === 'summary-selected') ? `${theme.font.size.title}` : type === 'list' ? `${theme.font.size.small}` : `${theme.font.size.basic}`,
    color: type === 'summary-selected' ? `${color.hover.primary}` : ``,
    weight: theme.font.weight.medium,
  };

  return (
    <ButtonUnstyled rootComponent={StyledTextButton} {...props} ref={buttonRef}>
      {type === 'custom-left' && <Icon />}
      {buttonYn === 'Y' ? (
        <TextBoxButton {...textDefaultValues} {...textProps} aria-label={ariaLabel}>
          {children}
        </TextBoxButton>
      ) : (
        <TextBox {...textDefaultValues} {...textProps}>
          {children}
        </TextBox>
      )}
      {type === 'move' ? (
        <IconRight width={'5px'} height={'8px'} />
      ) : type === 'list' ? (
        <StyledIconBelow width={'8px'} height={'5px'} open={open} />
      ) : type === 'custom' ? (
        <Icon />
      ) : null}
    </ButtonUnstyled>
  );
});

TextButton.displayName = 'TextButton';

TextButton.propTypes = {
  type: PropTypes.oneOf(['basic', 'move', 'list', 'custom', 'custom-left', 'summary', 'summary-selected']),
  textProps: PropTypes.object,
  customIcon: PropTypes.elementType,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  open: PropTypes.bool,
  buttonYn: PropTypes.oneOf(['Y']),
};

TextButton.defaultProps = {
  type: 'basic',
};
