import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '@ecp/common/src/style/theme/default';

import { ReactComponent as starRatingEmpty } from '@ecp/common/src/assets/icon/star-rating-empty.svg';
import { ReactComponent as starRatingFill } from '@ecp/common/src/assets/icon/star-rating-fill.svg';

const StarRatingEmptyIcon = styled(starRatingEmpty).attrs({
  viewBox: '0 0 12 12',
})``;
const StarRatingFillIcon = styled(starRatingFill).attrs({
  viewBox: '0 0 12 12',
})`
  fill: ${({ fill }) => fill};
`;

const StarRatingIcon = ({ size, fillPercent, fill }) => {
  return (
    <StarRatingIconWrapper>
      <StarRatingFillIconWrapper fillPercent={fillPercent}>
        <StarRatingFillIcon height={size} width={size} fill={fill} />
      </StarRatingFillIconWrapper>
      <StarRatingEmptyIcon height={size} width={size} />
    </StarRatingIconWrapper>
  );
};

const StarRatingIconWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
`;

const StarRatingFillIconWrapper = styled.div`
  width: ${({ fillPercent }) => (fillPercent ? `${fillPercent}%` : 0)};
  height: fit-content;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
`;

StarRatingIcon.displayName = 'StarRatingIcon';

StarRatingIcon.propTypes = {
  fillPercent: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

StarRatingIcon.defaultProps = {
  fillPercent: 0,
  size: 0,
  fill: theme.color.primary,
};

export default StarRatingIcon;
