import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';

import { sizePropTypes } from '../../style/propTypes';
import { overrideProperties } from '../../style/utils';
import { LabelComponent, Radio, InputRadio } from '../radio/Radio';
import { radioClassNames } from '../unstyled/input/RadioUnstyled';

const Tab = (props) => <Radio labelComponent={TabLabelComponent} hideControl {...props} />;

Tab.displayName = 'Tab';

export const TabLabelComponent = styled(LabelComponent)`
  flex: 1;
  max-width: ${({ width = '100%' }) => width};
  ${overrideProperties(sizePropTypes, {
    height: '100%',
  })};
`;

Tab.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hideControl: PropTypes.bool,
};

const StyledTabButton = styled(FlexBoxColumn)`
  justify-content: center;
  align-items: center;
  ${overrideProperties(sizePropTypes, {
    height: '100%',
  })};
  font-weight: ${theme.font.weight.bold};
  color: ${theme.color.secondary};
  background: ${theme.color.background.sub2};
  border: 1px solid ${theme.color.line.background};
  border-bottom: none;
  cursor: pointer;
  box-sizing: border-box;
  margin-left: -1px;
  padding-left: 1px;

  &:after {
    position: absolute;
    height: 1px;
    bottom: 0;
    left: -1px;
    right: -1px;
    content: '';
    background-color: ${theme.color.line.emphasis};
    z-index: 2;
  }

  &.${radioClassNames.checked} {
    color: ${theme.color.text.basic};
    background: ${theme.color.background.white};
    border: 1px solid ${theme.color.line.emphasis};
    border-bottom: none;
    z-index: 1;

    &:after {
      display: none;
    }
  }
`;
export const StyledEmptyTab = styled.div`
  height: 100%;
  flex: 1;
  border: none;
  border-bottom: 1px solid ${theme.color.line.emphasis};
  box-sizing: border-box;
`;

export const DefaultTabButton = (props) => <StyledTabButton {...props} />;

DefaultTabButton.displayName = 'DefaultTabButton';
export default Tab;

export const InputTab = (props) => <InputRadio labelComponent={TabLabelComponent} hideControl {...props} />;

InputTab.displayName = 'InputTab';

InputTab.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hideControl: PropTypes.bool,
};
