import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import WpayPaymentAuthForm from '@ecp/common/src/components/interface/WpayPaymentAuthForm';

const WpayPaymentAuthComp = ({ wpayApi, showAlertDialog }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [paymentAuthData, setPaymentAuthData] = useState({});
  const [orderInfo, setOrderInfo] = useState({});
  const [getParams, setGetParams] = useState(false);

  useEffect(() => {
    setGetParams(true);
    const redirectFromMemberReg = () => Boolean(state && state.oid);
    if (redirectFromMemberReg()) {
      setOrderInfo({ oid: state.oid, fromMemberReg: true });
    } else {
      setOrderInfo({
        oid: queryParams.get('oid'),
        goodsNameEncoded: encodeURIComponent(queryParams.get('goodsName')),
        goodsPrice: queryParams.get('goodsPrice'),
        buyerNameEncoded: encodeURIComponent(queryParams.get('buyerName')),
        buyerTel: queryParams.get('buyerTel'),
        buyerEmail: queryParams.get('buyerEmail'),
      });
    }
  }, [queryParams, state]);

  useEffect(() => {
    if (getParams) {
      wpayApi.getPaymentAuthParams(orderInfo).then(({ result, message }) => {
        if (message) {
          showAlertDialog(message, () => {
            navigate('/interface/wpay-member-reg', {
              state: {
                oid: orderInfo.oid,
              },
            });
          });
        } else {
          setPaymentAuthData(result);
        }
      });
    }
  }, [orderInfo, getParams, navigate, showAlertDialog, wpayApi]);

  return <WpayPaymentAuthForm paymentAuthData={paymentAuthData} />;
};

export default WpayPaymentAuthComp;

WpayPaymentAuthComp.propTypes = {
  wpayApi: PropTypes.object,
  showAlertDialog: PropTypes.func,
};
