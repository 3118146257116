import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { usePromiseTracker } from 'react-promise-tracker';

import useRouterScrollTop from '@ecp/common/src/hooks/useRouterScrollTop';

import { historyState } from '@fo-recoil/common/atom';
import DialogProvider from '@fo-components/common/provider/DialogProvider';

const RootRoute = () => {
  const setHistory = useSetRecoilState(historyState);
  const { pathname, search } = useLocation();

  const { promiseInProgress } = usePromiseTracker();

  const navigate = useNavigate();

  useRouterScrollTop(promiseInProgress);

  useEffect(() => {
    setHistory((state) => ({ prev: state?.current, current: pathname + search }));
  }, [pathname, search, setHistory]);

  useEffect(() => {
    //TODO 작업중. 전시url 전환 핸들링을 여기서 하거나
    if (pathname.startsWith('/good/')) {
      navigate(pathname, { replace: true });
    }
  }, [pathname]);

  return (
    <>
      <Outlet />
      <DialogProvider />
    </>
  );
};

export default RootRoute;
