import { useCallback } from 'react';

const useEventCallback = (callback, options = {}) => {
  const { stop = false, prevent = false, extractDataset = true } = options;

  return useCallback(
    (e, ...args) => {
      if (stop) {
        e?.stopPropagation?.();
      }

      if (prevent) {
        e?.preventDefault?.();
      }

      if (args[0] && args[0].valueType === 'array' && args[0].value) {
        callback(args[0].value, extractDataset ? e?.currentTarget?.dataset : e, ...args);
      } else {
        callback(e?.target?.value, extractDataset ? e?.currentTarget?.dataset : e, ...args);
      }
    },
    [stop, prevent, extractDataset, callback]
  );
};

export default useEventCallback;
