import React from 'react';
import PropTypes from 'prop-types';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import TextLink from '@ecp/common/src/text/TextLink';
import { TextBox } from '@ecp/common/src/text/TextBox';
import ContextMenuItem from '@ecp/common/src/components/contextmenu/ContextMenuItem';
import { ReactComponent as IconRight } from '@ecp/common/src/assets/icon/icon__arrow--right.svg';

const GnbHeaderBottomBrand = ({ brandList, handleBrandMenuClick }) => {
  return (
    <FlexBoxColumn gap="6px" align-items="flex-start" width="145px" height="100%" data-ds-label2={'g_brand'}>
      {brandList?.map((brand) => (
        <ContextMenuItem
          to={`/brand/${brand.brandNumber}`}
          key={brand.brandNumber}
          image={{
            url: brand.logoImageUrl,
            width: 30,
            height: 30,
            radius: '15px',
            border: '1px solid #DFDFDF',
            boxSizing: 'border-box',
            alt: '',
          }}
          onClick={handleBrandMenuClick}
        >
          <TextBox ellipsis width={'100px'}>
            {brand.brandName}
          </TextBox>
        </ContextMenuItem>
      ))}
      <Spacing top="6px" />
      <FlexBox width="100%" height="21px" justify-content="flex-end">
        <TextLink to="brand" size="13px" weight="500" gap="6px" onClick={handleBrandMenuClick}>
          전체보기
          <IconRight width="5px" height="8px" />
        </TextLink>
      </FlexBox>
    </FlexBoxColumn>
  );
};

export default GnbHeaderBottomBrand;

GnbHeaderBottomBrand.propTypes = {
  brandList: PropTypes.array,
  handleBrandMenuClick: PropTypes.func,
};
