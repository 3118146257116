import React, { useCallback, useMemo, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { clearAccessTokens } from '@ecp/common/src/utils/utils';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { NoneDecorationLink, StyledTextLink } from '@ecp/common/src/text/TextLink';
import ContextMenu from '@ecp/common/src/components/contextmenu/ContextMenu';
import ContextMenuItem from '@ecp/common/src/components/contextmenu/ContextMenuItem';
import StyledImg from '@ecp/common/src/components/image/StyledImg';
import { SwiperAdBannerRef } from '@ecp/common/src/components/swiper/SwiperTemplate';
import { MAIN_FUNCTION_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import { withCartBadgeCount } from '@fo-recoil/order/selector';
import { adBannerListState, employeeBenefitOpenState } from '@fo-recoil/main/atom';
import { withCommonMainFunctionUseQuery } from '@fo-recoil/main/selector';
import { myDeliveryPlaceListState } from '@fo-recoil/order/atom';
import { useConfirmDialog } from '@fo-hooks/common/useModal';
import useBanner from '@fo-hooks/display/useBanner';
import useSiteInfo from '@fo-hooks/common/useSiteInfo';
import authApi from '@fo-apis/common/authApi';
import { MainContentsBox } from '@fo-components/common/styled';
import BulkOrderPopupButton from '@fo-pages/home/footer/popup/bulkOrder/BulkOrderPopupButton';
import GnbIconButton from './GnbIconButton';
import GnbSearch from './search/GnbSearch';
import { ReactComponent as IconMyPage } from '@fo-assets/icon/icon__gnb-mypage.svg';
import { ReactComponent as IconOrder } from '@fo-assets/icon/icon__gnb-order.svg';
import { ReactComponent as IconCart } from '@fo-assets/icon/icon__gnb-cart.svg';
import { ReactComponent as IconCustomerService } from '@fo-assets/icon/icon__gnb-cs.svg';
import benefitIconPath from '@fo-assets/icon/icon__gnb-benefit.svg';

const GNB_LINK_URLS = {
  MY_PAGE: 'my-page',
  MEMBER: 'member',
  ORDER: 'my-page/order',
  CART: 'cart',
  CUSTOMER_SERVICE: 'customer-service',
};

const GnbHeader = React.forwardRef(({ searchPhraseList, searchPraseListIndex }, ref) => {
  const { showConfirmDialog } = useConfirmDialog();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    isMultimall,
    systemInformation: { pcGnbImgFilePath },
  } = useSiteInfo();

  const cartBadgeCount = useRecoilValue(withCartBadgeCount);
  const showEmployeeBenefit = useRecoilValue(withCommonMainFunctionUseQuery(MAIN_FUNCTION_CODE.EMPLOYEE_BENEFIT));
  const showAdBannerPopup = useRecoilValue(withCommonMainFunctionUseQuery(MAIN_FUNCTION_CODE.AD_BANNER));
  const setEmployeeBenefitOpen = useSetRecoilState(employeeBenefitOpenState);
  const resetDeliveryPlaceList = useResetRecoilState(myDeliveryPlaceListState);

  const logout = useCallback(async () => {
    await authApi.logout();
    clearAccessTokens();
    resetDeliveryPlaceList();
    navigate('/logout');
  }, [navigate, resetDeliveryPlaceList]);

  const handleLogoutClick = useCallback(() => {
    showConfirmDialog('로그아웃 하시겠습니까?', logout);
  }, [logout, showConfirmDialog]);

  const { isMyPage, isOrder, isCart, isCustomerService } = useMemo(
    () => ({
      isMyPage:
        pathname.endsWith(GNB_LINK_URLS.MEMBER) ||
        (!pathname.includes(GNB_LINK_URLS.ORDER) && pathname.includes(GNB_LINK_URLS.MY_PAGE)),
      isOrder: pathname.includes(GNB_LINK_URLS.ORDER),
      isCart: pathname.includes(GNB_LINK_URLS.CART),
      isCustomerService: pathname.includes(GNB_LINK_URLS.CUSTOMER_SERVICE),
    }),
    [pathname]
  );

  const handleEmployeeBenefitOpen = () => {
    setEmployeeBenefitOpen(true);
  };

  return (
    <Wrapper ref={ref}>
      <FlexBox>
        <NoneDecorationLink to={''}>
          <Logo data-ds-label2={'g_logo'} src={pcGnbImgFilePath} alt={'웰스토리몰'} />
        </NoneDecorationLink>
        <AdBox>{showAdBannerPopup && <AdBanner />}</AdBox>
        <GnbSearch searchPhraseList={searchPhraseList} searchPraseListIndex={searchPraseListIndex}/>
      </FlexBox>
      <FlexBox gap={'15px'}>
        {showEmployeeBenefit && (
          <BenefitIconWrapper>
            <IconBenefit onClick={handleEmployeeBenefitOpen} />
          </BenefitIconWrapper>
        )}
        <ContextMenu
          openWhenHovered
          placement={'bottom-end'}
          value={<MyPageMenuItems clickWhenLogout={handleLogoutClick} />}
        >
          <GnbIconButton
            iconType={IconMyPage}
            label={'마이페이지'}
            selected={isMyPage}
            to={GNB_LINK_URLS.MY_PAGE}
            data-ds-label2={'g_my_page'}
          />
        </ContextMenu>
        <GnbIconButton
          iconType={IconOrder}
          label={'주문내역'}
          selected={isOrder}
          to={GNB_LINK_URLS.ORDER}
          data-ds-label2={'g_order_history'}
        />
        <GnbIconButton
          showZero
          iconType={IconCart}
          badgeContent={cartBadgeCount}
          label={'장바구니'}
          selected={isCart}
          to={GNB_LINK_URLS.CART}
          data-ds-label2={'g_basket'}
        />
        <GnbIconButton
          iconType={IconCustomerService}
          label={'고객센터'}
          selected={isCustomerService}
          to={GNB_LINK_URLS.CUSTOMER_SERVICE}
          data-ds-label2={'g_cs'}
        />
        {!isMultimall && <BulkOrderPopupButton isIcon={true} />}
      </FlexBox>
    </Wrapper>
  );
});

GnbHeader.displayName = 'GnbHeader';
export default GnbHeader;

GnbHeader.propTypes = {
  searchPhraseList: PropTypes.array,
  searchPraseListIndex: PropTypes.number,
};

const MyPageMenuItems = (props) => {
  const { clickWhenLogout: handleLogoutClick } = props;
  return (
    <FlexBoxColumn gap="6px" width="100%" align-items="flex-start">
      <ContextMenuItem size={'13px'} to="/my-page">
        마이페이지
      </ContextMenuItem>
      <ContextMenuItem size={'13px'} to="/my-page/edit">
        내정보수정
      </ContextMenuItem>
      <StyledTextLink size={'13px'} decorationOnHover onClick={handleLogoutClick}>
        로그아웃
      </StyledTextLink>
    </FlexBoxColumn>
  );
};

MyPageMenuItems.propTypes = {
  clickWhenLogout: PropTypes.func,
};

const MAX_EXPOSURE_COUNT = 10;

const AdBanner = () => {
  const [adBannerList, setAdBannerList] = useRecoilState(adBannerListState);
  const { handleClick, dsLabel } = useBanner(
    MAIN_FUNCTION_CODE.AD_BANNER,
    adBannerList,
    setAdBannerList,
    MAX_EXPOSURE_COUNT
  );
  const swiperRef = useRef(null);
  const AdBannerMouseEnterHandler = () => {
    swiperRef.current.swiper.autoplay.stop();
  };
  const AdBannerMouseLeaveHandler = () => {
    swiperRef.current.swiper.autoplay.start();
  };
  return (
    <>
      {adBannerList?.length > 0 && (
        <SwiperAdBannerRef
          ref={swiperRef}
          data-ds-label2={dsLabel}
          itemList={adBannerList}
          onClick={handleClick}
          // onMouseEnter={AdBannerMouseEnterHandler}
          // onMouseLeave={AdBannerMouseLeaveHandler}
          onFocus={AdBannerMouseEnterHandler}
          onBlur={AdBannerMouseLeaveHandler}
          tabIndex={0}
        />
      )}
    </>
  );
};

const Wrapper = styled(MainContentsBox)`
  height: 50px;
  justify-content: space-between;
  min-width: 950px;

  .sticky & {
    padding-left: 180px;
  }
`;

const Logo = styled(StyledImg)`
  margin-right: 147px;
  width: 145px;
  height: 30px;

  .sticky & {
    display: none;
  }
`;

const AdBox = styled.div`
  width: 209px;
  height: 50px;
  margin-right: 20px;
  padding-left: 20px;
  visibility: visible;

  .sticky & {
    visibility: hidden;
    width: 0;
  }
`;

const BenefitIconWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 42px;
  padding-bottom: 12px;
  display: none;

  .sticky & {
    display: block;
  }
`;

const IconBenefit = styled.div`
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url('${benefitIconPath}');
  background-repeat: no-repeat;
  background-size: 30px;

  animation: benefit 3s cubic-bezier(0, 0, 1, 1) 1;

  @keyframes benefit {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(45deg);
    }
    20% {
      transform: rotate(-45deg);
    }
    30% {
      transform: rotate(30deg);
    }
    40% {
      transform: rotate(-30deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(-10deg);
    }
    70% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
