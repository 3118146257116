import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import toSafeInteger from 'lodash-es/toSafeInteger';

import Input, { inputBasePropTypes, StyledInput } from './Input';

const StyledDefaultNumberInput = styled(StyledInput)`
  text-align: right;
`;

export const NumberInputBase = React.forwardRef(
  (
    {
      value,
      format,
      onChange,
      onBlur,
      input: NumberInputRoot,
      inputComponent,
      max = Number.MAX_SAFE_INTEGER,
      ...props
    },
    ref
  ) => {
    const handleChange = (e) => {
      const v = toSafeInteger(e.target.value?.replace(/[^0-9.]/g, '') ?? 0);

      e.target.value = isNaN(v) ? 0 : Math.min(max, v);
      onChange?.(e);
    };

    const handleBlur = (e) => {
      const v = toSafeInteger(e.target.value?.replace(/[^0-9.]/g, '') ?? 0);

      onBlur?.({ ...e, target: { ...e.target, value: isNaN(v) ? 0 : Math.min(max, v) } });
    };

    const formattedValue = useMemo(
      () => (format ? Number(value).toLocaleString('ko-KR') : `${value}`),
      [value, format]
    );

    const empty = useMemo(() => formattedValue === '0', [formattedValue]);

    return (
      <NumberInputRoot
        inputComponent={inputComponent}
        value={formattedValue}
        empty={empty}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={ref}
        {...props}
      />
    );
  }
);

NumberInputBase.displayName = 'NumberInputBase';

NumberInputBase.propTypes = {
  ...inputBasePropTypes,
  input: PropTypes.elementType,
  format: PropTypes.bool,
  max: PropTypes.number,
};

NumberInputBase.defaultProps = {
  suffixText: '',
  placeholder: '',
  format: true,
  max: Number.MAX_SAFE_INTEGER,
};

const NumberInput = React.forwardRef((props, ref) => {
  return <NumberInputBase input={Input} inputComponent={StyledDefaultNumberInput} ref={ref} {...props} title="수량" />;
});

NumberInput.displayName = 'NumberInput';

NumberInput.propTypes = {
  ...inputBasePropTypes,
  format: PropTypes.bool,
  max: PropTypes.number,
};

NumberInput.defaultProps = {
  suffixText: '',
  placeholder: '',
  maxLength: 15,
  format: true,
  max: Number.MAX_SAFE_INTEGER,
};

export default NumberInput;
