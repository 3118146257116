export const zIndex = {
  dimWrapper: 500,
  dropdown: 1100,
  appBar: 1150,
  contextMenu: 1200,
  tooltip: 1200,
  bottomSheet: 1300,
  floating: 1300,
  popup: 1400,
  modal: 1400,
  dialog: 1450,
  toast: 1600,
  spinner: 2000,
};
