import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, VerticalDivider } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { LabelComponent } from '@ecp/common/src/components/swiper/component/SwiperController';
import useSwiperPaging from '@ecp/common/src/components/swiper/useSwiperPaging';
import { ReactComponent as IconRightPaging } from '@ecp/common/src/assets/icon/icon__arrow--right.svg';

import { numberOrStringType } from '../../../style/propTypes';

const Wrapper = styled(FlexBox)`
  position: absolute;
  right: 0;
  top: ${({ top }) => `-${top}`};
`;

const getPagingProps = ({ direction }) => {
  return {
    'border-radius': direction === 'left' ? '0 2px 2px 0' : '2px 0 0 2px',
    [`border-${direction}`]: 'none',
  };
};

const StyledPaging = styled(FlexBox)`
  justify-content: center;
  z-index: 2;
  background: none;
  content: '';
  cursor: pointer;
  width: 19px;
  height: 20px;
  border: 1px solid #dfdfdf;
  stroke: ${({ color }) => color ?? '#727272'};
  ${(props) => getPagingProps(props)};
`;

const IconLeftPaging = styled(IconRightPaging)`
  transform: scaleX(-1);
`;

const PagingPrev = (props) => (
  <StyledPaging direction="right" {...props}>
    <IconLeftPaging width="5px" heigth="8px" />
  </StyledPaging>
);

PagingPrev.displayName = 'PagingPrev';

PagingPrev.propTypes = {
  padding: numberOrStringType,
};
const PagingNext = (props) => (
  <StyledPaging direction="left" {...props}>
    <IconRightPaging width="5px" heigth="8px" />
  </StyledPaging>
);

PagingNext.displayName = 'PagingNext';

PagingNext.propTypes = {
  padding: numberOrStringType,
};

const SwiperPaging = (props) => {
  const { current, total, top = 0 } = props;

  const { handlePrev, handleNext } = useSwiperPaging(props);

  return (
    <Wrapper top={top} height="20px" align-items="flex-end">
      <LabelComponent current={current} total={total} size={theme.font.size.error} />
      <Spacing left={10} />
      <PagingPrev onClick={handlePrev} />
      <VerticalDivider color="#dfdfdf" />
      <PagingNext onClick={handleNext} />
    </Wrapper>
  );
};

SwiperPaging.propTypes = {
  top: numberOrStringType,
  current: numberOrStringType,
  total: numberOrStringType,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.func,
};

export default SwiperPaging;
