import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useScrollTop from '@ecp/common/src/hooks/useScrollTop';
import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import Dropdown from '@ecp/common/src/components/dropdown/Dropdown';
import Editor from '@ecp/common/src/components/editor/Editor';

const PolicyPaper = ({ items, content, dropdownValue, showHistory, onChange }) => {
  const { ref } = useScrollTop([content]);

  return (
    <PolicyPaperWrapper>
      <FlexBoxColumn width={'640px'}>
        {showHistory && (
          <FlexBox width={'100%'} justify-content={'flex-end'}>
            <Dropdown width={'200px'} items={items} onChange={onChange} value={dropdownValue} />
          </FlexBox>
        )}
        <Spacing top={10} />
        <EditorWrapper ref={ref}>
          <Editor readonly={true} html={content} />
        </EditorWrapper>
      </FlexBoxColumn>
    </PolicyPaperWrapper>
  );
};
const PolicyPaperWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const EditorWrapper = styled.div`
  width: 100%;
  height: 508px;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  border: 1px solid #dfdfdf;
  padding: 20px;
`;

PolicyPaper.propTypes = {
  items: PropTypes.array,
  content: PropTypes.string,
  dropdownValue: PropTypes.any,
  showHistory: PropTypes.bool,
  onChange: PropTypes.func,
};
export default PolicyPaper;
