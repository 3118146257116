import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { zeroOrStringType } from '@ecp/common/src/style/propTypes';

export const carouselItemNames = {
  before: 'carousel-item--selected-before',
  selected: 'carousel-item--selected',
  after: 'carousel-item--selected-after',
};

const SwiperSlideUnstyled = ({
  rootComponent: Root,
  index,
  onClick,
  current,
  total,
  children,
  width,
  height,
  item,
  tagGb,
  buttonComponent: Button,
  title,
}) => {
  const handleClick = (e) => {
    onClick(e, index, item);
  };

  const stateClasses = {
    [carouselItemNames.before]: index === (current - 1 + total) % total,
    [carouselItemNames.selected]: index === current,
    [carouselItemNames.after]: index === (current + 1 + total) % total,
  };

  return (
    <>
      {tagGb === 'button' ?
        (<Button width={width} height={height} type="button" title={title}>
          {React.cloneElement(children, {
            className: clsx({ [children.props.className]: true, ...stateClasses }),
            selected: index === current,
            onClick: handleClick,
          })}
        </Button>) :
        (<Root width={width} height={height}>
          {React.cloneElement(children, {
            className: clsx({ [children.props.className]: true, ...stateClasses }),
            selected: index === current,
            onClick: handleClick,
          })}
        </Root>)
      }
    </>
  );
};

SwiperSlideUnstyled.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  itemProps: PropTypes.object,
  onClick: PropTypes.func,
  current: PropTypes.number,
  total: PropTypes.number,
  width: zeroOrStringType,
  height: zeroOrStringType,
  children: PropTypes.node,
  isActive: PropTypes.bool,
  rootComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]).isRequired,
  imageComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  titleComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  subTitleComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  tagGb: PropTypes.oneOf(['button']),
  buttonComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]).isRequired,
  title: PropTypes.string,
};

SwiperSlideUnstyled.defaultProps = {
  index: 0,
  item: {},
  itemProps: {},
  onClick: () => {},
  current: -1,
  rootComponent: 'div',
  buttonComponent: 'button',
  imageComponent: 'img',
};

export default SwiperSlideUnstyled;
