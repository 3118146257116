import React, { useEffect } from 'react';

const InicisClosePage = () => {
  useEffect(() => {
    parent.INIStdPay.viewOff();
  }, []);

  return <></>;
};

export default InicisClosePage;
