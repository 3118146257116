import { useEffect, useRef, useState } from 'react';

const useSticky = (gap = 0) => {
  const stickyRef = useRef(null);
  const initialTop = useRef(0);
  const [sticky, setSticky] = useState(window.scrollY > initialTop.current);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > initialTop.current);
    };

    if (stickyRef) {
      initialTop.current = stickyRef.current.offsetTop + gap;
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [gap, stickyRef]);

  return { ref: stickyRef, sticky };
};

export default useSticky;
