import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NumberFormat } from '@ecp/common/src/utils/utils';
import useAssetPoint from '@ecp/common/src/hooks/asset/useAssetPoint';
import { FlexBox, FlexBoxColumn, FlexBoxSpaceBetween } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';

import { getIsMultimall } from '@fo-recoil/system/selector';
import couponApi from '@fo-apis/coupon/couponApi';
import myAssetPointApi from '@fo-apis/myPage/myAssetPointApi';
import { MembershipBadge } from '@fo-pages/myPage/membership/MembershipBadge';

const MembershipInfo = ({ gradeInfo, infoChanged, onClose }) => {
  const [assetSummary, setAssetSummary] = useState([]);
  const [myCouponCount, setMyCouponCount] = useState(0);

  const isMultimall = useRecoilValue(getIsMultimall);

  const { getSortedAssetSummary, getAssetUnit, createMoveAssetPointClickHandler } = useAssetPoint({
    myCouponCount,
  });

  const getMySummary = useCallback(async () => {
    const { result } = await myAssetPointApi.summary();
    setAssetSummary(getSortedAssetSummary(result));
  }, [getSortedAssetSummary]);

  const getMyCouponCount = useCallback(async () => {
    const { result } = await couponApi.getMyCouponListCount();
    setMyCouponCount(result);
  }, []);

  useEffect(() => {
    (async () => {
      await Promise.all([getMySummary(), getMyCouponCount()]);
    })();
  }, [getMyCouponCount, getMySummary, infoChanged]);

  const handleMembershipInfoClick = useCallback(
    (assetResourceDivisionCode) => {
      createMoveAssetPointClickHandler(assetResourceDivisionCode)();
      onClose();
    },
    [createMoveAssetPointClickHandler, onClose]
  );

  return (
    <FlexBoxColumn>
      {!isMultimall && (
        <GradeBadge>
          <MembershipBadge gradeName={gradeInfo?.memberGradeName ?? 'SILVER'} />
          <TextBox size={16} weight="700">
            {gradeInfo?.memberGradeName ?? 'SILVER'} 등급
          </TextBox>
        </GradeBadge>
      )}
      <Container>
        {assetSummary?.map(({ assetResourceDivisionCode, assetDivisionName, assetBalance }, idx) => (
          <AssetCategory
            key={assetResourceDivisionCode}
            onClick={() => handleMembershipInfoClick(assetResourceDivisionCode)}
          >
            <Spacing top={24} />
            <FlexBoxSpaceBetween
              width={'190px'}
              style={{
                paddingLeft: `${idx % 2 === 1 ? '14px' : 0}`,
                paddingRight: `${idx % 2 === 1 ? 0 : '14px'}`,
                borderRight: `${idx % 2 === 1 ? 0 : '1px solid #dfdfdf'}`,
              }}
            >
              <TextBox size={13} weight="350" height="140%">
                {assetDivisionName}
              </TextBox>
              <FlexBox>
                <TextBox size={18} weight="700" height="140%">
                  {NumberFormat(assetBalance ?? 0)}
                </TextBox>
                <Spacing right={4} />
                <TextBox size={14} weight="350" height="140%">
                  {getAssetUnit(assetResourceDivisionCode)}
                </TextBox>
              </FlexBox>
            </FlexBoxSpaceBetween>
            <Spacing bottom={17} />
          </AssetCategory>
        ))}
      </Container>
    </FlexBoxColumn>
  );
};

const Container = styled(FlexBox)`
  width: 380px;
  flex-wrap: wrap;
`;

const AssetCategory = styled(FlexBoxColumn)`
  width: 190px;
  border-bottom: 1px solid #dfdfdf;
  cursor: pointer;
`;

const GradeBadge = styled(FlexBox)`
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default MembershipInfo;

MembershipInfo.propTypes = {
  gradeInfo: PropTypes.object,
  infoChanged: PropTypes.bool,
  onClose: PropTypes.func,
};
