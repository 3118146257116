import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { systemInformationState } from '@fo-recoil/system/atom';
import {
  getIsMultimall,
  getLunchTime,
  getOperationTime,
  getIsEventMall,
  getIsMarketingUse,
  getDisplayPointName,
  getIsCartUse,
  getIsPriceOpen,
  getIsPointUse,
  getIsCouponMenuUse,
} from '@fo-recoil/system/selector';

const useSiteInfo = () => {
  const [systemInformation, setSystemInformation] = useRecoilState(systemInformationState);

  const getSystemInformationByKey = useCallback((key) => systemInformation[key], [systemInformation]);

  const operationTime = useRecoilValue(getOperationTime);
  const lunchTime = useRecoilValue(getLunchTime);
  const isMultimall = useRecoilValue(getIsMultimall);
  const isEventMall = useRecoilValue(getIsEventMall);
  const isMarketingUse = useRecoilValue(getIsMarketingUse);
  const displayPointName = useRecoilValue(getDisplayPointName);
  const isCartUse = useRecoilValue(getIsCartUse);
  const isPriceOpen = useRecoilValue(getIsPriceOpen);
  const isPointUse = useRecoilValue(getIsPointUse);
  const isCouponMenuUse = useRecoilValue(getIsCouponMenuUse);

  return {
    systemInformation,
    operationTime,
    lunchTime,
    getSystemInformationByKey,
    setSystemInformation,
    isMultimall,
    isEventMall,
    isMarketingUse,
    displayPointName,
    isCartUse,
    isPriceOpen,
    isPointUse,
    isCouponMenuUse,
  };
};

export default useSiteInfo;
