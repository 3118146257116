import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBoxAlignLeft } from '@ecp/common/src/text/TextBox';
import CouponBaseCard from '@ecp/common/src/components/coupon/components/CouponBaseCard';
import { COUPON_PROPERTY, COUPON_STYLE_PC } from '@ecp/common/src/const/coupon/couponConst';

const CategoryCouponCard = ({ messageValue, countValue, baseStyle, handleButtonClick }) => {
  const CardMessage = useMemo(() => {
    return (
      <FlexBoxColumn style={baseStyle.MESSAGE_STYLE} justify-content={'flex-start'} align-items={'flex-start'}>
        <TextBoxAlignLeft style={baseStyle.MESSAGE}>{`${messageValue} 카테고리`}</TextBoxAlignLeft>
        <TextBoxAlignLeft style={baseStyle.MESSAGE}>{'할인 쿠폰이 있어요'}</TextBoxAlignLeft>
        <Spacing top={10} />
      </FlexBoxColumn>
    );
  }, [baseStyle.MESSAGE, baseStyle.MESSAGE_STYLE, messageValue]);

  return (
    <CouponBaseCard
      baseStyle={baseStyle}
      iconImage={COUPON_PROPERTY.CATEGORY.iconImage}
      cardColor={COUPON_PROPERTY.CATEGORY.cardColor}
      buttonText={COUPON_PROPERTY.CATEGORY.buttonText}
      iconCountValue={countValue}
      handleButtonClick={handleButtonClick}
      altText={messageValue + '카테고리 할인 쿠폰이 있어요 ,' + COUPON_PROPERTY.CATEGORY.buttonText}
    >
      {CardMessage}
    </CouponBaseCard>
  );
};

CategoryCouponCard.propTypes = {
  messageValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  countValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  baseStyle: PropTypes.object,
  handleButtonClick: PropTypes.func,
};

CategoryCouponCard.defaultProps = {
  messageValue: '',
  countValue: '',
  baseStyle: COUPON_STYLE_PC,
};

export default CategoryCouponCard;
