import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

import theme from '@ecp/common/src/style/theme/default';
import { FlexBox, FlexBoxCenter } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { NoneDecorationLink } from '@ecp/common/src/text/TextLink';
import { TextBox } from '@ecp/common/src/text/TextBox';
//import { RoundButton } from '@ecp/common/src/components/button/RoundButton';
import { DivRoundButton } from '@ecp/common/src/components/button/DivRoundButton';
import ContextMenu from '@ecp/common/src/components/contextmenu/ContextMenu';
import ToggleMenuButton from '@ecp/common/src/components/toggle/ToggleMenuButton';
import { MAIN_FUNCTION_CODE } from '@ecp/common/src/const/display/mainTemplateConst';

import { withCommonMainFunctionUseQuery } from '@fo-recoil/main/selector';
import { employeeBenefitMessageState, employeeBenefitOpenState } from '@fo-recoil/main/atom';
import useBestBrandList from '@fo-hooks/brand/useBestBrandList';
import { MainContentsBox } from '@fo-components/common/styled';
import GnbHeaderBottomBrand from '@fo-pages/home/gnb/GnbHeaderBottomBrand';
import GnbCategory from './GnbCategory';
import { ReactComponent as IconBenefit } from '@fo-assets/icon/icon__gnb-benefit.svg';
import { ReactComponent as IconBenefitWhite } from '@fo-assets/icon/icon__gnb-benefit--white.svg';

const GnbHeaderBottom = ({ open, gnbList, onCategory, sticky }) => {
  const { pathname } = useLocation();
  const showEmployeeBenefit = useRecoilValue(withCommonMainFunctionUseQuery(MAIN_FUNCTION_CODE.EMPLOYEE_BENEFIT));
  const useBestBrand = useRecoilValue(withCommonMainFunctionUseQuery(MAIN_FUNCTION_CODE.BEST_BRAND));
  const [employeeBenefitMessage, setEmployeeBenefitMessage] = useRecoilState(employeeBenefitMessageState);
  const setEmployeeBenefitOpen = useSetRecoilState(employeeBenefitOpenState);
  const { bestBrandList } = useBestBrandList();

  const [brandMenuOpen, setBrandMenuOpen] = useState(false);

  const handleBrandMenuOpenChange = useCallback((v) => {
    setBrandMenuOpen(v);
  }, []);

  const handleCloseBenefit = () => {
    setEmployeeBenefitMessage('');
  };

  const handleEmployeeBenefitOpen = () => {
    setEmployeeBenefitOpen(true);
  };

  const menuList = useMemo(
    () => gnbList.map((menu) => ({ ...menu, active: menu.link && pathname === menu.link })),
    [gnbList, pathname]
  );

  return (
    <Wrapper>
      <FlexBox>
        <GnbCategory open={open} onCategory={onCategory} sticky={sticky} />
        <Divider />
        <CategoryMenu data-ds-label2={'g_menu'}>
          {menuList.map(({ title, link, bold, dot, active, ...item }, index) => (
            <FlexBox key={`${title}-${index}`} width={'max-content'}>
              <MenuText
                to={link}
                $active={active}
                $bold={bold}
                $dot={dot}
                {...item}
                title={dot ? '최신내용이 있습니다.' : undefined}
              >
                {title}
              </MenuText>
              {link === '/brand' && useBestBrand && (
                <Spacing left={6}>
                  <ContextMenu
                    openWhenClicked
                    placement={'bottom-end'}
                    offset={10}
                    value={
                      <GnbHeaderBottomBrand
                        brandList={bestBrandList}
                        handleBrandMenuClick={() => handleBrandMenuOpenChange(false)}
                      />
                    }
                    open={brandMenuOpen}
                    onOpenChange={handleBrandMenuOpenChange}
                    rootProps={{ padding: '20px 14px 20px 20px' }}
                  >
                    <BrandArrowArea>
                      <ToggleMenuButton checked={brandMenuOpen} onChange={handleBrandMenuOpenChange} />
                    </BrandArrowArea>
                  </ContextMenu>
                </Spacing>
              )}
            </FlexBox>
          ))}
        </CategoryMenu>
        <Spacing right={36} />
      </FlexBox>
      {showEmployeeBenefit && (
        <BenefitButton data-ds-label2={'g_benefit'}>
          {employeeBenefitMessage ? (
            <FamilyButton
              inversion
              closeable
              iconType={IconBenefitWhite}
              onClose={handleCloseBenefit}
              onClick={handleEmployeeBenefitOpen}
            >
              <BenefitText>{employeeBenefitMessage}</BenefitText>
            </FamilyButton>
          ) : (
            <DivRoundButton iconType={IconBenefit} onClick={handleEmployeeBenefitOpen}>
              임직원 혜택
            </DivRoundButton>
          )}
        </BenefitButton>
      )}
    </Wrapper>
  );
};

GnbHeaderBottom.propTypes = {
  open: PropTypes.bool,
  gnbList: PropTypes.array,
  onCategory: PropTypes.func,
  sticky: PropTypes.bool,
};
export default GnbHeaderBottom;

const BrandArrowArea = styled(FlexBoxCenter)`
  cursor: pointer;
  height: 23px;
`;

const Wrapper = styled(MainContentsBox)`
  justify-content: space-between;
  height: 34px;
  user-select: none;
  background-color: ${theme.color.background.white};

  .sticky & {
    height: 0;
    visibility: hidden;
    min-width: 950px;
  }
`;

const RedDot = css`
  &::after {
    content: '';
    position: absolute;
    right: -6px;
    top: 0;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${theme.color.point.negative};
  }
`;

const MenuText = styled(NoneDecorationLink)`
  position: relative;
  font-size: 16px;
  cursor: pointer;
  background-color: ${theme.color.background.white};

  color: ${({ $active, color = theme.color.text.basic }) => ($active ? theme.color.text.selected : color)};
  font-weight: ${({ $bold }) => ($bold ? theme.font.weight.bold : theme.font.weight.medium)};
  ${({ $dot }) => $dot && RedDot};
`;

const Divider = styled.div`
  width: 1px;
  height: 18px;
  background-color: #cacaca;
  margin-left: 25px;
  margin-right: 36px;

  .sticky & {
    display: none;
  }
`;

const CategoryMenu = styled(FlexBox)`
  gap: 30px;
  flex: 1;

  .sticky & {
    display: none;
  }
`;

const scaleWidth = keyframes`
    from {transform: scaleX(0);}
    to {transform: scaleX(1);}
`;

const FamilyButton = styled(DivRoundButton)`
  width: max-content;
  justify-content: flex-end;
  animation: 0.3s ease-in-out ${scaleWidth};
  transform-origin: 100% 0 0;
  border: none;
`;

const BenefitText = styled(TextBox)`
  font-weight: ${theme.font.weight.medium};
  white-space: nowrap;
  width: fit-content;
`;

const BenefitButton = styled.div`
  position: absolute;
  right: 0;

  .sticky & {
    display: none;
  }
`;
