import React from 'react';
import { useRecoilCallback, useRecoilTransactionObserver_UNSTABLE } from 'recoil';

export let getRecoilExternalLoadable = () => () => null;
export let setRecoilExternalState = () => () => null;

const RecoilExternalStatePortal = () => {
  useRecoilTransactionObserver_UNSTABLE(({ snapshot }) => {
    getRecoilExternalLoadable = snapshot.getLoadable;
  });

  useRecoilCallback(({ set }) => {
    setRecoilExternalState = set;

    return async () => {};
  })();

  return <></>;
};

export default RecoilExternalStatePortal;
