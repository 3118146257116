import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SUCCESS } from '@ecp/common/src/const/wpayResCode';

const WpayMemberReturnComp = ({ showAlertDialog, wpayApi }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [firstRender, setFirstRender] = useState(false);

  const resultCode = searchParams.get('resultCode');
  const resultMsg = searchParams.get('resultMsg');
  const returnType = searchParams.get('TYPE');

  useEffect(() => {
    setFirstRender(true);
  }, []);

  const closeWpayPopup = () => {
    window.close();
  };
  const showMsgAndClose = useCallback(
    (msg) => {
      showAlertDialog(msg, () => {
        closeWpayPopup();
      });
    },
    [showAlertDialog]
  );

  useEffect(() => {
    if (!firstRender) return;

    if (resultCode !== SUCCESS) {
      showMsgAndClose(`${decodeURI(resultMsg).replaceAll('+', ' ')}`);
    } else if (returnType === 'UNREG') {
      wpayApi
        .delWpayMemberKey()
        .then(({ message = '' }) => {
          if (message) showMsgAndClose('웰스토리페이 해지 후처리 중 에러가 발생했습니다. 담당자에게 문의하세요');
          else showMsgAndClose('웰스토리페이 해지가 정상적으로 처리되었습니다.');
        })
        .catch(() => {});
    } else {
      const wpayMemRegReturn = {
        resultCode,
        resultMsg,
        wtid: searchParams.get('wtid'),
        userIdEnc: searchParams.get('userId'),
        wpayUserKeyEnc: searchParams.get('wpayUserKey'),
        ci: searchParams.get('ci'),
        signature: searchParams.get('signature'),
        oid: searchParams.get('oid'),
      };

      wpayApi.postMemReqReturnParams(wpayMemRegReturn).then(({ message = '' }) => {
        if (message) {
          showMsgAndClose(message);
        } else {
          if (wpayMemRegReturn.oid) {
            navigate('/interface/wpay-payment-auth', { state: { oid: wpayMemRegReturn.oid } });
          } else {
            navigate('/interface/wpay-my-page');
          }
        }
      });
    }
  }, [resultCode, firstRender, navigate, resultMsg, searchParams, showMsgAndClose, wpayApi, returnType]);

  return <></>;
};

export default WpayMemberReturnComp;

WpayMemberReturnComp.propTypes = {
  showAlertDialog: PropTypes.func,
  wpayApi: PropTypes.object,
};

WpayMemberReturnComp.displayName = 'WpayMemberReturnComp';
