import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ItemGroup = React.memo(({ items, onItemClick, ItemSlot }) =>
  items.map((item) => (
    <div key={item.key} onClick={() => onItemClick(item)}>
      <ItemSlot />
    </div>
  ))
);

ItemGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
    })
  ),
  ItemSlot: PropTypes.oneOfType([PropTypes.elementType, PropTypes.element, PropTypes.string]),
  onItemClick: PropTypes.func,
};
ItemGroup.displayName = 'Dropdown Item Group';

const SelectUnstyled = React.forwardRef(
  (
    {
      items,
      selectRoot: SelectRoot,
      itemSlot: ItemSlot,
      itemsMaxHeight,
      onItemClick,
      style,
      isFlip,
      selectedItems,
      ...props
    },
    ref
  ) => {
    return (
      <SelectRoot ref={ref} max-height={itemsMaxHeight} style={style} isFlip={isFlip} {...props}>
        {items.map((item) => (
          <ClickableItem
            key={item.key}
            onClick={() => !item.disabled && onItemClick(item)}
            disabled={item.disabled}
            onKeyDown={(e) => e.key == 'Enter' && !item.disabled && onItemClick(item)}
          >
            <ItemSlot {...item} id={item.key} selectedItems={selectedItems} />
          </ClickableItem>
        ))}
      </SelectRoot>
    );
  }
);

const ClickableItem = styled.div.attrs({ tabIndex: 0 })`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

SelectUnstyled.propTypes = {
  selectRoot: PropTypes.elementType,
  itemSlot: PropTypes.elementType,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  onItemClick: PropTypes.func,
  itemsMaxHeight: PropTypes.string,
  style: PropTypes.object,
  isFlip: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

SelectUnstyled.displayName = 'SelectUnstyled';

export default SelectUnstyled;
