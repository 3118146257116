import { useEffect } from 'react';

import { autoUpdate, useDismiss, useFloating, useFloatingNodeId, useInteractions } from '@floating-ui/react';

const usePopup = ({ open, onOpenChange, closeWhenOutside, onClose }) => {
  const nodeId = useFloatingNodeId();

  const { refs, context } = useFloating({
    nodeId,
    open,
    onOpenChange: (changedValue) => {
      onOpenChange?.(changedValue);
      !changedValue && onClose?.(changedValue);
    },
    whileElementsMounted: autoUpdate,
  });

  const propsList = [
    useDismiss(context, {
      outsidePress: !!closeWhenOutside,
      bubbles: {
        escapeKey: false,
        outsidePress: !!closeWhenOutside,
      },
    }),
  ];

  const { getFloatingProps } = useInteractions(propsList);

  useEffect(() => {
    if (open) {
      refs.floating.current?.focus?.();
    }
  }, [open, refs.floating]);

  return {
    nodeId,
    getPopupProps: getFloatingProps,
    refs,
  };
};

export default usePopup;
