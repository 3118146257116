import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FlexBox, FlexBoxColumn } from '@ecp/common/src/layouts/flex/styled';
import { Spacing } from '@ecp/common/src/layouts/Spacing';
import { TextBox } from '@ecp/common/src/text/TextBox';
import BenefitCard from '@ecp/common/src/components/employeeBenefit/BenefitCard';
import { BENEFIT_TYPE } from '@ecp/common/src/const/benefit/benefitConst';

import AllianceBannerSwiper from '@fo-components/employeeBenefit/AllianceBannerSwiper';
import CardBenefit from '@fo-pages/home/gnb/sideFloating/CardBenefit';

const AllBenefitTab = ({ handleDrawerClose }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (url) => {
      navigate(url);
      handleDrawerClose();
    },
    [handleDrawerClose, navigate]
  );

  return (
    <FlexBoxColumn width={'100%'} data-ds-label2={'g_benefit_p'} align-items={'flex-start'}>
      <Spacing top={20} />
      <BenefitContainer width={'100%'}>
        <FlexBox>
          <BenefitCard type={BENEFIT_TYPE.PRICE} handleClick={handleClick} height={'245px'} />
          <BenefitCard type={BENEFIT_TYPE.MEMBERSHIP} handleClick={handleClick} height={'245px'} />
        </FlexBox>
        <FlexBox>
          <BenefitCard type={BENEFIT_TYPE.COUPON} handleClick={handleClick} height={'281px'} />
          <BenefitCard type={BENEFIT_TYPE.REVIEW} handleClick={handleClick} height={'281px'} />
        </FlexBox>
        <FlexBox>
          <BenefitCard type={BENEFIT_TYPE.EXHIBITION} handleClick={handleClick} height={'263px'} />
          <BenefitCard type={BENEFIT_TYPE.EVENT} handleClick={handleClick} height={'263px'} />
        </FlexBox>
      </BenefitContainer>
      <Spacing top={30} />
      <TitleTexBox>카드 혜택</TitleTexBox>
      <SubTitleTextBox>임직원 분들께 드리는 카드 무이자 할부 혜택입니다.</SubTitleTextBox>
      <Spacing top={14} />
      <CardBenefit />
      <AllianceBannerSwiper handleDrawerClose={handleDrawerClose} />
    </FlexBoxColumn>
  );
};

export default AllBenefitTab;

AllBenefitTab.propTypes = {
  handleDrawerClose: PropTypes.func,
};

const BenefitContainer = styled(FlexBoxColumn)`
  border-top: 1px solid #dfdfdf;
`;

const TitleTexBox = styled(TextBox)`
  font-size: 21px;
  font-weight: 500;
  line-height: 160%;
`;

const SubTitleTextBox = styled(TextBox)`
  font-size: 15px;
  font-weight: 350;
  line-height: 160%;
`;
