import { useEffect, useState } from 'react';

const useMousePress = ({ ref }) => {
  const [pressed, setPressed] = useState(false);

  useEffect(() => {
    const refValue = ref?.current;

    const handleMouseDown = () => setPressed(true);
    const handleMouseUp = () => setPressed(false);

    refValue?.addEventListener('mousedown', handleMouseDown);
    refValue?.addEventListener('mouseup', handleMouseUp);
    refValue?.addEventListener('mouseout', handleMouseUp);

    return () => {
      refValue?.removeEventListener('mousedown', handleMouseDown);
      refValue?.removeEventListener('mouseup', handleMouseUp);
      refValue?.removeEventListener('mouseout', handleMouseUp);
    };
  }, [ref]);

  return pressed;
};

export default useMousePress;
