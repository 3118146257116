import { useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useNavigateChangeCallback = (callback) => {
  const location = useLocation();
  const lastPathname = useRef(location.pathname);

  const callbackHandler = useCallback((pathname, callback) => {
    if (lastPathname.current !== pathname) {
      callback();
      lastPathname.current = pathname;
    }
  }, []);

  useEffect(() => () => callbackHandler(location.pathname, callback), [callback, callbackHandler, location.pathname]);
};

export default useNavigateChangeCallback;
