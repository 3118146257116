import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { bestBrandListState } from '@fo-recoil/brand/atom';
import brandApi from '@fo-apis/good/brandApi';

const useBestBrandList = () => {
  const [bestBrandList, setBestBrandList] = useRecoilState(bestBrandListState);

  useEffect(() => {
    (async () => {
      if (!bestBrandList?.length) {
        const { result = [] } = await brandApi.getBestBrandList();
        setBestBrandList(result);
      }
    })();
  }, [bestBrandList?.length, setBestBrandList]);

  return { bestBrandList };
};

export default useBestBrandList;
