import { useMemo } from 'react';

const useCategoryFilter = ({
  currentCategory,
  largeCategoryList = [],
  middleCategoryList = [],
  smallCategoryList = [],
  detailCategoryList = [],
}) => {
  const filteredLargeCategory = useMemo(
    () =>
      largeCategoryList
        ?.filter(({ display }) => !!display)
        ?.filter(
          ({ categoryNumber }) =>
            !currentCategory?.categoryNumber?.large || currentCategory?.categoryNumber?.large === categoryNumber
        ),
    [largeCategoryList, currentCategory?.categoryNumber?.large]
  );

  const filteredMiddleCategory = useMemo(
    () =>
      middleCategoryList
        ?.filter(({ display }) => !!display)
        ?.filter(
          ({ categoryNumber, upperCategoryNumber }) =>
            currentCategory?.categoryNumber?.middle === categoryNumber ||
            currentCategory?.categoryNumber?.current === upperCategoryNumber
        ),
    [middleCategoryList, currentCategory?.categoryNumber]
  );

  const filteredSmallCategory = useMemo(
    () =>
      smallCategoryList
        ?.filter(({ display }) => !!display)
        ?.filter(
          ({ categoryNumber, upperCategoryNumber }) =>
            currentCategory?.categoryNumber?.small === categoryNumber ||
            currentCategory?.categoryNumber?.current === upperCategoryNumber
        ),
    [smallCategoryList, currentCategory?.categoryNumber]
  );

  const filteredDetailCategory = useMemo(
    () =>
      detailCategoryList
        ?.filter(({ display }) => !!display)
        ?.filter(
          ({ categoryNumber, upperCategoryNumber }) =>
            currentCategory?.categoryNumber?.detail === categoryNumber ||
            currentCategory?.categoryNumber?.current === upperCategoryNumber
        ),
    [detailCategoryList, currentCategory?.categoryNumber]
  );

  const getTypeOfTreeItem = (depthNumber) => {
    switch (depthNumber) {
      case 1:
        return {
          largeCategoryType: 'secondary',
          middleCategoryType: 'third',
          smallCategoryType: 'none',
          detailCategoryType: 'none',
        };
      case 2:
        return {
          largeCategoryType: 'primary',
          middleCategoryType: 'secondary',
          smallCategoryType: 'third',
          detailCategoryType: 'none',
        };
      case 3:
        return {
          largeCategoryType: 'primary',
          middleCategoryType: 'primary',
          smallCategoryType: 'secondary',
          detailCategoryType: 'third',
        };
      case 4:
        return {
          largeCategoryType: 'primary',
          middleCategoryType: 'primary',
          smallCategoryType: 'secondary',
          detailCategoryType: 'third',
        };
      default:
        return {
          largeCategoryType: 'default',
          middleCategoryType: 'none',
          smallCategoryType: 'none',
          detailCategoryType: 'none',
        };
    }
  };

  const getCategoryToBeChanged = (categoryNumber, depthNumber) => {
    switch (depthNumber) {
      case 1:
        return {
          large: categoryNumber,
          middle: '',
          small: '',
          detail: '',
          current: categoryNumber,
        };
      case 2:
        return {
          middle: categoryNumber,
          small: '',
          detail: '',
          current: categoryNumber,
        };
      case 3:
        return {
          small: categoryNumber,
          detail: '',
          current: categoryNumber,
        };
      case 4:
        return {
          detail: categoryNumber,
          current: categoryNumber,
        };
      default:
        return {
          large: '',
          middle: '',
          small: '',
          detail: '',
          current: '',
        };
    }
  };

  const getCategoryDepthNumber = (categoryNumber) => {
    if (!categoryNumber || !categoryNumber.current) return 0;

    if (categoryNumber.large === categoryNumber.current) return 1;
    else if (categoryNumber.middle === categoryNumber.current) return 2;
    else if (categoryNumber.small === categoryNumber.current) return 3;
    else if (categoryNumber.detail === categoryNumber.current) return 4;
    else return 0;
  };

  const categoryNumberToName = useMemo(() => {
    return [...largeCategoryList, ...middleCategoryList, ...smallCategoryList, ...detailCategoryList].reduce(
      (acc, elem) => {
        acc[elem.categoryNumber] = elem.categoryName;
        return acc;
      },
      {}
    );
  }, [detailCategoryList, largeCategoryList, middleCategoryList, smallCategoryList]);

  return {
    filteredLargeCategory,
    filteredMiddleCategory,
    filteredSmallCategory,
    filteredDetailCategory,
    categoryNumberToName,
    getTypeOfTreeItem,
    getCategoryToBeChanged,
    getCategoryDepthNumber,
  };
};

export default useCategoryFilter;
