import React, { useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';

import { FloatingOverlay } from '@floating-ui/react';

import { zIndex } from '@ecp/common/src/style/constant';
import { ReactComponent as TooltipCloseIcon } from '@ecp/common/src/assets/icon/tooltip__close.svg';

import ModalUnstyled from '../unstyled/floating/ModalUnstyled';
import { modalPlacementClassNames, modalTypeClassName } from '../unstyled/floating/useModal';
import { overrideProperties } from '../../style/utils';
import { backgroundPropTypes, maxSizePropTypes, paddingPropTypes, sizePropTypes } from '../../style/propTypes';

export const ModalWrapperStyle = styled.div`
  background-color: #ffffff;
  overflow-y: auto;

  &.${modalPlacementClassNames.right} {
    height: 100vh;
  }

  &.${modalPlacementClassNames.left} {
    height: 100vh;
  }

  &.${modalPlacementClassNames.top} {
    width: 100vw;
  }

  &.${modalPlacementClassNames.bottom} {
    width: 100vw;
  }

  &:focus-visible {
    outline: none;
  }
`;

export const ModalWrapper = styled(ModalWrapperStyle)`
  ${overrideProperties(maxSizePropTypes, { maxWidth: 'none', maxHeight: '740px' })}

  ${overrideProperties(paddingPropTypes, { padding: '30px' })}
  
  ${overrideProperties(backgroundPropTypes, { 'background-color': '#ffffff' })}
  
  overflow-y: ${({ overflowY = 'auto' }) => overflowY};

  &.center {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }
`;

export const getPositionProp = (names) => {
  if (names.includes('top')) {
    return { alignItems: 'start' };
  } else if (names.includes('bottom')) {
    return { alignItems: 'end' };
  } else if (names.includes('right')) {
    return { justifyItems: 'end' };
  } else if (names.includes('left')) {
    return { justifyItems: 'start' };
  }
  return { placeItems: 'center' };
};

const StyledModalRoot = React.forwardRef(
  ({ children, zIndex: zIndexProp, modalWrapper: ModalWrapper, isOpen, ...props }, ref) => {
    const rootRef = useRef(null);

    useImperativeHandle(ref, () => rootRef.current);

    const { className } = props;

    return (
      <RemoveScroll>
        <FloatingOverlay
          data-testid="floating-overlay"
          style={{
            zIndex: zIndexProp ?? zIndex.modal,
            display: 'grid',
            overflow: 'hidden',
            background: className.includes(modalTypeClassName.inner) ? 'transparent' : 'rgba(25, 25, 25, 0.8)',
            ...getPositionProp(className),
          }}
        >
          <ModalWrapper ref={rootRef} isOpen={isOpen} {...props}>
            {children}
          </ModalWrapper>
        </FloatingOverlay>
      </RemoveScroll>
    );
  }
);

StyledModalRoot.propTypes = {
  zIndex: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.arrayOf(PropTypes.string),
  modalWrapper: PropTypes.elementType,
  isOpen: PropTypes.bool,
};

StyledModalRoot.defaultProps = {
  modalWrapper: ModalWrapper,
};

StyledModalRoot.displayName = 'StyledModalRoot';

export const modalRootProps = (props) => {
  const rootPropsTypes = Object.keys({
    ...paddingPropTypes,
    ...maxSizePropTypes,
    ...sizePropTypes,
    ...backgroundPropTypes,
    overflowY: PropTypes.string,
  });
  return Object.keys(props).reduce((accumulator, key) => {
    if (rootPropsTypes.includes(key)) {
      return { ...accumulator, [key]: props[key] };
    }
    return accumulator;
  }, {});
};

const Modal = (props) => (
  <ModalUnstyled
    rootComponent={StyledModalRoot}
    rootProps={modalRootProps(props)}
    openWhenClicked
    {...props}
    {...{
      closeComponent: props.closeComponent ?? <DefaultCloseComponent onClick={() => props.onClose?.()} />,
    }}
  />
);

Modal.displayName = 'Modal';
Modal.propTypes = ModalUnstyled.propTypes;

export default Modal;

const DefaultCloseComponent = styled(TooltipCloseIcon)`
  border: none;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  position: inherit;
  top: 25px;
  right: 30px;
  cursor: pointer;
`;
